import React, { ReactElement, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { RouterTransitionContextProps } from '~/contexts/RouterTransitionContext';
import { withRouterTransitionContext } from '~/contexts/RouterTransitionContext';

import { UnalignedButton } from '~/components/atom/Button';
import TEST_ID from './index.testid';

export type SaveBarProps = {
  /** The number of changes that are pending saving */
  numberOfChanges?: number;

  /** Disable the save button (i.e. if submitting, or invalid fields) */
  disableSave: boolean;

  /** Show loading state */
  loading?: boolean;

  /** What to do when the user presses save */
  onSave: () => null | void | Promise<void>;

  /** What to do when the user presses cancel */
  onCancel: () => null | void;

  /** If given, the component will show this as an errormessage */
  saveErrorMessage?: string | null | JSX.Element;

  /** If the save bar opens up in a block component buttons move to the right and become green */
  inBlockComponent?: boolean;
};

type Props = SaveBarProps & { routerProps?: RouterTransitionContextProps };

const text = {
  saveButton: 'Opslaan',
  cancelButton: 'Annuleren',
  saveText: (numberOfChanges?: number): string =>
    `Je hebt${
      numberOfChanges == null || numberOfChanges === 0
        ? ''
        : ` ${numberOfChanges}`
    } gewijzigde items op deze pagina`,
};

const SaveBar: React.FCC<Props> = ({
  numberOfChanges,
  onSave,
  onCancel,
  disableSave,
  saveErrorMessage,
  routerProps,
  children,
  inBlockComponent,
  loading,
}) => {
  useEffect(() => {
    routerProps?.leaveHandler(false);

    return () => {
      routerProps?.leaveHandler(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let messageComponent: ReactElement | null = null;
  if (saveErrorMessage == null || saveErrorMessage === '') {
    messageComponent = (
      <MessageComponent data-testid="save-bar-changes-text">
        {text.saveText(numberOfChanges)}
      </MessageComponent>
    );
  } else {
    messageComponent = (
      <ErrorText data-testid="save-bar-error-text">
        {saveErrorMessage}
      </ErrorText>
    );
  }

  return (
    <Container $inBlockComponent={inBlockComponent}>
      <ButtonContainer>
        <UnalignedButton
          dataTestId={TEST_ID.SAVE_BAR_CANCEL_BUTTON}
          appearance="danger"
          ghost
          onClick={onCancel}
          label={text.cancelButton}
        />
        <UnalignedButton
          dataTestId={TEST_ID.SAVE_BAR_SAVE_BUTTON}
          appearance="secondary"
          disabled={loading || disableSave}
          onClick={onSave}
          loading={loading}
          label={text.saveButton}
        />
        {!inBlockComponent && messageComponent}
      </ButtonContainer>

      {children && <ChildrenContainer>{children}</ChildrenContainer>}
    </Container>
  );
};

const MessageComponent = styled.p<{}>(
  ({ theme }) => css`
    margin-left: ${theme.space('l')};
  `,
);

const ErrorText = styled.div<{}>`
  ${({ theme }) => css`
    color: ${theme.color('danger')};
    margin-left: ${theme.space('l')};
  `};
`;

const ButtonContainer = styled.div<{}>(
  ({ theme }) => css`
    height: 100%;
    display: flex;
    align-items: center;
    gap: ${theme.space('base')};
  `,
);

const ChildrenContainer = styled.div<{}>`
  /* This is to leave some space with the intercom */
  padding-right: 120px;
`;

type InBlockComponent = {
  $inBlockComponent?: boolean;
};

const Container = styled.div<InBlockComponent>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  ${({ theme }) => css`
    box-shadow: ${theme.getTokens().boxShadow.inverted};
    padding: ${theme.space('s')} ${theme.space('xxxl')};
  `};

  ${({ theme, $inBlockComponent }) => {
    if ($inBlockComponent) {
      return css`
        box-shadow: none;
        padding: ${theme.space('m')} 0 0 ${theme.space('m')};
        justify-content: flex-end;
      `;
    }
    return null;
  }};
`;

export default withRouterTransitionContext(SaveBar);
