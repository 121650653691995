import React from 'react';
import styled, { css } from 'styled-components';
import {
  BillingCycle,
  PlanFeatureFragment,
  PlanOptionFragment,
} from '~/graphql/types';
import Divider from '~/components/atom/Divider';
import JustificationContainer from '~/components/atom/JustificationContainer';
import {
  Heading3,
  Heading5,
  Small,
  Variant,
} from '~/components/atom/Typography';
import { asPriceString } from '~/util/money';
import Button from '~/components/atom/Button';
import Features from './components/Features';
import TEST_ID from './index.testid';
import { isNil } from 'ramda';
import HighlightedLabel from '~/components/atom/HighlightedLabel';
import type { SystemColorPalette } from '~/theme';

export type Props = {
  plan: PlanOptionFragment;
  isSelected?: boolean;
  billingCycle: BillingCycle;
  isCurrentPlan?: boolean;
  isCurrentBillingCycle?: boolean;
  onSelect: () => void;
  disabled?: boolean;
  highlightedCaveat?: null | number;
  features: Array<PlanFeatureFragment>;
  previousTier?: PlanOptionFragment;
};

const billingCycleLabel = {
  Yearly: ' p/j',
  Monthly: ' p/m',
};

const text = {
  currentPlan: 'Huidig abonnement',
  selectPlan: 'Selecteer',
  notAvailableForBilling: 'Niet beschikbaar',
};

const PRICE_CARD_WIDTH = 250;
const DIVIDER_WIDTH = '72px';
const PriceCard: React.FCC<Props> = ({
  dataTestId,
  isSelected,
  plan,
  billingCycle,
  onSelect,
  isCurrentPlan,
  isCurrentBillingCycle,
  disabled,
  highlightedCaveat,
  features,
  previousTier,
  ...rest
}) => {
  const price = plan.price[billingCycle];
  const shouldDisable = disabled || isNil(price);

  return (
    <Container
      data-testid={dataTestId}
      data-selected={isSelected}
      {...rest}
      direction="column"
      $isSelected={isSelected}
      padding={['l', 'base']}
      align="center"
      justification="space-between"
      $disabled={shouldDisable}
    >
      <JustificationContainer direction="column" align="center">
        <Heading3
          color={isSelected ? { group: 'primary' } : undefined}
          size="base"
        >
          {plan.name}
        </Heading3>
        <Divider
          color={
            isSelected ? { group: 'primary', variant: 'light' } : undefined
          }
          margin={['xxs', 'm', 'l', 'm']}
          width={DIVIDER_WIDTH}
        />
        <PriceContainer>
          <PriceHeader $color={isSelected ? 'primary' : 'text'}>
            {asPriceString(plan.price[billingCycle] ?? 0, true)}
            <Small inline>{billingCycleLabel.Monthly}</Small>
            {billingCycle === BillingCycle.Yearly &&
              !isNil(plan.price.Monthly) &&
              plan.price.Monthly !== 0 && (
                <OriginalPriceLabel>
                  {asPriceString(plan.price.Monthly, true)}
                </OriginalPriceLabel>
              )}
          </PriceHeader>
          <Heading5 variant={Variant.primary} align="center">
            {plan.description}
          </Heading5>
        </PriceContainer>
        <JustificationContainer direction="column" padding={[null, 'l']}>
          <Features
            items={features}
            previousTier={previousTier}
            highlightedCaveat={highlightedCaveat}
            showCaveats
          />
        </JustificationContainer>
      </JustificationContainer>

      <JustificationContainer margin={['m', null, null, null]}>
        {!isCurrentPlan || !isCurrentBillingCycle ? (
          <Button
            dataTestId={TEST_ID.SELECT_BUTTON}
            size="medium"
            label={text.selectPlan}
            appearance="primary"
            onClick={onSelect}
            ghost={!isSelected}
            disabled={shouldDisable}
          />
        ) : (
          <Heading5
            padding={['s', 'm']}
            color={{ group: 'primary', variant: 'light' }}
            style={{ textAlign: 'center' }}
            variant={Variant.primary}
            data-testid={TEST_ID.CURRENT_CHOICE_LABEL}
            margin={[null]}
          >
            {isNil(price) ? text.notAvailableForBilling : text.currentPlan}
          </Heading5>
        )}
      </JustificationContainer>
    </Container>
  );
};

const PriceHeader = styled.div<{ $color: keyof SystemColorPalette }>(
  ({ theme, $color }) => css`
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: ${theme.fontSize('xl')};
    font-weight: ${theme.fontWeight('semiBold')};
    color: ${theme.color($color)};
  `,
);

const PriceContainer = styled.div<{}>`
  position: relative;
`;

const OriginalPriceLabel = styled(HighlightedLabel)(
  ({ theme }) => css`
    position: absolute;
    top: -${theme.space('s')};
    right: -${theme.space('base')};
    text-decoration: line-through;
    font-size: ${theme.fs('m')};
  `,
);

const Container = styled(JustificationContainer)<{
  $isSelected?: boolean;
  $disabled?: boolean;
}>(
  ({ theme, $isSelected, $disabled }) => css`
    opacity: ${$disabled ? 0.5 : 1};
    pointer-events: ${$disabled ? 'none' : 'all'};
    user-select: none;
    background: ${$isSelected
      ? theme.color('white')
      : theme.color('tertiary', 'translucent')};
    border-radius: ${theme.getTokens().border.radius.m};
    width: ${PRICE_CARD_WIDTH}px;
    border: 1px solid
      ${$isSelected ? theme.color('primary', 'light') : 'transparent'};
    box-shadow: ${$isSelected ? theme.boxShadow('s') : 'none'};

    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;

    &:hover {
      transform: translateY(-5px);
      border: 1px solid ${theme.color('primary', 'light')};
      box-shadow: ${theme.boxShadow('s')};
      background: ${theme.color('white', 'base')};
    }
  `,
);

export default PriceCard;
