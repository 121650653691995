import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useTable, useSortBy, useFlexLayout } from 'react-table';
import { noop } from 'lodash';
import { ApolloError } from '@apollo/client';

import type {
  TableColumnDefinitions,
  TableHeaderCellType,
} from '~/components/bad/DataTables/types.flow';
import type { ReturnProps } from '~/components/bad/DataTables/useRowSelect';
import Catalog from '~/Catalog';
import { CHECKBOX_COLUMN_ACCESSOR } from '~/components/bad/DataTables/util/TableHeaderCell/checkbox';
import CheckboxHeaderCell from '~/components/bad/DataTables/components/CheckboxHeaderCell';
import { isEmptyObject } from '~/util/object';
import TEST_ID from './index.testid';
import { Header } from '~/components/bad/DataTables/styling';
import TableBody from '~/components/bad/DataTables/styling/Body';
import { ContactListData } from '../../util/composeContactListData';
import useSortSettings from '~/hooks/useSortSettings';
import DropdownListContainer from '~/components/molecule/Dropdown/components/DropdownListContainer';
import { MAIN_CONTENT_WIDTH } from '~/components/organism/NavigationFrame/constants';
import AddContactModal, { ContactsMetaType } from '../AddContactModal';
import BodyContent from './components/BodyContent';
import useViewportSize from '~/hooks/useViewportSize';
import { HeaderCell } from '~/components/bad/DataTables/components';

export type Props = {
  /** Table columns, column grouping limited to at most one level */
  columns: TableColumnDefinitions;
  /** Table data*/
  data: Array<ContactListData>;
  networkStatus: number;
  loading: boolean;
  error: ApolloError | boolean;
  onSuccessfulDelete: (contactId: string) => void;
  options?: {
    renderHeaderCell?: (
      key: string,
      column: TableHeaderCellType<any>,
      isLastColumn: boolean,
    ) => JSX.Element;
  };
  selectionProps?: ReturnProps;

  /* Specify the amount of rows that should show in loading state */
  loadingAmount?: number;

  contactsMeta: ContactsMetaType;

  /** Total amount of contact in the account */
  totalContactsInAccount: number | null;
};

const text = {
  invalidContactFilters:
    'Je hebt ongeldige filters geselecteerd. Ververs de pagina en wijzig de filterselectie.',
  queryError: Catalog.genericUnknownErrorMessage,
  noSearchResults: Catalog.noResults,
  selectAll: 'Selecteer alles',
  selectAllOnPage: 'Selecteer alles op pagina',
  addYourFirstContact: 'Voeg je eerste contact toe',
  emptyStateDescription:
    'Voeg bestaande contacten toe of genereer nieuwe leads via apps.',
  noContacts: 'Je hebt nog geen contacten',
};

const ContactListTable: React.FC<Props> = ({
  columns,
  data,
  error,
  loading,
  networkStatus,
  loadingAmount = 20,
  onSuccessfulDelete,
  options,
  selectionProps = {
    itemsSelected: [],
    allSelected: false,
    selectAllItemsOnPage: f => f,
    toggleRowSelect: () => {},
    checkIfSelected: () => false,
    selectAllItems: () => {},
    deselectAllItems: () => {},
  },
  totalContactsInAccount,
  contactsMeta,
  ...rest
}) => {
  const [sortSettings] = useSortSettings('contactSortSettings');
  const [showAddContactModal, setShowModal] = useState(false);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setHiddenColumns,
    toggleHideAllColumns,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      disableMultiSort: true,
      autoResetSortBy: false,
      autoResetHiddenColumns: false,
      getRowId: ({ id }) => id,
      manualSortBy: true,
      disableSortRemove: true,
      initialState: {
        sortBy: [sortSettings],
      },
    },
    useFlexLayout,
    useSortBy,
  );

  const viewportSize = useViewportSize();
  useEffect(() => {
    if (viewportSize.width > MAIN_CONTENT_WIDTH) {
      toggleHideAllColumns(false);
    } else {
      setHiddenColumns(['tags', 'leadScore', 'createdDate']);
    }
  }, [viewportSize.width, setHiddenColumns, toggleHideAllColumns]);

  const [showSelectOptions, setShowSelectOptions] = useState<boolean>(false);

  const {
    checkIfSelected = () => false,
    toggleRowSelect = noop,
    allSelected = false,
    selectAllItemsOnPage = (f, z) => ({ f, z }),
    selectAllItems = noop,
    itemsSelected = [],
    deselectAllItems = noop,
  } = selectionProps;
  const isSelectable = !isEmptyObject(selectionProps);

  const oneCheckboxSelected = rows.some(row => checkIfSelected(row.id));
  const headerCheckboxRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      {showAddContactModal && (
        <AddContactModal
          onClose={() => setShowModal(false)}
          onComplete={() => setShowModal(false)}
          contactsMeta={contactsMeta}
        />
      )}
      <Container {...getTableProps()} {...rest} data-testid={TEST_ID.CONTAINER}>
        <Header.Large>
          {headerGroups.map(headerGroup => {
            const headerGroupProps = { ...headerGroup.getHeaderGroupProps() };
            return (
              <StyledHeaderTr
                key={headerGroupProps.key}
                data-testid={TEST_ID.HEADER_ROW}
              >
                {headerGroup.headers.map((column, idx) => {
                  const isLastColumn = idx === headerGroup.headers.length - 1;
                  const headerCellKey = `header-${idx}`;

                  if (options && options.renderHeaderCell) {
                    return options.renderHeaderCell(
                      headerCellKey,
                      column,
                      isLastColumn,
                    );
                  }

                  if (column.id === CHECKBOX_COLUMN_ACCESSOR) {
                    return (
                      <div
                        {...column.getHeaderProps()}
                        key={`header-${column.id}`}
                      >
                        <CheckboxHeaderCell
                          key={`header-cell-${idx}`}
                          column={column}
                          value={allSelected ? true : oneCheckboxSelected}
                          minus={oneCheckboxSelected || allSelected}
                          isFocused={showSelectOptions}
                          onChange={() => {
                            if (itemsSelected.length > 0) {
                              deselectAllItems();
                            } else {
                              setShowSelectOptions(true);
                            }
                          }}
                          noRows={rows.length === 0}
                          ref={headerCheckboxRef}
                        />

                        <DropdownListContainer
                          dropdownListOpen={showSelectOptions}
                          onClickOutside={() => setShowSelectOptions(false)}
                          onClose={() => setShowSelectOptions(false)}
                          options={checkboxOptions}
                          onChange={({ option }) => {
                            switch (option.payload) {
                              case 'select-all-on-page':
                                selectAllItemsOnPage(
                                  data.map(item => item.id),
                                  !oneCheckboxSelected,
                                );
                                break;
                              case 'select-all':
                                if (allSelected) {
                                  deselectAllItems();
                                } else {
                                  selectAllItems(data.map(item => item.id));
                                }
                                break;
                            }

                            setShowSelectOptions(false);
                          }}
                          openerRef={headerCheckboxRef}
                        />
                      </div>
                    );
                  }

                  return (
                    <HeaderCell
                      key={headerCellKey}
                      column={column}
                      isLastColumn={isLastColumn}
                      deselectAllItems={deselectAllItems}
                    />
                  );
                })}
              </StyledHeaderTr>
            );
          })}
        </Header.Large>
        <TableBody.Standard.Outer>
          <BodyContent
            data={data}
            loading={loading}
            error={error}
            networkStatus={networkStatus}
            rows={rows}
            loadingAmount={loadingAmount}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            totalContactsInAccount={totalContactsInAccount}
            isSelectable={isSelectable}
            allSelected={allSelected}
            checkIfSelected={checkIfSelected}
            toggleRowSelect={toggleRowSelect}
            onAddContact={() => setShowModal(true)}
            onSuccessfulDelete={onSuccessfulDelete}
          />
        </TableBody.Standard.Outer>
      </Container>
    </>
  );
};

const checkboxOptions = [
  {
    key: 'select-all',
    label: text.selectAll,
    payload: 'select-all',
  },
  {
    key: 'select-all-on-page',
    label: text.selectAllOnPage,
    payload: 'select-all-on-page',
  },
];

const StyledHeaderTr = styled.div<{}>`
  white-space: nowrap;
  display: flex;
`;

type TableWithStyleProps = {
  onClick?: () => void;
};
const Container = styled.div<TableWithStyleProps>(
  ({ onClick, theme }) => css`
    cursor: ${onClick ? 'pointer' : 'default'};
    user-select: none;
    border-spacing: 0;
    border-radius: ${theme.getTokens().border.radius.m};
    box-shadow: ${theme.boxShadow('card')};
    border: 1px solid ${theme.color('tertiary', 'light')};
  `,
);

const defaultColumn = {
  minWidth: 20,
  width: 120,
  maxWidth: 200,
};

export default ContactListTable;
