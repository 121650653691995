import { isNil } from 'ramda';
import React from 'react';
import styled, { css } from 'styled-components';
import type { IconType } from '~/components/atom/Icon';
import Icon from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Label, Variant } from '~/components/atom/Typography';
import useTooltipLayer from '~/hooks/useTooltipLayer';
import type { ColorString } from '~/theme/System/types';

export type Props = {
  label: React.ReactNode;
  icon: IconType;
  color: ColorString;
  disabled?: {
    reason: string;
  };
};

const BlockComponentTile: React.FCC<Props> = ({
  dataTestId,
  label,
  icon,
  color,
  disabled,
  ...rest
}) => {
  const tooltipProps = useTooltipLayer({ tooltipMessage: disabled?.reason });

  return (
    <Container
      backgroundColor={
        disabled
          ? { group: 'tertiary', variant: 'translucent' }
          : { group: 'white' }
      }
      padding={['base']}
      direction="column"
      data-testid={dataTestId}
      align="center"
      justification="center"
      border={{ radius: 'base' }}
      gap="xxs"
      {...rest}
      $disabled={!isNil(disabled)}
      {...tooltipProps}
    >
      <BlockIcon name={icon} color={disabled ? { group: 'tertiary' } : color} />
      <JustificationContainer justification="end" align="center">
        <StyledLabel
          color={{ group: disabled ? 'tertiary' : 'text' }}
          fontWeight="regular"
          variant={Variant.primary}
          margin={[null]}
        >
          {label}
        </StyledLabel>
      </JustificationContainer>
    </Container>
  );
};

const StyledLabel = styled(Label)`
  text-align: center;
`;

const Container = styled(JustificationContainer)<{ $disabled: boolean }>(
  ({ theme, $disabled }) => css`
    width: 100px;
    height: 100px;
    cursor: pointer;
    transition: all 0.3s ease;

    ${$disabled &&
    css`
      cursor: not-allowed;
    `}

    &:hover {
      ${!$disabled &&
      css`
        scale: 1.1;
        box-shadow: ${theme.getTokens().boxShadow.cardHover};
      `}
    }
  `,
);

const BlockIcon = styled(Icon)(
  ({ theme }) => css`
    font-size: ${theme.fontSize('l')};
  `,
);

export default BlockComponentTile;
