const getVersion = (): Partial<{
  version: string;
  commit_hash: string;
  branch: string;
}> => {
  const dhVersionEl = document.getElementById('dh_version');
  if (dhVersionEl) {
    try {
      const parsed = JSON.parse(dhVersionEl?.innerHTML);

      if (parsed) {
        return Object.keys(parsed).reduce((acc, curr) => {
          acc[curr.toLowerCase()] = parsed[curr];
          return acc;
        }, {});
      }

      return {};
    } catch {
      return {};
    }
  }
  return {};
};

export default getVersion;
