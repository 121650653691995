import React from 'react';
import styled, { css } from 'styled-components';
import { animated, useTransition } from 'react-spring';
import Icon, { IconType } from '~/components/atom/Icon';
import { Heading4, Variant } from '~/components/atom/Typography';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Link from '~/components/molecule/Link';
import getPercentage from '../../utils/getPercentage';
import getStatisticsValue from '../../utils/getStatisticsValue';
import { Units } from '../..';
import type { ColorString } from '~/theme/System/types';

export type Props = {
  heading: React.ReactNode;
  icon?: IconType;

  /** Color for icon and progress bar */
  color: ColorString;

  /** Amount to consider job fully completed */
  total: number;

  /** Completed amount */
  value: number;

  /** In case the statistics leads to somewhere, we can set the path */
  linkTo?: string;

  unit: Units;
};

const StatisticsItem: React.FCC<Props> = ({
  heading,
  icon,
  total,
  value,
  color,
  unit,
  linkTo,
}) => {
  const percentage = getPercentage({ total, value });
  const statisticsValue = getStatisticsValue({ unit, total, value });

  const transitions = useTransition(percentage, {
    from: { width: `${value}%` },
    enter: { width: `${percentage}%` },
    leave: { width: `${percentage}%` },
    config: { mass: 1, tension: 300, friction: 40, clamp: true },
  });

  return (
    <div>
      <JustificationContainer justification="start" align="center">
        {icon && <StyledIcon color={color} name={icon} />}
        <StyledHeading
          whiteSpace="nowrap"
          margin={[null]}
          variant={Variant.primary}
        >
          {heading}
        </StyledHeading>
      </JustificationContainer>

      <ProgressContainer>
        {transitions(style => (
          <Progress $color={color} style={style} />
        ))}
      </ProgressContainer>

      {linkTo && value > 0 ? (
        <Link to={linkTo}>{statisticsValue}</Link>
      ) : (
        <span>{statisticsValue}</span>
      )}
    </div>
  );
};

const StyledHeading = styled(Heading4)<{}>`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledIcon = styled(Icon)<{}>(
  ({ theme }) => css`
    padding-right: ${theme.spacing('xxs')};
  `,
);

const BAR_HEIGHT = '8px';

const Progress = styled(animated.div)<{ $color: ColorString }>`
  height: ${BAR_HEIGHT};
  width: 0%;

  ${({ theme, $color }) => css`
    border-radius: ${theme.getTokens().border.radius.base};
    background-color: ${$color};
  `}
`;

const ProgressContainer = styled.div<{}>`
  height: ${BAR_HEIGHT};
  overflow: hidden;

  ${({ theme }) => css`
    width: 100%;
    margin: ${theme.spacing('xxs')} 0;
    border-radius: ${theme.getTokens().border.radius.base};
    background-color: ${theme.color('white', 'dark')};
  `}
`;

export default StatisticsItem;
