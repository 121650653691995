import React from 'react';
import styled, { css } from 'styled-components';
import { Heading1 } from '~/components/atom/Typography';
import TEST_ID from './index.testid';
import SearchInput from '../SearchInput';
import JustificationContainer from '~/components/atom/JustificationContainer';

type ButtonList = Array<
  | {
      key: string;
      node: React.ReactNode;
    }
  | undefined
>;

export type Props = {
  buttons?: ButtonList;

  /** The title to show in the header */
  title: string;

  /** What to call when the search input is filled in */
  onFilterChange?: (text: string) => void;
  searchInputPlaceholder?: string;

  extraButtons?: ButtonList;
};

const OverviewListHeader: React.FCC<Props> = ({
  title,
  buttons,
  dataTestId,
  searchInputPlaceholder,
  onFilterChange,
  extraButtons,
}) => (
  <JustificationContainer
    dataTestId={dataTestId || ''}
    direction="column"
    margin={['l', null, 'l', null]}
    width="100%"
  >
    <Heading1
      color={{ group: 'primary' }}
      dataTestId={TEST_ID.OVERVIEW_HEADER_TITLE}
      whiteSpace="nowrap"
    >
      {title}
    </Heading1>
    {(buttons || extraButtons || onFilterChange) && (
      <JustificationContainer
        border={{
          radius: 'm',
          width: 's',
          color: { group: 'tertiary', variant: 'light' },
        }}
        backgroundColor={{ group: 'white' }}
        width="100%"
        align="center"
        justification="space-between"
        direction="row"
        wrap="wrap"
        gap="m"
        padding={['xxs']}
      >
        {buttons && buttons.length > 0 && (
          <ButtonsContainer>
            {buttons.map(
              button =>
                button && (
                  <ButtonContainer key={button.key} data-testid={button.key}>
                    {button.node}
                  </ButtonContainer>
                ),
            )}
          </ButtonsContainer>
        )}

        {onFilterChange && (
          <StyledSearchInput
            onFilterChange={onFilterChange}
            placeholder={searchInputPlaceholder}
          />
        )}

        {extraButtons && extraButtons.length > 0 && (
          <ButtonsContainer>
            {extraButtons.map(
              button =>
                button && (
                  <ButtonContainer key={button.key} data-testid={button.key}>
                    {button.node}
                  </ButtonContainer>
                ),
            )}
          </ButtonsContainer>
        )}
      </JustificationContainer>
    )}
  </JustificationContainer>
);

const ButtonContainer = styled.div<{}>`
  display: inline-flex;
  vertical-align: center;

  ${({ theme }) => css`
    gap: ${theme.space('xxs')};
  `};
`;

const StyledSearchInput = styled(SearchInput)<{}>`
  display: inline-flex;
`;

const ButtonsContainer = styled.div<{}>`
  display: inline-flex;
  flex-wrap: wrap;
  ${({ theme }) => css`
    gap: ${theme.space('xxs')};
  `};
`;

export default OverviewListHeader;
