export type LoadedImageData = {
  src: string;
  intrinsicSize: { width: number; height: number };
};

const getImageSrcFromFileList = (
  fileList: FileList,
): Promise<LoadedImageData> =>
  new Promise((resolve, reject) => {
    // Check if there is at least one file and it's an image
    if (fileList.length > 0 && fileList[0].type.startsWith('image/')) {
      const file = fileList[0];
      const reader = new FileReader();

      // Once the file is read successfully, resolve the promise with the result
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target && typeof e.target.result === 'string') {
          const src = e.target.result;

          // Create an image element to get the intrinsic dimensions
          const img = new Image();
          img.src = src;

          img.onload = () => {
            const intrinsicSize = {
              width: img.naturalWidth,
              height: img.naturalHeight,
            };

            resolve({ src, intrinsicSize });
          };

          img.onerror = () => {
            reject('Failed to load image to determine intrinsic size.');
          };
        } else {
          reject('FileReader failed to provide a valid result.');
        }
      };

      // If an error occurs while reading, reject the promise
      reader.onerror = error => {
        reject(error);
      };

      // Read the file as a data URL
      reader.readAsDataURL(file);
    } else {
      reject('No valid image files found in the FileList.');
    }
  });

export default getImageSrcFromFileList;
