import React from 'react';
import styled, { css } from 'styled-components';
import { SystemSize } from '~/theme';
import arrayToCss from '~/util/arrayToCss';

type Justification = 'start' | 'end' | 'center' | 'stretch';

export type Props = {
  className?: string;
  css?: string | null;
  margin?: Array<SystemSize | null>;
  padding?: Array<SystemSize | null>;
  justification?: Justification;
  gap?: SystemSize;
};

const GridContainer: React.FCC<Props> = ({
  dataTestId,
  css,
  children,
  padding,
  margin,
  justification,
  gap,
  className,
}) => (
  <Container
    className={className}
    data-testid={dataTestId}
    css={css}
    $padding={padding}
    $margin={margin}
    $justification={justification}
    $gap={gap}
  >
    {children}
  </Container>
);

type ContainerProps = {
  $gap?: SystemSize;
  $padding?: Props['padding'];
  $margin?: Props['margin'];
  $justification?: Props['justification'];
};

const Container = styled.div<ContainerProps>(
  ({
    theme,
    $gap,
    $justification,
    $padding = [null, null, null, null],
    $margin = [null, null, null, null],
  }) => css`
    display: grid;

    padding: ${arrayToCss($padding, theme)};
    margin: ${arrayToCss($margin, theme)};

    gap: ${$gap ? theme.space($gap) : 'unset'};
    justify-items: ${$justification};
  `,
);

export default GridContainer;
