import React from 'react';
import AnimatedBlock from '~/components/atom/AnimatedBlock';
import SynchronisedMailboxes from '../../components/SynchronisedMailboxes';
import convertMailboxes from '~/util/converters/convertMailboxes';
import useCurrentUser from '~/hooks/useCurrentUser';
import { SynchroniseEntityType } from '~/components/page/External/Nylas/types';
import { Heading3 } from '~/components/atom/Typography';

const text = {
  integrationHeader: 'E-mailintegratie',
};

type Props = {
  mailboxes: Array<any>;
  startPolling: (pollInterval: number) => void;
  stopPolling: () => void;
  refetch: () => Promise<any>;
};

const SynchronisedMailboxesBlock: React.FCC<Props> = ({
  mailboxes,
  refetch,
  startPolling,
  stopPolling,
}) => {
  const me = useCurrentUser();

  const filteredMailboxes = mailboxes.filter(
    mailbox =>
      mailbox.__typename === 'UserMailbox' ||
      mailbox.__typename === 'UserMailboxAlias',
  );

  return (
    <AnimatedBlock>
      <Heading3 size="m">{text.integrationHeader}</Heading3>
      <SynchronisedMailboxes
        mailboxes={convertMailboxes(filteredMailboxes)}
        entityType={SynchroniseEntityType.User}
        entityId={me.id}
        mayEdit
        startPolling={startPolling}
        stopPolling={stopPolling}
        refetch={refetch}
      />
    </AnimatedBlock>
  );
};

export default SynchronisedMailboxesBlock;
