/**
 *
 * @param dataURL string - The dataURL we get from the canvas
 * @param fileName string - The name of the file
 * @returns File - A file that can be uploaded
 */
const dataURLToFile = (dataURL: string, fileName: string): File => {
  // Decode the base64 string
  const byteString = atob(dataURL.split(',')[1]);

  // Get the mime type from the data URL
  const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];

  // Create an ArrayBuffer and a view to store the binary data
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const intArray = new Uint8Array(arrayBuffer);

  // Convert the binary data to Uint8Array
  for (let i = 0; i < byteString.length; i++) {
    intArray[i] = byteString.charCodeAt(i);
  }

  // Create a Blob from the binary data
  const blob = new Blob([intArray], { type: mimeString });

  // Create a File from the Blob (specify the name)
  return new File([blob], fileName, { type: mimeString });
};

export default dataURLToFile;
