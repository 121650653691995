import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Resizable } from 're-resizable';
import Icon from '~/components/atom/Icon';
import {
  useSelected,
  useFocused,
  RenderElementProps,
  useSlate,
  ReactEditor,
} from 'slate-react';
import deserialize, { INVALID_IMAGE_URL } from './deserialize';
import serialize from './serialize';
import { Element, Transforms } from 'slate';
import ImageElement from './components/ImageElement';
import useCurrentUser from '~/hooks/useCurrentUser';
import useErrorReporter from '~/hooks/useErrorReporter';
import useAddToast from '~/hooks/useAddToast';
import {
  DhImageElement as DhImageType,
  ImageElement as ImageType,
} from '~/components/organism/PluginsEditor/types';
import TEST_ID from './index.testid';
import uploadPendingImageToStorage from '~/components/organism/PluginsEditor/utils/uploadPendingImageIntoStorage';

export type Props = RenderElementProps & {
  element: ImageType | DhImageType;
};

const text = {
  error: 'Fout: ',
  incorrectAttachmentError:
    'Afbeelding is niet correct geüpload. Probeer het opnieuw of verwijder de afbeelding.',
  imageDownloadError:
    'Afbeelding is niet correct gedownload. Verwijder de afbeelding en probeer het opnieuw.',
};

const Image: React.FCC<Props> = React.memo(
  ({ attributes, children, element }) => {
    const [width, setWidth] = useState(element.width || '');
    const [opacity, setOpacity] = useState(element.opacity);
    const { id: userId } = useCurrentUser();

    const errorReporter = useErrorReporter();
    const addToast = useAddToast();

    const [uploadFailed, setUploadFailed] = useState<boolean>(false);

    useEffect(() => {
      /**
       *  Reupload the pending images when an image is copy pasted inside the same editor
       *  or from an external editor. This way if the original image is deleted
       *  the pasted image does not get broken because it has a different inlineId & url
       */

      if (
        element.pending &&
        element.url &&
        !element.url.startsWith(INVALID_IMAGE_URL)
      ) {
        void uploadPendingImageToStorage({
          editor,
          element,
          url: element.url,
          path,
          userId,
          errorReporter,
          addToast,
        }).then(({ success }) => {
          if (!success) {
            setUploadFailed(true);
          }
        });
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [element.pending]);

    const editor = useSlate();
    const path = ReactEditor.findPath(editor, element);

    const selected = useSelected();
    const focused = useFocused();
    const active = selected && focused;

    const isLoading = !!element.loading || !!element.pending;

    const setNodeWidth = () => {
      Transforms.setNodes<Element>(
        editor,
        { width },
        {
          at: path,
          match: node => Element.isElement(node) && node.type === element.type,
        },
      );
    };

    const imgStyle = {
      ...element.attributes?.style,
      display: element.display,
      float: element.float,
    };

    // If the image url starts with [[inlineId:, it means the inlineId does not exist
    // in the attachments so the image url could not be replaced
    const isBrokenInlineImage = !!element.url?.startsWith('[[inlineId:');

    return (
      <span {...attributes} data-testid={TEST_ID.CONTAINER}>
        <Container contentEditable={false}>
          {(element.hasError || uploadFailed) && (
            <ErrorLabel>
              <Icon
                name="exclamation"
                strokeWidth={3}
                margin={[null, 'xxs', null, null]}
              />
              <strong>{text.error}</strong>{' '}
              {uploadFailed
                ? text.imageDownloadError
                : text.incorrectAttachmentError}
            </ErrorLabel>
          )}

          <Resizable
            size={{ width, height: '100%' }}
            onResizeStop={() => setNodeWidth()}
            onResize={(e, direction, ref) => setWidth(ref.offsetWidth)}
            lockAspectRatio
            style={{
              ...imgStyle,
              opacity: opacity,
              cursor: isLoading ? 'not-allowed' : 'pointer',
            }}
            data-testid={TEST_ID.RESIZABLE}
          >
            {isLoading && (
              <IconContainer>
                <Icon name="spinner" color={{ group: 'tertiary' }} />
              </IconContainer>
            )}

            <ImageElement
              src={element.url || undefined}
              element={element}
              type={element.type}
              width={width}
              onDragStart={() => {
                ReactEditor.blur(editor);
                setOpacity('40%');
              }}
              onDragEnd={() => setOpacity(undefined)}
              $active={active}
              $loading={isLoading}
              dataTestId={TEST_ID.IMAGE_ELEMENT}
              style={imgStyle}
              $hasError={
                (uploadFailed && !!element.pending) || isBrokenInlineImage
              }
            />
          </Resizable>
        </Container>

        {children}
      </span>
    );
  },
);

const Container = styled.span<{}>``;

const ErrorLabel = styled.span<{}>(
  ({ theme }) => css`
    display: block;
    color: ${theme.color('danger')};

    /* align the icon with the text */
    & svg {
      padding-top: ${theme.space('xxxs')};
    }
  `,
);

const IconContainer = styled.span<{}>`
  position: absolute;
  font-size: 2rem;
  left: 50%;
  top: 50%;
`;

export default {
  nodeName: 'IMG',
  renderComponent: props => <Image {...props} />,
  serialize,
  deserialize,
};
