import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from '~/components/molecule/Link';
import { ImgDefinition } from '~/graphql/types.client.flow';

import Catalog from '~/Catalog';
import { Address } from '~/graphql/types.client';
import Icon from '~/components/atom/Icon';
import Office from '~/components/atom/Illustration/components/Office';
import Button from '~/components/atom/Button';
import useUserRights from '~/hooks/useUserRights';
import useViewingModeProps from '~/hooks/useViewingModeProps';
import { Heading3 } from '~/components/atom/Typography';
import Card from '~/components/atom/Card';
import useUsers from '~/hooks/useUsers';
import { UserStatus } from '~/graphql/types';
import JustificationContainer from '~/components/atom/JustificationContainer';

const text = {
  noName: ' is niet ingevuld',
  officeDetailButton: 'Wijzig gegevens',
  officeUserButton: 'Beheer gebruikers',
  workingAreaButton: 'Defineer werkgebied',
  userInOffice: ' gebruikers actief in deze vestiging',
};
type Props = {
  name: string;
  id: string;
  img: ImgDefinition | null | undefined;
  address: Address | null;
  phone: string | null | undefined;
  email: string | null | undefined;
};
const OfficeOverviewCard: React.FC<Props> = ({
  name,
  id,
  img,
  address,
  phone,
  email,
}) => {
  const usersInOffice = useUsers({
    officeIds: [id],
    statuses: [
      UserStatus.Active,
      UserStatus.Pending,
      UserStatus.InvitedPending,
    ],
  });

  const viewingModeProps = useViewingModeProps();
  const { addition, city, houseNumber, postcode, street } = address || {};
  const { view: officeAdminMayView } = useUserRights({
    category: 'Offices',
    entity: { type: 'office', id },
  });
  const { view: accountAdminMayView } = useUserRights({
    category: 'Offices',
  });

  const mayView = officeAdminMayView || accountAdminMayView;

  const image = img ? (
    <OfficeImg data-testid="office-img" src={img.medium} />
  ) : (
    <NoOfficeImage>
      <OfficeIllustration />
    </NoOfficeImage>
  );
  const officeEmail = email ? (
    <div>
      <b>{email}</b>
    </div>
  ) : (
    <NoData>
      {Catalog.emailLabel} {text.noName}
    </NoData>
  );
  const officePhone = phone ? (
    <div>
      <b>{phone}</b>
    </div>
  ) : (
    <NoData>
      {Catalog.phoneLabel} {text.noName}
    </NoData>
  );

  const button = (
    <StyledButton
      size="medium"
      disabled={!mayView}
      ghost
      icon="pencil"
      dataTestId="office-detail-link"
      label={text.officeDetailButton}
      appearance="tertiary"
    />
  );
  const officeDetailsButton = mayView ? (
    <ButtonLink to={`/-/settings/offices/${id}`}>{button}</ButtonLink>
  ) : (
    button
  );

  const workingAreaButton = (
    <StyledButton
      size="medium"
      disabled={!mayView}
      icon="map"
      dataTestId="office-detail-link"
      label={text.workingAreaButton}
      appearance="tertiary"
    />
  );

  const workingAreaDetailsButton = mayView ? (
    <ButtonLink to={`/-/settings/offices/${id}/working-area`}>
      {workingAreaButton}
    </ButtonLink>
  ) : (
    workingAreaButton
  );

  return (
    <CardElement
      key={`${id}_office`}
      dataTestId="office-list-item"
      data-objectid={id}
    >
      {image}
      <Heading3 align="center" margin={[null]} dataTestId="office-name">
        {name}
      </Heading3>
      <AddressContainer data-testid="office-address">
        <div>
          {street} {houseNumber}
          {addition}
        </div>
        <div>
          {postcode} {city}
        </div>
      </AddressContainer>
      <div data-testid="office-phone" {...viewingModeProps}>
        {officePhone}
      </div>
      <div data-testid="office-email" {...viewingModeProps}>
        {officeEmail}
      </div>
      <UsersBlock>
        <UserIcon name="user" />
        <UserNumber data-testid="user-number">
          {usersInOffice.length} {text.userInOffice}
        </UserNumber>
      </UsersBlock>
      <JustificationContainer
        direction="column"
        gap="xxs"
        width="100%"
        margin={[null, null, 'xxs', null]}
      >
        {officeDetailsButton}
        {workingAreaDetailsButton}
        <ButtonLink to={`/-/settings/offices/${id}/users`}>
          <StyledButton
            size="medium"
            ghost
            icon="user"
            dataTestId="office-user-link"
            label={text.officeUserButton}
            appearance="tertiary"
          />
        </ButtonLink>
      </JustificationContainer>
    </CardElement>
  );
};

const OfficeImg = styled.img<{}>`
  border-radius: 100%;
  height: 160px;
  width: 160px;
`;

const NoOfficeImage = styled.div<{}>`
  position: relative;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
  width: 160px;
  ${({ theme }) => css`
    background: ${theme.color('white', 'dark')};
  `};
`;

const OfficeIllustration = styled(Office)<{}>`
  position: absolute;
  ${({ theme }) => css`
    color: ${theme.color('primary', 'light')};
  `};

  > svg {
    height: 80px;
    width: 80px;
  }
`;

const AddressContainer = styled.div<{}>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > div {
    ${({ theme }) => css`
      margin-bottom: ${theme.space('xxs')};
    `};
  }
`;

const UserNumber = styled.span<{}>(
  ({ theme }) => css`
    color: ${theme.color('text')};
    font-size: ${theme.fontSize('base')};
  `,
);

const NoData = styled.span<{}>`
  font-style: normal;
  opacity: 0.3;
`;

const StyledButton = styled(Button)<{}>`
  width: 100%;
`;

const ButtonLink = styled(Link)(
  () => css`
    width: 100%;
    text-decoration: none;
  `,
);

const UsersBlock = styled.div<{}>`
  display: flex;
  align-items: center;
`;

const CardElement = styled(Card)<{}>(
  ({ theme }) => css`
    transition: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 380px;

    & > div {
      align-items: center;
      margin: ${theme.space('xxs')} 0;
    }

    & > ${UsersBlock} {
      margin-bottom: ${theme.space('l')};
    }
  `,
);

const UserIcon = styled(Icon)<{}>`
  ${({ theme }) => css`
    color: ${theme.color('success')};
    margin-right: ${theme.space('xxs')};
    margin-bottom: ${theme.space('xxxs')};
  `};
`;

export default OfficeOverviewCard;
