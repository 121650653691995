import Color from 'color';
import React from 'react';
import { useTheme } from 'styled-components';
import MarkerDefinition from '~/components/atom/MarkerDefinition';

export type Props = {
  dataTestId?: string;
};

export const MARKER_END_DISABLED = 'url(#disabled)';

const BASE_EDGE_COLOR = '#b1b1b7';
export const DISABLED_EDGE_COLOR = Color(BASE_EDGE_COLOR)
  .alpha(0.5)
  .rgb()
  .string();

export const generateMarkerEndId = (color: string): string =>
  `markerEnd-${color}`;

const MarkerDefinitions: React.FCC<Props> = React.memo(() => {
  const theme = useTheme();
  const portalColors = theme.getTokens().colors.portals;

  return (
    <svg>
      <defs>
        {portalColors.map((hexCode, index) => (
          <MarkerDefinition
            key={hexCode + index}
            id={generateMarkerEndId(hexCode)}
            color={hexCode}
          />
        ))}

        <MarkerDefinition id="disabled" color={DISABLED_EDGE_COLOR} />
        <MarkerDefinition id="arrow" color={theme.color('primary', 'dark')} />
      </defs>
    </svg>
  );
});

export default MarkerDefinitions;
