import React from 'react';
import CallToActionBlock from '~/components/organism/CallToActionBlock';
import useWizard from '~/hooks/useWizard';
import { SystemSize } from '~/theme';

const text = {
  skipThisStep: 'Sla deze stap over',
};

export type Props = {
  onSkipStep?: () => void;
  description: string;
  margin?: Array<SystemSize | null>;
};

const SkipStepButtonBlock: React.FCC<Props> = ({
  dataTestId,
  description,
  onSkipStep,
  margin,
}) => {
  const wizardApi = useWizard();

  return (
    <CallToActionBlock
      description={description}
      button={{
        label: text.skipThisStep,
        appearance: 'primary',
        icon: 'arrowRight',
        onClick: () => {
          onSkipStep && onSkipStep();
          wizardApi.next();
        },
      }}
      icon={{ name: 'triangle', background: { group: 'warning' } }}
      margin={margin}
      padding={['base']}
      dataTestId={dataTestId}
      align="center"
      width="100%"
    />
  );
};

export default SkipStepButtonBlock;
