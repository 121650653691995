import React, { useCallback, useImperativeHandle, useRef } from 'react';
import styled, { css } from 'styled-components';
import TEST_ID from './index.testid';
import TextButton from '~/components/atom/TextButton';

import type { Size } from '~/styles/constants';

const text = {
  uploading: 'Uploaden...',
  uploadFile: 'Upload een bestand',
};

export type Props = {
  onUpload: (files: FileList) => void;
  uploading: boolean;
  disabled?: boolean;
  size?: Size;
};

const ImageUploadTextButton = React.forwardRef<HTMLInputElement, Props>(
  ({ onUpload, uploading, disabled, size = 'large' }, ref) => {
    const fileUploader = useRef<HTMLInputElement | null>(null);

    // Keep track of the fileUploader element <input type="file" />
    // so we can reset it externally
    useImperativeHandle(ref, () => fileUploader.current as HTMLInputElement);

    const _onUpload = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
          onUpload(e.target.files);
        }
      },
      [onUpload],
    );

    return (
      <Container data-testid={TEST_ID.CONTAINER}>
        <StyledTextButton
          padding={[null]}
          onClick={() => {
            if (disabled) return;
            fileUploader.current?.click();
          }}
          disabled={uploading || disabled}
          label={uploading ? text.uploading : text.uploadFile}
          icon="upload"
          size={size}
          appearance="secondary"
          dataTestId={TEST_ID.UPLOAD_BUTTON}
        />

        <input
          hidden
          ref={fileUploader}
          type="file"
          accept="image/*"
          onChange={_onUpload}
          name="text-button-image-upload-input"
        />
      </Container>
    );
  },
);

const Container = styled.div<{}>`
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    font-weight: ${theme.fw('semiBold')};
    margin-bottom: ${theme.space('m')};
    ${theme.mq.lessThan('tabletLandscape')`
      justify-content: center;
    `}
  `}
`;

const StyledTextButton = styled(TextButton)<{}>(
  () => css`
    text-decoration: none;
  `,
);

export default ImageUploadTextButton;
