import type { QueriedActivity } from '../../types';

const highlightActivity = (activity: QueriedActivity) => {
  if (!('highlighted' in activity)) {
    return activity;
  }
  return { ...activity, highlighted: true };
};

export default highlightActivity;
