import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import TEST_ID from './index.testid';
import Button from '~/components/atom/Button';
import Icon from '~/components/atom/Icon';
import ImageOrPlaceholder from './components/ImageOrPlaceholder';
import useMeasure from '~/hooks/useMeasure';
import JustificationContainer from '~/components/atom/JustificationContainer';
import type { IllustrationType } from '~/components/atom/Illustration';
import { isNil } from 'ramda';

export type Props = {
  /** Depends on this prop we set the default icon either user icon or office */
  illustration?: IllustrationType;

  /** Image source URI */
  src?: string | null;

  /** Show Upload button or not */
  withUpload?: boolean;

  /** Action. I.e. upload image to server */
  onClick?: () => void;

  /** Show loading spinner */
  loading?: boolean;
  simpleImage?: boolean;
  imagePlaceholderText?: string;

  showDeleteButton?: boolean;
  onDelete?: () => void;

  /**
   * Is the current avatar editable, if not don't show labels
   */
  edit?: boolean;
};

export const text = {
  change: (
    <>
      wijzig
      <br />
      afbeelding
    </>
  ),
  deleteFile: 'Verwijderen',
};

const Avatar: React.FCC<Props> = ({
  src,
  onClick,
  loading,
  withUpload,
  simpleImage,
  imagePlaceholderText,
  showDeleteButton,
  onDelete,
  illustration = 'user',
  edit,
  ...rest
}) => {
  const { ref, bounds } = useMeasure();
  const theme = useTheme();

  return (
    <Container
      $isLoading={loading}
      $simpleImage={simpleImage}
      ref={ref}
      // To keep the icon square we let the width scale automatically and adjust the height to match it.
      $height={bounds.width}
      $withBorder={isNil(src)}
      onClick={onClick}
      align="center"
      justification="center"
      {...rest}
    >
      <JustificationContainer
        direction="column"
        width="100%"
        justification="center"
        align="center"
        className="inner"
      >
        <ImageOrPlaceholder
          illustration={{
            name: illustration,
            color: theme.color('tertiary', 'dark'),
          }}
          src={src}
          simpleImage={simpleImage}
          imagePlaceholderText={imagePlaceholderText}
          edit={edit}
        />
      </JustificationContainer>

      {showDeleteButton && withUpload && (
        <StyledButton
          onClick={event => {
            event.stopPropagation();
            onDelete && onDelete();
          }}
          dataTestId={TEST_ID.DELETE_BUTTON}
          icon="trashcan"
          label={text.deleteFile}
        />
      )}

      <LoadingIcon
        name="spinner"
        className="loading-element"
        color={{ group: 'white' }}
      />
    </Container>
  );
};

Avatar.defaultProps = {
  loading: false,
  withUpload: false,
};

type ContainerProps = {
  $isLoading?: boolean;
  $simpleImage?: boolean;
  $height?: number;
  $withBorder?: boolean;
};

const Container = styled(JustificationContainer)<ContainerProps>(
  ({ theme, $simpleImage, $isLoading, $height, $withBorder }) => css`
    overflow: hidden;
    width: 100%;
    max-width: calc(${theme.space('xxxxl')} * 2);
    height: ${$height}px;

    position: relative;
    user-select: none;
    cursor: pointer;

    border-radius: ${$simpleImage
      ? theme.getTokens().border.radius.base
      : theme.getTokens().border.radius.full};

    border: ${$withBorder
      ? `1px solid ${theme.color('tertiary', 'dark')}`
      : 'none'};
    background-color: ${theme.color('tertiary', 'base')};

    ${$isLoading &&
    css`
      img {
        filter: blur(3px);
      }
      .profile-placeholder {
        filter: blur(3px);
      }
      .uploading-section {
        display: none;
      }
      .loading-element {
        display: flex;
      }
    `};
  `,
);

const LoadingIcon = styled(Icon)<{}>`
  font-size: 3em;
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  user-select: none;
`;

const StyledButton = styled(Button)(
  ({ theme }) => css`
    position: absolute;
    bottom: ${theme.space('s')};
  `,
);

export default Avatar;
