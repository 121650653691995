import React, { forwardRef } from 'react';
import type { ComponentLevel, InputAppearance } from '~/styles/constants';
import useInputValue from '~/hooks/useInputValue';
import InputLabel from '~/components/atom/InputLabel';
import InputContainer from '~/components/atom/InputContainer';
import InputElement, {
  type BaseInputProps,
} from '~/components/atom/InputElement';
import type { ValidationFunction } from '~/util/getValidationErrors';
import Indicator from './components/Indicator';
import styled from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';

export type Props = BaseInputProps & {
  /** Input label. Becomes an error label if there is a validation error */
  label?: string;

  /** Input appearance */
  appearance?: InputAppearance;

  /** Width of the container */
  width?: string;

  /** Internal validation */
  validation?: Array<ValidationFunction>;

  /** External validation */
  externalErrors?: Array<string>;

  /** Shows a spinner on the right side */
  loading?: boolean;

  /** Determines input label and indicator colour */
  level?: ComponentLevel;
};

/**
 * Same as molecule/Input component. Includes the status indicator.
 */
const InputWithIndicator = forwardRef<HTMLInputElement, Props>(
  (
    {
      dataTestId,
      size = 'medium',
      disabled = false,
      label,
      value,
      defaultValue,
      validation = [],
      appearance,
      className,
      externalErrors = [],
      children,
      width,
      onChange,
      onFocus,
      onBlur,
      readOnly,
      loading,
      level,
      ...rest
    },
    ref,
  ) => {
    const {
      validationErrors,
      hasChanged,
      hasError,
      hasFocus,
      onValueChange,
      onFocusChange,
      inputRef,
    } = useInputValue({
      value,
      externalErrors,
      validation,
      type: rest.type,
      onChange,
      onFocus,
      onBlur,
      ref,
    });
    const showIndicator = hasChanged || readOnly;

    return (
      <JustificationContainer width="100%" direction="column">
        <InputLabel
          size={size}
          error={validationErrors?.[0]}
          label={label}
          color={
            level === 'warning'
              ? { group: 'warning', variant: 'dark' }
              : undefined
          }
        />
        <Container
          size={size}
          hasError={hasError}
          hasFocus={hasFocus}
          width={width}
          appearance={appearance}
          className={className}
          inputRef={inputRef}
          disabled={disabled || !!readOnly}
        >
          <InputElement
            {...rest}
            dataTestId={dataTestId}
            ref={inputRef}
            size={size}
            value={value ?? undefined}
            defaultValue={defaultValue ?? undefined}
            disabled={disabled || readOnly}
            onChange={onValueChange}
            onFocus={e => onFocusChange({ e, focus: true })}
            onBlur={e => onFocusChange({ e, focus: false })}
          />

          {children}

          {showIndicator && (
            <Indicator
              size={size}
              level={
                loading ? 'loading' : hasError ? 'danger' : level || 'success'
              }
            />
          )}
        </Container>
      </JustificationContainer>
    );
  },
);

const Container = styled(InputContainer)`
  position: relative;
  width: 100%;
`;

export default InputWithIndicator;
