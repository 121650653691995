import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { useRecoilState } from 'recoil';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

import ReactFlow, {
  Background,
  ReactFlowProvider,
  useReactFlow,
  ReactFlowInstance,
  useNodes,
  Node,
} from 'reactflow';

import createGraphLayout from './utils/createGraphLayout';
import getElementsFromActions from './utils/getElementsFromActions';
import nodeTypes from './components/nodeTypes';
import {
  DEFAULT_HEIGHT,
  DEFAULT_WIDTH,
} from './components/nodeTypes/constants';
import edgeTypes from './components/edgeTypes';
import { actionsSelector } from '../../state';
import interactions from '../../state/interactions';
import UpdateAction from '../UpdateAction';
import ControlComponents from './components/ControlComponents';
import useBuilderContext from './hooks/useBuilderContext';
import useBuilderBindings from './hooks/useBuilderBindings';
import ConnectingEdge from './components/ConnectingEdge';
import { REACT_FLOW_PANE } from './constants/reactFlowLayers';
import lastAddedActionId from '../../state/lastAddedActionId';
import usePanTo from './hooks/usePanTo';
import usePrevious from '~/hooks/usePrevious';
import lastDeletedActionId from '../../state/lastDeletedActionId';
import MarkerDefinitions from './components/MarkerDefinitions';
import { HIGHLIGHTED_EDGE } from './constants/zIndexes';
import Dialogs from './components/Dialogs';
import { CALCULATED_MIN_HEIGHT } from '~/components/organism/NavigationFrame/constants';

export type Props = {
  dataTestId?: string;
};

export const GRID_SIZE = 24;
export const DEFAULT_SCALE = 0.7;

const Builder: React.FCC<Props> = ({ dataTestId }) => {
  const theme = useTheme();
  const handle = useFullScreenHandle();
  const { accountId, flowBlueprintId } = useBuilderContext();
  const [activeInteraction, setActiveInteraction] =
    useRecoilState(interactions);

  const [actions, setActions] = useRecoilState(
    actionsSelector({
      accountId,
      flowBlueprintId,
    }),
  );

  const [reactflowInstance, setReactflowInstance] =
    useState<ReactFlowInstance | null>(null);

  const { fitBounds } = useReactFlow();

  const portalColors = theme.getTokens().colors.portals;

  const { nodes: initialNodes, edges } = useMemo(
    () =>
      getElementsFromActions({
        actions,
        portalColors,
      }),
    [actions, portalColors],
  );

  const [nodes, setNodes] = useState<Array<Node>>(initialNodes);

  const startAction = nodes.find(node => node.type === 'Start');
  // Adds all keybindings
  useBuilderBindings();

  const onInit = useCallback(
    (rfi: ReactFlowInstance) => {
      if (!reactflowInstance) {
        setReactflowInstance(rfi);
      }
    },
    [reactflowInstance],
  );

  useEffect(() => {
    // Fit startAction into the view before reactflowInstance is initialized
    // and when startAction's position becomes available
    if (
      reactflowInstance?.viewportInitialized === undefined &&
      startAction?.position.x !== undefined
    ) {
      fitBounds(
        {
          x: startAction.position.x || 0,
          y: startAction.position.y || 200,
          width: DEFAULT_WIDTH,
          height: DEFAULT_HEIGHT * 2,
        },
        { padding: 2 },
      );
    }
  }, [fitBounds, reactflowInstance?.viewportInitialized, startAction]);

  const toggleFullScreen = () => {
    if (handle.active) {
      return handle.exit();
    }
    return handle.enter();
  };

  const highlightedActionId =
    activeInteraction?.type === 'highlightActions'
      ? activeInteraction.highlightedActions[0]
      : null;

  return (
    <>
      <Container
        data-testid={dataTestId}
        $isFullScreen={handle.active}
        $highlightedActionId={highlightedActionId}
      >
        <FullScreen className="fullscreen" handle={handle}>
          {/* A div to portal an UpdateAction modal to, fixes opening a modal in a fullscreen mode */}
          <div id="fullscreen-modal" key="fullscreen" />
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodesDraggable={false}
            elementsSelectable={false}
            selectNodesOnDrag={false}
            onInit={onInit}
            snapToGrid
            snapGrid={[GRID_SIZE / 2, GRID_SIZE / 2]}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            minZoom={0.1}
            onPaneClick={() => {
              if (activeInteraction?.type === 'connectAction')
                setActiveInteraction(null);
            }}
          >
            <Background />
            <MarkerDefinitions />

            {activeInteraction?.type === 'connectAction' && <ConnectingEdge />}
          </ReactFlow>

          <GraphLayoutRenderer
            onNodesChange={nodes => setNodes(nodes)}
            initialNodes={initialNodes}
          />
          <ControlComponents
            isFullScreen={handle.active}
            toggleFullScreen={toggleFullScreen}
          />
        </FullScreen>
      </Container>

      {activeInteraction?.type === 'updateAction' && (
        <UpdateAction
          root="fullscreen-modal"
          action={activeInteraction.action}
          onClose={() => {
            setActiveInteraction(null);
          }}
          onConfirm={updatedAction => {
            setActions(actions => {
              const mutableActions = [...actions];
              const subjectIndex = mutableActions.findIndex(
                action => action.id === updatedAction.id,
              );

              if (subjectIndex !== -1) {
                mutableActions[subjectIndex] = updatedAction;
              }

              return mutableActions;
            });
            /*
             * Note: Do not set activeInteraction to null here, that would remove the modal instantly.
             * The onClose will be called internally
             */
          }}
        />
      )}

      <Dialogs />
    </>
  );
};

/** Calling useNodes in the Builder component causes it to re-render infinitely so we isolate it */
const GraphLayoutRenderer = React.memo(
  ({
    onNodesChange,
    initialNodes,
  }: {
    onNodesChange: (nodes: Array<Node>) => void;
    initialNodes: Array<Node>;
  }) => {
    const renderedNodes = useNodes();

    const panTo = usePanTo();

    const { fitView } = useReactFlow();

    const [lastActionId, setLastAddedActionId] =
      useRecoilState(lastAddedActionId);
    const [deletedActionId, setLastDeletedActionId] =
      useRecoilState(lastDeletedActionId);

    /** Used to prevent infinite triggering of useEffect */
    const nodesKey = JSON.stringify(
      renderedNodes.map(n => ({ id: n.id, height: n.height })),
    );

    useEffect(() => {
      onNodesChange(createGraphLayout(initialNodes, renderedNodes));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialNodes, nodesKey]);

    const prevLength = usePrevious(renderedNodes.length);

    /* Focus on last deleted action's parent */
    useEffect(() => {
      if (!prevLength) return;

      const actionDeleted = prevLength > renderedNodes.length;
      if (actionDeleted) {
        const action = renderedNodes.find(node => node.id === deletedActionId);
        if (!action) return;

        panTo({
          x: action.position.x,
          y: action.position.y - DEFAULT_HEIGHT / 2,
          zoom: 2,
        });

        setLastDeletedActionId(null);
        return;
      }

      /** Cancelling deletion of an action should fit all the actions back in the view */
      const deleteCancelled = prevLength < renderedNodes.length;
      if (deleteCancelled)
        fitView({ duration: 1000, includeHiddenNodes: true });

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prevLength, renderedNodes.length]);

    /* Focus on last added action */
    useEffect(() => {
      if (lastActionId) {
        const action = renderedNodes.find(node => node.id === lastActionId);
        if (!action) return;

        panTo({
          x: action.position.x,
          y: action.position.y - DEFAULT_HEIGHT / 2,
        });

        setLastAddedActionId(null);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderedNodes.length, lastActionId, panTo, setLastAddedActionId]);

    return null;
  },
);

const Container = styled.div<{
  $isFullScreen?: boolean;
  $highlightedActionId?: string | null;
}>(
  ({ theme, $isFullScreen, $highlightedActionId }) => css`
    width: 100%;
    height: ${CALCULATED_MIN_HEIGHT};
    position: relative;

    .fullscreen {
      background: ${$isFullScreen && theme.color('white', 'dark')};
      height: ${CALCULATED_MIN_HEIGHT};
    }

    ${REACT_FLOW_PANE} {
      cursor: grab;
    }

    .react-flow__arrowhead > polyline {
      stroke-width: 1.5;
    }

    .react-flow__attribution {
      display: none;
    }

    [data-id='${$highlightedActionId}'] {
      z-index: ${HIGHLIGHTED_EDGE + 1} !important;
    }
  `,
);

const WithProvider: React.FCC<Props> = React.memo(props => (
  <ReactFlowProvider>
    <Builder {...props} />
  </ReactFlowProvider>
));

export default WithProvider;
