import React from 'react';
import styled, { css } from 'styled-components';
import Label from '~/components/template/EventTimelineV2/components/Block/components/Data/components/Label';
import { ImageBlock } from '~/graphql/types';
import { BLUR_STYLES } from '../styles';
import { omit } from 'ramda';

export type Props = ImageBlock & {
  dataTestId?: string;
};

const ImageBlockComponent: React.FC<Props> = ({
  label,
  imageValue,
  css,
  blurred,
  ...rest
}) => (
  <>
    {label && <Label>{label}</Label>}
    <ImageEl
      {...omit(['__typename'], rest)}
      src={imageValue.src}
      css={css}
      $blurred={blurred || false}
    />
  </>
);

const ImageEl = styled.img<{ $blurred: boolean }>(
  ({ $blurred }) => css`
    width: 100%;
    max-width: 100%;

    ${$blurred && BLUR_STYLES};
  `,
);

export default ImageBlockComponent;
