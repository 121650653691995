export const EMPTY_TEMPLATE_TOKEN = 'empty-form-template';

const isValidToken = (token: any): boolean => {
  if (token === EMPTY_TEMPLATE_TOKEN) {
    return true;
  }

  const tokenRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  return tokenRegex.test(token);
};

export default isValidToken;
