import React from 'react';
import type { JsonStructure, JsonStructureItem } from 'react-cmdk';
import { navigate } from '@gatsbyjs/reach-router';
import useDHFlag from '~/hooks/useDHFlag';
import Icon from '~/components/atom/Icon';
import { useSetRecoilState } from 'recoil';
import { pageSelector } from '../../state';

const useFormsItems = (): JsonStructure[number] | null => {
  const hasForms = useDHFlag('form-builder');
  const setPage = useSetRecoilState(pageSelector);

  if (!hasForms) return null;

  const items: Array<JsonStructureItem> = [
    {
      id: 'forms',
      children: 'Formulieren',
      icon: () => <Icon name="file-text" color={{ group: 'secondary' }} />,
      onClick: () => {
        void navigate('/-/forms');
      },
      showType: false,
      keywords: ['forms'],
    },
    {
      id: 'create-form',
      children: 'Nieuw formulier',
      icon: () => <Icon name="plus" color={{ group: 'secondary' }} />,
      showType: false,
      keywords: ['new', 'forms'],
      onClick: () => {
        void navigate('/-/forms/wizard/');
      },
    },
    {
      id: 'open-forms',
      children: 'Formulier openen',
      icon: () => <Icon name="search" color={{ group: 'secondary' }} />,
      onClick: () => {
        setPage('open-forms');
      },
      keywords: ['forms'],
      closeOnSelect: false,
      showType: false,
    },
  ];

  return {
    heading: 'Formulieren',
    id: 'forms',
    items,
  };
};

export default useFormsItems;
