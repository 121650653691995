import React, { useState } from 'react';
import Catalog from '~/Catalog';
import {
  useGetBillingDetailsQuery,
  useStartAddBillingMethodMutation,
  useUpdateBillingMethodMutation,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { isInitialLoadStatus } from '~/graphql/ApolloConstants';
import DatHuisLoading from '~/components/atom/DatHuisLoading';
import AppErrorScreen from '~/components/template/AppErrorScreen';
import Button from '~/components/atom/Button';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Body, Heading3, Variant } from '~/components/atom/Typography';
import { IbanElement, useStripe, useElements } from '@stripe/react-stripe-js';
import useIsMounted from '~/hooks/useIsMounted';
import TEST_ID from './index.testid';
import Dropdown from '~/components/molecule/Dropdown';
import { PAYMENT_OPTIONS } from '../../constants';
import IBANElement from '~/components/atom/StripeIBANElement';
import cleanedFilename from '~/util/cleanedFilename';
import { isNil } from 'ramda';
import withStripeWrapper from '~/hocs/withStripeWrapper';
import TextButton from '~/components/atom/TextButton';
import withModalOverlay from '~/hocs/withModalOverlay';

export type Props = {
  onComplete: () => void;
  onClose?: () => void;
};

export const MODAL_WIDTH = '890px';

const text = {
  title: 'Wijzig betaalmethode',
  paymentOptionLabel: 'Betaalmethode',
  noPaymentIdFromStripeErrorMessage:
    'Er kan geen verbinding worden gemaakt met de betalingsserver',
  noCustomerSecretErrorMessage: Catalog.genericUnknownErrorMessageShort,
  mutationError: Catalog.genericUnknownErrorMessageShort,
  buttonLabel: 'Opslaan',
  cancel: 'Afbreken',
};

const UpdatePaymentMethod: React.FC<Props> = ({ onComplete, onClose }) => {
  const account = useCurrentAccount();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [stripeErrorMsg, setStripeErrorMsg] = useState<string | null>(null);
  const isMounted = useIsMounted();

  const [startAddBillingMethod, { error: startAddBillingError }] =
    useStartAddBillingMethodMutation({
      variables: {
        accountId: account.id,
      },
    });
  const [updateBillingMethod, { error: updateBillingError }] =
    useUpdateBillingMethodMutation({
      onCompleted: onComplete,
    });

  const { data, networkStatus, error } = useGetBillingDetailsQuery({
    variables: {
      accountId: account.id,
    },
  });

  if (isInitialLoadStatus(networkStatus)) {
    // If there are no overages we do not want to show anything, so also don't show loading
    return <DatHuisLoading />;
  }

  if (error || !data?.getBillingDetails) {
    return <AppErrorScreen inline setBackgroundColor={false} />;
  }

  return (
    <JustificationContainer padding={['xl']} direction="column" align="center">
      <Heading3 variant={Variant.primary}>{text.title}</Heading3>
      <JustificationContainer
        dataTestId={TEST_ID.CONTAINER}
        direction="column"
        width="100%"
        gap="m"
      >
        {(() => {
          if (stripeErrorMsg != null) {
            return <Body color={{ group: 'danger' }}>{stripeErrorMsg}</Body>;
          } else if (startAddBillingError != null || updateBillingError) {
            return (
              <Body color={{ group: 'danger' }}>{text.mutationError}</Body>
            );
          }

          return null;
        })()}
        <JustificationContainer width="100%">
          <Dropdown
            options={PAYMENT_OPTIONS}
            onChange={() => {}}
            selectedOptionIdx={0}
            label={text.paymentOptionLabel}
          />
        </JustificationContainer>
        <JustificationContainer width="100%">
          <IBANElement
            elements={elements}
            currentLast4={data.getBillingDetails.last4}
          />
        </JustificationContainer>
        <JustificationContainer
          width="100%"
          margin={['m', null, null, null]}
          justification="space-between"
          align="center"
        >
          <TextButton
            label={text.cancel}
            appearance="danger"
            onClick={onClose}
          />
          <Button
            size="medium"
            icon="check"
            appearance="secondary"
            dataTestId={TEST_ID.SAVE_BUTTON}
            loading={loading}
            disabled={loading}
            onClick={async () => {
              if (elements == null) {
                throw Error(
                  `${cleanedFilename(
                    __filename,
                  )} | Should not occur | elements == null`,
                );
              }
              if (stripe == null) {
                throw Error(
                  `${cleanedFilename(
                    __filename,
                  )} | Should not occur | stripe == null`,
                );
              }

              const ibanElement = elements.getElement(IbanElement);
              if (ibanElement == null) {
                throw Error(
                  `${cleanedFilename(
                    __filename,
                  )} | Should not occur | ibanElement == null`,
                );
              }

              setLoading(true);

              const result = await startAddBillingMethod({
                variables: {
                  accountId: account.id,
                },
              });

              const customerSecret =
                result?.data?.startAddBillingMethod?.customerSecret;

              if (isNil(customerSecret)) {
                setStripeErrorMsg(text.noCustomerSecretErrorMessage);
                setLoading(false);

                return;
              }

              if (!isMounted()) return;
              if (
                !data?.getBillingDetails?.name ||
                !data.getBillingDetails.email
              )
                return;

              const { setupIntent, error } = await stripe.confirmSepaDebitSetup(
                customerSecret,
                {
                  payment_method: {
                    sepa_debit: ibanElement,
                    billing_details: {
                      name: data.getBillingDetails.name,
                      email: data.getBillingDetails.email,
                    },
                  },
                },
              );

              const paymentId = setupIntent?.payment_method as string;

              if (paymentId == null) {
                const stripeError = error?.message;

                setStripeErrorMsg(
                  stripeError == null
                    ? text.noPaymentIdFromStripeErrorMessage
                    : stripeError,
                );
                setLoading(false);

                return;
              }

              if (!isMounted()) return;

              return updateBillingMethod({
                variables: {
                  accountId: account.id,
                  paymentId,
                },
              });
            }}
            label={text.buttonLabel}
          />
        </JustificationContainer>
      </JustificationContainer>
    </JustificationContainer>
  );
};

export default withStripeWrapper(
  withModalOverlay(UpdatePaymentMethod, { maxWidth: MODAL_WIDTH }),
);
