import { navigate } from '@gatsbyjs/reach-router';
import React, { useState } from 'react';
import Button from '~/components/atom/Button';
import AddTriggerDiagram from '~/components/molecule/Diagrams/AddZapierTrigger';
import JustificationContainer from '~/components/atom/JustificationContainer';
import TextButton from '~/components/atom/TextButton';
import { Body, Heading4, Variant } from '~/components/atom/Typography';
import { useInsertZapierTriggerMutation } from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import { LINK_PREFIX } from '~/components/page/Apps/Zapier/components/Wizard/index';
import { StepProps } from '../Connect';
import TEST_ID from './index.testid';
import TriggerTemplatesContainer from './components/TriggerTemplatesContainer';
import useIsMounted from '~/hooks/useIsMounted';
import TipBanner from '~/components/organism/TipBanner';
import ChatLink from '~/components/organism/TipBanner/ChatLink';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import Link from '~/components/molecule/Link';
import type { ZapierTriggerTemplates } from '~/components/organism/WizardSteps/Apps/Zapier/AddZapierTrigger/components/TriggerTemplatesContainer/zapierTriggerTemplates';

const text = {
  header: 'Uitgaande koppelingen',
  body: 'DatHuis kan vanuit een flow gegevens sturen naar Zapier, die vervolgens kunnen worden doorgestuurd naar andere diensten. Om gegevens naar Zapier te sturen, dien je een uitgaande koppeling in te stellen. Vervolgens kan je de verstuurde gegevens in een ‘zap’ in Zapier gebruiken.',
  exampleHeader: 'Een voorbeeld hoe een flow gegevens naar Zapier stuurt',
  chooseExampleHeader: 'Kies uitgaande koppelingen',
  chooseConnectionBody:
    'Met een uitgaande koppeling bepaal je welke gegevens je verstuurt naar Zapier. We hebben we een aantal uitgaande koppelingen voor je klaargezet. Deze zijn gemaakt voor de meest voorkomende toepassingen. Na het afronden van deze wizard is het mogelijk om zelf een koppeling te maken.',
  tip: (
    <>
      Lees hoe je de koppeling moet instellen in onze{' '}
      <Link to="https://help.dathuis.nl/">kennisbank</Link>. Kom je er niet uit?
      &nbsp;
      <ChatLink linkText="Start een chat met ons." />
    </>
  ),
};

const AddTrigger: React.FCC<StepProps> = ({
  onClose,
  disableClosing = false,
}) => {
  const { id: accountId } = useCurrentAccount();
  const isMounted = useIsMounted();
  const addToast = useAddToast();
  const [selectedTriggers, setSelectedTriggers] =
    useState<ZapierTriggerTemplates>([]);

  const [insertZapierTrigger, { loading }] = useInsertZapierTriggerMutation();

  const onGetTriggers = async () => {
    if (selectedTriggers.length > 0) {
      const data = await Promise.all(
        selectedTriggers.map(trigger =>
          insertZapierTrigger({
            variables: {
              accountId,
              name: trigger.name,
              fields: trigger.fields,
            },
          }).then(({ data, errors }) => {
            if (errors && errors.length > 0) {
              addToast([
                formatToastMessage(
                  `Er is iets fout gegaan bij het toevoegen van "${trigger.name}" uitgaande koppeling, probeer het later opnieuw.`,
                  'danger',
                ),
              ]);
            }
            return { data };
          }),
        ),
      );

      const successResult = data.filter(({ data }) => data != null);
      if (successResult.length > 0) {
        addToast([
          formatToastMessage(
            `${successResult.length} uitgaande koppeling(en) succesvol toegevoegd.`,
            'success',
          ),
        ]);
      }
    }

    if (!isMounted()) return;

    /** We decided to navigate to the next step even if one or more insert mutations fail because the chances are very low that we get an error.
     *  We make the default values ourselves and we have no user input here.
     */
    void navigate(`${LINK_PREFIX}/add-zapier-event`, { replace: true });
  };

  return (
    <AppDetailsContainer
      header={text.header}
      icon="externalLink"
      data-testid={TEST_ID.CONTAINER}
    >
      <Body>{text.body}</Body>

      <Heading4 margin={['xl', null, 'm', null]} variant={Variant.primary}>
        {text.exampleHeader}
      </Heading4>

      <AddTriggerDiagram />

      <Heading4 margin={['xl', null, 'm', null]} variant={Variant.primary}>
        {text.chooseExampleHeader}
      </Heading4>

      <Body>{text.chooseConnectionBody}</Body>

      <TriggerTemplatesContainer
        onChange={triggers => setSelectedTriggers(triggers)}
        selectedTriggers={selectedTriggers}
      />
      <TipBanner id="add-trigger-tip">{text.tip}</TipBanner>

      <JustificationContainer
        justification="space-between"
        align="center"
        margin={['l', null, null]}
      >
        {!disableClosing && (
          <TextButton
            label="Afbreken"
            onClick={onClose}
            padding={[null]}
            appearance="danger"
            dataTestId="exit-wizard-button"
          />
        )}
        <Button
          icon="arrowRight"
          label="Gegevens ontvangen"
          onClick={onGetTriggers}
          size="medium"
          dataTestId={TEST_ID.NEXT_BUTTON}
          loading={loading}
        />
      </JustificationContainer>
    </AppDetailsContainer>
  );
};

export default AddTrigger;
