import React from 'react';
import { UseTransitionProps, useTransition } from 'react-spring';
import DateLabel from '../DateLabel';
import styled, { css } from 'styled-components';
import { EventTimelineContact } from '../..';
import { Task as TaskDetailProps } from '~/components/page/Tasks/types';
import { ActivityV2 } from '../../types';
import { ANIMATION_CONFIG } from '~/styles/constants';
import Activity from './Activity';

type Props = {
  date: string;
  items: Array<ActivityV2>;
  contact?: EventTimelineContact;
  onOpenTaskModal?: (task: TaskDetailProps | null) => void;
};

const CardGroup = React.forwardRef<HTMLDivElement, Props>(
  ({ date, items, ...rest }, ref) => {
    const transitionProps: UseTransitionProps = {
      from: ANIMATION_CONFIG.from,
      enter: ANIMATION_CONFIG.to,
      leave: ANIMATION_CONFIG.from,
      config: ANIMATION_CONFIG.config,
      trail: 125,
      keys: (item: ActivityV2) => item.id,
    };

    const transitions = useTransition(items, transitionProps);

    return (
      <Container key={date} id={date} ref={ref}>
        <DateLabel value={date} />
        {transitions((style, activity: ActivityV2) => (
          <Activity data={activity} style={style} {...rest} />
        ))}
      </Container>
    );
  },
);

const Container = styled.div(() => css``);

export default CardGroup;
