import React from 'react';
import styled, { css } from 'styled-components';
import DatHuisLoading from '~/components/atom/DatHuisLoading';
import Icon, { IconType } from '~/components/atom/Icon';
import SpringContainer from '~/components/molecule/SpringContainer';
import { Body, Heading2 } from '~/components/atom/Typography';
import JustificationContainer from '~/components/atom/JustificationContainer';

export type Props = {
  className?: string;
  /** Header text */
  header: React.ReactNode;
  /** Icon to be rendered before the header text */
  icon?: IconType;
  /** Shows a loading animation */
  loading?: boolean;

  pageDescription?: React.ReactNode;
};

const AppDetailsContainer: React.FCC<Props> = ({
  dataTestId,
  header,
  icon = 'file',
  children,
  loading,
  pageDescription,
  className,
}) => {
  const details = loading === true ? <DatHuisLoading /> : children;

  return (
    <Container data-testid={dataTestId} className={className}>
      <JustificationContainer
        align="center"
        margin={[null, null, 'base', null]}
      >
        <StyledIcon color={{ group: 'primary' }} name={icon} />
        <Heading2 margin={[null]} color={{ group: 'primary' }} size="l">
          {header}
        </Heading2>
      </JustificationContainer>
      {pageDescription && (
        <Body margin={[null, null, 'l', null]}>{pageDescription}</Body>
      )}
      {details}
    </Container>
  );
};

const Container = styled(SpringContainer)<{}>(
  ({ theme }) => css`
    background-color: ${theme.color('white')};
    padding: ${theme.space('l')};
    flex-grow: 1;
    border-radius: ${theme.getTokens().border.radius.m};
    border: 1px solid ${theme.color('tertiary', 'light')};
  `,
);
const StyledIcon = styled(Icon)<{}>(
  ({ theme }) => css`
    margin-right: ${theme.space('s')};
    margin-bottom: 3px;
    font-size: 2em;
  `,
);

export default AppDetailsContainer;
