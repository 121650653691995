import React from 'react';
import Icon from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Label } from '~/components/atom/Typography';

export type Props = { shared: boolean; onClick: () => void };

const ShareIndicator: React.FC<Props> = ({ shared, onClick }) => (
  <JustificationContainer
    width="100%"
    justification="start"
    align="center"
    gap="s"
    onClick={event => {
      event.preventDefault();
      event.stopPropagation();
      onClick();
    }}
  >
    <Icon
      name="share"
      color={{ group: shared ? 'secondary' : 'tertiary' }}
      size="m"
    />
    <Label size="base" margin={[null]} textDecoration="underline">
      {shared ? 'Instellingen' : 'Delen'}
    </Label>
  </JustificationContainer>
);

export default ShareIndicator;
