import React, { useState } from 'react';
import styled from 'styled-components';
import { Helmet as MetaTags } from 'react-helmet';
import ContentContainerDefault from '~/components/molecule/ContentContainer';
import OverviewListHeader from '~/components/molecule/OverviewListHeader';
import { useGetSessionHydrationQuery, UserStatus } from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';

import OfficeUserCard from '../OfficeUserCard';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import Catalog from '~/Catalog';
import useOffice from '~/hooks/useOffice';
import useUsers from '~/hooks/useUsers';
import { sortByName } from '~/components/page/Settings/Users/utils';
import { isNil } from 'ramda';
import useUserRights from '~/hooks/useUserRights';
import Button from '~/components/atom/Button';
import AddUserSidebar from '~/components/page/Settings/components/AddUserSidebar';
import useLimit from '~/hooks/useLimit';
import NoResultSection from '~/components/page/NoResultSection';
import AppErrorScreen from '~/components/template/AppErrorScreen';
import createPageTitle from '~/util/createPageTitle';

export const text = {
  users: 'Gebruikers',
  offices: 'Vestigingen',
  noResults: Catalog.noResults,
  userUpdateError: Catalog.genericUnknownErrorMessage,
  addUserButton: 'Nieuwe gebruiker',
  pageTitle: 'Gebruikerslijst',
};

export type Props = RouteComponentProps<{
  officeId: string;
  hasError: boolean;
}>;

const OfficeUserList: React.FCC<Props> = ({
  dataTestId,
  hasError,
  officeId,
}) => {
  const { id: accountId } = useCurrentAccount();

  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const { refetch } = useGetSessionHydrationQuery({
    variables: { accountId },
  });

  const office = useOffice(officeId);

  const userLimit = useLimit('root.users');
  const allUsers = useUsers({
    statuses: [
      UserStatus.Active,
      UserStatus.Pending,
      UserStatus.InvitedPending,
    ],
  });
  const hasExceededUserLimit = allUsers.length >= userLimit.limit;

  const officeUsers = useUsers({
    statuses: [
      UserStatus.Active,
      UserStatus.Pending,
      UserStatus.InvitedPending,
    ],
    officeIds: [officeId],
  });

  const { update: accountAdminMayUpdate } = useUserRights({
    category: 'Offices',
  });
  const { update: officeAdminMayUpdate } = useUserRights({
    category: 'Offices',
    entity: { type: 'office', id: officeId },
  });

  const mayEdit = accountAdminMayUpdate || officeAdminMayUpdate;

  if (isNil(officeId))
    return <NoResultSection>{Catalog.noResults}</NoResultSection>;

  const sortedUsers = sortByName(officeUsers);

  if (hasError || !officeId || !office) return <AppErrorScreen />;

  const officeName = office ? office.name : '';

  const ifUsersInOffice = sortedUsers.length !== 0;

  return (
    <Container data-testid={dataTestId}>
      <ContentContainerDefault
        breadcrumbs={[
          {
            to: '/-/settings/offices',
            label: text.offices,
          },
          {
            to: `/-/settings/offices/${officeId}`,
            label: officeName,
          },
          {
            label: text.users,
          },
        ]}
      >
        <MetaTags>
          <title>{createPageTitle(text.pageTitle)}</title>
        </MetaTags>

        <>
          <OverviewListHeader
            title={officeName}
            buttons={
              mayEdit && !hasExceededUserLimit
                ? [
                    {
                      node: (
                        <Button
                          onClick={() => setShowSidebar(true)}
                          dataTestId="add-user-button"
                          label={text.addUserButton}
                          size="medium"
                        />
                      ),
                      key: 'add-user-button',
                    },
                  ]
                : undefined
            }
          />
          {mayEdit && (
            <AddUserSidebar
              officeOptions={[
                {
                  key: office?.id,
                  payload: office,
                  label: office?.name,
                },
              ]}
              accountId={accountId}
              onUserAdded={refetch}
              hide={() => setShowSidebar(false)}
              isShowing={showSidebar}
            />
          )}

          {!ifUsersInOffice && (
            <NoResultSection>{text.noResults}</NoResultSection>
          )}

          {ifUsersInOffice != null && (
            <section data-testid="user-list">
              {sortedUsers.map(({ id }) => (
                <OfficeUserCard
                  key={id}
                  refetch={refetch}
                  officeId={officeId}
                  userId={id}
                  canEdit={mayEdit}
                />
              ))}
            </section>
          )}
        </>
      </ContentContainerDefault>
    </Container>
  );
};

const Container = styled.div<{}>``;

export default OfficeUserList;
