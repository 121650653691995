import type { ConditionExpression } from '../../types';

/**
 * Checks whether a ConditionExpression is complete.
 *
 * E.g. can be send to the backend.
 */
const isEmptyConditionExpression = ({
  conditionSubExpression,
}: ConditionExpression): boolean => {
  if (conditionSubExpression.length === 0) return true;
  if (conditionSubExpression.length > 1) return false;

  const [{ conditionGroups }] = conditionSubExpression;
  if (conditionGroups.length === 0) return true;
  if (conditionGroups.length > 1) return false;

  const [{ conditions }] = conditionGroups;
  if (conditions.length === 0) return true;
  if (conditions.length > 1) return false;

  const [condition] = conditions;
  return condition == null;
};

export default isEmptyConditionExpression;
