import React from 'react';
import { clone } from 'ramda';
import styled, { css } from 'styled-components';
import TextButton from '~/components/atom/TextButton';
import type {
  ArgumentId,
  ConditionExpression,
  ConditionSubExpression,
} from '../../types';
import { ConnectorOperator } from '~/graphql/types';

type Props = {
  subConditions: Array<React.JSX.Element>;
  newConditionLabel: React.ReactNode;
  conditionSubExpression: Array<ConditionSubExpression>;
  conditionSubExpressionIdx: number;
  conditionExpressionConnector: ConnectorOperator;
  setConditionExpression: (
    nextConditionExpression: ConditionExpression,
  ) => void;
  setActiveSelector: (value: React.SetStateAction<ArgumentId | null>) => void;
};

const SubConditions: React.FCC<Props> = ({
  subConditions,
  newConditionLabel,
  conditionSubExpression,
  conditionSubExpressionIdx,
  setActiveSelector,
  setConditionExpression,
  conditionExpressionConnector,
}) => (
  <Container>
    {subConditions}
    <TextButton
      padding={[null]}
      label={newConditionLabel}
      icon="plus"
      onClick={() => {
        const nextConditionSubExpression = clone(conditionSubExpression);

        nextConditionSubExpression[
          conditionSubExpressionIdx
        ].conditionGroups.push({
          conditions: [null],
          connector: ConnectorOperator.And,
        });

        setConditionExpression({
          conditionSubExpression: nextConditionSubExpression,
          connector: conditionExpressionConnector,
        });

        setActiveSelector({
          conditionSubExpressionIdx,
          conditionGroupIdx:
            nextConditionSubExpression[conditionSubExpressionIdx]
              .conditionGroups.length - 1,
        });
      }}
    />
  </Container>
);

const Container = styled.div(
  ({ theme }) => css`
    background-color: ${theme.color('tertiary', 'light')};
    padding: ${theme.space('s')} ${theme.space('m')};
    border-bottom-right-radius: ${theme.getTokens().border.radius.base};
    border-bottom-left-radius: ${theme.getTokens().border.radius.base};
  `,
);

export default SubConditions;
