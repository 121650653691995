import React from 'react';
import { Props } from '..';
import styled, { useTheme } from 'styled-components';
import useDHFlag from '~/hooks/useDHFlag';

const DatHuisEmblemXBNDL: React.FCC<Props> = () => {
  const holidayCheer = useDHFlag('holiday-cheer');
  const theme = useTheme();

  return (
    <Container>
      {/* 🤫 our little holiday secret 🧑‍🎄 */}
      {holidayCheer && (
        <svg
          width="55"
          height="47"
          viewBox="0 0 55 47"
          fill="none"
          id="santa-hat"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M44.9705 21.8373L24.021 30.9339C23.3069 31.2433 22.6838 31.73 22.2108 32.348C21.5549 29.2455 19.8437 26.4665 17.3686 24.4842C14.8936 22.5019 11.8078 21.4391 8.63704 21.4768C10.031 17.5666 12.5689 14.1662 15.921 11.7174C19.273 9.26863 23.2842 7.88482 27.4331 7.74584C31.582 7.60685 35.6768 8.71911 39.1852 10.938C42.6937 13.157 45.4535 16.38 47.106 20.1881C47.2994 20.6335 47.4697 21.0828 47.6295 21.5335C46.7327 21.3669 45.8066 21.4727 44.9705 21.8373Z"
            fill={theme.color('danger')}
          />
          <path
            d="M8.78171 27.0888C6.51867 28.0715 3.88751 27.0335 2.90487 24.7704C1.92222 22.5074 2.96018 19.8763 5.22322 18.8936C7.48626 17.911 10.1174 18.9489 11.1001 21.212C12.0827 23.475 11.0447 26.1062 8.78171 27.0888Z"
            fill="#EAEAEA"
          />
          <path
            d="M23.331 31.2335L45.6604 21.5377C48.1377 20.4621 51.0179 21.5983 52.0936 24.0756C53.1693 26.5528 52.033 29.4331 49.5558 30.5087L27.2264 40.2045C24.7491 41.2802 21.8689 40.144 20.7932 37.6667C19.7175 35.1894 20.8537 32.3092 23.331 31.2335Z"
            fill="#EAEAEA"
          />
        </svg>
      )}

      <svg
        width="100%"
        height="100%"
        viewBox="0 0 60 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.35565"
          y="0.482363"
          width="59.4373"
          height="56.6512"
          rx="5"
          fill="#1E2647"
        />
        <path
          d="M39 21.3404C39 20.9609 38.6966 20.6595 38.327 20.6595H35.2654C34.8903 20.6595 34.5924 20.9665 34.5924 21.3404V26.1846C34.5924 27.1668 33.5277 28.4001 32.5348 28.4392C32.3417 28.4504 30.6536 28.4504 29.3463 28.4504C28.0444 28.4504 27.3494 28.4392 27.1563 28.4392C25.8158 28.4392 25.1869 27.3509 25.1869 25.6097V21.3404C25.1869 20.9665 24.8891 20.6595 24.5139 20.6595H21.673C21.3034 20.6595 21 20.9609 21 21.3404V37.4746C21.011 37.8541 21.3144 38.161 21.684 38.161H24.6077C24.9828 38.161 25.2807 37.8541 25.2807 37.4801V35.6385C25.2807 34.2991 25.1925 32.6416 26.8198 32.2621C27.0791 32.2007 27.3549 32.2342 27.6197 32.2342H29.2415C30.5544 32.2342 31.8673 32.2342 33.1802 32.2286H33.8477C34.2228 32.2286 34.5207 32.5355 34.5207 32.9094V47.3191C34.5207 47.6986 34.8241 48 35.1937 48H38.327C38.7021 48 39 47.6931 39 47.3191V21.3404Z"
          fill="white"
        />
        <path
          d="M21.0109 25.4645V10.759C21.0109 10.3404 21.3474 10 21.7612 10H24.5359C24.9497 10 25.2862 10.3404 25.2862 10.759V14.4814C25.2862 14.9 25.6227 15.2404 26.0364 15.2404H28.8111C29.2249 15.2404 29.5614 15.5808 29.5614 15.9994V18.4996C29.5614 18.9182 29.2249 19.2586 28.8111 19.2586H25.1979V25.4142C25.1979 26.8987 25.4406 28.4446 27.2886 28.4446H29.1201V32.2284H27.4541C27.4541 32.2284 24.6628 32.301 22.9968 30.8165C22.2356 30.1356 21.7005 29.2259 21.353 28.266C21.0109 27.3229 21.0109 26.4634 21.0109 25.4645Z"
          fill="white"
        />
      </svg>
    </Container>
  );
};

const Container = styled.span`
  position: relative;
  display: block;

  #santa-hat {
    position: absolute;
    top: -25px;
    left: -25px;
  }
`;

export default DatHuisEmblemXBNDL;
