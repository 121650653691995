import React from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import type { CommonNodeData } from '../../..';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  LayoutDirection,
  LayoutSize,
  type FormBuilder_EventNode,
} from '~/graphql/types';
import BaseEventCard from '~/components/template/EventTimelineV2/components/BaseEventCard';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import Block from '~/components/template/EventTimelineV2/components/Block';
import type { StyleBlockFE } from '~/components/template/EventTimelineV2/types';
import { isNil, pluck } from 'ramda';
import styled, { css } from 'styled-components';
import CardMenu from '../../../../CardMenu';
import PointerRenderer from './components/PointerRenderer';
import { interactionState } from '../../../../../state';
import { nodeById, eventById } from '../../../../../state/nodesAndEvents';
import { issuesByPath } from '../../../../../state/issues';
import useIsBasicForm from '../../../../../hooks/useIsBasicForm';

type NodeData = CommonNodeData & {
  value: number;
};

const EventNode: React.FC<NodeProps<NodeData>> = ({ isConnectable, id }) => {
  const currentAccount = useCurrentAccount();
  const node = useRecoilValue(nodeById(id)) as FormBuilder_EventNode;
  const event = useRecoilValue(eventById(node?.formBuilderEventId ?? ''));
  const setInteraction = useSetRecoilState(interactionState);
  const issues = useRecoilValue(issuesByPath([id]));
  const isBasicForm = useIsBasicForm();

  const eventBlocks: Array<StyleBlockFE> = [
    {
      label: null,
      value: '## Velden',
      css: null,
      id: 'e---e4d48640-6fa9-449b-a687-4a6d1066b02b#00',
      __typename: 'MarkdownBlock',
    },
    ...(event?.fields ?? []).map(
      ({ name, ...field }, index): StyleBlockFE => ({
        label: name,
        // @ts-ignore
        value: (() => {
          const pointer = node.mapping.find(
            ({ key }) => key === field.key,
          )?.pointer;

          if (!pointer) return <span>Unassigned</span>;

          return <PointerRenderer pointer={pointer as [string, string]} />;
        })(),
        css: null,
        id: `e---e4d48640-6fa9-449b-a687-4a6d1066b02b#${index}`,
        __typename: 'TextBlock',
      }),
    ),
  ];

  const activity: Array<StyleBlockFE> = [
    {
      __typename: 'FlexLayout',
      css: null,
      margin: null,
      padding: [LayoutSize.M],
      direction: LayoutDirection.Column,
      justification: null,
      align: null,
      gap: null,
      id: '123',
      childrenIds: pluck('id', eventBlocks),
      blocks: eventBlocks,
    },
  ];

  if (isNil(node)) return null;

  return (
    <Container $hidden={isBasicForm}>
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
      />
      <CardMenu nodeId={node.id} nodeType="FormBuilder_EventNode" />
      <StyledBaseEventCard
        $hasError={issues.length !== 0}
        onClick={() => {
          setInteraction({
            interactionType: 'edit-node-event',
            subjectId: id,
          });
        }}
        __typename="Event_Contact_Generic"
        _v={0}
        accountId={currentAccount.id}
        createdDate=""
        header={{ text: `## ${node.name}` }}
        icon={{
          url: 'https://dathuis-stash.imgix.net/event_icons/Plus.svg',
          __typename: 'Image',
        }}
        sortingDate=""
        body={activity.map(block => (
          <Block {...block} key={block.id} />
        ))}
      />
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
      />
    </Container>
  );
};

const StyledBaseEventCard = styled(BaseEventCard)<{ $hasError: boolean }>(
  ({ theme, $hasError }) => css`
    margin: 0;
    transition: outline 0.2s ease-in;

    ${$hasError
      ? css`
          box-shadow: ${theme.boxShadow('aroundDanger')};
          outline: 2px solid ${theme.color('danger')} !important;
        `
      : ''}

    &:hover {
      outline: 2px solid ${theme.color('primary')};
    }
  `,
);

const Container = styled.div<{ $hidden: boolean }>(
  ({ $hidden }) => css`
    ${$hidden &&
    // If we use display:none the edges are not drawn so a workaround for this
    css`
      opacity: 0;
      pointer-events: none;
    `}
  `,
);

export default EventNode;
