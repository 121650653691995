import React from 'react';

import FoldableCardBlock from '../FoldableCardBlock';
import TEST_ID from './index.testid';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { EmailStatus, EmailStatusType } from '~/graphql/types';
import EditableText from '~/components/organism/EditableText';
import Icon from '~/components/atom/Icon';
import { Body } from '~/components/atom/Typography';
import { Field } from 'react-final-form';
import type { ThemeColor } from '~/theme/System/tokens/colorPalette';
import BouncedEmailCTA from './components/BouncedEmailCTA';
import useViewingModeProps from '~/hooks/useViewingModeProps';
import Phone from '~/util/Validation/Phone';
import Catalog from '~/Catalog';

type Props = {
  contactId: string;
  name?: string | null;
  phone?: string | null;
  email?: string | null;
  blockMarketing?: boolean | null;
  emailStatus?: EmailStatusType | null;
  isFormDisabled: boolean;
};

const text = {
  title: 'Contactgegevens',
  phoneNumber: 'Telefoonnummer',
  name: 'Naam',
};

const ContactInfoBlock: React.FC<Props> = ({
  email,
  name,
  phone,
  isFormDisabled,
  contactId,
  emailStatus,
}) => {
  const viewingModeProps = useViewingModeProps();
  const emailNotOK = emailStatus && emailStatus.status !== EmailStatus.Ok;
  const emailColor: ThemeColor = emailNotOK
    ? { group: 'danger' }
    : { group: 'primary' };

  return (
    <FoldableCardBlock
      icon="user"
      title={text.title}
      dataTestId={TEST_ID.CONTAINER}
    >
      <JustificationContainer
        width="100%"
        direction="column"
        gap="m"
        padding={['xxs', null, null, null]}
        {...viewingModeProps}
      >
        <Field name="name">
          {({ input: { onChange } }) => (
            <EditableText
              as="h5"
              size="m"
              iconSize="base"
              color={{ group: 'primary' }}
              justification="space-between"
              disabled={isFormDisabled}
              dataTestId={TEST_ID.NAME}
              text={name ?? ''}
              onSave={value => {
                onChange(value);
              }}
              placeholder={text.name}
            />
          )}
        </Field>
        <Field name="phone">
          {({ input: { onChange } }) => (
            <JustificationContainer gap="xs" align="center" width="100%">
              <Icon name="phone" />
              <EditableText
                as="p"
                size="base"
                iconSize="base"
                fontWeight="regular"
                color={{ group: 'primary' }}
                justification="space-between"
                disabled={isFormDisabled}
                dataTestId={TEST_ID.PHONE}
                validation={[
                  value => Phone.isValid(`${value}`) || Catalog.invalidPhone,
                ]}
                text={phone ?? ''}
                onSave={value => {
                  onChange(value);
                }}
                placeholder={text.phoneNumber}
              />
            </JustificationContainer>
          )}
        </Field>
        <JustificationContainer gap="xs" align="center" width="100%">
          <Icon name="email-sign" color={emailColor} />
          <Body
            size="base"
            fontWeight="regular"
            margin={[null]}
            color={emailColor}
          >
            {email}
          </Body>
        </JustificationContainer>
        <BouncedEmailCTA contactId={contactId} emailStatus={emailStatus} />
      </JustificationContainer>
    </FoldableCardBlock>
  );
};

export default ContactInfoBlock;
