import {
  ZAPIER_TEMPLATES_OBJECT,
  type ZapierTemplateField,
} from '~/components/page/Apps/Zapier/constants';
import { ZapierField__Input } from '~/graphql/types';

import { camelToSnakeCase } from '~/util/string';

export type ZapierTriggerTemplate = {
  id: string;
  name: string;
  description?: string;
  fields: Array<ZapierField__Input>;
  insertedId?: string;
};
export type ZapierTriggerTemplates = Array<ZapierTriggerTemplate>;

const outputFieldsToZapierTemplateFields = (
  outputFields: Array<ZapierTemplateField>,
): Array<ZapierField__Input> =>
  outputFields.map(({ label, name, outputType }) => ({
    label,
    key: camelToSnakeCase(name.replace(/\./g, '_')),
    type: outputType,
  }));

const zapierTriggerTemplates: ZapierTriggerTemplates = [
  {
    id: 'brainbay_waardecheck_report',
    name: 'Brainbay waardecheck - Rapport',
    fields: outputFieldsToZapierTemplateFields(
      ZAPIER_TEMPLATES_OBJECT.APP_BBWAARDECHECK_REPORT.fields,
    ),
  },
  {
    id: 'brainbay_waardecheck_appraisal_request',
    name: 'Brainbay waardecheck - Waardebepaling',
    fields: outputFieldsToZapierTemplateFields(
      ZAPIER_TEMPLATES_OBJECT.APP_BBWAARDECHECK_APPRAISALREQUEST.fields,
    ),
  },
  {
    id: 'brainbay_waardecheck_contact_request',
    name: 'Brainbay waardecheck - Contactverzoek',
    fields: outputFieldsToZapierTemplateFields(
      ZAPIER_TEMPLATES_OBJECT.APP_BBWAARDECHECK_CONTACTREQUEST.fields,
    ),
  },
  {
    id: 'funda_viewing_request',
    name: 'Funda - Bezichtingsaanvraag',
    fields: outputFieldsToZapierTemplateFields(
      ZAPIER_TEMPLATES_OBJECT.APP_FUNDA_VIEWINGREQUEST.fields,
    ),
  },
  {
    id: 'funda_brochure_request',
    name: 'Funda - Brochureaanvraag',
    fields: outputFieldsToZapierTemplateFields(
      ZAPIER_TEMPLATES_OBJECT.APP_FUNDA_BROCHUREREQUEST.fields,
    ),
  },
  {
    id: 'funda_contact_request',
    name: 'Funda - Contactaanvraag',
    fields: outputFieldsToZapierTemplateFields(
      ZAPIER_TEMPLATES_OBJECT.APP_FUNDA_CONTACTREQUEST.fields,
    ),
  },
  {
    id: 'waarderappot_valuation_report',
    name: 'Waarderapport',
    fields: outputFieldsToZapierTemplateFields(
      ZAPIER_TEMPLATES_OBJECT.APP_VALUATIONREPORT.fields,
    ),
  },
  {
    id: 'waarderappot_valuation_request',
    name: 'Waarderapport - waardebepaling',
    fields: outputFieldsToZapierTemplateFields(
      ZAPIER_TEMPLATES_OBJECT.APP_VALUATIONREQUEST.fields,
    ),
  },
];

export default zapierTriggerTemplates;
