/**
 * Helps create Zapier trigger templates
 */
export enum FLOW_OUTPUT_TYPE {
  FlowOutputString = 'Flow_Output_String',
  FlowOutputNumber = 'Flow_Output_Number',
  FlowOutputBoolean = 'Flow_Output_Boolean',
  FlowOutputContact = 'Flow_Output_Contact',
  FlowOutputEventEmail = 'Flow_Output_Event_Email',
  FlowOutputUser = 'Flow_Output_User',
  FlowOutputOffice = 'Flow_Output_Office',
  FlowOutputTask = 'Flow_Output_Task',
  FlowOutputAppValuationReport = 'Flow_Output_AppValuationReport',
  FlowOutputFile = 'Flow_Output_File',
  FlowOutputEventContactAppValuationReport = 'Flow_Output_Event_Contact_AppValuationReport',
  FlowOutputEventContactAppValuationRequest = 'Flow_Output_Event_Contact_AppValuationRequest',
  FlowOutputEventContactAppFundaViewingRequest = 'Flow_Output_Event_Contact_App_Funda_ViewingRequest',
  FlowOutputEventContactAppFundaContactRequest = 'Flow_Output_Event_Contact_App_Funda_ContactRequest',
  FlowOutputEventContactAppFundaBrochureRequest = 'Flow_Output_Event_Contact_App_Funda_BrochureRequest',
  FlowOutputEventContactAppBBWaardecheckReport = 'Flow_Output_Event_Contact_App_BBWaardecheck_Report',
  FlowOutputEventContactAppBBWaardecheckAppraisalRequest = 'Flow_Output_Event_Contact_App_BBWaardecheck_AppraisalRequest',
  FlowOutputEventContactAppBBWaardecheckContactRequest = 'Flow_Output_Event_Contact_App_BBWaardecheck_ContactRequest',
  FlowOutputEventContactAppVBOWaardecheckReport = 'Flow_Output_Event_Contact_App_VBOWaardecheck_Report',
  FlowOutputEventContactAppVBOWaardecheckAppraisalRequest = 'Flow_Output_Event_Contact_App_VBOWaardecheck_AppraisalRequest',
  FlowOutputEventContactAppVBOWaardecheckContactRequest = 'Flow_Output_Event_Contact_App_VBOWaardecheck_ContactRequest',
  FlowOutputEventContactAppZapier = 'Flow_Output_Event_Contact_App_Zapier',
  FlowOutputEventContactAppHypotheekbond = 'Flow_Output_Event_Contact_App_Hypotheekbond',
  Link = 'Flow_Output_Link',
}

import { ZapierFieldType } from '~/graphql/types';

export type ZapierTemplateField = {
  label: string;
  name: string;
  outputType: ZapierFieldType;
};

type OutputObject = {
  name: string;
  outputType: FLOW_OUTPUT_TYPE;
  objectLabel: string;
  objectSelectLabel: string;
  fields: Array<ZapierTemplateField>;
};

const contactOutputObject: OutputObject = {
  name: 'contact',
  outputType: FLOW_OUTPUT_TYPE.FlowOutputContact,
  objectLabel: 'Contact',
  objectSelectLabel: 'Selecteer contact',
  fields: [
    {
      label: 'Naam',
      name: 'name',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'Telefoonnummer',
      name: 'phone',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'E-mailadres',
      name: 'email',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'Woonadres > Straat + huisnummer + toevoeging',
      name: 'address',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'Woonadres > Stad',
      name: 'address.city',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'Woonadres > Postcode',
      name: 'address.postcode',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'Woonadres > Straatnaam',
      name: 'address.street',
      outputType: ZapierFieldType.String,
    },
  ],
};

const valuationReportOutputObject: OutputObject = {
  name: 'eventContactAppValuationReport',
  outputType: FLOW_OUTPUT_TYPE.FlowOutputEventContactAppValuationReport,
  objectLabel: 'Waarderapport',
  objectSelectLabel: 'Selecteer waarderapport',
  fields: [
    {
      label: 'Aangevraagd adres > Straat + huisnummer + toevoeging',
      name: 'property.address',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'Aangevraagd adres > Stad',
      name: 'property.city',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'Aangevraagd adres > Postcode',
      name: 'property.postcode',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'Aangevraagd adres > Straatnaam',
      name: 'property.street',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'Aangevraagd adres > Huisnummer',
      name: 'property.houseNumber',
      outputType: ZapierFieldType.Number,
    },
    {
      label: 'Aangevraagd adres > Huisnummer toevoeging',
      name: 'property.addition',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'Aangevraagd adres > Waarde',
      name: 'valuation.predicted',
      outputType: ZapierFieldType.Number,
    },
    {
      label: 'Contactverzoek',
      name: 'contactRequested',
      outputType: ZapierFieldType.Boolean,
    },
    {
      label: 'Reden aanvraag',
      name: 'why',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'Verhuisintentie',
      name: 'when',
      outputType: ZapierFieldType.String,
    },
  ],
};
const valuationRequestOutputObject: OutputObject = {
  name: 'eventContactAppValuationRequest',
  outputType: FLOW_OUTPUT_TYPE.FlowOutputEventContactAppValuationRequest,
  objectLabel: 'Waarderapport - waardebepaling',
  objectSelectLabel: 'Selecteer waardebepaling',
  fields: [
    {
      label: 'Aangevraagd adres > Straat + huisnummer + toevoeging',
      name: 'property.address',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'Aangevraagd adres > Stad',
      name: 'property.city',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'Aangevraagd adres > Postcode',
      name: 'property.postcode',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'Aangevraagd adres > Straatnaam',
      name: 'property.street',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'Aangevraagd adres > Huisnummer',
      name: 'property.houseNumber',
      outputType: ZapierFieldType.Number,
    },
    {
      label: 'Aangevraagd adres > Huisnummer toevoeging',
      name: 'property.addition',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'Reden aanvraag',
      name: 'why',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'Verhuisintentie',
      name: 'when',
      outputType: ZapierFieldType.String,
    },
  ],
};

const fundaContactRequestOutputObject: OutputObject = {
  name: 'eventContactAppFundaContactRequest',
  outputType: FLOW_OUTPUT_TYPE.FlowOutputEventContactAppFundaContactRequest,
  objectLabel: 'Funda contactverzoek',
  objectSelectLabel: 'Selecteer Funda contactverzoek',
  fields: [
    {
      label: 'Adres uit Funda verzoek',
      name: 'subjectAddress',
      outputType: ZapierFieldType.String,
    },
  ],
};
const fundaViewingRequestOutputObject: OutputObject = {
  name: 'eventContactAppFundaViewingRequest',
  outputType: FLOW_OUTPUT_TYPE.FlowOutputEventContactAppFundaViewingRequest,
  objectLabel: 'Funda bezichtigingsverzoek',
  objectSelectLabel: 'Selecteer Funda bezichtigingsverzoek',
  fields: [
    {
      label: 'Voorkeursdag',
      name: 'day',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'Voorkeursdagdeel',
      name: 'timeOfDay',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'Adres uit Funda verzoek',
      name: 'subjectAddress',
      outputType: ZapierFieldType.String,
    },
  ],
};
const fundaBrochureRequestOutputObject: OutputObject = {
  name: 'eventContactAppFundaBrochureRequest',
  outputType: FLOW_OUTPUT_TYPE.FlowOutputEventContactAppFundaBrochureRequest,
  objectLabel: 'Funda brochureverzoek',
  objectSelectLabel: 'Selecteer Funda brochureverzoek',
  fields: [
    {
      label: 'Adres uit Funda verzoek',
      name: 'subjectAddress',
      outputType: ZapierFieldType.String,
    },
  ],
};

const bbWaardecheckReportOutputObject: OutputObject = {
  name: 'eventContactAppBBWaardecheckReport',
  outputType: FLOW_OUTPUT_TYPE.FlowOutputEventContactAppBBWaardecheckReport,
  objectLabel: 'Brainbay waardecheck',
  objectSelectLabel: 'Selecteer brainbay waardecheck',
  fields: [
    {
      label: 'Aangevraagd adres > Straat + huisnummer + toevoeging',
      name: 'property.address',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'Aangevraagd adres > Stad',
      name: 'property.city',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'Aangevraagd adres > Postcode',
      name: 'property.postcode',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'Aangevraagd adres > Straatnaam',
      name: 'property.street',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'Aangevraagd adres > Huisnummer',
      name: 'property.houseNumber',
      outputType: ZapierFieldType.Number,
    },
    {
      label: 'Aangevraagd adres > Huisnummer toevoeging',
      name: 'property.houseNumberAddition',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'Aangevraagd adres > Waarde',
      name: 'valuationPrice',
      outputType: ZapierFieldType.Number,
    },
    {
      label: 'Reden aanvraag',
      name: 'why',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'Verhuisintentie',
      name: 'when',
      outputType: ZapierFieldType.String,
    },
  ],
};

const bbWaardecheckAppraisalRequestOutputObject: OutputObject = {
  name: 'eventContactAppBBWaardecheckAppraisalRequest',
  outputType:
    FLOW_OUTPUT_TYPE.FlowOutputEventContactAppBBWaardecheckAppraisalRequest,
  objectLabel: 'Brainbay waardecheck - waardebepaling',
  objectSelectLabel: 'Selecteer brainbay waardecheck - waardebepaling',
  fields: [
    {
      label: 'Aangevraagd adres > Straat + huisnummer + toevoeging',
      name: 'property.address',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'Aangevraagd adres > Stad',
      name: 'property.city',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'Aangevraagd adres > Postcode',
      name: 'property.postcode',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'Aangevraagd adres > Straatnaam',
      name: 'property.street',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'Aangevraagd adres > Huisnummer',
      name: 'property.houseNumber',
      outputType: ZapierFieldType.Number,
    },
    {
      label: 'Aangevraagd adres > Huisnummer toevoeging',
      name: 'property.houseNumberAddition',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'Reden aanvraag',
      name: 'why',
      outputType: ZapierFieldType.String,
    },
    {
      label: 'Verhuisintentie',
      name: 'when',
      outputType: ZapierFieldType.String,
    },
  ],
};

const bbWaardecheckContactRequestOutputObject: OutputObject = {
  name: 'eventContactAppBBWaardecheckContactRequest',
  outputType:
    FLOW_OUTPUT_TYPE.FlowOutputEventContactAppBBWaardecheckContactRequest,
  objectLabel: 'Brainbay contactverzoek',
  objectSelectLabel: 'Selecteer brainbay contactverzoek',
  fields: [],
};

const zapierOutputObject: OutputObject = {
  name: 'eventContactAppZapier',
  outputType: FLOW_OUTPUT_TYPE.FlowOutputEventContactAppZapier,
  objectLabel: 'Zapier event',
  objectSelectLabel: 'Selecteer Zapier event',
  fields: [],
};

export const ZAPIER_TEMPLATES_OBJECT: {
  CONTACT: OutputObject;
  APP_VALUATIONREPORT: OutputObject;
  APP_VALUATIONREQUEST: OutputObject;
  APP_FUNDA_CONTACTREQUEST: OutputObject;
  APP_FUNDA_VIEWINGREQUEST: OutputObject;
  APP_FUNDA_BROCHUREREQUEST: OutputObject;
  APP_BBWAARDECHECK_REPORT: OutputObject;
  APP_BBWAARDECHECK_APPRAISALREQUEST: OutputObject;
  APP_BBWAARDECHECK_CONTACTREQUEST: OutputObject;
  APP_ZAPIER: OutputObject;
} = Object.freeze({
  CONTACT: contactOutputObject,
  APP_VALUATIONREPORT: valuationReportOutputObject,
  APP_VALUATIONREQUEST: valuationRequestOutputObject,
  APP_FUNDA_CONTACTREQUEST: fundaContactRequestOutputObject,
  APP_FUNDA_VIEWINGREQUEST: fundaViewingRequestOutputObject,
  APP_FUNDA_BROCHUREREQUEST: fundaBrochureRequestOutputObject,
  APP_BBWAARDECHECK_REPORT: bbWaardecheckReportOutputObject,
  APP_BBWAARDECHECK_APPRAISALREQUEST: bbWaardecheckAppraisalRequestOutputObject,
  APP_BBWAARDECHECK_CONTACTREQUEST: bbWaardecheckContactRequestOutputObject,
  APP_ZAPIER: zapierOutputObject,
});
