import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  ConnectedEmailAliasFieldsFragment,
  StartEmailAliasVerificationMutationVariables,
  EmailAliasStatus,
  useStartEmailAliasVerificationMutation,
} from '~/graphql/types';
import Button from '~/components/atom/Button';
import useCurrentAccount from '~/hooks/useCurrentAccount';

import TEST_ID from './EmailAlias.testid';
import cleanedFilename from '~/util/cleanedFilename';
import Validation from '~/util/Validation';
import EmailAliasDeleteButton from './EmailAliasDeleteButton';
import { subMinutes } from '~/util/date';
import useConfirmModal from '~/hooks/useConfirmModal';
import { assertNever } from '~/util/assertion';
import { SynchroniseEntityType } from '~/components/page/External/Nylas/types';
import AppErrorScreen from '~/components/template/AppErrorScreen';
import { EMAIL_SYNC_TIMEOUT } from '~/components/molecule/ChooseEmailSyncType/constants';
import JustificationContainer from '~/components/atom/JustificationContainer';
import InputWithIndicator from '~/components/molecule/InputWithIndicator';
import useErrorReporter from '~/hooks/useErrorReporter';
import Catalog from '~/Catalog';

const text = {
  verifyButtonLabel: 'Verifieer',
  verifyingTooltip: 'Het alias wordt geverifieerd',
  inputLabel: 'Alias',
  syncMutationErrorText:
    'Er is iets fout gegaan bij het verifiëren van het alias.',
  modalLabels: {
    title: 'Verificatieproces',
    message:
      'Het alias is toegevoegd. Een verificatie e-mail is gestuurd naar het alias. Let op, verificatie kan tot 15 minuten duren.',
    buttonConfirmTitle: 'Ok',
  },
};
export type EmailAliasType = {
  email: string | null;
  state: EmailAliasStatus | null;
  validatedDate: Date | null;
};
type Props = {
  mayEdit: boolean;
  entityType: SynchroniseEntityType;
  entityId: string;
  parentEmail: string;
  emailAlias: EmailAliasType | null;
  onChange: (newEmailAlias: string) => void;
  onSuccessfulDelete: () => void;
  onSuccessfulVerificationStart: (
    newFields: ConnectedEmailAliasFieldsFragment,
  ) => void;
};

const EmailAlias = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      parentEmail,
      emailAlias,
      onChange,
      entityType,
      entityId,
      onSuccessfulVerificationStart,
      onSuccessfulDelete,
      mayEdit,
    },
    ref,
  ) => {
    const errorReporter = useErrorReporter();
    const [startedVerification, setStartedVerification] = useState(false);
    const { setShowModal, modal } = useConfirmModal({
      level: 'success',
      labels: text.modalLabels,
      hideCancel: true,
      buttons: [
        {
          label: text.modalLabels.buttonConfirmTitle,
          // Do nothing, just inform the user
          onClick: () => {},
        },
      ],
    });

    const { email, state, validatedDate } = emailAlias || {
      email: null,
      state: null,
      validatedDate: null,
    };
    const account = useCurrentAccount();

    const [startEmailAliasVerification, { error, loading: mutationLoading }] =
      useStartEmailAliasVerificationMutation();

    if (error) {
      return (
        <StyledAppErrorScreen
          inline={true}
          message={text.syncMutationErrorText}
        />
      );
    }

    const startVerification = () => {
      setStartedVerification(true);

      if (emailAlias == null || emailAlias.email == null) {
        errorReporter.captureMessage(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | cannot determine email to sync for!`,
          'error',
        );
        return;
      }

      if (email == null) {
        errorReporter.captureMessage(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | cannot sync if no email is given`,
          'error',
        );
        return;
      }

      const variables = getVariables(
        entityType,
        entityId,
        parentEmail,
        emailAlias.email,
        account.id,
      );

      void startEmailAliasVerification({
        variables,
      }).then(mutationResult => {
        // If anything went wrong error prop would handle it
        if (mutationResult && mutationResult.data) {
          const { data } = mutationResult;
          const { startEmailAliasVerification } = data;

          onSuccessfulVerificationStart(startEmailAliasVerification);
          setShowModal(true);
        }
      });
    };

    const showVerifyButton = state == null;
    const isVerifyButtonDisabled =
      emailAlias == null || !Validation.Email.isValid(emailAlias.email);

    const isValidationFailed =
      state === EmailAliasStatus.Unverified &&
      validatedDate &&
      validatedDate.getTime() <
        subMinutes(new Date(), EMAIL_SYNC_TIMEOUT / 60000).getTime();

    const loading =
      mutationLoading ||
      (state === EmailAliasStatus.Unverified && !isValidationFailed);

    return (
      <>
        {modal}
        <JustificationContainer
          dataTestId={TEST_ID.CONTAINER}
          width="100%"
          align="end"
          gap="s"
          margin={['m', null, null, null]}
        >
          <InputWithIndicator
            ref={ref}
            data-testid={TEST_ID.INPUT}
            value={email}
            readOnly={!mayEdit || state != null}
            label={text.inputLabel}
            onChange={e => {
              if (e.target) {
                onChange(e.target.value);
              }
            }}
            validation={[
              (value: string) =>
                Validation.Email.isValid(value) ? true : Catalog.invalidEmail,
            ]}
            loading={loading}
            externalErrors={
              isValidationFailed ? [text.syncMutationErrorText] : []
            }
            onKeyDown={e => {
              if (e.key === 'Enter') {
                if (showVerifyButton && !isVerifyButtonDisabled) {
                  startVerification();
                }
              }
            }}
          />

          {showVerifyButton && (
            <Button
              size="medium"
              appearance="secondary"
              loading={startedVerification && state == null}
              disabled={!mayEdit || isVerifyButtonDisabled}
              data-testid={TEST_ID.VERIFY_BUTTON}
              onClick={startVerification}
              label={text.verifyButtonLabel}
            />
          )}
          <EmailAliasDeleteButton
            disabled={!mayEdit}
            loading={loading}
            accountId={account.id}
            emailAlias={emailAlias}
            onSuccessfulDelete={onSuccessfulDelete}
          />
        </JustificationContainer>
      </>
    );
  },
);

const getVariables = (
  type: SynchroniseEntityType,
  entityId: string,
  email: string,
  emailAlias: string,
  accountId: string,
): StartEmailAliasVerificationMutationVariables => {
  const variables = {
    email: email.toLocaleLowerCase(),
    emailAlias: emailAlias.toLocaleLowerCase(),
    accountId,
  };

  switch (type) {
    case SynchroniseEntityType.Account:
      return {
        accountId,
        account: variables,
      };
    case SynchroniseEntityType.User:
      return {
        accountId,
        user: variables,
      };
    case SynchroniseEntityType.Office:
      return {
        accountId,
        office: {
          ...variables,
          officeId: entityId,
        },
      };

    default:
      return assertNever(type);
  }
};

const StyledAppErrorScreen = styled(AppErrorScreen)<{}>`
  ${({ theme }) => css`
    margin-top: ${theme.space('m')};
    border-radius: ${theme.getTokens().border.radius.base};
  `}
`;

export default EmailAlias;
