import React from 'react';
import styled, { css } from 'styled-components';

import { Theme } from '~/theme';
import { FileType } from '~/util/constants';

import TEST_ID from './index.testid';
import Icon from '~/components/atom/Icon';
import ProgressBar from '~/components/molecule/ProgressBar';

type Props = {
  error: string | null;
  filename: string;
  fileType: FileType;
  onRemove: () => void;
  isUploading: boolean;
};
const FileUploadBar: React.FCC<Props> = ({
  filename,
  isUploading,
  onRemove,
  error,
}) => {
  const hasError = error != null && error.length > 0;

  return (
    <Container>
      <FileInfoContainer>
        <Icon name="file-text" />
        <Filename $hasError={hasError}>{filename}</Filename>
        {isUploading && !hasError && <ProgressBar loading={isUploading} />}
      </FileInfoContainer>
      <ButtonContainer onClick={onRemove} data-testid={TEST_ID.REMOVE_BUTTON}>
        <StyledCloseIcon name="close" />
      </ButtonContainer>
    </Container>
  );
};

const getPadding = (theme: Theme) => theme.space('xxs');
const Container = styled.div<{}>`
  display: flex;
  align-items: center;
  width: 100%;

  ${({ theme }) => css`
    background-color: ${theme.color('white', 'dark')};
    color: ${theme.color('tertiary')};
  `};
`;
const FileInfoContainer = styled.div<{}>`
  display: flex;
  align-items: center;
  flex-grow: 1;

  ${({ theme }) => css`
    padding: ${getPadding(theme)} 0 ${getPadding(theme)} ${getPadding(theme)};
  `};
`;

type FilenameProps = {
  $hasError: boolean;
};
const Filename = styled.span<FilenameProps>`
  ${({ theme }) => css`
    margin: 0 auto 0 ${theme.space('s')};
    color: ${theme.color('text')};
  `};

  ${({ theme, $hasError }) => {
    if ($hasError) {
      return css`
        color: ${theme.color('danger')};
      `;
    }
    return ``;
  }}
`;

const ButtonContainer = styled.div<{}>`
  cursor: pointer;

  ${({ theme }) => css`
    padding: ${getPadding(theme)};
    margin-left: ${theme.space('xxs')};
  `};
`;
const StyledCloseIcon = styled(Icon)<{}>(
  ({ theme }) => css`
    font-size: ${theme.fontSize('s')};
  `,
);

export default FileUploadBar;
