import { isNil } from 'ramda';
import React, { memo, useState } from 'react';
import { animated, useTransition } from 'react-spring';
import styled, { css } from 'styled-components';
import Icon from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { WidgetSettingsPinned } from '~/graphql/types';
import { PinAction } from '../Group';
import useDebounce from '~/hooks/useDebounce';
import SelectGroup, { Option } from '~/components/molecule/SelectGroup';

export type Props = {
  onClick: (type: PinAction, weight: WidgetSettingsPinned['weight']) => void;
  options: Array<Option>;
  selectedOption?: Option;
  disabled?: boolean;
};

const Pin: React.FCC<Props> = memo(
  ({ dataTestId, onClick, options, selectedOption, disabled, ...rest }) => {
    const [hovered, setHovered] = useState<boolean>(false);
    const debouncedHovered = useDebounce(hovered);
    const isPinned = !isNil(selectedOption);

    const showSelection = debouncedHovered || isPinned;
    const transition = useTransition(showSelection, {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      config: {
        mass: 1,
        tension: 110,
        friction: 14,
      },
    });

    return (
      <Container
        {...rest}
        dataTestId={dataTestId}
        align="center"
        disabled={disabled}
        onMouseEnter={() => {
          !disabled && !showSelection && setHovered(true);
        }}
        onMouseLeave={() => setHovered(false)}
      >
        <StyledIcon
          disabled={disabled}
          $hovered={hovered}
          $pinned={isPinned}
          name="pin"
          onClick={() => {
            if (selectedOption) {
              onClick('unpin', selectedOption.value);
            }
          }}
        />
        {transition(
          (style, showSelection) =>
            showSelection && (
              <animated.div style={style}>
                <StyledSelectGroup
                  size="small"
                  options={options}
                  selectedIndex={options.findIndex(
                    option => option.value === selectedOption?.value,
                  )}
                  onChange={option => {
                    if (option.option) {
                      if (option.option.value === selectedOption?.value) {
                        return onClick('unpin', option.option.value);
                      }
                      return onClick('pin', option.option.value);
                    }
                  }}
                />
              </animated.div>
            ),
        )}
      </Container>
    );
  },
);

const Container = styled(JustificationContainer)<{ disabled?: boolean }>(
  ({ disabled = false }) => css`
    ${disabled && `pointer-events: none`}
  `,
);

const StyledSelectGroup = styled(SelectGroup)(
  ({ theme }) => css`
    label {
      font-weight: ${theme.fontWeight('regular')};
      padding: ${theme.space('xxxs')} ${theme.space('xxs')};
    }
    margin-left: ${theme.space('s')};
  `,
);

const StyledIcon = styled(Icon)<{
  $pinned: boolean;
  $hovered: boolean;
  disabled?: boolean;
}>(
  ({ theme, $pinned, $hovered, disabled = false }) => css`
    cursor: pointer;
    transition: all 0.2s ease;
    stroke: ${disabled
      ? theme.color('tertiary')
      : $pinned
        ? theme.color('primary')
        : 'inherit'};
    transform: ${$hovered || $pinned
      ? 'scale(1.2) rotate(-45deg)'
      : 'scale(1)'};
  `,
);
export default Pin;
