import { isEmpty } from 'ramda';
import {
  AppConfigurationFragment,
  WidgetSettingsAppConfiguration__Input,
  WidgetSettingsPinned,
  WidgetSettingsPinned__Input,
} from '~/graphql/types';
import { PinnedAppsMap } from '../../state/pinnedApps';
import indexToWeight from '../indexToWeight';
import type { GroupLookup } from '../../components/Apps/components/ToggleAccordion';

/**
 * returns appGroupsLookup in a shape for widget settings apps configuration input
 * @param {GroupLookup} appGroupsLookup
 * @param {Array<AppsConfigurationFragment>} appsConfiguration
 */

const serializePinnedFields = (
  pinned?: WidgetSettingsPinned,
): WidgetSettingsPinned__Input | undefined => {
  if (!pinned) return;
  const serializedWeight = indexToWeight(pinned.weight);
  return { enabled: pinned.enabled, weight: serializedWeight };
};
const getAppsConfigurationInput = (
  pinnedApps: PinnedAppsMap,
  appGroupsLookup: GroupLookup,
  appsConfiguration: Array<AppConfigurationFragment>,
): Array<WidgetSettingsAppConfiguration__Input> => {
  if (appsConfiguration.length === 0 || isEmpty(appGroupsLookup)) return [];

  const appsConfigurationInput: Array<WidgetSettingsAppConfiguration__Input> =
    [];

  for (const key in appGroupsLookup) {
    const { mainChecked, items, hidden } = appGroupsLookup[key];

    appsConfigurationInput.push({
      type: key,
      enabled: mainChecked,
      hidden,
      pinned: serializePinnedFields(pinnedApps[key]),
      items: items
        ? items.map(item => ({
            id: item.id,
            enabled: item.checked,
            hidden: item.hidden,
            pinned: serializePinnedFields(pinnedApps[item.id]),
          }))
        : [],
    });
  }

  return appsConfigurationInput;
};
export default getAppsConfigurationInput;
