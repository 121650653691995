import { equals } from 'ramda';
import type { ConditionExpression } from '~/components/page/Automation/v2/components/UpdateAction/components/ConditionEditorV2/types.ts';

const isMetadataRequired = ({
  before,
  after,
}: {
  before?: ConditionExpression | null;
  after: ConditionExpression;
}): boolean => {
  if (!before) return false;
  return !equals(before, after);
};

export default isMetadataRequired;
