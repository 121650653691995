import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

export type Props = {
  lowQualitySrc: string;
  highQualitySrc: string;
  srcset?: string;
  width: number;
  alt?: string;
  className?: string;
};

const ProgressiveImage: React.FCC<Props> = ({
  srcset,
  width,
  highQualitySrc,
  lowQualitySrc,
  className,
  alt,
}) => {
  const [source, setSource] = useState<string>(lowQualitySrc);
  const [loaded, setLoaded] = useState<boolean>(false);
  const highQualitySource = highQualitySrc ?? lowQualitySrc;
  const lowQualitySource = lowQualitySrc ?? highQualitySrc;

  useEffect(() => {
    let sourceWasUpdated = false;
    setLoaded(false);
    setSource(lowQualitySource);
    const img = new Image();
    if (srcset) {
      img.srcset = srcset;
    } else {
      img.src = highQualitySource;
    }
    img.onload = () => {
      if (!sourceWasUpdated) {
        setSource(highQualitySource);
        setLoaded(true);
      }
    };
    return () => {
      sourceWasUpdated = true;
    };
  }, [lowQualitySource, highQualitySource, srcset]);

  return (
    <LocalImage
      className={className}
      alt={alt}
      src={source}
      srcSet={loaded ? srcset : undefined}
      $blurred={!loaded}
      $width={width}
    />
  );
};

const LocalImage = styled.img<{ $blurred: boolean; $width: number }>(
  ({ $blurred, $width }) => css`
    ${$blurred ? 'filter: blur(20px); transition: filter 1s ease-out;' : ''}
    max-width: ${$width}px;
    width: 100%;

    display: block;
    page-break-inside: avoid;
  `,
);

export default ProgressiveImage;
