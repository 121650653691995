import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Button from '~/components/atom/Button';
import useRelativeMaps from '~/components/page/Automation/v2/components/Builder/hooks/useRelativeMaps';
import TextButton from '~/components/atom/TextButton';
import {
  FlowV2_Action_SendEmail_PlainFragment,
  Flow___ArgumentFragment,
  type FlowV2_Action_SendNotificationFragment,
} from '~/graphql/types';
import useBuilderContext from '~/components/page/Automation/v2/components/Builder/hooks/useBuilderContext';
import Selector from '../../../Selector';
import getPathForPointer from '~/components/page/Automation/v2/components/UpdateAction/components/Selector/utils/getPathForPointer';
import { getPathRepresentation } from '../../../ConditionEditorV2/utils/getLabelForRep';
import FileAttachment from '../FileAttachment';
import UploadAttachment from '../UploadAttachment';
import TEST_ID from './index.testid';
import { Container as VariableContainer } from '../../../../../Builder/components/nodeTypes/components/TemplateString/components/Variable';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { animated, useTransition, UseTransitionProps } from 'react-spring';
import groupAttachments from '../../utils/groupAttachments';
import { isNil } from 'ramda';
import RepresentationLabel from '~/components/page/Automation/v2/components/Builder/components/RepresentationLabel';
import { ANIMATION_CONFIG } from '~/styles/constants';
import passKeysToAttachments from '../../utils/passKeysToAttachments';
import type { Client_FlowV2_EmailAttachment } from '../../../types';
import type { Flow___ConditionPartial } from '~/components/page/Automation/v2/components/UpdateAction/components/ConditionEditorV2/types.ts';

type Attachment = Client_FlowV2_EmailAttachment;
export type Props = {
  dataTestId?: string;
  action:
    | FlowV2_Action_SendEmail_PlainFragment
    | FlowV2_Action_SendNotificationFragment;
  attachments: Array<Attachment>;
  onError: (error: string | null) => void;
  onDelete: (file: Attachment['file']) => void;
  onPointerInsert: (attachment: Attachment) => void;
  onPointerUpdate: (obj: { key: string; value: Attachment }) => void;
  onFileUpload: (attachment: Attachment) => void;
};

const text = {
  addAttachment: 'Bijlagen toevoegen',
  header: 'Bijlagen:',
  noPointer:
    'Foute variabele (waarschijnlijk heb je een stap aangepast of verwijderd)',
};

const EmailAttachments: React.FCC<Props> = ({
  onPointerInsert,
  onPointerUpdate,
  onFileUpload,
  action,
  attachments,
  onError,
  onDelete,
}) => {
  const attachmentsWithKey = passKeysToAttachments(attachments);

  const [showSelector, setShowSelector] = useState(false);
  const [currentKey, setCurrentKey] = useState<string | null>(null);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const [openingPosition, setOpeningPosition] = useState<number>(0);
  const [moveSelector, setMoveSelector] = useState<number>(0);

  const builderContext = useBuilderContext();
  const maps = useRelativeMaps({ actionId: action.id });
  const { opts, superSubjects } = builderContext;

  const onClose = useCallback(() => {
    setCurrentKey(null);
    setShowSelector(false);
    setMoveSelector(0);
    setOpeningPosition(0);
  }, []);

  const onSelectPointer = useCallback(
    (value: Flow___ArgumentFragment | Flow___ConditionPartial) => {
      if (value.__typename !== 'Flow___Argument_Pointer') return;

      const newValue: Client_FlowV2_EmailAttachment = {
        __typename: 'FlowV2_EmailAttachment',
        file: {
          __typename: 'Flow___Argument_Pointer',
          pointer: {
            __typename: 'Flow___Pointer',
            path: value.pointer.path,
          },
        },
      };

      if (currentKey) {
        onPointerUpdate({ key: currentKey, value: newValue });
      } else {
        onPointerInsert(newValue);
      }

      onClose();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentKey],
  );

  const onAttachmentDelete = useCallback(
    (file: Client_FlowV2_EmailAttachment['file']) => {
      onClose();
      onDelete(file);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const groupedAttachments = groupAttachments(attachmentsWithKey);
  const { file: fileAttachments, pointer: pointerAttachments } =
    groupedAttachments;

  const pointerTransitions = useTransition(pointerAttachments, transitionProps);
  const fileTransitions = useTransition(fileAttachments, transitionProps);

  useEffect(() => {
    if (containerRef.current && showSelector) {
      const rect = containerRef.current?.getBoundingClientRect();
      /** Subtract 40 because of the triangle */
      setMoveSelector(openingPosition - rect.bottom - 40);
    } else {
      setMoveSelector(0);
    }
  }, [openingPosition, showSelector]);

  return (
    <Container data-testid={TEST_ID.CONTAINER}>
      {showSelector && (
        <SelectorContainer $top={moveSelector} ref={containerRef}>
          <Selector
            onClose={onClose}
            onSelect={value => {
              if (
                value.__typename === 'Flow___InstanceCondition' ||
                value.__typename === 'Flow___SubjectFieldCondition'
              )
                return;

              onSelectPointer(value);
            }}
            opts={{
              ...maps,
              conditionMap: opts.conditionMap,
              subjectToConditionMap: opts.subjectToConditionMap,
              action: action.actionType,
              conditionType: 'condition',
              limitToInstanceSubjects: superSubjects.File.subjectIds,
            }}
            pointerLocation="bottom"
          >
            <UploadAttachment
              isPointerUpdating={isNil(currentKey)}
              attachments={attachmentsWithKey}
              onFileUploaded={value => {
                onClose();
                onFileUpload(value);
              }}
              onError={onError}
            />
          </Selector>
        </SelectorContainer>
      )}

      <Header>{text.header}</Header>

      <TextButton
        label={text.addAttachment}
        icon="plus"
        onClick={e => {
          setShowSelector(true);
          setCurrentKey(null);
          setMoveSelector(0);
          setOpeningPosition(e.clientY);
        }}
        dataTestId={TEST_ID.ADD_ATTACHMENT_BUTTON}
        margin={['m', null, null, null]}
      />

      <JustificationContainer
        justification="space-between"
        margin={['base', null]}
        direction="column"
        dataTestId={TEST_ID.FILE_ATTACHMENTS_CONTAINER}
      >
        {fileTransitions((style, attachment) => (
          <AnimationContainer style={style}>
            <FileAttachment
              key={attachment.key}
              attachment={attachment}
              onDelete={() => onAttachmentDelete(attachment.file)}
              dataTestId={TEST_ID.INSERTED_FILE_ATTACHMENT}
            />
          </AnimationContainer>
        ))}
      </JustificationContainer>

      {pointerTransitions((style, attachment) => {
        if (!attachment) return;

        const path = getPathForPointer(attachment.file.pointer, maps);

        const label =
          'fullResult' in path
            ? getPathRepresentation(path.fullResult)
            : text.noPointer;

        return (
          <AnimationContainer style={style}>
            <JustificationContainer
              justification="space-between"
              key={attachment.key}
              margin={[null, null, 'xxs', null]}
            >
              <VariableContainer
                onClick={e => {
                  if (attachment.key === currentKey) return;
                  setCurrentKey(attachment.key);
                  setShowSelector(true);

                  setMoveSelector(0);
                  setOpeningPosition(e.clientY);
                }}
                data-testid={TEST_ID.INSERTED_POINTER_ATTACHMENT}
                $isValid={isNil(path.error)}
              >
                <RepresentationLabel label={label} />
              </VariableContainer>
              <Button
                icon="delete"
                appearance="danger"
                ghost
                onClick={() => onAttachmentDelete(attachment.file)}
                dataTestId={TEST_ID.DELETE_POINTER_ATTACHMENT_BUTTON}
              />
            </JustificationContainer>
          </AnimationContainer>
        );
      })}
    </Container>
  );
};

const transitionProps: UseTransitionProps = {
  from: { transform: 'translate(20px,0px)', opacity: 0 },
  enter: { transform: 'translate(0px,0px)', opacity: 1 },
  leave: { transform: 'translate(20px,0px)', opacity: 0 },
  config: ANIMATION_CONFIG.config,
  keys: item => item.key,
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    margin-top: ${theme.space('m')};
    position: relative;
  `,
);

const AnimationContainer = styled(animated.div)<{}>`
  width: 100%;
`;

const SelectorContainer = styled.div<{ $top: number }>(
  ({ theme, $top }) => css`
    background-color: ${theme.color('white')};
    box-shadow: ${theme.getTokens().boxShadow.around};
    width: 100%;
    position: absolute;
    top: ${`${$top}px`};
  `,
);

const Header = styled.span<{}>``;

export default EmailAttachments;
