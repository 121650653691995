import type { FlowPath } from '../../getFieldsByPath';
import getSubject, { SubjectMap } from '../../getSubject';
import hydrateField from '../../hydrateField';
import { DirectoryMap, hydrateDirectories } from '../../getDirectory';
import type { Flow___SubjectFieldConditionPartial } from '~/components/page/Automation/v2/components/UpdateAction/components/ConditionEditorV2/types.ts';

const getPathForFieldSubjectCondition = (
  condition: Flow___SubjectFieldConditionPartial,
  {
    subjectMap,
    directoryMap,
  }: {
    subjectMap: SubjectMap;
    directoryMap: DirectoryMap;
  },
):
  | { result: FlowPath; fullResult: FlowPath; error?: undefined }
  | { error: string } => {
  const subject = getSubject(
    { type: condition.type, typeId: condition.typeId },
    subjectMap,
  );
  if (!subject) {
    return {
      error: `Could not find subject ${condition.type} ${condition.typeId}`,
    };
  }

  const field = subject.fields.find(field => field.key === condition.field);
  if (!field) {
    return {
      error: `Could not find field ${condition.field} in subject ${condition.type} ${condition.typeId}`,
    };
  }

  const result = hydrateDirectories(subject.dir ?? [], {
    directoryMap,
  });
  if (!result) return { error: 'Missing dir' };

  return { result, fullResult: [...result, hydrateField(field, subject)] };
};
export default getPathForFieldSubjectCondition;
