import { SynchroniseEntityType } from '~/components/page/External/Nylas/types';
import {
  type EmailProvider_Realworks__Input,
  type RealworksEmailSync__Input,
} from '~/graphql/types';
import { assertNever } from '~/util/assertion';

/**
 * Returns variables for startEmailSyncV3 for Realworks
 */
const getRealworksVariablesForMutation = ({
  entityType,
  entityId,
  providerInfo,
}: {
  entityType: SynchroniseEntityType;
  entityId: string;
  providerInfo: EmailProvider_Realworks__Input;
}): RealworksEmailSync__Input => {
  switch (entityType) {
    case SynchroniseEntityType.Account:
      return {
        account: providerInfo,
      };
    case SynchroniseEntityType.Office:
      return {
        office: {
          ...providerInfo,
          officeId: entityId,
        },
      };
    case SynchroniseEntityType.User:
      return {
        user: providerInfo,
      };

    default:
      return assertNever(entityType);
  }
};

export default getRealworksVariablesForMutation;
