import type { ColorString } from '../types';

type LeadScoreColorMap = {
  eq99: ColorString;
  gte90: ColorString;
  gte70: ColorString;
  gte50: ColorString;
  gte30: ColorString;
  lt30: ColorString;
};

const leadScore: LeadScoreColorMap = {
  eq99: '#C6020B',
  gte90: '#FF6900',
  gte70: '#FFB464',
  gte50: '#FFD6A8',
  gte30: '#B6D3FF',
  lt30: '#F7F9FC',
};

export type SystemLeadScoreMap = typeof leadScore;
export type SystemLeadScore = keyof SystemLeadScoreMap;

export default leadScore;
