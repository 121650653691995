import React from 'react';
import styled, { css } from 'styled-components';
import Overlay from '~/components/organism/ModalsV2/Overlay';
import Header from '../Header';
import Aside from '../Aside';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { ExtendedAppConfig } from '~/hooks/useApps';
import Modal from '~/components/organism/ModalsV2/Modal';

export type Props = {
  /** App config data */
  data: ExtendedAppConfig;

  /** Components to add to the main part of the modal */
  main: React.ReactNode;

  /** Components to add at the top of the side bar */
  aside?: React.ReactNode;

  /** Close modal */
  onClose: () => void;
};

const MODAL_MAX_WIDTH = '890px';
const ModalTemplate: React.FCC<Props> = ({
  dataTestId,
  onClose,
  data,
  main,
  aside,
}) => (
  <Overlay root="app-setup" dataTestId={dataTestId} onClose={onClose}>
    <Modal onClose={onClose} maxWidth={MODAL_MAX_WIDTH}>
      <ChildContainer align="stretch">
        <Main direction="column">
          <Header name={data.name} logo={data.logo} tags={data.tags} />
          {main}
        </Main>

        <Aside
          price={data.price}
          compatibility={data.compatibility}
          help={data.help}
          provider={data.provider}
        >
          {aside}
        </Aside>
      </ChildContainer>
    </Modal>
  </Overlay>
);

const ChildContainer = styled(JustificationContainer)(
  ({ theme }) => css`
    ${theme.mq.lessThan('tablet')`
     flex-direction: column;
    `}
  `,
);

const Main = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    background-color: ${theme.color('white')};
    padding: ${theme.space('xxl')};
    flex-basis: 70%;
    min-height: 70vh;

    ${theme.mq.lessThan('tablet')`
      min-height: max-content;
    `}
  `,
);

export default ModalTemplate;
