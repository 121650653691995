import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Strong, Heading4, Body } from '~/components/atom/Typography';

const text = {
  success: 'E-mailadres is succesvol gekoppeld:',
  successHeading: 'Succes!',
};

export type Props = {
  email: string;
};

const SuccessDisplay: React.FCC<Props> = ({ email }) => (
  <Container padding={['base']} direction="column">
    <Heading4 color={{ group: 'success' }}>{text.successHeading}</Heading4>
    <JustificationContainer gap="s" align="center">
      <Icon
        name="check"
        background={{ group: 'success' }}
        color={{ group: 'white' }}
      />
      <Body margin={[null]}>{text.success}</Body>
      <Strong margin={[null]} size="m" color={{ group: 'success' }}>
        {email}
      </Strong>
    </JustificationContainer>
  </Container>
);

const Container = styled(JustificationContainer)(
  ({ theme }) => css`
    background-color: ${theme.color('success', 'translucent')};
    border: ${theme.getTokens().border.radius.base};
  `,
);

export default SuccessDisplay;
