import React from 'react';
import type { JsonStructure, JsonStructureItem } from 'react-cmdk';
import { navigate } from '@gatsbyjs/reach-router';
import Icon from '~/components/atom/Icon';
import { useSetRecoilState } from 'recoil';
import { pageSelector } from '../../state';

const useFlowsItems = (): JsonStructure[number] => {
  const setPage = useSetRecoilState(pageSelector);

  const items: Array<JsonStructureItem> = [
    {
      id: 'flows',
      children: 'Flows',
      icon: () => <Icon name="branches" color={{ group: 'secondary' }} />,
      onClick: () => {
        void navigate('/-/automation/flows');
      },
      showType: false,
      keywords: ['flow', 'automation'],
    },
    {
      id: 'create-flow',
      children: 'Nieuwe Flow',
      icon: () => <Icon name="plus" color={{ group: 'secondary' }} />,
      showType: false,
      keywords: ['new', 'automation', 'flow'],
      onClick: () => {
        void navigate('/-/automation/flows/wizard/v2/');
      },
    },
    {
      id: 'open-flow',
      children: 'Flow openen',
      icon: () => <Icon name="branches" color={{ group: 'secondary' }} />,
      onClick: () => {
        setPage('open-flow');
      },
      keywords: ['automation', 'flow'],
      closeOnSelect: false,
      showType: false,
    },
  ];

  return {
    heading: 'Flows',
    id: 'automation',
    items,
  };
};

export default useFlowsItems;
