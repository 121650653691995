import { useCallback, useEffect, useRef } from 'react';
import { uniqBy, prop } from 'ramda';
import { sleep } from '~/util';
import { GroupedActivities } from '~/state/activities';
import type { ActivityV2 } from '../../types';
import type {
  GetActivitiesForContactQuery,
  MutatedActivitySubscription,
  GetActivitiesForContactQueryVariables,
} from '~/graphql/types';
import type {
  FetchMoreQueryOptions,
  FetchMoreOptions,
  FetchResult,
} from '@apollo/client';

// Export for test
export const activityLookupMap = new Map<string, boolean>();

type FetchMoreFunction = (
  options: FetchMoreQueryOptions<
    GetActivitiesForContactQueryVariables,
    GetActivitiesForContactQuery
  > &
    FetchMoreOptions<GetActivitiesForContactQuery>,
) => Promise<FetchResult<GetActivitiesForContactQuery>>;

type UseFindActivityParams = {
  initialNextToken?: string | null;
  groupedActivities: GroupedActivities;
  fetchMore: FetchMoreFunction;
  mutatedActivity?: MutatedActivitySubscription['mutatedActivity'];
  limit: number;
};

const MAX_FETCH_ATTEMPTS = 10;

const useFindActivity = ({
  groupedActivities,
  initialNextToken,
  fetchMore,
  mutatedActivity,
  limit,
}: UseFindActivityParams) => {
  // Use ref to track previous activities to prevent unnecessary map updates
  const prevActivitiesRef = useRef<GroupedActivities>();

  useEffect(() => {
    // Only update map if activities have changed
    if (prevActivitiesRef.current !== groupedActivities) {
      Object.values(groupedActivities).forEach(activities => {
        activities?.forEach(activity => {
          if (!activityLookupMap.has(activity.id)) {
            activityLookupMap.set(activity.id, true);
          }
        });
      });
      prevActivitiesRef.current = groupedActivities;
    }
  }, [groupedActivities]);

  const fetchUntilActivityFound = useCallback(
    async (activityId: string) => {
      let nextToken = initialNextToken;
      let attempts = 0;

      try {
        if (activityLookupMap.has(activityId)) {
          // Activity is already loaded, exiting..
          return true;
        }

        while (nextToken && attempts < MAX_FETCH_ATTEMPTS) {
          attempts++;

          const result = await fetchMore({
            variables: {
              nextToken,
              limit,
            },
            updateQuery: (
              previousResult: GetActivitiesForContactQuery,
              { fetchMoreResult },
            ) => {
              if (!fetchMoreResult?.getActivitiesForContact) {
                return previousResult;
              }

              return {
                __typename: 'Query',
                getActivitiesForContact: {
                  ...fetchMoreResult.getActivitiesForContact,
                  items: uniqBy(prop('id'), [
                    ...(mutatedActivity ? [mutatedActivity] : []),
                    ...previousResult.getActivitiesForContact.items,
                    ...fetchMoreResult.getActivitiesForContact.items,
                  ]),
                },
              };
            },
          });

          if (!result.data?.getActivitiesForContact?.items) {
            break;
          }

          const foundInNewBatch =
            result.data.getActivitiesForContact.items.some(
              (activity: ActivityV2) => activity.id === activityId,
            );

          if (foundInNewBatch) {
            return true;
          }

          nextToken = result.data.getActivitiesForContact.nextToken ?? null;
          if (!nextToken) break;

          await sleep(300);
        }

        return false;
      } catch (error) {
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialNextToken, fetchMore, mutatedActivity],
  );

  return { fetchUntilActivityFound };
};

export default useFindActivity;
