import React from 'react';
import {
  Form,
  Field,
  NumberField,
  PostalCodeField,
  EmailField,
} from '~/components/organism/Forms';
import Validation from '~/util/Validation';
import objectDiff from '~/util/objectDiff';
import Catalog from '~/Catalog';
import AnimatedBlock from '~/components/atom/AnimatedBlock';
import FormUtils from '~/components/organism/FormUtils';
import Input from '~/components/molecule/Input';
import JustificationContainer from '~/components/atom/JustificationContainer';
import FormSaveBar from '~/components/organism/SaveBar/components/FormSaveBar';

const text = {
  phoneValidation: Catalog.invalidPhone,
  postcodeValidation: Catalog.invalidPostalCode,
  emailValidation: Catalog.invalidEmail,
  emailLabel: Catalog.emailLabel,
  phoneLabel: Catalog.phoneLabel,
  streetLabel: Catalog.streetLabel,
  houseNumberLabel: Catalog.houseNumberLabel,
  houseAdditionLabel: Catalog.houseAdditionLabel,
  postalCodeLabel: Catalog.postalCodeLabel,
  cityLabel: Catalog.cityLabel,
  noAddress: Catalog.noAddress,
  noPostalCode: Catalog.noPostalCode,
  noCity: Catalog.noCity,
  noHouseNumber: Catalog.noHouseNumber,
  noEmail: Catalog.noEmail,
  noPhone: Catalog.noPhone,
  noStreet: Catalog.noAddress,
};
export type FormData = {
  email: string | null;
  phone: string | null;
  street: string | null;
  houseNumber: number | null;
  addition: (string | null | undefined) | null;
  postcode: string | null;
  city: string | null;
};

type Props = {
  loading: boolean;
  isUserAdmin: boolean;
  initialValues: FormData;
  onSubmitChanges: (changedFields: $Object) => any;
};
const OfficeDetailForm: React.FCC<Props> = ({
  onSubmitChanges,
  initialValues,
  isUserAdmin,
  loading,
}) => (
  <Form
    onSubmit={(fields: FormData) => {
      const changed = objectDiff(initialValues, fields);

      if (changed != null) {
        onSubmitChanges(changed);
      }
    }}
    validate={validate}
    initialValues={initialValues}
  >
    {({ handleSubmit, submitError, submitting }) => (
      <form
        id="office-detail-form"
        data-testid="office-detail-form"
        onSubmit={handleSubmit}
      >
        {submitError ? (
          <div data-testid="office-error-message">{submitError}</div>
        ) : null}
        <AnimatedBlock>
          <JustificationContainer direction="column" width="100%" gap="s">
            <EmailField name="email">
              {({ input, meta: { error, touched } }) => (
                <Input
                  size="large"
                  width="100%"
                  label={{ text: text.emailLabel }}
                  externalErrors={
                    FormUtils.showError(error, touched)
                      ? [FormUtils.showError(error, touched)]
                      : []
                  }
                  disabled={submitting || !isUserAdmin}
                  {...input}
                />
              )}
            </EmailField>

            <Field name="phone">
              {({ input, meta: { error, touched } }) => (
                <Input
                  size="large"
                  width="100%"
                  label={{ text: text.phoneLabel }}
                  externalErrors={
                    FormUtils.showError(error, touched)
                      ? [FormUtils.showError(error, touched)]
                      : []
                  }
                  disabled={submitting || !isUserAdmin}
                  {...input}
                  dataTestId="user-phone-input"
                />
              )}
            </Field>

            <Field name="street">
              {({ input, meta: { error, touched } }) => (
                <Input
                  size="large"
                  width="100%"
                  label={{ text: text.streetLabel }}
                  type="text"
                  externalErrors={
                    FormUtils.showError(error, touched)
                      ? [FormUtils.showError(error, touched)]
                      : []
                  }
                  disabled={submitting || !isUserAdmin}
                  {...input}
                />
              )}
            </Field>
            <JustificationContainer width="100%" gap="s">
              <NumberField name="houseNumber">
                {({ input, meta: { error, touched } }) => (
                  <Input
                    size="large"
                    width="100%"
                    label={{ text: text.houseNumberLabel }}
                    type="text"
                    externalErrors={
                      FormUtils.showError(error, touched)
                        ? [FormUtils.showError(error, touched)]
                        : []
                    }
                    disabled={submitting || !isUserAdmin}
                    {...input}
                  />
                )}
              </NumberField>

              <Field name="addition">
                {({ input, meta: { error, touched } }) => (
                  <Input
                    size="large"
                    width="100%"
                    label={{ text: text.houseAdditionLabel }}
                    type="text"
                    error={
                      FormUtils.showError(error, touched)
                        ? [FormUtils.showError(error, touched)]
                        : []
                    }
                    disabled={submitting || !isUserAdmin}
                    {...input}
                  />
                )}
              </Field>
            </JustificationContainer>
            <JustificationContainer width="100%" gap="s">
              <PostalCodeField name="postcode">
                {({ input, meta: { error, touched } }) => (
                  <Input
                    size="large"
                    width="100%"
                    label={{ text: text.postalCodeLabel }}
                    type="text"
                    externalErrors={
                      FormUtils.showError(error, touched)
                        ? [FormUtils.showError(error, touched)]
                        : []
                    }
                    disabled={submitting || !isUserAdmin}
                    {...input}
                  />
                )}
              </PostalCodeField>

              <Field name="city">
                {({ input, meta: { error, touched } }) => (
                  <Input
                    size="large"
                    width="100%"
                    label={{ text: text.cityLabel }}
                    type="text"
                    externalErrors={
                      FormUtils.showError(error, touched)
                        ? [FormUtils.showError(error, touched)]
                        : []
                    }
                    disabled={submitting || !isUserAdmin}
                    {...input}
                  />
                )}
              </Field>
            </JustificationContainer>
          </JustificationContainer>

          <FormSaveBar disableSave={loading || submitting} inBlockComponent />
        </AnimatedBlock>
      </form>
    )}
  </Form>
);

export const validate = (fields: FormData) => {
  const errors: {
    email: string | undefined;
    phone: string | undefined;
    street: string | undefined;
    houseNumber: string | undefined;
    addition: string | undefined;
    postcode: string | undefined;
    city: string | undefined;
  } = {
    email: undefined,
    phone: undefined,
    street: undefined,
    houseNumber: undefined,
    addition: undefined,
    postcode: undefined,
    city: undefined,
  };

  if (Validation.String.isEmpty(fields.email)) {
    errors.email = text.noEmail;
  }
  if (Validation.String.isEmpty(fields.phone)) {
    errors.phone = text.noPhone;
  }
  if (Validation.String.isEmpty(fields.street)) {
    errors.street = text.noStreet;
  }
  if (Validation.String.isEmpty(fields.houseNumber)) {
    errors.houseNumber = text.noHouseNumber;
  }
  if (Validation.String.isEmpty(fields.postcode)) {
    errors.postcode = text.noPostalCode;
  }
  if (Validation.String.isEmpty(fields.city)) {
    errors.city = text.noCity;
  }

  if (
    Validation.String.isNonEmptyString(fields.phone) &&
    !Validation.Phone.isValid(fields.phone)
  ) {
    errors.phone = text.phoneValidation;
  }
  if (
    Validation.String.isNonEmptyString(fields.postcode) &&
    !Validation.Postcode.isValid(fields.postcode)
  ) {
    errors.postcode = text.postcodeValidation;
  }
  if (
    Validation.String.isNonEmptyString(fields.email) &&
    !Validation.Email.isValid(fields.email)
  ) {
    errors.email = text.emailValidation;
  }

  return errors;
};

export default OfficeDetailForm;
