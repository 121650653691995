import { type StartEmailSyncV3MutationVariables } from '~/graphql/types';
import { reporter } from '~/hooks/useErrorReporter';
import { NylasState, SynchroniseEntityType } from '../../types';
import { REDIRECT_URI } from '../../constants';

/**
 * Return mutation variables for startEmailSyncV3 mutation
 * @param {NylasState} state - NylasState
 * @param {string} code - Code returned from nylas
 * keywords: startEmailSyncV3
 */
const mutationVariablesForState = (
  state: NylasState,
  code: string,
): StartEmailSyncV3MutationVariables | null => {
  const redirectUri = REDIRECT_URI;

  switch (state.entityType) {
    case SynchroniseEntityType.Account:
      return {
        accountId: state.accountId,
        hosted: {
          account: {
            code,
            redirectUri,
          },
        },
      };
    case SynchroniseEntityType.Office:
      return {
        accountId: state.accountId,
        hosted: {
          office: {
            code,
            officeId: state.entityId,
            redirectUri,
          },
        },
      };

    case SynchroniseEntityType.User:
      return {
        accountId: state.accountId,
        hosted: {
          user: {
            code,
            redirectUri,
          },
        },
      };

    default:
      reporter.captureException(
        new Error(`Unknown entity type given to Nylas: ${state.entityType}`),
      );
      return null;
  }
};

export default mutationVariablesForState;
