import styled, { css } from 'styled-components';
import Card from '~/components/atom/Card';

import Icon from '~/components/atom/Icon';

export const OfficeIcon = styled(Icon)<{}>`
  margin-right: 0.5em;

  ${({ theme }) => css`
    color: ${theme.color('primary', 'light')};
    font-size: ${theme.fontSize('m')};
  `};
`;

export const OfficeCounter = styled.div<{}>`
  display: flex;
  padding-left: 1em;
`;

export const ButtonContainer = styled.div<{}>`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > div {
    ${({ theme }) => css`
      margin-left: ${theme.space('m')};
    `};
  }

  & > button {
    align-self: center;
  }
`;

export const CardElement = styled(Card)<{ $isPending?: boolean }>(
  ({ $isPending }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: ${$isPending ? 'default' : 'pointer'};

    & > span {
      flex: 0;
      margin-left: auto;
      white-space: nowrap;
    }

    & > div {
      flex: 1 0 15%;
      text-align: left;
      padding-right: 1em;
      white-space: nowrap;
    }

    & > div:first-child {
      flex: 0 0 25%;
      white-space: normal;
    }

    & > ${OfficeCounter} {
      flex: 0;
      padding-right: 0;
    }
  `,
);

export const UserEmailElement = styled.div<{}>`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const UserPhoneElement = styled.div<{}>`
  flex: 0 0 10%;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
`;

export const EnvelopeIcon = styled(Icon)<{}>`
  margin-right: 1em;

  ${({ theme }) => css`
    color: ${theme.color('accent')};
  `};
`;

export const UserNameContainer = styled.div<{}>`
  display: flex;
`;

export const UserRoleElement = styled.div<{}>`
  max-width: 130px;
`;
