import React, { useLayoutEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Portal from '~/components/molecule/Portal';
import calculateListPosition, {
  type ListSize,
} from '../../utils/calculateListPosition';

export type Props = {
  openerRect: DOMRect | null;
  listSize: ListSize;
};

const DropdownPositioner: React.FCC<Props> = ({
  dataTestId,

  /**
   * Children to display in the portal
   */
  children,

  /**
   * DOMRect of the component that opened the list
   */
  openerRect,

  /**
   * Size of the list to open
   */
  listSize,
}) => {
  const { newX, newY } = calculateListPosition({ openerRect, listSize });

  const [transform, setTransform] = useState<{ x: number; y: number }>({
    x: newX,
    y: newY,
  });

  useLayoutEffect(() => {
    const { newX, newY } = calculateListPosition({ openerRect, listSize });

    setTransform({ x: newX, y: newY });
  }, [openerRect, listSize]);

  return (
    <Portal root="dropdown-portal">
      <PortalInner
        $top={transform.y}
        $left={transform.x}
        data-testid={dataTestId}
      >
        {children}
      </PortalInner>
    </Portal>
  );
};

const PortalInner = styled.div<{ $top: number; $left: number }>(
  ({ $top, $left, theme }) => css`
    position: absolute;
    top: ${$top || 0}px;
    left: ${$left || 0}px;
    z-index: ${theme.z('top')};
  `,
);

export default DropdownPositioner;
