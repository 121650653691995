import { Amplify } from 'aws-amplify';
import { tmpPrivate, tmpPublic } from '~/amplify/getConfig';

export type ImageLevel = 'protected' | 'private';

export enum ImageUploadError {
  InvalidFileType = 'InvalidFileType',
  InvalidFileSize = 'InvalidFileSize',
}

export const validImageTypes = [
  'image/gif',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/svg+xml',
  'image/webp',
];

type Args = {
  file: File;
  uploadImageName?: string | undefined;
  level?: ImageLevel;
};

export const FILE_SIZE_LIMIT = 10000000; // 10MB in bytes

const uploadS3Image = async ({
  file,
  uploadImageName,
  level = 'protected',
}: Args): Promise<{
  identityId: string;
  path: string;
  filename: string;
  permanentLink: string;
}> => {
  const fileType = file.type;
  const uploadFilename = uploadImageName || file.name;

  if (!validImageTypes.includes(fileType)) {
    throw Error(`${ImageUploadError.InvalidFileType}:${fileType}`);
  }

  if (file.size > FILE_SIZE_LIMIT) {
    throw Error(`${ImageUploadError.InvalidFileSize}:${file.size}`);
  }

  const { key }: { key: string } = await Amplify.Storage.put(
    uploadFilename,
    file,
    { contentType: file.type, level },
  );

  const permanentLink = await Amplify.Storage.get(key, { level });
  const { identityId } = await Amplify.Auth.currentCredentials();

  let path: string;
  switch (level) {
    case 'private':
      path = `${tmpPrivate}${identityId}/${key}`;
      break;
    case 'protected':
      path = `${tmpPublic}${identityId}/${key}`;
      break;
  }

  return {
    path,
    permanentLink,
    filename: uploadFilename,
    identityId,
  };
};

export default uploadS3Image;
