import type {
  FlowV2_ConditionList__Input,
  Flow___ArgumentFragment,
  Flow___Argument__Input,
  Flow___ConditionFragment,
  Flow___Condition__Input,
} from '~/graphql/types';
import { getBooleanOperator } from '~/components/page/Automation/v2/components/Builder/utils/expressionHelpersV2';
import { assertNever } from '~/util/assertNever';
import type { CompleteConditionExpression } from '../../types';
import isCompleteCondition from '../isCompleteCondition';

/**
 * Takes a condition list from the BE and converts it into a
 * condition expression which can be easily displayed on the FE.
 */
const generateConditionList = (
  condition: CompleteConditionExpression,
): FlowV2_ConditionList__Input => {
  const conditionResult: FlowV2_ConditionList__Input['conditions'] = [];
  const conditionIdToIndex: { [id: string]: number } = {};

  const exp = `(${condition.conditionSubExpression
    .map(
      ({ conditionGroups, connector }) =>
        `[${conditionGroups
          .map(({ conditions, connector }) =>
            conditions
              .map(condition => {
                if (condition?.id == null) {
                  throw Error(`Condition is null - Cannot`);
                }
                if (!isCompleteCondition(condition)) {
                  throw Error(`Incomplete!!`);
                }
                if (conditionIdToIndex[condition.id]) {
                  throw Error(`Duplicated id in conditions?!`);
                }

                conditionIdToIndex[condition.id] = conditionResult.length;
                conditionResult.push(
                  convertConditionToConditionInput(condition),
                );

                return conditionResult.length - 1;
              })
              .join(` ${getBooleanOperator(connector)} `),
          )
          .join(`] ${getBooleanOperator(connector)} [`)}]`,
    )
    .join(`) ${getBooleanOperator(condition.connector)} (`)})`;

  return {
    exp: exp === '([])' ? '' : exp,
    conditions: conditionResult,
  };
};

const convertConditionToConditionInput = (
  condition: Flow___ConditionFragment,
): Flow___Condition__Input => {
  switch (condition.__typename) {
    case 'Flow___InstanceCondition': {
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        __typename,
        args,
        input: { path },
        ...rest
      } = condition;

      return {
        InstanceCondition: {
          args: getArgumentInput(args),
          input: {
            path,
          },
          ...rest,
        },
      };
    }
    case 'Flow___SubjectFieldCondition': {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { __typename, args, ...rest } = condition;

      return {
        SubjectFieldCondition: {
          args: getArgumentInput(args),
          ...rest,
        },
      };
    }
    default:
      return assertNever(condition);
  }
};

const getArgumentInput = (
  args: Array<Flow___ArgumentFragment>,
): Array<Flow___Argument__Input> => {
  const result: Array<Flow___Argument__Input> = [];

  for (const argument of args) {
    switch (argument.__typename) {
      case 'Flow___Argument_Pointer': {
        const {
          pointer: { path },
        } = argument;

        result.push({
          pointer: {
            path,
          },
        });
        break;
      }
      case 'Flow___Argument_File': {
        const {
          value_file: { contentLength, filename, s3key },
        } = argument;

        result.push({
          value_file: {
            contentLength,
            filename,
            s3key,
          },
        });
        break;
      }

      case 'Flow___Argument_AWSDateTime':
      case 'Flow___Argument_Boolean':
      case 'Flow___Argument_Float':
      case 'Flow___Argument_Integer':
      case 'Flow___Argument_String': {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { __typename, ...rest } = argument;
        result.push(rest);
        break;
      }
      default:
        return assertNever(argument);
    }
  }

  return result;
};

export default generateConditionList;
