import React from 'react';
import styled, { css } from 'styled-components';
import Row from '~/components/bad/DataTables/styling/Row';
import TableBodyComponent from '~/components/bad/DataTables/styling/Body';
import { ApolloError } from '@apollo/client';
import TEST_ID from './index.testid';
import ErrorTypes, { errorTypeFromMutationError } from '~/ErrorTypes';
import Catalog from '~/Catalog';
import Loading from '~/components/atom/Loading';
import NoResultSection from '~/components/page/NoResultSection';
import { RowCell } from '~/components/bad/DataTables/components';

export type Props = {
  rowCount: number;
  getTableBodyProps: any;
  prepareRow: (row: any) => void;
  page: any;
  error?: ApolloError;
  loading: boolean;
};

const TableBody: React.FCC<Props> = ({
  dataTestId,
  rowCount,
  getTableBodyProps,
  prepareRow,
  page,
  error,
  loading,
}) => {
  const isImpersonatingError =
    errorTypeFromMutationError(error) ===
    ErrorTypes.impersonationIsOngoingError;

  if (error) {
    if (isImpersonatingError) {
      return (
        <StyledNoResultSection>
          You cannot see the accounts when you are impersonating another user.
          Stop impersonation to see the accounts.
        </StyledNoResultSection>
      );
    }
    return (
      <StyledNoResultSection>
        {Catalog.genericUnknownErrorMessageShort}
      </StyledNoResultSection>
    );
  }

  if (rowCount === 0) {
    return (
      <StyledNoResultSection data-testid={TEST_ID.NO_RESULTS_SECTION}>
        No results found
      </StyledNoResultSection>
    );
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <TableBodyComponent.Standard.Inner
      {...getTableBodyProps()}
      data-testid={dataTestId}
    >
      {page.map(row => {
        prepareRow(row);
        const rowProps = { ...row.getRowProps() };
        const accountId = row.original.id;

        return (
          <Row
            {...rowProps}
            key={rowProps.key}
            data-objectid={accountId}
            dataTestId={TEST_ID.LIST_ITEM}
            to={`/-/accounts/${accountId}`}
          >
            {row.cells.map((cell, idx) => {
              const isLastColumn = idx === row.cells.length - 1;

              return (
                <RowCell
                  key={`cell-${idx}`}
                  cell={cell}
                  isLastColumn={isLastColumn}
                  allowFieldCopying={cell.column.id === 'id'}
                />
              );
            })}
          </Row>
        );
      })}
    </TableBodyComponent.Standard.Inner>
  );
};

const StyledNoResultSection = styled(NoResultSection)<{}>(
  ({ theme }) => css`
    background-color: ${theme.color('white')};
  `,
);

export default TableBody;
