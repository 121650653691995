import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  ContactFiltersOptionTypeFragment,
  ContactFiltersTypeFragment,
} from '~/graphql/types';
import useScrollIntoView from '~/hooks/useScrollIntoView';
import InputForAppearance from './components/InputForAppearanceComponent';
import formatValue from '~/components/organism/Filters/util/contactFilter/formatValue';

export type Props = {
  input: ContactFiltersTypeFragment | ContactFiltersOptionTypeFragment;
  value: string | number | boolean | null | undefined;
  onChange: (value: Props['value']) => void;
  shouldShowValidationError: boolean;
};

const TypeBlock: React.FCC<Props> = ({
  dataTestId,
  input,
  value,
  shouldShowValidationError,
  onChange,
}) => {
  const [inputErrors, setInputErrors] = useState<Array<string>>([]);

  useEffect(() => {
    const rawValue = value;
    const { formattedValue, invalidInputDescription } = formatValue(
      rawValue,
      input,
    );
    if (invalidInputDescription && shouldShowValidationError) {
      setInputErrors([invalidInputDescription]);
    } else {
      setInputErrors([]);
    }
    onChange(formattedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _onChange = useCallback(
    (value: string | null) => {
      const rawValue = value;

      const { formattedValue } = formatValue(rawValue, input);

      setInputErrors([]);

      onChange(formattedValue);
    },
    [input, onChange],
  );

  const [scrollToContainer, containerRef] = useScrollIntoView({
    isCentered: true,
  });

  return (
    <Container
      data-testid={dataTestId}
      ref={containerRef}
      onClick={scrollToContainer}
    >
      <InputForAppearance
        input={input}
        value={value}
        inputErrors={inputErrors}
        onChange={_onChange}
      />
    </Container>
  );
};

const Container = styled.div<{}>`
  display: flex;
  flex: 1 1 150px;
`;

export default TypeBlock;
