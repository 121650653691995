import React, { useState } from 'react';
import { navigate, type RouteComponentProps } from '@gatsbyjs/reach-router';
import { Helmet as MetaTags } from 'react-helmet';
import { v4 as uuid } from 'uuid';
import TEST_ID from './index.testid';
import ContentContainerDefault, {
  MAX_CONTAINER_WIDTH,
} from '~/components/molecule/ContentContainer';
import { Heading1 } from '~/components/atom/Typography';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Toolbar from '~/components/molecule/Toolbar';
import Button from '~/components/atom/Button';
import {
  useDeleteFormMutation,
  useGetFormsQuery,
  type FormBuilder,
  type SortDirection,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import Loading from '~/components/atom/Loading';
import FormListTable from './components/Table';
import composeListData from './components/Table/utils/composeData';
import useSortSettings from '~/hooks/useSortSettings';
import formListColumns from './components/Table/utils/formListColumns';
import EmptyStateComponent from '~/components/template/EmptyStateComponent';
import useDHFlag from '~/hooks/useDHFlag';
import createPageTitle from '~/util/createPageTitle';
import { FORM_SORT_SETTINGS } from '~/util/localStorageKeys';
import useLimit from '~/hooks/useLimit';

const text = {
  title: 'Formulieren',
  emptyStateHeader: 'Je hebt nog geen formulieren',
  emptyStateDescription:
    'Verzamel informatie op een manier die het beste werkt voor jou',
  addFormLabel: 'Nieuw formulier',
  importFormLabel: 'Formulier importeren',
  deleteAllForms: 'Delete all forms',
};

export const LINK_PREFIX = '/-/forms';

type Props = {} & RouteComponentProps;
const FormsOverview: React.FCC<Props> = () => {
  const [sortSettings, updateSortSettings] =
    useSortSettings<Array<string>>(FORM_SORT_SETTINGS);
  const [version, setVersion] = useState(uuid());
  const account = useCurrentAccount();

  const isDeveloper = useDHFlag('is-developer');
  const [deleteForm] = useDeleteFormMutation();

  const { data, loading, networkStatus, error, refetch } = useGetFormsQuery({
    variables: {
      accountId: account.id,
    },
  });
  const formsLimit = useLimit('root.forms.total');

  const hasFormBuilder = useDHFlag('form-builder');
  if (!hasFormBuilder) return null;

  if (loading) {
    <ContentContainerDefault maxContentWidth={MAX_CONTAINER_WIDTH}>
      <MetaTags>
        <title>{createPageTitle(text.title)}</title>
      </MetaTags>
      <Loading />
    </ContentContainerDefault>;
  }

  const totalEnabledForms =
    data?.getForms.filter(({ enabled }) => enabled === true).length ?? 0;

  const onDeleteAllForms = () => {
    data &&
      data.getForms &&
      data.getForms.forEach(async (item: FormBuilder) => {
        await deleteForm({
          variables: {
            formBuilderId: item.id,
            accountId: account.id,
          },
        });
      });

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <ContentContainerDefault maxContentWidth={MAX_CONTAINER_WIDTH}>
      <MetaTags>
        <title>{createPageTitle(text.title)}</title>
      </MetaTags>

      <JustificationContainer
        align="start"
        justification="start"
        margin={['xl', null, 'xxl', null]}
        direction="column"
      >
        <Heading1 color={{ group: 'primary' }} margin={[null, null, 'l', null]}>
          {text.title}
        </Heading1>
        <Toolbar>
          <JustificationContainer width="100%" justification="space-between">
            <JustificationContainer gap="m">
              <Button
                limited={totalEnabledForms >= formsLimit.limit}
                size="medium"
                label={text.addFormLabel}
                icon="plus"
                onClick={() => navigate(`${LINK_PREFIX}/wizard/`)}
                dataTestId={TEST_ID.OVERVIEW_HEADER}
              />
              <Button
                size="medium"
                label={text.importFormLabel}
                icon="plus"
                ghost
                appearance="secondary"
                onClick={() => navigate(`${LINK_PREFIX}/import`)}
                dataTestId={TEST_ID.OVERVIEW_HEADER}
              />
            </JustificationContainer>
            {isDeveloper && (
              <Button
                size="medium"
                appearance="danger"
                label={text.deleteAllForms}
                icon="trashcan"
                onClick={onDeleteAllForms}
              />
            )}
          </JustificationContainer>
        </Toolbar>
      </JustificationContainer>
      {data?.getForms.length === 0 ? (
        <EmptyStateComponent
          dataTestId={TEST_ID.EMPTY_STATE}
          header={text.emptyStateHeader}
          description={text.emptyStateDescription}
          buttonLabel={text.addFormLabel}
          illustration="list"
          onButtonClick={() => navigate(`${LINK_PREFIX}/wizard/`)}
        />
      ) : (
        <FormListTable
          columns={formListColumns((key, direction: SortDirection) => {
            updateSortSettings({
              sortField: key,
              sortDirection: direction,
            });
            setVersion(uuid());
          }, version)}
          data={composeListData(data?.getForms ?? [], version, sortSettings)}
          loading={loading}
          networkStatus={networkStatus}
          error={error != null}
          refreshTable={async () => {
            await refetch();

            const nextID = uuid();
            setVersion(nextID);
          }}
        />
      )}
    </ContentContainerDefault>
  );
};

export default FormsOverview;
