import { useApolloClient } from '@apollo/client';
import { navigate } from '@gatsbyjs/reach-router';
import React, { useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { useStopImpersonationMutation } from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useCurrentUser from '~/hooks/useCurrentUser';
import useErrorReporter from '~/hooks/useErrorReporter';
import useSessionHydration from '~/hooks/useSessionHydration';

import formatToastMessage from '~/util/formatToastMessage';
import {
  ACCOUNT_ID_CURRENTLY_IMPERSONATED,
  getLocalStorageItem,
  removeLocalStorageItem,
  SELECTED_ACCOUNT_STORAGE_KEY,
} from '~/util/localStorageKeys';
import Button from '~/components/atom/Button';
import Icon from '~/components/atom/Icon';
import { Heading2, Heading5, Variant } from '~/components/atom/Typography';
import TEST_ID from './index.testid';

export type Props = {};

const ImpersonationContainer: React.FCC<Props> = ({ ...rest }) => {
  const [{ impersonatedBy }, refetch] = useSessionHydration();
  const [hideInner, setHideInner] = useState<boolean>(false);
  const addToast = useAddToast();
  const me = useCurrentUser();
  const account = useCurrentAccount();
  const errorReporter = useErrorReporter();
  const client = useApolloClient();

  const [stopImpersonation, { loading }] = useStopImpersonationMutation({
    variables: {
      accountId: account.id,
      userId: me.id,
    },
    onError: error => {
      errorReporter.captureException(error, 'info');
      addToast([
        formatToastMessage(error.message, 'danger', {
          label: 'Refresh',
          onClick: () => window.location.reload(),
          to: '/-/accounts',
        }),
      ]);
    },
    onCompleted: async data => {
      if (!data) {
        // We need to throw in the `onCompleted` to trigger `onError`
        throw new Error(
          'Could not stop impersonation session, please refresh to recover',
        );
      }

      removeLocalStorageItem(ACCOUNT_ID_CURRENTLY_IMPERSONATED);
      const accountId = getLocalStorageItem(SELECTED_ACCOUNT_STORAGE_KEY);
      // When we don't have a previous account ID stored
      // we need to hard refresh to get a fresh session hydration from BE
      if (!accountId) {
        return (window.location.pathname = '/-/accounts');
      }

      await refetch({ accountId });
      await navigate('/-/accounts');
      await client.clearStore();
      // Ensure a completely fresh state after stopping impersonation
      return window.location.reload();
    },
  });

  return (
    <>
      <Rim data-testid={TEST_ID.RIM} $loading={loading} {...rest}>
        {loading && <Heading2>Stopping impersonation....</Heading2>}
      </Rim>
      <ControlsContainer $hide={hideInner}>
        <ControlsInner data-testid={TEST_ID.CONTROLS}>
          <HideInnerButton onClick={() => setHideInner(hide => !hide)}>
            <Icon name="chevron" clockwise={hideInner ? 180 : 0} />
          </HideInnerButton>
          <Heading5 variant={Variant.secondary} size="xs">
            {impersonatedBy && `Impersonating: ${me.name}`}
          </Heading5>
          <Button
            label="Stop impersonation"
            dataTestId={TEST_ID.STOP}
            icon="close"
            loading={loading}
            size="small"
            style={{ width: '100%' }}
            onClick={() => {
              void stopImpersonation();
            }}
          />
        </ControlsInner>
      </ControlsContainer>
    </>
  );
};

const zIndexAddition = 100;
const Rim = styled.div<{ $loading: boolean }>(
  ({ theme, $loading }) => css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 5px solid ${theme.color('danger')};
    z-index: calc(${theme.getTokens().zIndex.top} + ${zIndexAddition});
    pointer-events: none;

    background-color: ${$loading ? 'rgba(0, 0, 0, 0.5)' : 'transparent'};
    transition: all 0.2s ease-out;

    animation: ${rimIntro} 0.7s ease-out forwards;

    h1 {
      color: ${theme.color('white')};
    }
  `,
);

const rimIntro = keyframes`
  from { border-width: 0px; }
  50% { border-width: 8px; }
  to { border-width: 5px; }
`;

const CONTROL_HEIGHT = '4.5rem';
const ControlsContainer = styled.div<{ $hide: boolean }>(
  ({ $hide, theme }) => css`
    position: fixed;
    bottom: ${$hide ? `-${CONTROL_HEIGHT}` : 0};
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    z-index: calc(${theme.getTokens().zIndex.top} + ${zIndexAddition});
    transition: bottom 0.2s ease;
  `,
);

const ControlsInner = styled.div<{}>(
  ({ theme }) => css`
    background: ${theme.color('danger')};
    color: ${theme.color('white')};
    box-shadow: ${theme.getTokens().boxShadow.around};
    padding: ${theme.space('s')};
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    h4 {
      color: ${theme.color('white')};
    }
  `,
);

const HideInnerButton = styled.button<{}>(
  ({ theme }) => css`
    background: ${theme.color('danger')};
    border: none;
    position: absolute;
    top: -1rem;
    right: 0;
    cursor: pointer;
  `,
);

export default ImpersonationContainer;
