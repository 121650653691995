import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Icon from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import copyTextToClipboard from '~/util/copyTextToClipboard';
import useTooltipLayer from '~/hooks/useTooltipLayer';

export type Props = {
  textToCopy: string;
  label?: string;
};

const text = {
  copy: 'Kopiëren',
};

const CopyTextBlock: React.FCC<Props> = ({
  dataTestId,
  label,
  textToCopy,
  ...rest
}) => {
  const [copied, setCopied] = useState(false);
  const tooltipProps = useTooltipLayer({ tooltipMessage: text.copy });

  const onCopy = e => {
    void copyTextToClipboard(textToCopy)
      .then(() => {
        setCopied(true);
        tooltipProps.onMouseLeave(e);
      })
      .catch(() => {
        setCopied(false);
      });

    const timer = setTimeout(() => {
      clearTimeout(timer);
      setCopied(false);
    }, 1200);
  };

  return (
    <Container data-testid={dataTestId} {...rest} align="center">
      {label && <Label>{label}:</Label>}
      {textToCopy}
      <div>
        <StyledIcon
          {...tooltipProps}
          color={{ group: copied ? 'success' : 'primary' }}
          onClick={onCopy}
          name={copied ? 'check' : 'copy'}
        />
      </div>
    </Container>
  );
};

const Label = styled.span(
  ({ theme }) => css`
    font-weight: ${theme.fontWeight('semiBold')};
    margin-right: ${theme.space('xxs')};
  `,
);

const Container = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    border-radius: ${theme.getTokens().border.radius.s};
    padding: ${theme.space('xxs')};
    background: ${theme.color('primary', 'translucent')};
  `,
);

const StyledIcon = styled(Icon)(
  ({ theme }) => css`
    cursor: pointer;
    margin-left: ${theme.space('base')};
  `,
);

export default CopyTextBlock;
