import React from 'react';
import styled, { css } from 'styled-components';

export type Props = {
  children: React.ReactNode;
  className?: string;
  /** Add additional visual elevation to the card like box-shadow and border */
  elevated?: boolean;
  maxWidth?: string;
  onClick?: () => void;
};

const MasonryCard: React.FCC<Props> = ({
  children,
  className = '',
  dataTestId = '',
  maxWidth = '640px',
  elevated = false,
  ...rest
}) => (
  <Container
    $elevated={elevated}
    className={className}
    data-testid={dataTestId}
    $maxWidth={maxWidth}
    {...rest}
  >
    {children}
  </Container>
);

const Container = styled.div<{ $maxWidth: string; $elevated: boolean }>`
  min-height: 100px;
  display: inline-block;

  ${({ theme, $maxWidth, $elevated }) => css`
    width: 100%;
    max-width: ${$maxWidth};
    padding: ${theme.space('l')} ${theme.space('l')};
    background-color: ${theme.color('white')};
    border-radius: ${theme.getTokens().border.radius.m};
    /** This should be same as column-gap of MasonryGrid container */
    margin-bottom: ${theme.space('xxl')};

    ${$elevated &&
    css`
      box-shadow: ${theme.boxShadow('card')};
      border: 1px solid ${theme.color('tertiary', 'light')};
    `}
  `}
`;

export default MasonryCard;
