import React from 'react';
import { css, styled } from 'styled-components';
import Div from '~/components/atom/Div';
import Icon from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { clone, remove } from 'ramda';
import DeleteExpressionContainer from '../../components/DeleteExpressionContainer';
import ConnectorComponent from '../../components/ConditionGroup/components/ConnectorComponent';
import type { ConnectorOperator } from '~/graphql/types';
import type {
  ArgumentId,
  ConditionExpression,
  ConditionSubExpression,
} from '../../types';

const text = {
  explanation: 'De volgende condities moeten aanwezig zijn:',
};

type Args = {
  conditionGroupElements: Array<React.JSX.Element>;
  conditionSubExpressionConnector: ConnectorOperator;
  conditionExpressionConnector: ConnectorOperator;
  conditionSubExpression: Array<ConditionSubExpression>;
  conditionSubExpressionIdx: number;
  setActiveSelector: React.Dispatch<React.SetStateAction<ArgumentId | null>>;
  setConditionExpression: (
    nextConditionExpression: ConditionExpression,
  ) => void;
};

const getSubConditions = ({
  conditionGroupElements,
  conditionSubExpressionConnector,
  conditionSubExpression,
  conditionSubExpressionIdx,
  setConditionExpression,
  conditionExpressionConnector,
  setActiveSelector,
}: Args): Array<React.JSX.Element> =>
  conditionGroupElements.slice(1).map((subCondition, index) => {
    const isFirst = index === 0;
    const label = isFirst ? (
      <SubConditionLabel
        backgroundColor={{ group: 'white' }}
        padding={['xxs']}
        border={{
          radius: 's',
          width: 's',
          color: { group: 'tertiary' },
        }}
      >
        {text.explanation}
      </SubConditionLabel>
    ) : (
      <ConnectorComponent
        connector={conditionSubExpressionConnector}
        onChange={nextValue => {
          const nextConditionSubExpression = clone(conditionSubExpression);

          nextConditionSubExpression[conditionSubExpressionIdx].connector =
            nextValue;

          setConditionExpression({
            conditionSubExpression: nextConditionSubExpression,
            connector: conditionExpressionConnector,
          });
        }}
        editable={true}
        label={{ and: 'en ', or: 'of' }}
      />
    );

    return (
      <React.Fragment key={`${subCondition.key}`}>
        <ConnectorContainer
          padding={['s', null]}
          margin={[null, null, null, 'xxxs']}
        >
          <ConnectorLabel>{label}</ConnectorLabel>
        </ConnectorContainer>
        <Container
          backgroundColor={{ group: 'white' }}
          border={{ radius: 's' }}
          padding={['xs']}
        >
          {subCondition}
          <DeleteExpressionContainer
            onClick={e => {
              e.preventDefault();

              const nextConditionSubExpression = clone(conditionSubExpression);

              nextConditionSubExpression[
                conditionSubExpressionIdx
              ].conditionGroups = remove(
                index + 1,
                1,
                nextConditionSubExpression[conditionSubExpressionIdx]
                  .conditionGroups,
              );

              setActiveSelector(null);
              setConditionExpression({
                conditionSubExpression: nextConditionSubExpression,
                connector: conditionExpressionConnector,
              });
            }}
          >
            <Icon name="trashcan" />
          </DeleteExpressionContainer>
        </Container>
      </React.Fragment>
    );
  });

const Container = styled(Div)(
  ({ theme }) => css`
    position: relative;
    border: 1px solid ${theme.color('info')};
    border-left: 4px solid ${theme.color('info', 'dark')};
    margin-bottom: ${theme.space('s')};
  `,
);

const SubConditionLabel = styled(Div)(
  ({ theme }) => css`
    font-size: ${theme.fontSize('s')};
  `,
);

const ConnectorContainer = styled(JustificationContainer)(
  ({ theme }) => css`
    display: flex;
    border-left: 1px solid ${theme.color('tertiary')};
    margin-left: ${theme.space('xxxs')};
    padding: ${theme.space('s')} 0px;
    z-index: ${theme.z('top')};
    margin-top: -${theme.space('s')};
  `,
);

const ConnectorLabel = styled.div(
  ({ theme }) => css`
    background-color: ${theme.color('white')};
    position: relative;
    left: -5px;
  `,
);

export default getSubConditions;
