import React from 'react';
import styled from 'styled-components';

import { FormApi } from 'final-form';
import { InsertOfficeMutationVariables } from '~/graphql/types';

import { ApolloError } from '@apollo/client';

import {
  Field,
  NumberField,
  Form,
  PostalCodeField,
} from '~/components/organism/Forms';
import FormUtils from '~/components/organism/FormUtils';
import Button from '~/components/atom/Button';
import Validation from '~/util/Validation';
import Catalog from '~/Catalog';
import cleanedFilename from '~/util/cleanedFilename';
import Input from '~/components/molecule/Input';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Body } from '~/components/atom/Typography';

export type FormData = {
  name: string | null;
  street: string | null;
  houseNumber: string | null;
  addition: string | null;
  postcode: string | null;
  city: string | null;
};
type Props = {
  accountId: string;
  loading: boolean;
  onSubmit: (variables: InsertOfficeMutationVariables) => Promise<any>;
  insertionError?: ApolloError;
};
const text = {
  btnTitle: 'Toevoegen',
  noName: Catalog.noName,
  noAddress: Catalog.noAddress,
  noPostalCode: Catalog.noPostalCode,
  noCity: Catalog.noCity,
  noHouseNumber: Catalog.noHouseNumber,
  postcodeValidation: Catalog.invalidPostalCode,
  officeInsertError: Catalog.genericUnknownErrorMessage,
  disclaimer: Catalog.mayIncreaseCostsWarning('Vestigingen'),
  streetLabel: Catalog.streetLabel,
  houseNumber: Catalog.houseNumberLabel,
  name: Catalog.nameLabel,
  houseAddition: Catalog.houseAdditionLabel,
  postcode: Catalog.postalCodeLabel,
  city: 'Plaatsnaam',
};

const NewOfficeForm: React.FCC<Props> = ({
  accountId,
  loading,
  onSubmit,
  insertionError,
}) => (
  <Form
    onSubmit={async (formData: FormData, form: FormApi<FormData>) => {
      await onSubmit(
        convertFieldsToMutationVariablesObject(accountId, formData),
      );
      const fieldNames = Object.keys(formData) as Array<keyof typeof formData>;

      fieldNames.forEach(name => {
        form.change(name, null);
      });
    }}
    validate={validate}
    initialValues={{
      name: null,
      street: null,
      houseNumber: null,
      addition: null,
      postcode: null,
      city: null,
    }}
  >
    {({ handleSubmit, submitError, submitting, pristine }) => {
      const addOfficeError =
        insertionError || submitError ? text.officeInsertError : false;
      return (
        <>
          {addOfficeError && (
            <ErrorMessage data-testid="add-office-error-message">
              {addOfficeError}
            </ErrorMessage>
          )}
          <form data-testid="new-office-form" onSubmit={handleSubmit}>
            <JustificationContainer
              width="100%"
              direction="column"
              gap="base"
              margin={['base', null]}
            >
              <Field name="name">
                {({ input, meta: { error, touched } }) => (
                  <Input
                    width="100%"
                    size="large"
                    label={{ text: text.name }}
                    type="text"
                    externalErrors={
                      FormUtils.showError(error, touched)
                        ? [FormUtils.showError(error, touched)]
                        : []
                    }
                    disabled={submitting}
                    {...input}
                  />
                )}
              </Field>

              <Field name="street">
                {({ input, meta: { error, touched } }) => (
                  <Input
                    width="100%"
                    size="large"
                    label={{ text: text.streetLabel }}
                    type="text"
                    externalErrors={
                      FormUtils.showError(error, touched)
                        ? [FormUtils.showError(error, touched)]
                        : []
                    }
                    disabled={submitting}
                    {...input}
                  />
                )}
              </Field>
              <JustificationContainer width="100%" gap="base">
                <NumberField name="houseNumber">
                  {({ input, meta: { error, touched } }) => (
                    <Input
                      width="100%"
                      size="large"
                      label={{ text: text.houseNumber }}
                      type="text"
                      externalErrors={
                        FormUtils.showError(error, touched)
                          ? [FormUtils.showError(error, touched)]
                          : []
                      }
                      disabled={submitting}
                      {...input}
                    />
                  )}
                </NumberField>
                <Field name="addition">
                  {({ input, meta: { error, touched } }) => (
                    <Input
                      width="100%"
                      size="large"
                      label={{ text: text.houseAddition }}
                      type="text"
                      externalErrors={
                        FormUtils.showError(error, touched)
                          ? [FormUtils.showError(error, touched)]
                          : []
                      }
                      disabled={submitting}
                      {...input}
                    />
                  )}
                </Field>
              </JustificationContainer>
              <JustificationContainer width="100%" gap="base">
                <PostalCodeField name="postcode">
                  {({ input, meta: { error, touched } }) => (
                    <Input
                      width="100%"
                      size="large"
                      label={{ text: text.postcode }}
                      type="text"
                      externalErrors={
                        FormUtils.showError(error, touched)
                          ? [FormUtils.showError(error, touched)]
                          : []
                      }
                      disabled={submitting}
                      {...input}
                    />
                  )}
                </PostalCodeField>
                <Field name="city">
                  {({ input, meta: { error, touched } }) => (
                    <Input
                      width="100%"
                      size="large"
                      label={{ text: text.city }}
                      type="text"
                      externalErrors={
                        FormUtils.showError(error, touched)
                          ? [FormUtils.showError(error, touched)]
                          : []
                      }
                      disabled={submitting}
                      {...input}
                    />
                  )}
                </Field>
              </JustificationContainer>
            </JustificationContainer>

            <JustificationContainer justification="end" width="100%">
              <Button
                size="medium"
                loading={loading}
                appearance="secondary"
                type="submit"
                disabled={submitting || pristine}
                dataTestId="save-new-office-btn"
                label={text.btnTitle}
              />
            </JustificationContainer>
            <Body margin={['s', null, null, null]} size="s" italic>
              {text.disclaimer}
            </Body>
          </form>
        </>
      );
    }}
  </Form>
);

const ErrorMessage = styled.div<{}>`
  ${({ theme }) => `
    color: ${theme.color('danger')};
  `};
`;

export const convertFieldsToMutationVariablesObject = (
  accountId: string,
  fields: FormData,
): InsertOfficeMutationVariables => {
  const { name, street, postcode, city, houseNumber, addition } = fields;
  const houseNumberInt = houseNumber == null ? null : parseInt(houseNumber);

  if (
    name == null ||
    street == null ||
    postcode == null ||
    city == null ||
    houseNumberInt == null
  ) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | name (${name}) == null || street (${street}) == null || postcode (${postcode}) == null || city (${city}) == null || houseNumberInt (${houseNumberInt}) == null`,
    );
  }

  return {
    accountId: accountId,
    office: {
      name: name,
      address: {
        street: street,
        postcode: postcode,
        city: city,
        houseNumber: houseNumberInt,
        addition: addition,
      },
    },
  };
};

export const validate = (fields: FormData) => {
  const errors: {
    name: string | undefined;
    street: string | undefined;
    houseNumber: string | undefined;
    addition: string | undefined;
    postcode: string | undefined;
    city: string | undefined;
  } = {
    name: undefined,
    street: undefined,
    houseNumber: undefined,
    addition: undefined,
    postcode: undefined,
    city: undefined,
  };

  if (Validation.String.isEmpty(fields['name'])) {
    errors.name = text.noName;
  }

  if (Validation.String.isEmpty(fields['street'])) {
    errors.street = text.noAddress;
  }

  if (Validation.String.isEmpty(fields['postcode'])) {
    errors.postcode = text.noPostalCode;
  }

  if (Validation.String.isEmpty(fields['city'])) {
    errors.city = text.noCity;
  }

  if (Validation.String.isEmpty(fields['houseNumber'])) {
    errors.houseNumber = text.noHouseNumber;
  }

  if (
    Validation.String.isNonEmptyString(fields['postcode']) &&
    !Validation.Postcode.isValid(fields['postcode'])
  ) {
    errors.postcode = text.postcodeValidation;
  }
  return errors;
};

export default NewOfficeForm;
