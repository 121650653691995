import type { ColorString } from '~/theme/System/types';

const isColorString = (value: any): value is ColorString => {
  const hexRegex = /^#([0-9a-fA-F]{3}|[0-9a-fA-F]{6}|[0-9a-fA-F]{8})$/;
  const rgbRegex = /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/;
  const rgbaRegex =
    /^rgba\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*((0|1|0?\.\d+)|(\d{1,3})%)\s*\)$/;
  const cmykRegex =
    /^cmyk\(\s*(\d{1,3})%\s*,\s*(\d{1,3})%\s*,\s*(\d{1,3})%\s*,\s*(\d{1,3})%\s*\)$/;

  if (typeof value !== 'string') {
    return false;
  }

  // Validate HEX
  if (hexRegex.test(value)) {
    return true;
  }

  // Validate RGB
  const rgbMatch = value.match(rgbRegex);
  if (rgbMatch) {
    const [r, g, b] = [
      Number(rgbMatch[1]),
      Number(rgbMatch[2]),
      Number(rgbMatch[3]),
    ];
    return r >= 0 && r <= 255 && g >= 0 && g <= 255 && b >= 0 && b <= 255;
  }

  // Validate RGBA
  const rgbaMatch = value.match(rgbaRegex);
  if (rgbaMatch) {
    const [r, g, b, a] = [
      Number(rgbaMatch[1]),
      Number(rgbaMatch[2]),
      Number(rgbaMatch[3]),
      rgbaMatch[4],
    ];

    // Check if alpha is a valid value (either a float or percentage)
    const alphaIsValid =
      (a.includes('%') &&
        Number(a.slice(0, -1)) >= 0 &&
        Number(a.slice(0, -1)) <= 100) ||
      (!a.includes('%') && Number(a) >= 0 && Number(a) <= 1);

    return (
      r >= 0 &&
      r <= 255 &&
      g >= 0 &&
      g <= 255 &&
      b >= 0 &&
      b <= 255 &&
      alphaIsValid
    );
  }

  // Validate CMYK
  const cmykMatch = value.match(cmykRegex);
  if (cmykMatch) {
    const [c, m, y, k] = [
      Number(cmykMatch[1]),
      Number(cmykMatch[2]),
      Number(cmykMatch[3]),
      Number(cmykMatch[4]),
    ];
    return (
      c >= 0 &&
      c <= 100 &&
      m >= 0 &&
      m <= 100 &&
      y >= 0 &&
      y <= 100 &&
      k >= 0 &&
      k <= 100
    );
  }

  return false;
};

export default isColorString;
