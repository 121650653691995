import React from 'react';
import styled, { css } from 'styled-components';
import { Props } from '../..';
import TEST_ID from '../../index.testid';
import Icon from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Body, Heading5, Variant } from '~/components/atom/Typography';
import Button from '~/components/atom/Button';
import Link from '~/components/molecule/Link';
import CloseButton from '~/components/atom/CloseButton';
import hasValue from '~/util/hasValue';

const Large: React.FCC<Props> = ({
  dataTestId,
  icon,
  header,
  description,
  button,
  children,
  margin,
  width,
  padding = ['m'],
  align,
  linkButton,
  onClose,
  ...rest
}) => (
  <Container
    justification="space-between"
    align={align}
    margin={margin}
    padding={padding}
    dataTestId={dataTestId ?? TEST_ID.CONTAINER}
    width={width}
    border={{ radius: 'm' }}
    backgroundColor={{ group: 'primary' }}
    {...rest}
  >
    <StyledIcon
      name={icon?.name || 'triangle'}
      background={icon?.background}
      color={{ group: 'white' }}
    />

    <JustificationContainer direction="column" width="100%">
      {header && (
        <Heading5
          fontWeight="medium"
          margin={!hasValue(description) ? [null] : undefined}
          variant={Variant.primary}
          color={{ group: 'white' }}
        >
          {header}
        </Heading5>
      )}
      {description && (
        <Body margin={[null]} color={{ group: 'white' }} size="base">
          {description}
        </Body>
      )}
      {children}
    </JustificationContainer>

    {button && (
      <Button
        loading={button.loading}
        label={button.label}
        icon={button.icon}
        appearance={button.appearance}
        onClick={button.onClick}
        dataTestId={button.dataTestId}
        size="medium"
      />
    )}

    {linkButton && (
      <Link
        to={linkButton.to}
        target="_blank"
        style={{ textDecoration: 'none' }}
      >
        <Button
          size="medium"
          label={linkButton.label}
          icon={linkButton.icon}
          appearance={linkButton.appearance}
        />
      </Link>
    )}
    {onClose && (
      <StyledCloseButton onClick={onClose} dataTestId={TEST_ID.CLOSE_BUTTON} />
    )}
  </Container>
);

const Container = styled(JustificationContainer)(
  ({ theme }) => css`
    position: relative;
    color: ${theme.color('white')};
  `,
);

const StyledCloseButton = styled(CloseButton)<{}>(
  ({ theme }) => css`
    align-self: flex-start;
    font-size: ${theme.fs('base')};
    margin-left: ${theme.space('m')};
  `,
);

const StyledIcon = styled(Icon)<{}>(
  ({ theme, name }) => css`
    font-size: 1.5em;
    margin: 0 ${theme.space('l')} ${theme.space('xxxs')} 0;

    & > svg {
      width: 0.8em;
      height: 0.8em;

      /* triangle is unaligned in this case. Should be solved on the icon but since new icons are incoming
        I really don't want to fix this now. This will do.
      */
      margin-top: ${name === 'triangle' ? '-1px' : 'unset'};
    }
  `,
);

export default Large;
