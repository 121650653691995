import type { ArgumentId } from '../../types';
import isActiveCondition from '../isActiveCondition';

const isActiveConditionArgument = ({
  activeSelector,
  argumentId,
}: {
  activeSelector?: ArgumentId | null;
  argumentId: ArgumentId;
}): boolean =>
  activeSelector != null &&
  isActiveCondition({ activeSelector, argumentId }) &&
  activeSelector.argumentIdx === argumentId.argumentIdx;

export default isActiveConditionArgument;
