import React, { useState } from 'react';
import { animated, useSpring } from 'react-spring';
import styled, { css } from 'styled-components';
import Icon from '~/components/atom/Icon';
import { Label, Variant } from '~/components/atom/Typography';

import { Sentiment } from '~/graphql/types';
import useLocale from '~/hooks/useLocale';
import { SystemColorPalette } from '~/theme';
import { ColorVariant } from '~/theme/System/tokens/colorPalette';
import Tooltip from '~/components/molecule/Tooltip';

export type Props = {
  label: string;
  value: number;
  sentiment: Sentiment;
  formatOptions: Intl.NumberFormatOptions;

  tooltip?: React.ReactNode;
};

const StatIndicator: React.FCC<Props> = ({
  label,
  value,
  sentiment,
  formatOptions,
  dataTestId = '',
  tooltip,
}) => {
  const locale = useLocale();
  const numberFormatter = new Intl.NumberFormat(locale, formatOptions);
  const { startY, clockwise } = getSentimentConfig(sentiment);
  const [showTooltip, setShowTooltip] = useState(false);

  const { number } = useSpring({
    number: value,
    from: { number: 0 },
    config: animationConfig,
  });

  const labelSpring = useSpring({
    from: { opacity: 0, transform: 'translate(-40px,0)' },
    to: { opacity: 1, transform: 'translate(0px,0)' },
    config: animationConfig,
  });

  const arrowSpring = useSpring({
    from: {
      transform: `translate(-200%, ${startY})`,
    },
    to: { transform: 'translate(0, 0)' },
    config: animationConfig,
  });

  return (
    <Container data-testid={dataTestId}>
      <ArrowContainer $sentiment={sentiment}>
        <ArrowAnimationContainer style={arrowSpring}>
          <AnimatedArrow name="arrow" clockwise={clockwise} />
        </ArrowAnimationContainer>
      </ArrowContainer>

      <NumberContainer
        margin={[null]}
        size="xl"
        fontWeight="extraLight"
        variant={Variant.primary}
      >
        {number.to((x: number) =>
          numberFormatter.format(
            formatOptions.style === 'percent' ? x / 100 : value,
          ),
        )}
      </NumberContainer>

      <animated.div style={labelSpring}>
        <Label
          size="base"
          fontWeight="medium"
          margin={[null, null, null, 'xs']}
          variant={Variant.primary}
        >
          {label}
          {tooltip && (
            <StyledIcon
              margin={[null, null, null, 'xxs']}
              name="question-mark"
              onClick={() => {
                setShowTooltip(prev => !prev);
              }}
            />
          )}
        </Label>
        {showTooltip && <StyledTooltip>{tooltip}</StyledTooltip>}
      </animated.div>
    </Container>
  );
};

const animationConfig = {
  mass: 1,
  tension: 100,
  friction: 15,
};

type SentimentConfig = {
  backgroundColor:
    | [keyof SystemColorPalette, keyof ColorVariant]
    | [keyof SystemColorPalette];
  color: keyof SystemColorPalette;
  startY: string;
  clockwise: number;
};

const sentimentMap: { [key in Sentiment]: SentimentConfig } = {
  [Sentiment.Negative]: {
    backgroundColor: ['danger'],
    color: 'white',
    startY: '-200%',
    clockwise: 135,
  },
  [Sentiment.Positive]: {
    backgroundColor: ['success'],
    color: 'white',
    startY: '200%',
    clockwise: 45,
  },
  [Sentiment.Neutral]: {
    backgroundColor: ['tertiary'],
    color: 'primary',
    startY: '0%',
    clockwise: 90,
  },
};

const getSentimentConfig = (sentiment: Sentiment) =>
  sentimentMap[sentiment] ?? sentimentMap[Sentiment.Neutral];

const Container = styled.div<{}>`
  height: 50px;
  display: flex;
  align-items: center;
  position: relative;

  ${({ theme }) => css`
    margin: ${theme.space('m')};
    margin-right: ${theme.space('xl')};
  `};
`;

const StyledTooltip = styled(Tooltip)(
  () => css`
    width: 200px;
    a,
    a:hover {
      color: currentColor;
    }
  `,
);

const StyledIcon = styled(Icon)(
  () => css`
    cursor: pointer;
  `,
);

const ArrowContainer = styled(animated.div)<{ $sentiment: Sentiment }>`
  min-width: 48px;
  min-height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  ${({ theme, $sentiment }) => {
    const { backgroundColor, color } = getSentimentConfig($sentiment);
    return css`
      margin-right: ${theme.space('base')};
      font-size: ${theme.fs('xl')};
      background-color: ${theme.color(backgroundColor[0], backgroundColor[1])};
      color: ${theme.color(color)};
    `;
  }}
`;

const ArrowAnimationContainer = styled(animated.div)<{}>`
  display: flex;
  justify-content: center;
  transform-origin: center;
`;

const NumberContainer = styled(animated(Label))``;

const AnimatedArrow = animated(Icon);

export default StatIndicator;
