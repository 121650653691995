import React from 'react';
import styled, { css } from 'styled-components';
import {
  FlowAction,
  FlowData___ConditionRepresentationFragment,
} from '~/graphql/types';
import { getCondition } from '~/components/page/Automation/v2/components/UpdateAction/components/Selector/utils/getConditions';
import getSubject from '~/components/page/Automation/v2/components/UpdateAction/components/Selector/utils/getSubject';
import getLabelForRep from '~/components/page/Automation/v2/components/UpdateAction/components/ConditionEditorV2/utils/getLabelForRep';
import useBuilderContext from '~/components/page/Automation/v2/components/Builder/hooks/useBuilderContext';
import TEST_ID from './index.testid';
import useRelativeMaps from '../Builder/hooks/useRelativeMaps';
import TextButton from '~/components/atom/TextButton';
import { ConditionType } from '../UpdateAction/components/Selector/utils/getFieldsByPath';
import { useRecoilValue } from 'recoil';
import { actionById } from '../../state';
import RepresentationLabel from '../Builder/components/RepresentationLabel';
import type { Flow___ConditionPartial } from '../UpdateAction/components/ConditionEditorV2/types';

type Props = {
  condition: Flow___ConditionPartial | null;
  parentCondition?: Flow___ConditionPartial | null;
  actionId: string;
  hasIssue: boolean;
  type?: ConditionType;
  onClick?: (e: React.MouseEvent<HTMLSpanElement>, index?: number) => void;
};

const text = {
  addCondition: 'Klik om een conditie toe te voegen',
  addTrigger: 'Klik om een trigger toe te voegen',
};

const RepresentationString: React.FCC<Props> = ({
  condition,
  parentCondition,
  actionId,
  hasIssue,
  onClick,
  ...rest
}) => {
  const { opts } = useBuilderContext();
  const maps = useRelativeMaps({ actionId });
  const isInteractive = onClick != null;
  const action = useRecoilValue(actionById(actionId));
  if (action == null) return null;

  const conditionType =
    parentCondition == null && action.actionType != FlowAction.IfElse
      ? 'trigger'
      : 'condition';

  if (condition === null) {
    return (
      <TextButton
        icon="arrowRight"
        label={
          conditionType === 'trigger' && action.actionType === FlowAction.Start
            ? text.addTrigger
            : text.addCondition
        }
        onClick={e => {
          if (onClick) {
            e.stopPropagation();
            onClick(e, 0);
          }
        }}
        appearance={hasIssue ? 'danger' : 'accent'}
        padding={[null]}
        dataTestId={TEST_ID.ADD_CONDITION_BUTTON}
      />
    );
  }

  const representations: Array<FlowData___ConditionRepresentationFragment> | null =
    condition == null
      ? [
          {
            __typename: 'FlowData___ConditionRepresentation',
            rep: '...',
            variable: 0,
          },
        ]
      : condition.__typename === 'Flow___SubjectFieldCondition'
        ? getSubject(
            { type: condition.type, typeId: condition.typeId },
            maps.subjectMap,
          )?.fields.find(({ key }) => condition.field === key)
            ?.representation ?? null
        : getCondition(condition?.type, opts.conditionMap)?.representation ??
          null;

  if (representations === null) {
    return (
      <Container data-testid={TEST_ID.CONTAINER} {...rest}>
        <span>Waarde is niet (meer) beschikbaar</span>
      </Container>
    );
  }

  return (
    <Container data-testid={TEST_ID.CONTAINER} {...rest}>
      {representations.map((rep, repIndex) => {
        const representation = getLabelForRep(
          {
            representation: rep,
            condition,
            ctx: {
              action: action.actionType,
              type: conditionType,
            },
          },
          maps,
        );

        if (rep.variable == null)
          return (
            <RepChunkContainer key={repIndex}>
              {representation.error != null
                ? representation.error
                : representation.result}
            </RepChunkContainer>
          );

        return (
          <VariableContainer
            $isInteractive={isInteractive}
            key={repIndex}
            onClick={e => {
              if (onClick) {
                e.stopPropagation();
                onClick(e, repIndex);
              }
            }}
            data-testid={TEST_ID.VARIABLE_REP}
            {...rest}
          >
            {representation.error != null ? (
              representation.error
            ) : (
              <RepresentationLabel label={representation.result} />
            )}
          </VariableContainer>
        );
      })}
    </Container>
  );
};

const Container = styled.span(
  ({ theme }) => css`
    line-height: ${theme.lineHeight('m')};
  `,
);

const RepChunkContainer = styled.span(
  ({ theme }) => css`
    display: inline-block;
    margin-right: ${theme.space('xxs')};
  `,
);

const VariableContainer = styled(RepChunkContainer)<{
  $isInteractive: boolean;
}>(
  ({ theme, $isInteractive }) => css`
    font-weight: ${theme.fw('semiBold')};

    ${$isInteractive &&
    css`
      &:hover {
        color: ${theme.color('primary', 'light')};
      }
    `}
  `,
);

export default RepresentationString;
