import React from 'react';
import TEST_ID from './index.testid';
import Link, { linkStyles } from '~/components/molecule/Link';
import styled, { css } from 'styled-components';
import { Body } from '~/components/atom/Typography';

export type Props = {};

const text = {
  acceptTerms: 'Ik ga akkoord met de ',
  termsAndConditionsPdf: 'algemene voorwaarden',
  and: ' en ',
  processingAgreementPdf: '(verwerkers)overeenkomst',
  acceptTermsPost: ' van DatHuis',
};

const CheckboxLabel: React.FCC<Props> = () => (
  <Label margin={[null]} size="base" color={{ group: 'white' }}>
    {text.acceptTerms}
    <Link
      to="https://dhstash.s3-eu-west-1.amazonaws.com/Nederland+ICT+Voorwaarden+2014+-+NL.pdf"
      target="_blank"
      download="NederlandICTVoorwaarden.pdf"
      data-testid={TEST_ID.PDF_LINK}
    >
      {text.termsAndConditionsPdf}
    </Link>
    {text.and}
    <Link
      to="https://dhstash.s3.eu-west-1.amazonaws.com/DatHuis+(verwerkers)overeenkomst+v20241118.pdf"
      target="_blank"
      download="DatHuis verwerkers overeenkomst.pdf"
      data-testid={TEST_ID.PDF_LINK_PROCESSING}
    >
      {text.processingAgreementPdf}
    </Link>
    {text.acceptTermsPost}
  </Label>
);

const Label = styled(Body)(
  () => css`
    a {
      ${linkStyles}
    }
  `,
);

export default CheckboxLabel;
