import React from 'react';
import styled, { css } from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import TextButton from '~/components/atom/TextButton';

import Button, { Props as ButtonProps } from '~/components/atom/Button';
import Icon from '~/components/atom/Icon';
import { Heading3 } from '~/components/atom/Typography/index';
import TEST_ID from './index.testid';
import Modal from '../Modal';
import Catalog from '~/Catalog';
import {
  levelToAppearanceMapping,
  levelToColorMapping,
  levelToIconNameMapping,
  type ComponentLevel,
} from '~/styles/constants';
import type { BaseColor } from '~/theme/System/tokens';

type LocalButtonProps = Pick<
  ButtonProps,
  | 'label'
  | 'onClick'
  | 'appearance'
  | 'loading'
  | 'dataTestId'
  | 'icon'
  | 'disabled'
>;

export type Props = {
  header: React.ReactNode;
  body: React.ReactNode;

  /* Method provided by an Overlay component */
  onClose?: () => false | void | undefined;

  /** Button props to render buttons on the right side of the modal */
  buttons: Array<LocalButtonProps>;

  /**
   * Replaces the label of cancel button
   */
  cancelLabel?: string;

  /** Hides cancel button */
  hideCancel?: boolean;

  /** Loading state for all buttons in the modal */
  loading?: boolean;

  /**
   * Determines the colors and icons of the modal. If there is only one button
   * on the right side its color is determined by the level.
   */
  level?: ComponentLevel;
};

const Dialog: React.FCC<Props> = ({
  dataTestId,
  header,
  body,
  buttons,
  onClose,
  loading,
  cancelLabel = Catalog.cancel,
  hideCancel = false,
  level = 'warning',
  ...rest
}) => {
  const icon =
    level === 'danger' ? 'alert-circle' : levelToIconNameMapping[level];
  const color = levelToColorMapping[level];

  return (
    <Modal maxWidth={`${MAX_DIALOG_WIDTH}px`} onClose={onClose}>
      <Container
        backgroundColor={{ group: 'white' }}
        border={{ radius: 'base' }}
        data-testid={dataTestId}
        direction="column"
        width="100%"
        padding={['xxl', 'xxxxl', 'xl', 'xxxxl']}
        $borderColor={color}
        {...rest}
      >
        <JustificationContainer
          nonResponsive
          justification="start"
          align="center"
          gap="m"
          margin={[null, null, 'base', null]}
        >
          <LocalIcon name={icon} background={{ group: color }} />
          <Heading3 margin={['xxs', null, null, null]}>{header}</Heading3>
        </JustificationContainer>

        {body}

        <JustificationContainer
          width="100%"
          justification={hideCancel ? 'end' : 'space-between'}
          gap="m"
          align="center"
          margin={['s', null, null, null]}
        >
          {!hideCancel && (
            <TextButton
              label={cancelLabel || Catalog.cancel}
              size="medium"
              appearance="danger"
              loading={loading}
              onClick={onClose}
              dataTestId={TEST_ID.MODAL_CANCEL_BUTTON}
              padding={[null]}
            />
          )}

          <JustificationContainer gap="base">
            {buttons.map((props, index) => (
              <Button
                {...props}
                key={index}
                loading={props.loading || loading}
                appearance={
                  buttons.length === 1
                    ? levelToAppearanceMapping[level]
                    : props.appearance
                }
              />
            ))}
          </JustificationContainer>
        </JustificationContainer>
      </Container>
    </Modal>
  );
};

const MAX_DIALOG_WIDTH = 760;

const Container = styled(JustificationContainer)<{ $borderColor: BaseColor }>(
  ({ theme, $borderColor }) => css`
    border-top: 8px solid ${theme.color($borderColor)};
    box-shadow: ${theme.getTokens().boxShadow.modal};
  `,
);

const LocalIcon = styled(Icon)<{}>(
  ({ theme }) => css`
    font-size: ${theme.fs('l')};
    color: ${theme.color('white')};

    /* Correct position of icon */
    svg {
      margin-top: -0.1rem;
      font-size: 0.9em;
    }

    /* Make icon a little bigger */
    &::before {
      width: 1.4em;
      height: 1.4em;
    }
  `,
);

export default Dialog;
