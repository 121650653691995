import React from 'react';
import { useSpring, animated } from 'react-spring';
import styled, { css } from 'styled-components';
import Icon from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import TEST_ID from './index.testid';
import { BaseColor, SystemColorPalette } from '~/theme/System/tokens';
import useMeasure from '~/hooks/useMeasure';

export type Props = {
  /** Group id */
  id: string;

  /** Assigns to the container to be able to scroll to the block category */
  anchorId?: string;
  header: React.ReactNode;
  variant?: keyof SystemColorPalette;
  isOpen: boolean;
  disabled?: boolean;
  itemsLength: number;
  showSelectAllButton?: boolean;
  onToggle: () => void;
  markAllCheckboxes?: () => void;
};

const CollapsibleBlock: React.FCC<Props> = ({
  dataTestId,
  header,
  anchorId,
  variant = 'primary',
  children,
  isOpen,
  disabled = false,
  onToggle,
  itemsLength,
  id,
}) => {
  const { ref, bounds } = useMeasure();

  const { opacity } = useSpring({
    from: { opacity: 0 },
    to: { opacity: isOpen ? 1 : 0 },
    config: {
      tension: 2000,
      clamp: true,
    },
  });

  const { height, y } = useSpring({
    from: { height: 0, y: 0 },
    to: {
      height: isOpen ? bounds.height + 5 : 0,
      y: isOpen ? 0 : 20,
    },
  });

  const hasItems = itemsLength > 0;

  return (
    <Container id={anchorId} data-testid={dataTestId} $color={variant}>
      <StyledJustificationContainer
        justification="space-between"
        align="center"
        padding={isOpen ? [null, null, 'm', null] : [null]}
        data-objectid={id}
      >
        {hasItems && (
          <CollapseIcon
            dataTestId={TEST_ID.COLLAPSE_BUTTON}
            name={isOpen ? 'minusSquare' : 'plusSquare'}
            strokeWidth={2.5}
            color={{ group: variant }}
            onClick={() => onToggle && onToggle()}
          />
        )}

        {header}
      </StyledJustificationContainer>

      {children && (
        <animated.div
          data-testid={TEST_ID.CHILD_CONTAINER}
          style={{ opacity, height, overflow: 'hidden' }}
        >
          <ChildrenContainer ref={ref} style={{ y }} $disabled={disabled}>
            {children}
          </ChildrenContainer>
        </animated.div>
      )}
    </Container>
  );
};

const Container = styled.div<{ $color: BaseColor }>`
  ${({ $color, theme }) => css`
    margin: ${theme.space('xxxs')} 0;
    background-color: ${theme.color($color, 'light')};
    padding: ${theme.space('m')};
    border-radius: ${theme.getTokens().border.radius.m};

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  `}
`;

const StyledJustificationContainer = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    user-select: none;

    ${theme.mq.lessThan('mobile')`
      flex-direction: row;
    `};
  `,
);

const ChildrenContainer = styled(animated.div)<{ $disabled: boolean }>`
  ${({ $disabled }) => css`
    opacity: ${$disabled ? 0.5 : 1};
  `}
`;

const CollapseIcon = styled(Icon)<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  /** Because of use spring's initial value it is not accessible without z-index */
  z-index: 100;

  ${({ theme }) => css`
    font-size: ${theme.fs('m')};
  `};
`;

export default CollapsibleBlock;
