import React from 'react';
import { WithNavigationFrame as NotFound } from '~/components/page/404';

import DHRouter from '~/components/atom/DHRouter';

import withErrorBoundary from '~/ErrorBoundary';
import ErrorScreen from '~/components/page/ErrorScreen';
import Catalog from '~/Catalog';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import Nylas from './Nylas';

type Props = RouteComponentProps;
const External: React.FCC<Props> = () => (
  <DHRouter>
    <Nylas path="/nylas/auth/v3" />
    <NotFound default />
  </DHRouter>
);

export default withErrorBoundary<Props>(
  External,
  <ErrorScreen explanation={Catalog.genericUnknownErrorMessage} />,
);
