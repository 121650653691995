import { reporter } from '~/hooks/useErrorReporter';
import Validation from '~/util/Validation';
import { REDIRECT_URI } from '../../constants';

/**
 * Returns the link to sign in via Nylas
 * @param {string} state
 * @param {string} email
 * keywords: nylaslink
 */
const getNylasLink = ({
  state,
  email,
}: {
  state: string;
  email?: string;
}): string => {
  let link = `https://api.us.nylas.com/v3/connect/auth?client_id=${process.env.NYLAS_V3_CLIENT_ID}&response_type=code`;

  if (email && Validation.Email.isValid(email)) {
    link = link + `&login_hint=${email}`;
  }

  link = link + `&redirect_uri=${REDIRECT_URI}`;

  if (!Validation.String.isNonEmptyString(state)) {
    reporter.captureException(
      new Error(
        'Error while trying to build Nylas link because the state is not a string.',
      ),
    );
    return '';
  }

  if (state.length > 255) {
    reporter.captureException(
      new Error(
        `Error while trying to build Nylas link because the state: ${state} is too long. Max length is 255 characters.`,
      ),
    );
    return '';
  }

  link = link + `&state=${state}`;

  return link;
};

export default getNylasLink;
