import React, { useLayoutEffect } from 'react';
import {
  useLocation,
  type RouteComponentProps,
  navigate,
} from '@gatsbyjs/reach-router';
import { Helmet as MetaTags } from 'react-helmet';

import { Heading3 } from '~/components/atom/Typography';
import ContentContainerDefault, {
  MAX_CONTAINER_WIDTH,
} from '~/components/molecule/ContentContainer';

import EntityActivityOverview from './components/EntityActivityOverview';
import TEST_ID from './index.testid';
import EmailEffectiveness from './components/EmailEffectiveness';
import ChangeInLeadScore from './components/ChangeInLeadScore';
import MasonryGrid from '~/components/organism/MasonryGrid';
import ContactsCountByLeadSource from './components/ContactsCountByLeadSource';
import StatisticsOverview from './components/StatisticsOverview';
import FlowEffectiveness from './components/FlowEffectiveness';

// Holiday cheer!!
import Snowfall from 'react-snowfall';
import useDHFlag from '~/hooks/useDHFlag';
import Button from '~/components/atom/Button';
import RevenuePerSourceGroup from './components/RevenuePerSourceGroup';
import usePermissions from '~/hooks/usePermissions';
import OverviewListHeader from '~/components/molecule/OverviewListHeader';
import Icon from '~/components/atom/Icon';
import createPageTitle from '~/util/createPageTitle';

type Props = {
  today?: Date;
};

const text = {
  pageTitle: 'Dashboard',
  performanceHeader: 'Prestaties',
};

const Dashboard: React.FCC<RouteComponentProps & Props> = ({ today }) => {
  const hasHolidayCheer = useDHFlag('holiday-cheer');
  const hasDismissedCheer2022 = getDismissCheerStatus();
  const location = useLocation();
  const hasFlows = usePermissions(['root.automation']);

  useLayoutEffect(() => {
    if (location.pathname === '/-') {
      void navigate('/-/', { replace: true });
    }
  }, [location]);

  return (
    <>
      <MetaTags>
        <title>{createPageTitle(text.pageTitle)}</title>
      </MetaTags>

      {hasHolidayCheer && !hasDismissedCheer2022 && <Snowfall />}
      <ContentContainerDefault
        dataTestId={TEST_ID.CONTAINER}
        maxContentWidth={MAX_CONTAINER_WIDTH}
      >
        {hasHolidayCheer && (
          <Button
            ghost
            type="button"
            label={hasDismissedCheer2022 ? 'Let it snow!' : 'Zet de sneeuw uit'}
            style={{ float: 'right' }}
            size="small"
            onClick={toggleDismissCheerStatus}
          />
        )}
        <OverviewListHeader title={text.pageTitle} />

        <EntityActivityOverview dataTestId={TEST_ID.ENTITY_ACTIVITY_OVERVIEW} />
        <StatisticsOverview dataTestId={TEST_ID.OVERVIEW} today={today} />
        <Heading3 margin={['xl', null, 'm']}>
          <Icon name="activity" margin={[null, 's', null, null]} />
          {text.performanceHeader}
        </Heading3>
        <MasonryGrid>
          {hasFlows.allowed && (
            <FlowEffectiveness dataTestId={TEST_ID.FLOW_EFFECTIVENESS} />
          )}
          {hasFlows.allowed && (
            <EmailEffectiveness
              dataTestId={TEST_ID.EMAIL_EFFECTIVENESS}
              today={today}
            />
          )}
          <ChangeInLeadScore dataTestId={TEST_ID.CHANGE_IN_LEADSCORE} />
          <ContactsCountByLeadSource
            dataTestId={TEST_ID.CONTACT_COUNT_BY_LEADSCORE}
            today={today}
          />
          <RevenuePerSourceGroup
            dataTestId={TEST_ID.REVENUE_PER_SOURCE_GROUP}
            today={today}
          />
        </MasonryGrid>
      </ContentContainerDefault>
    </>
  );
};

const getDismissCheerStatus = () => {
  try {
    const stored = localStorage.getItem('dismissHolidayCheer2022');
    if (stored === '1') return true;
    return false;
  } catch (error) {
    return true;
  }
};

const toggleDismissCheerStatus = () => {
  const dismissed = getDismissCheerStatus();
  try {
    if (dismissed) {
      localStorage.setItem('dismissHolidayCheer2022', '0');
    } else {
      localStorage.setItem('dismissHolidayCheer2022', '1');
    }
    // Just reload the window no need to keep a state and sync it
    window.location.reload();
  } catch {
    // nothing...
  }
};

export default Dashboard;
