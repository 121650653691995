import { clone, isEmpty, isNil } from 'ramda';
import type { ZapierField } from '~/graphql/types';
import type {
  ClientFlowAction,
  ClientFlowActionZapierTrigger,
} from '~/graphql/types.client';

type MappingIds = { [mappingId: string]: boolean };

type Params = {
  action: ClientFlowActionZapierTrigger;
  fields: Array<ZapierField>;
};

/**
 * Removes the mapping ids that do not exist in the Zapier fields anymore
 * @param {ClientFlowActionZapierTrigger} action - Zapier action
 * @param {Array<ZapierField>} fields - Zapier fields returned from getZapierTrigger query
 */
const removeObsoleteMappings = ({
  action,
  fields,
}: Params): {
  outdatedMappingsIds: MappingIds;
  updatedAction: ClientFlowAction;
} => {
  if (isNil(fields) || isEmpty(fields))
    return {
      outdatedMappingsIds: {},
      updatedAction: action,
    };

  const outdatedMappingsIds: MappingIds = action.mappings.reduce(
    (prev, mapping) => {
      const found = fields?.find(field => field.key === mapping.mappingId);
      if (!found) prev[mapping.mappingId] = true;
      return prev;
    },
    {},
  );

  const clonedAction = clone(action);

  return {
    outdatedMappingsIds,
    updatedAction: {
      ...clonedAction,
      mappings: clonedAction.mappings.filter(
        mapping => !outdatedMappingsIds[mapping.mappingId],
      ),
    },
  };
};

export default removeObsoleteMappings;
