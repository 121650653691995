import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';

export type Props = {
  step: number;
  isHovered: boolean;
};

const StepOrIconComponent: React.FCC<Props> = ({ isHovered, step }) => (
  <StepOrIcon
    justification="center"
    align="center"
    margin={[null, 'm', null, null]}
    $isHovered={isHovered}
  >
    {isHovered ? (
      <StyledIcon name="arrowRight" color={{ group: 'white' }} />
    ) : (
      <StepIndicator>{step}</StepIndicator>
    )}
  </StepOrIcon>
);

type HoveredProps = {
  $isHovered: boolean;
};

const CIRCLE_SIZE = '60px';
const TRANSITION = 'all 0.5s ease';

const StepOrIcon = styled(JustificationContainer)<HoveredProps>(
  ({ theme, $isHovered }) => css`
    width: ${CIRCLE_SIZE};
    height: ${CIRCLE_SIZE};
    border-radius: ${theme.getTokens().border.radius.full};
    background: ${$isHovered
      ? theme.color('primary', 'light')
      : theme.color('white')};
    color: ${theme.color('primary')};
    flex-shrink: 0;
    transition: ${TRANSITION};
  `,
);

const StyledIcon = styled(Icon)<{}>(
  ({ theme }) => css`
    font-size: ${theme.fs('l')};
  `,
);

const StepIndicator = styled.div<{}>(
  ({ theme }) => css`
    font-size: ${theme.fs('l')};
    font-weight: ${theme.fw('semiBold')};
  `,
);

export default StepOrIconComponent;
