import { clone } from 'ramda';
import { v4 } from 'uuid';
import type { Flow___ConditionPartial } from '~/components/page/Automation/v2/components/UpdateAction/components/ConditionEditorV2/types.ts';

/**
 * Extracts a condition without arguments
 */
const getBaseCondition = (
  condition: Flow___ConditionPartial,
): Flow___ConditionPartial => ({
  ...clone(condition),
  args: condition.args.map(() => null),
  id: v4(),
});

export default getBaseCondition;
