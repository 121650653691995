import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '~/components/atom/Icon';
import Button from '~/components/atom/Button';
import JustificationContainer from '~/components/atom/JustificationContainer';
import TEST_ID from './index.testid';
import { Body } from '~/components/atom/Typography';
import type { Client_FlowV2_EmailAttachment } from '../../../types';

const text = {
  brokenFile: ' (Foutief bestand, upload het opnieuw)',
};

export type Props = {
  dataTestId?: string;
  attachment: Client_FlowV2_EmailAttachment;
  onDelete: () => void;
};

const FileAttachment: React.FCC<Props> = ({
  attachment,
  onDelete,
  dataTestId,
}: Props) => {
  const file = attachment?.file;

  const fileName =
    file && file.__typename === 'Flow___Argument_File'
      ? file.value_file.filename
      : '';

  const isBroken =
    file &&
    file.__typename === 'Flow___Argument_File' &&
    file.value_file &&
    file.value_file.s3key === '';

  return (
    <StyledJustificationContainer
      dataTestId={dataTestId}
      justification="space-between"
      align="center"
      margin={['xxxs', null]}
    >
      <JustificationContainer align="center">
        {isBroken ? (
          <>
            <StyledIcon name="file-text" color={{ group: 'danger' }} />
            <Body color={{ group: 'danger' }} margin={[null]}>
              {fileName}
              <>{text.brokenFile}</>
            </Body>
          </>
        ) : (
          <>
            <StyledIcon name="file-text" />
            <Anchor
              href={attachment?.url as string}
              target="_blank"
              rel="noopener noreferrer"
              key={fileName}
              download={fileName}
            >
              {fileName}
            </Anchor>
          </>
        )}
      </JustificationContainer>

      <Button
        icon="delete"
        appearance="danger"
        ghost
        onClick={onDelete}
        dataTestId={TEST_ID.DELETE_BUTTON}
      />
    </StyledJustificationContainer>
  );
};

const StyledJustificationContainer = styled(JustificationContainer)<{}>(
  () => css`
    width: 100%;
  `,
);

const Anchor = styled.a<{}>(
  ({ theme }) => css`
    color: ${theme.color('primary', 'light')};
    margin-right: ${theme.space('s')};
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
);

const StyledIcon = styled(Icon)<{}>`
  ${({ theme }) => css`
    font-size: ${theme.space('m')};
    margin-right: ${theme.space('s')};
  `}
`;

export default FileAttachment;
