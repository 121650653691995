import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Icon, { type IconType } from '~/components/atom/Icon';
import { Heading4, Variant } from '~/components/atom/Typography';
import { Sentiment } from '~/graphql/types';
import type { ThemeColor } from '~/theme/System/tokens/colorPalette';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Divider from '~/components/atom/Divider';
import useMeasure from '~/hooks/useMeasure';
import { animated, useSpring } from 'react-spring';
import hasChildren from '~/util/hasChildren';

type Icon = 'GEAR' | 'HOME' | 'PERSON' | 'LEADSCORE';

export type Props = {
  children: React.ReactNode;

  /** The title to show in the header of the block */
  title: string;

  /** Which icon to show */
  icon: IconType;

  loading?: boolean;

  /**
   * Collapses the section by default when true.
   */
  defaultCollapse?: boolean;

  /* Changes color of header according to sentiment */
  sentiment?: Sentiment;
};

const FoldableCardBlock: React.FCC<Props> = ({
  dataTestId,
  children,
  title,
  icon,
  loading,
  sentiment = Sentiment.Neutral,
  defaultCollapse,
  ...rest
}) => {
  const [folded, setFolded] = useState<boolean>(
    defaultCollapse !== undefined ? defaultCollapse : !hasChildren(children),
  );

  const toggleFold = () => setFolded(prevState => !prevState);
  const sentimentColor = sentimentToColorName(sentiment);

  const { ref, bounds } = useMeasure();

  const { opacity } = useSpring({
    from: { opacity: 0 },
    to: { opacity: !folded ? 1 : 0 },
    config: {
      tension: 2000,
      clamp: true,
    },
  });

  const { height, y } = useSpring({
    from: { height: 0, y: 0 },
    to: {
      height: !folded ? bounds.height + 20 : 0,
      y: !folded ? 0 : 20,
    },
  });

  return (
    <Container data-testid={dataTestId} {...rest}>
      <Header
        data-testid="foldable-card-header"
        onClick={toggleFold}
        align="center"
        justification="space-between"
      >
        <JustificationContainer gap="s" align="center">
          <Icon name={icon} skewed color={sentimentColor} />
          <Heading4
            fontWeight="medium"
            margin={[null]}
            variant={Variant.primary}
            color={sentimentColor}
          >
            {title}
          </Heading4>
        </JustificationContainer>

        <Icon
          name={folded ? 'chevron-left' : 'chevron'}
          flipX={folded}
          size="l"
          color={{ group: 'secondary' }}
        />
      </Header>

      <animated.div style={{ opacity, height, overflow: 'hidden' }}>
        <animated.div ref={ref} style={{ y }}>
          <Divider margin={['s', null]} />
          {loading ? <Spinner name="loading" /> : children}
        </animated.div>
      </animated.div>
    </Container>
  );
};

const sentimentToColorName = (sentiment: Sentiment): ThemeColor => {
  switch (sentiment) {
    case Sentiment.Negative:
      return { group: 'danger' };
    case Sentiment.Neutral:
      return { group: 'primary' };
    case Sentiment.Positive:
      return { group: 'success' };
  }
};

const Container = styled.div<{}>`
  ${({ theme }) => css`
    padding: ${theme.space('m')} ${theme.space('l')};
    border-radius: ${theme.getTokens().border.radius.m};
    background-color: ${theme.color('white')};
    margin-bottom: ${theme.space('l')};
    box-shadow: ${theme.boxShadow('card')};
    border: 1px solid ${theme.color('tertiary', 'light')};
  `};
`;

const Header = styled(JustificationContainer)<{}>(
  ({}) => css`
    min-width: 100%;
    cursor: pointer;
    user-select: none;
  `,
);

const Spinner = styled(Icon)<{}>`
  display: block;
  & svg {
    margin: auto;
  }
  ${({ theme }) => css`
    padding: ${theme.space('xxs')};
  `};
`;

export default FoldableCardBlock;
