import React from 'react';
import styled, { css } from 'styled-components';
import SelectBlock from '~/components/molecule/SelectBlock';
import MasonryGrid from '~/components/organism/MasonryGrid';

import TEST_ID from './index.testid';
import useTooltipLayer from '~/hooks/useTooltipLayer';
import type {
  ZapierTriggerTemplate,
  ZapierTriggerTemplates,
} from '~/components/organism/WizardSteps/Apps/Zapier/AddZapierTrigger/components/TriggerTemplatesContainer/zapierTriggerTemplates';
import zapierTriggerTemplates from '~/components/organism/WizardSteps/Apps/Zapier/AddZapierTrigger/components/TriggerTemplatesContainer/zapierTriggerTemplates';

export type Props = {
  selectedTriggers: ZapierTriggerTemplates;
  onChange: (triggers: ZapierTriggerTemplates) => void;
};

const text = {
  tooltip:
    'Contactgegevens worden standaard aan iedere uitgaande koppeling toegevoegd.',
};

const TriggerTemplatesContainer: React.FCC<Props> = ({
  dataTestId,
  selectedTriggers,
  onChange,
  ...rest
}) => {
  const tooltipProps = useTooltipLayer({ tooltipMessage: text.tooltip });

  return (
    <Container data-testid={dataTestId} {...rest}>
      <SelectBlock
        type="checkbox"
        checked
        disabled
        checkboxTitle="Contactgegevens"
        {...tooltipProps}
      />

      {zapierTriggerTemplates.map((trigger: ZapierTriggerTemplate) => {
        const isTriggerSelected = selectedTriggers.some(
          selected => selected.id === trigger.id,
        );

        return (
          <SelectBlock
            type="checkbox"
            checked={isTriggerSelected}
            data-checked={isTriggerSelected}
            checkboxTitle={trigger.name}
            description={trigger.description}
            key={trigger.id}
            dataTestId={TEST_ID.TEMPLATE}
            onClick={() => {
              if (isTriggerSelected) {
                onChange(
                  selectedTriggers.filter(
                    selectedTrigger => selectedTrigger.id !== trigger.id,
                  ),
                );
              } else {
                onChange([...selectedTriggers, trigger]);
              }
            }}
          />
        );
      })}
    </Container>
  );
};

const Container = styled(MasonryGrid)<{}>`
  ${({ theme }) => css`
    margin-top: ${theme.space('l')};
    margin-bottom: ${theme.space('base')};
    column-gap: ${theme.space('l')};
  `}
`;

export default TriggerTemplatesContainer;
