import styled, { css } from 'styled-components';
import type { DefaultTheme } from 'styled-components/dist/types';
import TextButton from '~/components/atom/TextButton';

const getSelectedStyles = (theme: DefaultTheme) => css`
  color: ${theme.color('secondary')};
  box-shadow: ${theme.boxShadow('sleek')};
  background-color: ${theme.color('white')};
`;

const PaginationButton = styled(TextButton)<{
  $selected?: boolean;
}>(
  ({ theme, $selected, disabled }) => css`
    text-decoration: none;
    height: ${theme.space('xl')};

    ${$selected === true && getSelectedStyles(theme)}

    svg {
      color: ${disabled ? 'currentColor' : theme.color('secondary')};
    }
  `,
);

export default PaginationButton;
