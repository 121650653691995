import React from 'react';
import type { JsonStructure, JsonStructureItem } from 'react-cmdk';
import { navigate } from '@gatsbyjs/reach-router';
import Icon from '~/components/atom/Icon';
import useMenuOptions from '~/components/organism/Navigation/components/SettingsMenu/hooks/useMenuOptions';

const useTasksItems = (): JsonStructure[number] => {
  const settingsMenuItems = useMenuOptions();

  const items = settingsMenuItems.map(
    (option): JsonStructureItem => ({
      id: option.payload,
      children: option.label,
      icon: () => (
        <Icon
          name={option.icon?.name ?? 'gear'}
          color={{ group: 'secondary' }}
        />
      ),
      showType: false,
      keywords: ['settings', option.payload.replace('/-/settings/', '')],
      onClick: () => {
        void navigate(option.payload);
      },
    }),
  );

  return {
    heading: 'Instellingen',
    id: 'settings',
    items: items,
  };
};

export default useTasksItems;
