import {
  UserStatus,
  EmailSyncStatus,
  EmailDeliveryStatus,
  EmailAliasStatus,
} from '~/graphql/types';

/*** The types of activity that the backend can deliver ***/
export enum ACTIVITY_TYPE {
  EVENT_CONTACT_NEW = 'Event_Contact_New',
  EVENT_CONTACT_CALL = 'Event_Contact_Call',
  EVENT_CONTACT_NOTE = 'Event_Contact_Note',
  EVENT_CONTACT_EMAIL = 'Event_Contact_Email',
  EVENT_CONTACT_FORM = 'Event_Contact_Form',
  TASK_CONTACT = 'Task_Contact',
  EVENT_CONTACT_VALUATIONREPORT = 'Event_Contact_App_ValuationReport',
  EVENT_CONTACT_FUNDA_VIEWINGREQUEST = 'Event_Contact_App_Funda_ViewingRequest',
  EVENT_CONTACT_FUNDA_CONTACTREQUEST = 'Event_Contact_App_Funda_ContactRequest',
  EVENT_CONTACT_FUNDA_BROCHUREREQUEST = 'Event_Contact_App_Funda_BrochureRequest',
  EVENT_CONTACT_VALUATIONREQUEST = 'Event_Contact_App_ValuationRequest',
  EVENT_CONTACT_BRAINBAY_REPORT = 'Event_Contact_App_BBWaardecheck_Report',
  EVENT_CONTACT_BRAINBAY_CONTACT_REQUEST = 'Event_Contact_App_BBWaardecheck_ContactRequest',
  EVENT_CONTACT_BRAINBAY_APPRAISAL_REQUEST = 'Event_Contact_App_BBWaardecheck_AppraisalRequest',

  EVENT_CONTACT_VBO_REPORT = 'Event_Contact_App_VBOWaardecheck_Report',
  EVENT_CONTACT_VBO_CONTACT_REQUEST = 'Event_Contact_App_VBOWaardecheck_ContactRequest',
  EVENT_CONTACT_VBO_APPRAISAL_REQUEST = 'Event_Contact_App_VBOWaardecheck_AppraisalRequest',

  EVENT_CONTACT_ZAPIER = 'Event_Contact_App_Zapier',
  EVENT_TRIGGER_ZAPIER = 'Event_Contact_App_ZapierTrigger',

  EVENT_CONTACT_HYPOTHEEKBOND_APP = 'Event_Contact_App_Hypotheekbond',

  EVENT_CONTACT_ARXGROEP_CONTRACT_CREATED = 'Event_Contact_App_ARXGroep_ContractCreated',
  EVENT_CONTACT_ARXGROEP_TRANSACTION_CREATED = 'Event_Contact_App_ARXGroep_TransactionCreated',

  EVENT_CONTACT_APP_ENVENREQUEST = 'Event_Contact_App_EnvenRequest',
  EVENT_CONTACT_APP_ENVENTRANSACTION = 'Event_Contact_App_EnvenTransaction',

  EVENT_CONTACT_APP_TRUSTOO = 'Event_Contact_App_Trustoo',
  EVENT_CONTACT_APP_TRUSTOO_SUBMITTED = 'Event_Contact_App_TrustooSubmitted',
  EVENT_CONTACT_APP_TRUSTOO_CONVERSION = 'Event_Contact_App_TrustooConversion',

  EVENT_CONTACT_SUSTAINABILITY_REPORT_APP = 'Event_Contact_App_SustainabilityReport',
  EVENT_CONTACT_APP_GEOGAP_TRANSACTION = 'Event_Contact_App_GeoGap_Transaction',
  EVENT_CONTACT_APP_STELLAX_AI_TRANSACTION = 'Event_Contact_App_StellaxAi_Transaction',
}

export const isPendingStatus = (
  status: UserStatus | 'Deleted' | undefined,
): boolean =>
  status === UserStatus.Invited ||
  status === UserStatus.InvitedPending ||
  status === UserStatus.Pending;

export const isSuccessEmailDeliveryStatus = (
  status: EmailDeliveryStatus,
): boolean =>
  status === EmailDeliveryStatus.Synced ||
  status === EmailDeliveryStatus.Sending;
export const isErrorEmailDeliveryStatus = (status: EmailDeliveryStatus) =>
  status === EmailDeliveryStatus.Error;
export const isSendingEmailDeliveryStatus = (status: EmailDeliveryStatus) =>
  status === EmailDeliveryStatus.Scheduled;

export enum MAILBOX_TYPE {
  AccountMailbox = 'AccountMailbox',
  AccountMailboxAlias = 'AccountMailboxAlias',
  OfficeMailbox = 'OfficeMailbox',
  OfficeMailboxAlias = 'OfficeMailboxAlias',
  UserMailbox = 'UserMailbox',
  UserMailboxAlias = 'UserMailboxAlias',
}
export const mailboxTypes = [
  MAILBOX_TYPE.AccountMailbox,
  MAILBOX_TYPE.OfficeMailbox,
  MAILBOX_TYPE.UserMailbox,
];
export const aliasMailboxTypes = [
  MAILBOX_TYPE.AccountMailboxAlias,
  MAILBOX_TYPE.OfficeMailboxAlias,
  MAILBOX_TYPE.UserMailboxAlias,
];

export const isSuccessEmailAliasSyncStatus = (status: EmailAliasStatus) =>
  status === EmailAliasStatus.Verified;
export const isSuccessEmailSyncStatus = (status: EmailSyncStatus) =>
  status === EmailSyncStatus.Running ||
  status === EmailSyncStatus.Downloading ||
  // Debatable if this is the right way to handle as running.
  status === EmailSyncStatus.Partial ||
  status === EmailSyncStatus.Initializing ||
  status === EmailSyncStatus.Valid ||
  status === EmailSyncStatus.MigrationResyncNeeded;

export const isErrorEmailSyncStatus = (status: EmailSyncStatus) =>
  status === EmailSyncStatus.InvalidCredentials ||
  status === EmailSyncStatus.Stopped ||
  status === EmailSyncStatus.Exception ||
  status === EmailSyncStatus.SyncError ||
  status === EmailSyncStatus.Invalid;

/** The levels of authorising we have */
export enum AUTHORISE_LEVEL {
  ACCOUNT = 'Account',
  OFFICE = 'Office',
  USER = 'User',
}

export type FileType =
  | '*'
  | 'application/json'
  | 'application/ld+json'
  | 'application/msword'
  | 'application/pdf'
  | 'application/vnd.api+json'
  | 'application/vnd.ms-excel'
  | 'application/vnd.ms-powerpoint'
  | 'application/vnd.oasis.opendocument.text'
  | 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  | 'application/xml'
  | 'application/zip'
  | 'audio/mpeg'
  | 'audio/ogg'
  | 'image/gif'
  | 'image/apng'
  | 'image/flif'
  | 'image/webp'
  | 'image/x-mng'
  | 'image/jpeg'
  | 'image/png'
  | 'text/csv'
  | 'text/html'
  | 'text/plain'
  | 'text/xml';

export enum FILE_TYPE {
  CSV = 'text/csv',
  JSON = 'application/json',
  PDF = 'application/pdf',
}

export enum PART_OF_DAY {
  MORNING = 'morning',
  AFTERNOON = 'afternoon',
  EVENING = 'evening',
  NIGHT = 'night',
}
