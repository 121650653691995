import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import Button from '~/components/atom/Button';
import Icon, { type IconType } from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Label } from '~/components/atom/Typography';
import type { BaseColor } from '~/theme/System/tokens';
import { isNil } from 'ramda';
import type { WorkingAreaType } from '../MapsContainer/components/Draw';
import AddAreaButtons from './components/AddAreaButtons';

const text = {
  select: 'Selecteer: ',
  activeArea: 'Actief: ',
  stopSelection: 'Klaar met selecteren',
  // Used below as key so do not remove or change
  inclusive: 'Werkgebied toevoegen',
  exclusive: 'Werkgebied uitsluiten',
};

export type Props = {
  activeDrawType: WorkingAreaType | null;
  onStopSelection: () => void;
  onStartSelection: (type: WorkingAreaType) => void;
};

const AddWorkingAreaContainer: React.FCC<Props> = ({
  activeDrawType,
  onStopSelection,
  onStartSelection,
}) => {
  const theme = useTheme();

  if (!isNil(activeDrawType)) {
    const iconName = getLabelProps(activeDrawType).icon;
    const colorGroup = getLabelProps(activeDrawType).color;
    const color = theme.color(colorGroup);

    return (
      <JustificationContainer padding={['xxs']} gap="xxs" align="center">
        <LeftSide
          align="center"
          justification="stretch"
          padding={['xxs']}
          backgroundColor={{ group: colorGroup, variant: 'translucent' }}
          // Add border to make it the same height as the button next to it
          border={{
            radius: 's',
            width: 'base',
          }}
        >
          <Label lineHeight="s" size="base" margin={[null, 'xxs', null, null]}>
            {text.activeArea}
          </Label>

          <Icon
            strokeWidth={2.5}
            name={iconName}
            color={{ group: colorGroup }}
            margin={[null, 'xxxs', null, null]}
          />
          <Label
            lineHeight="s"
            margin={[null]}
            color={{ group: colorGroup }}
            style={{ borderBottom: `2px solid ${color}` }}
          >
            {text[activeDrawType]}
          </Label>
        </LeftSide>

        <Button label={text.stopSelection} onClick={onStopSelection} ghost />
      </JustificationContainer>
    );
  }

  return <AddAreaButtons onStartSelection={onStartSelection} />;
};

const getLabelProps = (
  drawType: WorkingAreaType,
): { icon: IconType; color: BaseColor } => {
  if (drawType === 'inclusive') return { color: 'success', icon: 'shield' };
  return { color: 'danger', icon: 'shield-off' };
};

const LeftSide = styled(JustificationContainer)(
  () => css`
    border-color: transparent;
  `,
);

export default AddWorkingAreaContainer;
