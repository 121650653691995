import React from 'react';
import styled from 'styled-components';
import { DateRangeInput, SortFieldAdvanced } from '~/graphql/types';

import TEST_ID from './index.testid';
import Dropdown, { OptionOf } from '~/components/molecule/Dropdown';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Heading4, Variant } from '~/components/atom/Typography';

export type Props = {
  selectedOptionIndex: number;
  options: Array<OptionOf<DateRangeInput | SortFieldAdvanced | number | null>>;
  onOptionChange: ({
    selectedOptionIdx,
  }: {
    selectedOptionIdx: number;
  }) => void;
  headingText: string;
};

const CardHeader: React.FCC<Props> = ({
  dataTestId,
  selectedOptionIndex,
  options,
  onOptionChange,
  headingText,
}) => (
  <JustificationContainer
    data-testid={`${dataTestId}-${TEST_ID.CONTAINER}`}
    justification="space-between"
    margin={[null, null, 'l', null]}
  >
    <Heading4 margin={[null]} variant={Variant.primary}>
      {headingText}
    </Heading4>
    <DropdownRestriction>
      <Dropdown
        dataTestId={`${dataTestId}-${TEST_ID.DROPDOWN}`}
        options={options}
        selectedOptionIdx={selectedOptionIndex}
        onChange={onOptionChange}
      />
    </DropdownRestriction>
  </JustificationContainer>
);

const DropdownRestriction = styled.div<{}>`
  flex: 0 1 50%;
`;

export default CardHeader;
