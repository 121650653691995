import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { css, useTheme } from 'styled-components';
import { clone, isNil, pluck } from 'ramda';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { NodeProps } from 'reactflow';
import { animated, useSpring } from 'react-spring';
import { actionsSelector } from '~/components/page/Automation/v2/state';
import interactions from '~/components/page/Automation/v2/state/interactions';
import flowIssues, {
  issuesByActionId,
} from '~/components/page/Automation/v2/state/flowIssues';
import { DEFAULT_WIDTH } from '../../constants';
import Icon from '~/components/atom/Icon';
import Button from '~/components/atom/Button';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Body, Heading4, Variant } from '~/components/atom/Typography';
import useBuilderContext from '../../../../hooks/useBuilderContext';
import getIssueLevel from '../../../../utils/getIssueLevel';
import getCardOptions from './utils/getCardOptions';
import { getCardValues } from './utils/getCardValues';
import type { ClientFlowAction } from '~/graphql/types.client';
import TEST_ID from './index.testid';
import isEmptyAction from '../../../../utils/isEmptyAction';
import getIssuesForAction from '~/components/page/Automation/v2/components/UpdateAction/utils/getIssuesForAction';
import useRelativeMaps from '../../../../hooks/useRelativeMaps';
import lastDeletedActionId from '~/components/page/Automation/v2/state/lastDeletedActionId';
import flowChanges from '~/components/page/Automation/v2/state/flowChanges';
import DropdownListContainer from '~/components/molecule/Dropdown/components/DropdownListContainer';
import ActionLabel from '~/components/page/Automation/v2/components/Builder/components/RepresentationLabel/components/ActionLabel';
import getCardLabel from './utils/getCardLabel';
import onConnectActions from './utils/onConnectActions';
import metadata from '~/components/page/Automation/v2/state/metadata';
import useOffices from '~/hooks/useOffices';
import useUsers from '~/hooks/useUsers';
import dialogs from '~/components/page/Automation/v2/state/dialogs';
import { useGetZapierTriggersQuery } from '~/graphql/types';

export type NodeData<ActionT> = {
  id: string;
  action: ActionT;
};

export type Props<ActionT extends ClientFlowAction> = NodeProps<
  NodeData<ActionT>
> & {
  dataTestId?: string;
  actionType: ClientFlowAction['actionType'];
  heading: string;
  disabled?: boolean;
  children?: React.ReactNode;
};

const CardTemplate: React.FCC<Props<ClientFlowAction>> = ({
  actionType,
  heading,
  disabled = false,
  selected = false,
  children,
  data,
  dataTestId,
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const { accountId, flowBlueprintId, initialFlow, availableActions } =
    useBuilderContext();

  const setIssues = useSetRecoilState(flowIssues);
  const storedIssues = useRecoilValue(issuesByActionId(data.action.id));
  const relativeMaps = useRelativeMaps({
    actionId: data.action.id,
  });

  const offices = useOffices({ onlyExistingOffices: false });
  const users = useUsers();

  const { data: zapierTriggerData } = useGetZapierTriggersQuery({
    variables: { accountId },
  });

  const opts =
    zapierTriggerData && zapierTriggerData.getZapierTriggers
      ? {
          offices,
          users,
          zapier: {
            triggers: pluck('id', zapierTriggerData.getZapierTriggers),
          },
        }
      : { offices, users };

  const relativeIssues = getIssuesForAction({
    action: data.action,
    relativeMaps,
    availableActions,
    opts,
  });

  const [actions, setActions] = useRecoilState(
    actionsSelector({
      accountId,
      flowBlueprintId,
    }),
  );
  const [currentInteraction, setInteraction] = useRecoilState(interactions);
  const setFlowMetadata = useSetRecoilState(metadata);
  const setDialog = useSetRecoilState(dialogs);
  const [shouldHighlight, setShouldHighlight] = useState(false);

  const dotMenuRef = useRef<HTMLElement | null>(null);

  const styles = useSpring({
    from: {
      transform: 'scale(0)',
      opacity: 0,
    },
    to: {
      transform: 'scale(1)',
      opacity: 1,
    },
    config: {
      mass: 1,
      tension: 170,
      friction: 13,
    },
  });

  const theme = useTheme();
  const issueLevel = getIssueLevel(storedIssues);
  const { icon, color } = getCardValues({ actionType, issueLevel, theme });

  useEffect(() => {
    const actionIds = pluck('id', actions);
    if (
      relativeIssues.length !== storedIssues.length &&
      actionIds.includes(data.action.id) &&
      !isEmptyAction(data.action) &&
      currentInteraction?.type !== 'updateAction'
    ) {
      setIssues(prev => ({
        ...prev,
        [data.action.id]: relativeIssues,
      }));
    }
  }, [
    actions,
    currentInteraction,
    data.action,
    relativeIssues,
    setIssues,
    storedIssues,
  ]);

  const isExistingAction =
    initialFlow.actions.findIndex(action => action.id === data.action.id) !==
    -1;

  // Disabled due to lack of time
  // const onDragStart = useCallback(
  //   (event: DragEvent) => {
  //     event.stopPropagation();
  //     setInteraction({ type: 'dragging', action: data.action });
  //     event.dataTransfer.setData(
  //       'application/reactflow',
  //       JSON.stringify(data.action),
  //     );
  //     event.dataTransfer.effectAllowed = 'move';
  //   },
  //   [data, setInteraction],
  // );

  const setLastDeletedActionId = useSetRecoilState(lastDeletedActionId);
  const setFlowHasChanges = useSetRecoilState(flowChanges);

  const initialActions = initialFlow.actions;
  const flowEnabled = initialFlow.enabled;
  const dropdownOptions = useMemo(
    () =>
      getCardOptions({
        action: data.action,
        actions,
        setActions,
        setInteraction,
        setDialog,
        isExistingAction,
        setIssues,
        setLastDeletedActionId: id => setLastDeletedActionId(id),
        setFlowMetadata,
        initialActions,
        flowEnabled,
      }),
    [
      data.action,
      actions,
      setActions,
      setInteraction,
      setDialog,
      isExistingAction,
      setIssues,
      setLastDeletedActionId,
      setFlowMetadata,
      initialActions,
      flowEnabled,
    ],
  );

  const isConnecting =
    currentInteraction?.type === 'connectAction' &&
    isNil(
      currentInteraction.validTargets.find(({ id }) => id === data.action.id),
    );

  const highlighted =
    currentInteraction?.type === 'highlightActions'
      ? currentInteraction.highlightedActions?.includes(data.action.id)
      : true;

  const enableHighlightOnMouseOver =
    currentInteraction?.type === 'connectAction';

  const isBeingCopied =
    currentInteraction?.type === 'copyAction' &&
    currentInteraction?.copiedAction.id === data.action.id;
  const isInactiveWhenCopying =
    currentInteraction?.type === 'copyAction' && !isBeingCopied;

  const actionLabel = data.action.label
    ? getCardLabel(data.action.label)
    : null;

  const onCardClick = useCallback(
    e => {
      if (isBeingCopied) return;

      e.stopPropagation();
      e.preventDefault();

      switch (currentInteraction?.type) {
        case 'connectAction': {
          setActions(prevActions => {
            const nextActions = onConnectActions({
              actions: prevActions,
              currentInteraction,
              targetAction: data.action,
            });

            return nextActions;
          });

          setFlowHasChanges(prev => {
            const flowChanges = clone(prev);
            flowChanges.actions[data.action.id] = true;
            return flowChanges;
          });

          setShouldHighlight(false);
          return setInteraction(null);
        }
        case null:
        default:
          return setInteraction({
            type: 'updateAction',
            action: data.action,
          });
      }
    },
    [
      currentInteraction,
      setInteraction,
      data.action,
      setActions,
      setFlowHasChanges,
      isBeingCopied,
    ],
  );

  const optionsDisabled =
    !highlighted ||
    isConnecting ||
    currentInteraction?.type === 'copyAction' ||
    enableHighlightOnMouseOver;

  return (
    <Container
      // Disabled due to lack of time
      // draggable
      onClick={onCardClick}
      // Add nowheel classname to enable scrolling in cards
      // className="nowheel"
      data-testid={dataTestId}
      $color={color}
      $selected={selected || showDropdown || shouldHighlight}
      $disabled={
        disabled || !highlighted || isConnecting || isInactiveWhenCopying
      }
      $dashedBorder={isBeingCopied}
      $hasIssue={!isNil(issueLevel)}
      // onDragStart={onDragStart}
      style={styles}
      onMouseEnter={
        enableHighlightOnMouseOver ? () => setShouldHighlight(true) : undefined
      }
      onMouseLeave={
        enableHighlightOnMouseOver ? () => setShouldHighlight(false) : undefined
      }
      data-hasissue={!isNil(issueLevel)}
    >
      {dropdownOptions.length !== 0 && (
        <Options>
          <StyledMenuIcon
            dataTestId={TEST_ID.OPTIONS}
            name="dot-menu"
            $disabled={disabled || optionsDisabled}
            onClick={e => {
              e.stopPropagation();
              setShowDropdown(prev => !prev);
            }}
            ref={dotMenuRef}
          />
          <DropdownListContainer
            dataTestId={TEST_ID.DROPDOWN}
            onChange={v => {
              v.option.payload(data.action);
              setShowDropdown(false);
            }}
            dropdownListOpen={showDropdown}
            onClickOutside={() => setShowDropdown(false)}
            onClose={() => setShowDropdown(false)}
            options={dropdownOptions}
            openerRef={dotMenuRef}
          />
        </Options>
      )}
      <JustificationContainer
        justification="start"
        align="center"
        nonResponsive={true}
        margin={children ? [null, null, 'm', null] : [null, null]}
      >
        <StyledIcon
          name={icon}
          $iconColor={color}
          $issue={!isNil(issueLevel)}
        />
        {actionLabel && <ActionLabel label={actionLabel} />}
        <Heading4 variant={Variant.primary} margin={[null]}>
          {heading}
        </Heading4>
      </JustificationContainer>
      <ChildrenWrapper>
        {isEmptyAction(data.action) ? (
          <>
            <Body>Deze actie is nog niet gedefineerd</Body>
            <Button
              onClick={onCardClick}
              label="Defineer deze actie"
              appearance="secondary"
            />
          </>
        ) : (
          children
        )}
      </ChildrenWrapper>
    </Container>
  );
};

const Container = styled(animated.div)<{
  $color: string;
  $selected?: boolean;
  $disabled?: boolean;
  $hasIssue?: boolean;
  $dashedBorder: boolean;
}>(
  ({ theme, $color, $selected, $disabled, $hasIssue, $dashedBorder }) => css`
    border-radius: ${theme.getTokens().border.radius.m};
    background: ${theme.color('white')};
    outline: ${!$disabled && $selected
      ? `2px solid ${$color}`
      : `1px solid transparent`};
    border-left: ${theme.spacing('xxs')} solid ${$color};
    width: ${DEFAULT_WIDTH}px;
    padding: ${theme.spacing('l')};
    cursor: pointer;
    pointer-events: ${$disabled ? 'none' : 'auto'};
    box-shadow: ${$hasIssue ? `0 0 10px 0 ${$color}` : 'unset'};
    user-select: none;
    opacity: ${$disabled && 0.7} !important;
    position: relative;

    transition:
      outline 0.2s ${$selected ? 'ease-out' : 'ease-in'},
      opacity 0.2s ease-out;

    ${$dashedBorder &&
    css`
      border: 5px dashed ${theme.color('primary', 'light')};
    `}
  `,
);

const StyledIcon = styled(Icon)<{ $issue: boolean; $iconColor: string }>(
  ({ theme, $issue, $iconColor }) => css`
    padding-right: ${theme.spacing('l')};
    font-size: ${theme.fontSize('xl')};
    color: ${$issue && $iconColor};
  `,
);
const StyledMenuIcon = styled(Icon)<{ $disabled?: boolean }>(
  ({ theme, $disabled }) => css`
    color: ${$disabled
      ? theme.color('tertiary', 'dark')
      : theme.color('primary', 'light')};
    pointer-events: ${$disabled ? 'none' : 'auto'};

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: ${theme.space('xxs')};
    border-radius: ${theme.getTokens().border.radius.base};
    transition: all 0.3s ease-out;

    &:hover {
      background-color: ${theme.color('tertiary', 'translucent')};
    }
  `,
);

const Options = styled.div<{}>(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.space('m')};
    right: ${theme.space('m')};
  `,
);

const ChildrenWrapper = styled.div<{}>(
  ({ theme }) => css`
    margin-left: ${theme.spacing('xxxl')};
    margin-right: ${theme.spacing('xxs')};
  `,
);

export const handleStyle = { opacity: '0', height: '1rem', left: '50%' };

export default CardTemplate;
