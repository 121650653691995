import type { FlowV2_ConditionListFragment } from '~/graphql/types';
import extractExpressionV2 from '~/components/page/Automation/v2/components/Builder/utils/expressionHelpersV2';
import type { ConditionExpression } from '../../types';

/**
 * Takes a condition list from the BE and converts it into a
 * condition expression which can be easily displayed on the FE.
 */
const generateConditionExpression = (
  conditionList: FlowV2_ConditionListFragment,
): ConditionExpression => {
  const { conditionSubExpression, connector } = extractExpressionV2(
    conditionList.exp,
  );

  return {
    connector,
    conditionSubExpression: conditionSubExpression.map(
      ({ conditionGroup, connector }) => ({
        connector,
        conditionGroups: conditionGroup.map(({ connector, indices }) => ({
          connector,
          conditions: indices.map(
            index => conditionList.conditions[index] ?? null,
          ),
        })),
      }),
    ),
  };
};

export default generateConditionExpression;
