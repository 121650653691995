import React, { ReactElement, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Divider from '~/components/atom/Divider';
import JustificationContainer from '~/components/atom/JustificationContainer';

export type ActivityTabProps = {
  label: string;
  datatestId: string;
};

type ActivityTabComponent = ReactElement<ActivityTabProps>;

export type Props = {
  initiallySelectedTab?: number;
  children: Array<ActivityTabComponent | null>;
};

const ActivityTabs: React.FCC<Props> = ({
  initiallySelectedTab,
  children,
  ...rest
}) => {
  const [markerStyle, setMarkerStyle] = useState({ width: 0, left: 0 });
  const [selectedTabIdx, setSelectedTabIdx] = useState<number>(
    initiallySelectedTab || 0,
  );
  const activeTabRef = useRef<HTMLDivElement>(null);
  const offsetLeft = activeTabRef.current?.offsetLeft || 0;
  const tabWidth = activeTabRef.current?.clientWidth || 0;

  useEffect(() => {
    setMarkerStyle({
      left: offsetLeft,
      width: tabWidth,
    });
  }, [offsetLeft, tabWidth, selectedTabIdx]);

  const onlyOneTab = React.Children.count(children) === 1;

  if (onlyOneTab) {
    return (
      <Container direction="column" width="100%" padding={['l']} {...rest}>
        <TabContainer>{children[selectedTabIdx]}</TabContainer>
      </Container>
    );
  }

  return (
    <Container direction="column" width="100%" padding={['l']} {...rest}>
      <TabBar width="100%" gap="base">
        {React.Children.map(children, (child: ActivityTabComponent, idx) => {
          const childProps = child.props;
          const active = idx === selectedTabIdx;

          return (
            <TabHeader
              key={`activity-tab-bar-${childProps.datatestId}`}
              onClick={() => setSelectedTabIdx(idx)}
              $active={active}
              dataTestId={`activity-tab-${childProps.datatestId}`}
              align="center"
              justification="center"
              ref={active ? activeTabRef : null}
            >
              <span>{childProps.label}</span>
            </TabHeader>
          );
        })}
        <Marker $width={markerStyle.width} $left={markerStyle.left} />
      </TabBar>
      <Divider margin={['base', null]} />

      <TabContainer>{children[selectedTabIdx]}</TabContainer>
    </Container>
  );
};

type TabHeaderProps = {
  $active: boolean;
};

const ACTIVE_LINE_HEIGHT = '4px';

const Marker = styled.div<{ $width: number; $left: number }>(
  ({ $width, $left, theme }) => css`
    position: absolute;
    bottom: -${theme.space('base')};
    background-color: ${theme.color('secondary')};
    height: ${ACTIVE_LINE_HEIGHT};
    left: ${$left}px;
    width: ${$width}px;
    transition: all 0.3s ease;
  `,
);

const TabHeader = styled(JustificationContainer)<TabHeaderProps>(
  ({ $active, theme }) => css`
    cursor: pointer;
    height: 100%;
    color: ${theme.color($active ? 'secondary' : 'primary')};
    font-weight: ${theme.fontWeight('medium')};

    &:hover {
      color: ${theme.color('secondary')};
    }
  `,
);

const TabContainer = styled.div<{}>`
  width: 100%;
`;

type TabBarProps = {
  bordered?: boolean;
};
const TabBar = styled(JustificationContainer)<TabBarProps>`
  position: relative;
`;

const Container = styled(JustificationContainer)<{}>`
  width: 100%;

  ${({ theme }) => css`
    border-radius: ${theme.getTokens().border.radius.m};
    background-color: ${theme.color('white')};
  `};
`;

export default ActivityTabs;
