import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import {
  ContactFiltersCommandFragment,
  ContactFiltersLeafCommands__Input,
} from '~/graphql/types';

import { clone, isNil, lensPath, set } from 'ramda';

import { isEmptyObject } from '~/util/object';
import { Leaf } from '../../../FilterGroupV2';
import { OptionTypeMap } from '../../../../util/contactFilter/getOptionType';
import { CommandMap } from '../../../../util/contactFilter/getCommand';
import { TypeMap } from '../../../../util/contactFilter/getType';
import { CurrentCommand } from '../../../FilterActionSelector';

import { InnerContactFilterFieldOutput } from '../../../../util/contactFilter/getInnerFieldsByPath';
import InputAppearanceComponent from '../InputAppearanceComponent';
import { SelectedInnerField } from '../../../FilterInnerSelector';

type Props = {
  command: Partial<ContactFiltersLeafCommands__Input> & { commandId: string };
  leafIndex: number;
  currentCommand: NonNullable<CurrentCommand>;
  shouldShowValidationError: boolean;
  setLeafs: React.Dispatch<React.SetStateAction<Array<Leaf>>>;
  selectedField: SelectedInnerField;
  typeMap: TypeMap;
  optionTypeMap: OptionTypeMap;
  commandMap: CommandMap;
};

const CommandInputComponent: React.FCC<Props> = ({
  dataTestId,
  setLeafs,
  shouldShowValidationError,
  command,
  commandMap,
  typeMap,
  optionTypeMap,
  selectedField,
  leafIndex,
  currentCommand,
}) => {
  const commandIndex = currentCommand.commandIdx;
  const commandBlock = commandMap[command.commandId];

  const commandLensPath = lensPath([
    leafIndex,
    'instanceAttribute',
    'commands',
    commandIndex,
  ]);

  const argsLensPath = lensPath(['args']);

  // set the initial values for the args
  useEffect(() => {
    setLeafs(prev => {
      const pathToValidate = (
        (selectedField ?? []).slice(0, -1) as Array<
          Exclude<InnerContactFilterFieldOutput, ContactFiltersCommandFragment>
        >
      ).map(x => x.key);

      const args = [
        ...(command.args ?? []).map((arg, index) => {
          const argBlock = commandBlock.args[index];
          if (isEmptyObject(arg) || isNil(arg)) {
            return getEmptyStateForArg(
              (typeMap[argBlock.typeId] ?? optionTypeMap[argBlock.typeId])
                .inputField,
            );
          }
          return arg;
        }),
        ...commandBlock.args.map(argBlock =>
          getEmptyStateForArg(
            (typeMap[argBlock.typeId] ?? optionTypeMap[argBlock.typeId])
              .inputField,
          ),
        ),
      ].slice(0, commandBlock.args.length);

      return set(
        commandLensPath,
        set(argsLensPath, args, clone({ ...command, path: pathToValidate })),
        clone(prev),
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const argsToHandle = command?.args ?? [];
  const compsToRender: Array<React.ReactNode> = [];
  let argIndex = 0;
  // We process the arguments two at a time
  // Reason: If 'before' field is set for an argument, we want to render that argument and its previous argument on the same line and then we put the 'before' label in between.
  while (argIndex < argsToHandle.length) {
    const arg = argsToHandle[argIndex];

    const nextArgIndex = argIndex + 1;
    const argLensPath = lensPath(['args', argIndex]);
    const nextArgLensPath = lensPath(['args', argIndex + 1]);
    const argBlock = commandBlock.args[argIndex];

    if (
      commandBlock.args.length > argIndex + 1 &&
      commandBlock.args[argIndex + 1].before != null
    ) {
      const nextArgBlock = commandBlock.args[nextArgIndex];
      const nextArg = argsToHandle[nextArgIndex];

      compsToRender.push(
        <ArgsPairContainer key={`${argIndex}-${nextArgIndex}`}>
          <InputAppearanceComponent
            shouldShowValidationError={shouldShowValidationError}
            input={typeMap[argBlock.typeId] ?? optionTypeMap[argBlock.typeId]}
            arg={clone(arg)}
            onChange={newArg => {
              setLeafs(prev => {
                const pathToValidate = (
                  (selectedField ?? []).slice(0, -1) as Array<
                    Exclude<
                      InnerContactFilterFieldOutput,
                      ContactFiltersCommandFragment
                    >
                  >
                ).map(x => x.key);

                return set(
                  commandLensPath,
                  set(
                    argLensPath,
                    newArg,
                    clone({ ...command, path: pathToValidate }),
                  ),
                  clone(prev),
                );
              });
            }}
          />
          <ArgBeforeContainer>{nextArgBlock.before}</ArgBeforeContainer>
          <InputAppearanceComponent
            shouldShowValidationError={shouldShowValidationError}
            input={
              typeMap[nextArgBlock.typeId] ?? optionTypeMap[nextArgBlock.typeId]
            }
            arg={clone(nextArg)}
            onChange={newArg => {
              setLeafs(prev => {
                const pathToValidate = (
                  (selectedField ?? []).slice(0, -1) as Array<
                    Exclude<
                      InnerContactFilterFieldOutput,
                      ContactFiltersCommandFragment
                    >
                  >
                ).map(x => x.key);

                return set(
                  commandLensPath,
                  set(
                    nextArgLensPath,
                    newArg,
                    clone({ ...command, path: pathToValidate }),
                  ),
                  clone(prev),
                );
              });
            }}
          />
        </ArgsPairContainer>,
      );
      // Skip the next arg since we already processed it.
      argIndex += 2;
      continue;
    }

    compsToRender.push(
      <InputAppearanceComponent
        key={argIndex}
        shouldShowValidationError={shouldShowValidationError}
        input={typeMap[argBlock.typeId] ?? optionTypeMap[argBlock.typeId]}
        arg={arg}
        onChange={newArg => {
          setLeafs(prev => {
            const pathToValidate = (
              (selectedField ?? []).slice(0, -1) as Array<
                Exclude<
                  InnerContactFilterFieldOutput,
                  ContactFiltersCommandFragment
                >
              >
            ).map(x => x.key);

            return set(
              commandLensPath,
              set(
                argLensPath,
                newArg,
                clone({ ...command, path: pathToValidate }),
              ),
              clone(prev),
            );
          });
        }}
      />,
    );
    argIndex++;
    continue;
  }

  return (
    <CommandsContainer data-testid={dataTestId}>
      {compsToRender}
    </CommandsContainer>
  );
};

const ArgsPairContainer = styled.span<{}>`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: ${theme.space('xxs')};
  `};
`;
const CommandsContainer = styled.div(
  ({ theme }) => css`
    display: flex;

    width: 100%;
    max-width: 700px;
    align-items: center;
    justify-content: start;
    flex-flow: row wrap;
    flex: 1 1 150px;

    margin-top: ${theme.space('s')};
    margin-bottom: ${theme.space('s')};
    padding-left: ${theme.space('s')};
    padding-right: ${theme.space('s')};

    &:last-child {
      margin: 0;
    }
  `,
);
const ArgBeforeContainer = styled.span<{}>`
  display: flex;
  align-self: center;
  ${({ theme }) => css`
    padding-top: ${theme.space('base')};
    margin-right: ${theme.space('xxs')};
  `};
`;

const getEmptyStateForArg = (
  inputField: string,
): $GetElementType<ContactFiltersLeafCommands__Input['args']> => ({
  [inputField]: null,
});

export default CommandInputComponent;
