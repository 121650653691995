import { assertType } from '~/util/assertion';
import type {
  Mailbox,
  SessionHydrationAccountRelationFieldsFragment,
  SessionHydrationOfficeRelationFieldsFragment,
  Address as _Address,
  GetAppValuationReportsQuery,
  GetAppStatusesQuery,
  ActivityFields_Event_Contact_Email_Fragment,
  ActivityFieldsFragment,

  // Flow v2
  Flow___ArgumentFragment,
  FlowV2_Action_IfElseFragment,
  FlowV2_Action_StartFragment,
  FlowV2_Action_WaitFragment,
  FlowV2_Action_Contact_AddTagFragment,
  FlowV2_Action_Contact_AssignFragment,
  FlowV2_Action_Contact_DeleteTagFragment,
  FlowV2_Action_Contact_DetailsFragment,
  FlowV2_Action_Realworks_SendContactFragment,
  FlowV2_Action_SendEmail_PlainFragment,
  FlowV2_Action_Task_CreateFragment,
  FlowV2_Action_Zapier_TriggerFragment,
  FlowV2_Update_Action,
  AccountMailbox,
  OfficeMailbox,
  UserMailbox,
  Flow___File,
  ActivityFields_Event_Contact_Generic_Fragment,
  ActivityFields_Task_Contact_Fragment,
  FlowV2_Action_SendNotificationFragment,
} from './types';
import { FlowAction } from '~/graphql/types';
import { WizardStep } from '~/components/organism/Wizard/context/WizardContext';
import type { ToAddressOption } from '~/components/page/Automation/v2/components/UpdateAction/components/SendNotification/utils/convertToDropdownOption';
import type { StepId } from '~/components/organism/WizardSteps';
import type { EditorValue } from '~/components/organism/PluginsEditor/types';
import type { ConditionExpression } from '~/components/page/Automation/v2/components/UpdateAction/components/ConditionEditorV2/types';

export type MailboxAlias = Extract<
  Mailbox,
  | { __typename: 'AccountMailboxAlias' }
  | { __typename: 'OfficeMailboxAlias' }
  | { __typename: 'UserMailboxAlias' }
>;

export type NonAliasMailbox = AccountMailbox | OfficeMailbox | UserMailbox;

export type Address = Omit<_Address, 'countryCode'>;

export type EntityRelation =
  | SessionHydrationOfficeRelationFieldsFragment
  | SessionHydrationAccountRelationFieldsFragment;

type GetAppValuationReportsItem =
  GetAppValuationReportsQuery['getAppValuationReports'][0];

export type ExtractedApp_PremiumValuationReport = Extract<
  NonNullable<GetAppValuationReportsItem>,
  { __typename: 'App_PremiumValuationReport' }
>;

export type ExtractedApp_ValuationReport = Extract<
  NonNullable<GetAppValuationReportsItem>,
  { __typename: 'App_ValuationReport' }
>;

export type AppStatus = GetAppStatusesQuery['getAppStatuses'][number];

export type AppStatus__typename =
  GetAppStatusesQuery['getAppStatuses'][number]['__typename'];

/**
 * List of all handled Activities within the app.
 */
export type HandledActivityFieldsFragment =
  | ActivityFields_Event_Contact_Generic_Fragment
  | ActivityFields_Event_Contact_Email_Fragment
  | ActivityFields_Task_Contact_Fragment;

type UnHandledActivityFieldsFragment = Exclude<
  ActivityFieldsFragment,
  HandledActivityFieldsFragment
>;

/**
 * Filters out all activities which we currently do not handle.
 * This is mainly to prevent our ts linter to throw errors when we add new
 * flow activities on the backend, but it also has the advantage of ensuring that we
 * filter out the currently not implemented flow actions on the frontend.
 */
export const extractHandledActivities = (
  activities: Array<ActivityFieldsFragment>,
): Array<HandledActivityFieldsFragment> =>
  activities.filter((activity): activity is HandledActivityFieldsFragment => {
    switch (activity.__typename) {
      case 'Event_Contact_Email':
      case 'Task_Contact':
      case 'Event_Contact_Generic':
        assertType<HandledActivityFieldsFragment>(activity);
        return true;
      default: {
        assertType<UnHandledActivityFieldsFragment>(activity);
      }
    }

    return false;
  });

/**
 * Flow Builder V2
 */

export const HandledFlowActionMap: {
  [key in Exclude<FlowAction, FlowAction.Goal>]: key;
} = Object.freeze({
  Contact_Details: FlowAction.ContactDetails,
  Contact_Assign: FlowAction.ContactAssign,
  Contact_AddTag: FlowAction.ContactAddTag,
  Contact_DeleteTag: FlowAction.ContactDeleteTag,
  SendEmail_Plain: FlowAction.SendEmailPlain,
  Start: FlowAction.Start,
  Wait: FlowAction.Wait,
  Task_Create: FlowAction.TaskCreate,
  IfElse: FlowAction.IfElse,
  Zapier_Trigger: FlowAction.ZapierTrigger,
  Realworks_SendContact: FlowAction.RealworksSendContact,
  SendNotification: FlowAction.SendNotification,
});

export type HandledFlowAction = keyof typeof HandledFlowActionMap;

/** Used when an attachment is not a pointer attachment */
export type EmailFileAttachment = {
  __typename: 'FlowV2_EmailAttachment';
  file: {
    __typename: 'Flow___Argument_File';
    value_file: Flow___File;
  };
  inlineId?: string | null;
  url?: string;
};

export type Flow___PrimitiveArgument = Exclude<
  Flow___ArgumentFragment,
  | { __typename: 'Flow___Argument_Pointer' }
  | { __typename: 'Flow___Argument_File' }
>;

type ConditionalFlowActionAttributes = {
  condition: ConditionExpression;
};

export type ClientFlowActionIfElse = Omit<
  FlowV2_Action_IfElseFragment,
  'condition'
> &
  ConditionalFlowActionAttributes & {
    actionType: FlowAction.IfElse;
  };

export type ClientFlowActionStart = Omit<
  FlowV2_Action_StartFragment,
  'condition'
> &
  ConditionalFlowActionAttributes & {
    actionType: FlowAction.Start;
  };

export type ClientFlowActionWait = Omit<
  FlowV2_Action_WaitFragment,
  'condition'
> &
  ConditionalFlowActionAttributes & {
    actionType: FlowAction.Wait;
    metadataAction?: FlowV2_Update_Action | null;
  };

export type ConditionalFlowAction =
  | ClientFlowActionIfElse
  | ClientFlowActionWait
  | ClientFlowActionStart;

export type ClientFlowActionContactDetails =
  FlowV2_Action_Contact_DetailsFragment & {
    actionType: FlowAction.ContactDetails;
    field: FlowV2_Action_Contact_DetailsFragment['field'] & {
      nameValue?: EditorValue;
      phoneValue?: EditorValue;
      unsubscribeEmail?: boolean | null;
    };
  };
export type ClientFlowActionContactAssign =
  FlowV2_Action_Contact_AssignFragment & {
    actionType: FlowAction.ContactAssign;
  };
export type ClientFlowActionContactAddTag =
  FlowV2_Action_Contact_AddTagFragment & {
    actionType: FlowAction.ContactAddTag;
  };
export type ClientFlowActionContactDeleteTag =
  FlowV2_Action_Contact_DeleteTagFragment & {
    actionType: FlowAction.ContactDeleteTag;
  };

export type ClientFlowActionSendEmailPlain =
  FlowV2_Action_SendEmail_PlainFragment & {
    actionType: FlowAction.SendEmailPlain;
    bodyValue?: EditorValue;
    subjectValue?: EditorValue;
    customHtml?: string | null;
  };
export type ClientFlowActionTaskCreate = FlowV2_Action_Task_CreateFragment & {
  actionType: FlowAction.TaskCreate;
  titleValue?: EditorValue;
  descriptionValue?: EditorValue;
};
export type ClientFlowActionZapierTrigger =
  FlowV2_Action_Zapier_TriggerFragment & {
    actionType: FlowAction.ZapierTrigger;
  };
export type ClientFlowActionRealworksSendContact =
  FlowV2_Action_Realworks_SendContactFragment & {
    actionType: FlowAction.RealworksSendContact;
    descriptionValue?: EditorValue;
  };

export type ClientFlowActionSendNotification =
  FlowV2_Action_SendNotificationFragment & {
    actionType: FlowAction.SendNotification;
    bodyValue?: EditorValue;
    subjectValue?: EditorValue;
    toOptions?: Array<ToAddressOption>;
  };

export type ClientFlowAction = (
  | ConditionalFlowAction
  | ClientFlowActionContactDetails
  | ClientFlowActionContactAssign
  | ClientFlowActionContactAddTag
  | ClientFlowActionContactDeleteTag
  | ClientFlowActionSendEmailPlain
  | ClientFlowActionTaskCreate
  | ClientFlowActionZapierTrigger
  | ClientFlowActionRealworksSendContact
  | ClientFlowActionSendNotification
) & {
  label?: {
    letterIdx: number;
    number: number;
  };
  appSlug?: string | null;
};

export type BasePrimitive = string | number | boolean | null;

// Wizard
export type WizardFlow = {
  /**
   * Used to store specific predefined flows
   */
  id: string;
  /**
   * The steps this wizard includes
   */
  steps: Array<WizardStep>;

  /**
   * Step IDs which output should also be shown on the output.
   * Can be used when reusing dedicated Onboarding wizard steps in enablement flows
   */
  outsideStepDependencies?: Array<StepId>;
};
