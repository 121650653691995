import React, { useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import Icon from '~/components/atom/Icon';
import { animated, useTransition } from 'react-spring';
import { EdgeLabelRenderer, EdgeProps } from 'reactflow';
import { DEFAULT_WIDTH } from '../../../nodeTypes/constants';
import { useRecoilState } from 'recoil';
import addBlockMenu from '~/components/page/Automation/v2/state/addBlockMenu';
import AddBlockMenu from '../../../AddBlockMenu/OnEdge';
import { ClientFlowAction } from '~/graphql/types.client';
import { EdgeData } from '../DropEdge';
import usePanTo from '../../../../hooks/usePanTo';
import Div from '~/components/atom/Div';

type Props = Pick<EdgeProps<EdgeData>, 'targetX' | 'targetY' | 'id'> & {
  onAddAction: (actionType: ClientFlowAction['actionType']) => void;
  parentActionType?: ClientFlowAction['actionType'];
};

const AddBlockButton: React.FCC<Props> = React.memo(
  ({ targetX, targetY, id, onAddAction, parentActionType }) => {
    const [menuState, setMenuState] = useRecoilState(addBlockMenu);
    const [show, setShow] = useState(false);
    const theme = useTheme();

    const foreignObjectSize = theme.remToPxRaw(theme.space('l'));
    const baseSpace = theme.remToPxRaw(theme.space('base'));

    const transition = useTransition(show, {
      from: { opacity: 0, width: '0%' },
      enter: { opacity: 1, width: '100%' },
      leave: { opacity: 0, width: '0%' },
    });

    const panTo = usePanTo();

    return (
      <>
        {menuState.openedOnEdge && menuState.edgeId === id && (
          <AddBlockMenu
            style={{
              left: targetX + baseSpace + 'px',
              top: targetY + baseSpace + 'px',
            }}
            onSelect={onAddAction}
            parentActionType={parentActionType}
          />
        )}

        <EdgeLabelRenderer>
          <Div
            style={{
              position: 'absolute',
              transform: `translate(${targetX + baseSpace}px,${targetY - foreignObjectSize}px)`,
              // everything inside EdgeLabelRenderer has no pointer events by default
              // if you have an interactive element, set pointer-events: all
              pointerEvents: 'all',
            }}
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
            className="nodrag nopan"
            width={`${DEFAULT_WIDTH / 2}px`}
            height={`${foreignObjectSize}px`}
          >
            <Container
              onClick={() => {
                setMenuState({
                  openedOnEdge: true,
                  edgeId: id,
                  targetX,
                  targetY,
                });
                panTo({ x: targetX, y: targetY });
              }}
            >
              <Icon name="plus" />
              &nbsp;
              <div>
                {transition(
                  (style, item) =>
                    item && <Label style={style}>Stap toevoegen</Label>,
                )}
              </div>
            </Container>
          </Div>
        </EdgeLabelRenderer>
      </>
    );
  },
);

const Container = styled.button`
  background-color: transparent;
  justify-content: flex-start;
  align-items: center;
  border: none;
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  white-space: nowrap;
  user-select: none;
  cursor: pointer;
  position: relative;

  &:hover {
    color: ${({ theme }) => theme.color('primary', 'light')};
  }
`;

const Label = styled(animated.div)(
  ({ theme }) => css`
    font-size: ${theme.fs('s')};
    line-height: ${theme.lineHeight('base')};
    overflow: hidden;
  `,
);

export default AddBlockButton;
