import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import DropdownListContainer from '../Dropdown/components/DropdownListContainer';
import Input, { type Props as InputProps } from '../Input';
import type { OptionOf } from '../Dropdown';
import SelectedOptions from './components/SelectedOptions';
import { Label } from '~/components/atom/Typography';
import useDebounce from '~/hooks/useDebounce';
import type { IconType } from '~/components/atom/Icon';
import type { ThemeColor } from '~/theme/System/tokens/colorPalette';
import TEST_ID from './index.testid';
import Catalog from '~/Catalog';
import Icon from '~/components/atom/Icon';
import { isNil } from 'ramda';

const text = {
  maxAmountReached:
    'Je hebt het maximum aantal ontvangers bereikt. Verwijder eerst een ontvanger om een toe te voegen.',
  addValue: (value: string) => `Voeg de waarde toe: "${value}"`,
};

const NO_RESULT_KEY = 'no-result';

/**
 * Options must have a filterLabel in the payload. This is what we use to filter them.
 * Option keys must be unique so we can delete/add the correct one.
 *
 * tagLabel is used to display the selected value in the SelectedOptions
 */
type CustomPayload<T> = { filterLabel: string; tagLabel: string } & T;

export type SelectOption<T = any> = OptionOf<CustomPayload<T>>;

export type Props = InputProps & {
  /** List is loading. Used when an async list is returned */
  loading?: boolean;

  /** Disables the input */
  disabled?: boolean;

  /** Fixed options to be displayed. Options must be sorted before passing it here. */
  options?: Array<SelectOption>;

  /** Selected options */
  selectedOptions: Array<SelectOption>;

  /** Sets a limit to the selected options */
  maxSelectedOptionAmount?: number;

  /** Icon to render on the left side of the input element */
  icon?: { name: IconType; color: ThemeColor };

  /** Adds option to the selected options */
  onSelected: (value: SelectOption) => void;

  /** Removes option from the selected options */
  onRemoved: (value: SelectOption) => void;

  /**
   * Allows to add custom entries in the selected options. Creates a dropdown option from the typed in value.
   */
  convertCustomEntry?: (obj: {
    /** Value to be added to selected options */
    value: string;

    /** Displays a custom label for the dropdown option */
    dropdownLabel?: string;
  }) => SelectOption;

  /**
   * Used to return an async list of options. If you are using this and want to add
   * fixed options as well, return it along with this function instead of passing it with 'options' prop
   */
  onAsyncSearch?: (searchValue: string) => Promise<Array<SelectOption> | void>;
};

const MultiSelectInput: React.FCC<Props> = ({
  selectedOptions,
  options = [],
  disabled = false,
  loading = false,
  maxSelectedOptionAmount,
  icon,
  onSelected,
  onRemoved,
  convertCustomEntry,
  onAsyncSearch,
  ...rest
}) => {
  const inputContainerRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const hasCustomEntry = !isNil(convertCustomEntry);

  const hasReachedLimit =
    maxSelectedOptionAmount !== undefined &&
    selectedOptions.length >= maxSelectedOptionAmount;

  const [typedValue, setTypedValue] = useState('');
  const debouncedValue = useDebounce(typedValue, 300);

  const [showList, setShowList] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    const fetchItems = async () => {
      if (!onAsyncSearch) return;
      const result = await onAsyncSearch(debouncedValue);
      if (result) setFilteredOptions(result);
    };

    void fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const onReset = () => {
    setShowList(false);
    setTypedValue('');
    if (!onAsyncSearch) setFilteredOptions(options);
  };

  const onAdd = (option: SelectOption) => {
    if (hasReachedLimit || option.key === NO_RESULT_KEY) return;
    // do not close the dropdown list
    setTypedValue('');
    if (!onAsyncSearch) setFilteredOptions(options);

    if (selectedOptions.some(({ label }) => label === option.label)) return;
    onSelected(option);
  };

  const onChangeInput = (value: string) => {
    setTypedValue(value);

    if (!onAsyncSearch) {
      const filteredOptions = options.filter(({ payload }) =>
        payload.filterLabel.toLowerCase().includes(value.toLowerCase()),
      );
      setFilteredOptions(
        filteredOptions.length > 0
          ? filteredOptions
          : !hasCustomEntry
            ? [
                {
                  key: NO_RESULT_KEY,
                  label: Catalog.noResults,
                  payload: null,
                  type: 'DISABLED',
                },
              ]
            : [],
      );
    }
  };

  const onKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') onReset();
  };

  const visibleOptions = filteredOptions.filter(
    option => !selectedOptions.some(selected => selected.key === option.key),
  );

  if (hasCustomEntry && typedValue !== '') {
    visibleOptions.unshift({
      ...convertCustomEntry({
        value: typedValue,
        dropdownLabel: text.addValue(typedValue),
      }),
      icon: { name: 'plus' },
    });
  }

  return (
    <Container width={rest.width}>
      <SelectedOptions
        selectedOptions={selectedOptions}
        onRemoved={onRemoved}
        disabled={disabled}
      />
      {hasReachedLimit && (
        <Label dataTestId={TEST_ID.MAX_LIMIT_LABEL}>
          {text.maxAmountReached}
        </Label>
      )}

      <div ref={inputContainerRef}>
        <Input
          type="text"
          disabled={disabled || hasReachedLimit}
          value={typedValue}
          onKeyDown={onKeydown}
          onChange={e => onChangeInput(e.target.value)}
          onFocus={() => setShowList(true)}
          // In Storybook userEvent.type uses click handler to focus on an element so we need to pass  this
          onClick={() => setShowList(true)}
          icon={icon}
          allowKeybinding
          ref={inputRef}
          {...rest}
        >
          <Icon
            name="chevron"
            strokeWidth={3}
            color={{ group: 'primary', variant: 'light' }}
          />
        </Input>
      </div>

      <DropdownListContainer
        options={visibleOptions}
        dropdownListOpen={visibleOptions?.length > 0 && showList}
        onChange={({ selectedOptionIdx }) => {
          const selectedOption = visibleOptions[selectedOptionIdx];
          if (selectedOption) onAdd(selectedOption);
        }}
        onClickOutside={() => setShowList(false)}
        dataTestId={TEST_ID.DROPDOWN_LIST}
        loading={loading}
        withDefaultFirstOption
        openerRef={inputContainerRef}
      />
    </Container>
  );
};

const Container = styled.div<{ width?: string }>(
  ({ width }) => css`
    width: ${width};
  `,
);

export default MultiSelectInput;
