import React from 'react';
import styled, { css } from 'styled-components';
import { Heading5 } from '~/components/atom/Typography';
import CallToActionBlock from '~/components/organism/CallToActionBlock';
import { UserStatus } from '~/graphql/types';
import useUserLookup from '~/hooks/useUserLookup';
import useUsers from '~/hooks/useUsers';
import getUserLabel from '~/util/getUserLabel';

export type Props = {
  officeId: string;

  // Currently selected user ids
  currentUserIds: Array<string>;
};

const text = {
  header: 'Geselecteerde gebruikers niet beschikbaar',
  description:
    'Je kan alleen een werkgebied toewijzen aan gebruikers binnen je huidige vestiging.',
};

const MissingUsersErrorMessage: React.FCC<Props> = ({
  officeId,
  currentUserIds,
}) => {
  const allInactiveUsers = useUsers({
    statuses: [
      UserStatus.Active,
      UserStatus.Pending,
      UserStatus.InvitedPending,
    ],
    officeIds: [officeId],
  });
  const inactiveIds = allInactiveUsers.map(({ id }) => id);
  const missingUsers = currentUserIds.filter(
    userId => !inactiveIds.includes(userId),
  );

  const allUsersLookup = useUserLookup(['User', 'DeletedUser', 'PendingUser']);
  if (!allUsersLookup || missingUsers.length === 0) return null;

  return (
    <>
      <CallToActionBlock
        icon={{ background: { group: 'danger' }, name: 'triangle' }}
        header={text.header}
        description={text.description}
        align="center"
      >
        <br />
        <Heading5 color={{ group: 'white' }}>Ontbrekende gebruikers</Heading5>
        <MissingUserList>
          {missingUsers.map(userId => (
            <li key={userId}>{getUserLabel(allUsersLookup[userId])}</li>
          ))}
        </MissingUserList>
      </CallToActionBlock>
    </>
  );
};

const MissingUserList = styled.ul(
  ({ theme }) => css`
    padding: 0;
    margin: 0;
    list-style: none;
    font-weight: ${theme.fw('semiBold')};
  `,
);

export default MissingUsersErrorMessage;
