import React from 'react';
import styled, { css } from 'styled-components';
import { SystemSize } from '~/theme/System/tokens';
import Icon, { IconType } from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Variant, Heading4 } from '~/components/atom/Typography';
import Div from '~/components/atom/Div';
import { BaseColor, ThemeColor } from '~/theme/System/tokens/colorPalette';

export type Props = {
  headerText: string;
  margin?: Array<SystemSize | null>;
  padding?: Array<SystemSize | null>;
  width?: string;
  /**
   * Sets icon props
   */
  icon?: {
    name?: IconType;
    color?: ThemeColor;
    backgroundColor?: ThemeColor;
  };
  /**
   * Sets the background color and header color of the main container 'danger', 'warning', 'success'
   */
  appearance?: BaseColor;
};

const InfoBlock: React.FCC<Props> = ({
  dataTestId,
  appearance = 'primary',
  icon = {
    name: 'check',
    backgroundColor: { group: 'success' },
    color: { group: 'white' },
  },
  headerText,
  children,
  padding = ['l'],
  margin = [null],
  width,
  ...rest
}) => (
  <Div
    width={width}
    margin={margin}
    padding={padding}
    data-testid={dataTestId}
    backgroundColor={{ group: appearance, variant: 'translucent' }}
    border={{ radius: 'base' }}
    {...rest}
  >
    <JustificationContainer
      justification="start"
      align="center"
      margin={[null, null, children ? 'base' : null, null]}
    >
      <IconContainer
        backgroundColor={icon.backgroundColor || { group: 'success' }}
        align="center"
        justification="center"
        margin={[null, 'xs', null, null]}
        border={{ radius: 'full' }}
      >
        <Icon name={icon.name || 'check'} color={icon.color} strokeWidth={3} />
      </IconContainer>
      <Heading4
        color={{ group: appearance }}
        padding={['xxxs', null, null, null]}
        variant={Variant.primary}
        margin={[null]}
      >
        {headerText}
      </Heading4>
    </JustificationContainer>
    {children}
  </Div>
);

const IconContainer = styled(JustificationContainer)<{}>(
  () => css`
    width: 2em;
    height: 2em;
    min-width: 2em;
  `,
);

export default InfoBlock;
