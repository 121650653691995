import React from 'react';

import { TableCell } from '~/components/bad/DataTables/types.flow';

import ActionColumnCell, {
  ActionListOption,
} from '~/components/bad/DataTables/components/ActionColumnCell';
import useConfirmModal from '~/hooks/useConfirmModal';
import formatToastMessage from '~/util/formatToastMessage';
import useAddToast from '~/hooks/useAddToast';
import {
  useCopyFormMutation,
  useDeleteFormMutation,
  useGetFormLazyQuery,
  useUpdateFormMutation,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import deserializeFormBuilder from '~/components/page/Forms/utils/deserializeFormBuilder';
import Catalog from '~/Catalog';
import useErrorReporter from '~/hooks/useErrorReporter';
import Icon from '~/components/atom/Icon';
import useTooltipLayer from '~/hooks/useTooltipLayer';
import cleanedFilename from '~/util/cleanedFilename';
import { navigate } from '@gatsbyjs/reach-router';

const text = {
  deactivateLabel: 'Deactiveer',
  activateLabel: 'Activeer',
  copyLabel: 'Kopieer formulier',
  deleteLabel: 'Verwijderen',
  deleteErrorMessage:
    'Er is iets misgegaan bij het verwijderen van het formulier. Probeer het nog eens.',
  confirmDeleteTitle: 'Formulier verwijderen?',
  confirmDeleteButton: 'Verwijderen',
  confirmDeleteMessage: 'Weet je zeker dat je dit formulier wilt verwijderen?',
  deleteToast: 'Formulier verwijderd',
  noDataError: 'No data recieved',
  brokenFormError:
    'Er is iets misgegaan met dit formulier, neem contact met ons op via de chat rechts onderin.',
};

const confirmDeleteModalText = {
  title: text.confirmDeleteTitle,
  buttonConfirmTitle: text.confirmDeleteButton,
};

type Props = {
  id: string;
  cell: TableCell<any>;
  enabled: boolean;
  name: string;
  refreshTable: () => void;
  description?: string;
  shared: {
    enabled: boolean;
    id: string;
  };
};
const FormActionColumnCell: React.FC<Props> = ({
  id,
  cell,
  enabled,
  refreshTable,
  shared,
}) => {
  const addToast = useAddToast();
  const [deleteForm, { loading: deleteLoading }] = useDeleteFormMutation();
  const account = useCurrentAccount();
  const reporter = useErrorReporter();
  const [getForm, { loading, error }] = useGetFormLazyQuery({
    variables: { formBuilderId: id, accountId: account.id },
  });
  const [updateForm] = useUpdateFormMutation();
  const tooltipProps = useTooltipLayer({
    tooltipMessage: text.brokenFormError,
  });

  const deleteFn = async e => {
    e.stopPropagation();
    void deleteForm({
      variables: {
        accountId: account.id,
        formBuilderId: id,
      },
    }).then(({ errors }) => {
      if (errors && errors.length !== 0) {
        return addToast([
          formatToastMessage(text.deleteErrorMessage, 'danger'),
        ]);
      }

      refreshTable();
      return addToast([formatToastMessage(text.deleteToast, 'success')]);
    });
  };

  const [copyForm, { loading: copyFormLoading }] = useCopyFormMutation();
  const { setShowModal, modal } = useConfirmModal({
    level: 'danger',
    labels: {
      ...confirmDeleteModalText,
      message: text.confirmDeleteMessage,
    },
    buttons: [
      {
        label: confirmDeleteModalText.buttonConfirmTitle,
        onClick: deleteFn,
        appearance: 'danger',
        loading: deleteLoading,
      },
    ],
  });

  const reportError = (_error: string) => {
    reporter.captureMessage(
      `${cleanedFilename(__filename)}>>${id}>>${_error}`,
      'error',
    );
  };

  const toggleFormEnabled = {
    label: enabled ? text.deactivateLabel : text.activateLabel,
    key: 'update-status',
    onClick: async () => {
      const { data: queryData, error: queryError } = await getForm({
        variables: { formBuilderId: id, accountId: account.id },
      });

      if (!queryData) {
        return reportError(text.noDataError);
      }

      if (queryError) {
        return reportError(queryError.message);
      }

      const formBuilderInput = deserializeFormBuilder(queryData.getForm);

      void updateForm({
        variables: {
          accountId: account.id,
          formBuilderId: id,
          formBuilder: {
            ...formBuilderInput,
            enabled: !enabled,
          },
        },
      }).then(({ data, errors }) => {
        if (errors && errors.length > 0) {
          const limitError = errors.find(
            (err: any) => err.errorType === 'DHLimitExceededError',
          );
          if (limitError) {
            return addToast([
              formatToastMessage(Catalog.formsLimitExceeded, 'danger'),
            ]);
          }

          reportError(JSON.stringify(errors));
          return;
        }

        if (data) {
          refreshTable();

          addToast([
            formatToastMessage(Catalog.genericSuccessMessage, 'success'),
          ]);
        }
      });
    },
  };

  const options: Array<ActionListOption> = [
    toggleFormEnabled,
    {
      label: shared && shared.enabled ? 'Deel instellingen' : 'Delen',
      onClick: () => navigate(`/-/forms/sharing/${id}`),
      key: 'share',
    },

    {
      label: 'Inzendingen exporteren',
      onClick: () => navigate(`/-/forms/export/${id}`),
      key: 'export',
    },

    {
      label: text.copyLabel,
      onClick: async (_rowId: string) => {
        const { data, errors } = await copyForm({
          variables: { accountId: account.id, formBuilderId: id },
        });

        if (errors && errors.length > 0) {
          reporter.captureMessage(
            `An error occured while copying the form ${id}. ${JSON.stringify(errors)}`,
            'error',
          );
          addToast([
            formatToastMessage(Catalog.genericUnknownErrorMessage, 'danger'),
          ]);

          return;
        }

        if (data) {
          const copiedFormName = data.copyForm.name;

          return navigate(
            `/-/forms/${data.copyForm.id}?name=${encodeURIComponent(
              copiedFormName,
            )}&description=${encodeURIComponent(data.copyForm.description || '')}`,
          );
        }
      },
      key: 'copy',
    },
    {
      label: text.deleteLabel,
      onClick: () => setShowModal(true),
      key: 'delete',
      type: 'DELETE',
    },
  ];

  // Not very likely to happen but gives us feedback if the form is broken
  if (error) {
    return (
      <Icon
        name="error"
        color={{ group: 'danger' }}
        margin={[null, 'm', null]}
        {...tooltipProps}
      />
    );
  }

  return (
    <>
      {modal}
      <ActionColumnCell
        key={'contact-action-column-cell'}
        loading={loading || copyFormLoading}
        options={options}
        cell={cell}
      />
    </>
  );
};

export default FormActionColumnCell;
