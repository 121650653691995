import React from 'react';
import styled, { css } from 'styled-components';
import TEST_ID from './index.testid';
import { Body, Heading4, Variant } from '~/components/atom/Typography';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { navigate } from '@gatsbyjs/reach-router';
import StatusIndicator, { Status } from '~/components/atom/StatusIndicator';
import { AppStatusLockedReason } from '~/graphql/types';
import getPriceWithInterval from '~/util/getPriceWithInterval';
import ProgressiveImgIx from '~/components/atom/ProgressiveImgIx';
import type { ExtendedAppConfig } from '~/hooks/useApps';
import getAppStatusIndicator, {
  AppStatusIndicator,
} from '../AppsList/components/AppCards/utils/getAppStatusIndicator';
import LimitedIcon from '~/components/molecule/LimitedIcon';
import HighlightedLabel from '~/components/atom/HighlightedLabel';
import useTooltipLayer from '~/hooks/useTooltipLayer';

const text = {
  comingSoon: 'Komt binnenkort',

  statusMessages: {
    enabled: 'Actief',
    incomplete: 'Installatie niet afgerond',
    disabled: 'Inactief',
    expired: 'Autoriseer',
    Disabling: 'Deinstalleren..',
  },
};

export type Props = {
  /**
   * A badge to be rendered top right
   */
  badge?: string;
  /**
   * General app information
   */
  app: ExtendedAppConfig;
  /**
   * True if the user can interact with this card.
   *
   * This can be used if the user has e.g. no rights
   * to change anything.
   */
  disabled?: boolean;

  /**
   * Object ID to target from e2e tests
   */
  'data-objectid'?: string;

  /**
   * Shows account limitation indicator
   */
  limited?: boolean;
};

const AppCard: React.FCC<Props> = React.memo(
  ({ badge, disabled = false, app, limited }) => {
    const statusIndicator = getAppStatusIndicator(app.appStatus);

    const tagsToShow = app.tags.slice(0, 2);
    const tagsInTooltip = app.tags.slice(2);

    const tooltipProps = useTooltipLayer({
      tooltipMessage: tagsInTooltip.join(', '),
    });

    return (
      <Container
        $disabled={disabled}
        onClick={() => navigate(`/-/apps/setup/${app.slug}`)}
        data-testid={TEST_ID.CONTAINER}
      >
        {badge && <Badge>{badge}</Badge>}
        {limited && <LimitedIcon />}

        <Inner padding={['l', 'l', 's', 'l']}>
          <BrandingContainer
            align="center"
            justification="center"
            margin={[null, 'm', null, null]}
          >
            <IllustrationContainer align="center" justification="center">
              <ProgressiveImgIx width={60} src={app.image.src} />
            </IllustrationContainer>
          </BrandingContainer>

          <Header $disabled={disabled}>
            <JustificationContainer
              justification="space-between"
              align="center"
            >
              <Heading4 variant={Variant.primary}>{app.name}</Heading4>
            </JustificationContainer>
            <InfoContainer margin={[null, null, 'xxxs', null]}>
              {app.provider}
            </InfoContainer>
            <InfoContainer margin={[null]}>
              {Array.isArray(app.price)
                ? `vanaf ${getPriceWithInterval(app.price[0])}`
                : getPriceWithInterval(app.price)}
              {}
            </InfoContainer>{' '}
            {tagsToShow.length > 0 && (
              <JustificationContainer
                wrap="wrap"
                margin={['xs', null, null, null]}
                gap="xxs"
              >
                {tagsToShow.map(tag => (
                  <HighlightedLabel key={tag}>{tag}</HighlightedLabel>
                ))}
                {tagsInTooltip.length > 0 && (
                  <HighlightedLabel {...tooltipProps}>
                    + {tagsInTooltip.length}
                  </HighlightedLabel>
                )}
              </JustificationContainer>
            )}
          </Header>
        </Inner>

        <Footer
          justification="space-between"
          align="center"
          data-testid={TEST_ID.STATUS_CONTAINER}
          padding={['base', 's', 'base', 'l']}
        >
          <HorizontalJustificationContainer
            justification="start"
            align="center"
          >
            <Body fontWeight="regular" margin={[null, 'base', null, null]}>
              Status:
            </Body>
            <HorizontalJustificationContainer
              justification="start"
              align="center"
            >
              <StyledStatusIndicator status={getStatus(statusIndicator)} />
              <span>{text.statusMessages[statusIndicator]}</span>
            </HorizontalJustificationContainer>
          </HorizontalJustificationContainer>
        </Footer>
      </Container>
    );
  },
);
const getStatus = (status: AppStatusIndicator): Status => {
  switch (status) {
    case 'enabled':
      return 'active';
    case 'expired':
      return 'error';
    case 'incomplete':
      return 'warning';
    case 'disabled':
    case AppStatusLockedReason.Disabling:
    default:
      return 'inactive';
  }
};

const StyledStatusIndicator = styled(StatusIndicator)<{}>(
  ({ theme }) => css`
    margin-right: ${theme.space('xxs')};
  `,
);

type HeaderProps = {
  $disabled: boolean;
};
const Header = styled.div<HeaderProps>`
  width: 100%;

  ${({ $disabled }) => {
    if ($disabled) {
      return css`
        opacity: 0.6;
      `;
    }

    return '';
  }}
`;

type ContainerProps = {
  $disabled: boolean;
};

const Container = styled.div<ContainerProps>(
  ({ $disabled, theme }) => css`
    cursor: ${$disabled ? 'not-allowed' : 'pointer'};
    opacity: ${$disabled ? '.3' : '1'};
    display: flex;
    flex-direction: column;

    position: relative;
    min-width: 390px;
    width: 100%;
    transition-property: transform;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;

    background-color: ${theme.color('white')};
    border-radius: ${theme.getTokens().border.radius.m};
    user-select: none;
    height: 100%;
    box-shadow: ${theme.boxShadow('card')};
    outline: 1px solid ${theme.color('tertiary', 'light')};

    &:hover {
      transform: translateY(-5px);
    }
  `,
);

const Inner = styled(JustificationContainer)<{}>`
  width: 100%;
  height: 100%;
`;

const BrandingContainer = styled(JustificationContainer)<{}>`
  position: relative;
`;

const ILLUSTRATION_SIZE = 60;
const IllustrationContainer = styled(JustificationContainer)<{}>`
  width: ${ILLUSTRATION_SIZE}px;
  height: ${ILLUSTRATION_SIZE}px;
`;

const Badge = styled.div<{}>(
  ({ theme }) => css`
    right: 0;
    position: absolute;
    top: ${theme.space('xxs')};
    color: ${theme.color('white')};
    background-color: ${theme.color('success')};
    padding: ${theme.space('xxs')} ${theme.space('s')};
  `,
);

const InfoContainer = styled(Body)<{}>(
  ({ theme }) => css`
    font-size: ${theme.fs('base')};
    color: ${theme.color('tertiary')};
  `,
);

const HorizontalJustificationContainer = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    ${theme.mq.lessThan('mobile')`
      flex-direction: row;
    `}
  `,
);

const Footer = styled(HorizontalJustificationContainer)<{}>(
  ({ theme }) => css`
    width: 100%;
    border-top: 1px solid ${theme.color('tertiary', 'light')};
    border-bottom-right-radius: ${theme.getTokens().border.radius.m};
    border-bottom-left-radius: ${theme.getTokens().border.radius.m};
  `,
);

export default AppCard;
