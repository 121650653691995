import { isEmpty } from 'ramda';
import type { WidgetSettingsAppConfiguration } from '~/graphql/types';
import hasValue from '~/util/hasValue';

/**
 * Checks if there are any valid apps to display in the widget
 *
 * A valid configuration is:
 * - An app has a non-empty appConfiguration
 * - An app has non-empty items array
 * @param data - Array of widget settings configurations to check
 * @returns boolean indicating whether there are valid apps to display
 */
const hasAppsToShow = (
  data: Array<WidgetSettingsAppConfiguration>,
): boolean => {
  if (isEmpty(data)) return false;

  return data.some(
    app => hasValue(app.appConfiguration) || hasValue(app.items),
  );
};

export default hasAppsToShow;
