import React from 'react';
import styled, { css } from 'styled-components';
import { sort } from 'ramda';
import { LINK_PREFIX } from '../../';
import TemplateDetails from './components/TemplateDetails';
import groupByCategory from './utils/groupByCategory';
import MasterDetail from '~/components/template/MasterDetail';
import { useGetFormTemplatesQuery, type FormTemplate } from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import Loading from '~/components/atom/Loading';
import { EMPTY_TEMPLATE_TOKEN } from '../../../ImportForm/utils/isValidToken';

export type FormTemplatesCategory = {
  [key: string]: {
    name: string;
    slug: string;
    templates: Array<FormTemplate>;
  };
};

export type Props = {
  onSelect: (template: FormTemplate | null) => void;
  template?: FormTemplate | null;
};

export const MOST_USED_CATEGORY_SLUG = 'meest-gebruikt';
export const MOST_USED_CATEGORY_LABEL = 'Meest gebruikt';

const emptyFormTemplate: Array<FormTemplate> = [
  {
    __typename: 'FormTemplate',
    token: EMPTY_TEMPLATE_TOKEN,
    name: 'Nieuwe form',
    categories: [MOST_USED_CATEGORY_LABEL],
    weight: 9999,
    description: 'The most basic template ported from static template',
  },
];

const FormTemplates: React.FCC<Props> = ({ onSelect, template }) => {
  const account = useCurrentAccount();
  const { data, loading } = useGetFormTemplatesQuery({
    variables: {
      accountId: account.id,
    },
  });

  const groupedTemplates: FormTemplatesCategory = groupByCategory(
    data?.getFormTemplates && data.getFormTemplates.length > 0
      ? data.getFormTemplates
      : emptyFormTemplate,
  );

  const categories = Object.keys(groupedTemplates);
  const sortedCategories = sort((a, b) => a.localeCompare(b), categories);

  if (loading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  return (
    <Container>
      <StyledMasterDetail
        basePath={LINK_PREFIX}
        navbar={[
          {
            type: 'link',
            // To select the first group by default
            to: `/`,
            name: MOST_USED_CATEGORY_LABEL,
          },
          ...sortedCategories
            .filter(slug => slug !== MOST_USED_CATEGORY_SLUG)
            .map(slug => ({
              type: 'link' as const,
              // To select the first group by default
              to: `/${slug}`,
              name: groupedTemplates[slug].name,
            })),
        ]}
      >
        <TemplateDetails
          default
          path={`/${EMPTY_TEMPLATE_TOKEN}`}
          formCategories={groupedTemplates}
          onSelect={onSelect}
          templateToken={template?.token ?? null}
        />
        <TemplateDetails
          path=":slug"
          formCategories={groupedTemplates}
          onSelect={onSelect}
          templateToken={template?.token ?? null}
        />
      </StyledMasterDetail>
    </Container>
  );
};

const StyledMasterDetail = styled(MasterDetail)(
  ({ theme }) => css`
    min-width: 200px;
    width: 100%;

    border-right: 1px solid ${theme.color('tertiary')};
    margin-right: ${theme.space('l')};
  `,
);

const Container = styled.div(
  ({ theme }) => css`
    width: 100%;
    margin: ${theme.space('l')} 0;
  `,
);

export default FormTemplates;
