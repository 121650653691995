import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { FormApi } from 'final-form';
import { useStartEmailSyncV3Mutation } from '~/graphql/types';
import { Form, EmailField, PasswordField } from '~/components/organism/Forms';
import Catalog from '~/Catalog';
import Button from '~/components/atom/Button';
import TEST_ID from './index.testid';
import useSessionHydration from '~/hooks/useSessionHydration';
import CollapsibleChevronBlock from '~/components/molecule/CollapsibleChevronBlock';
import { Body, Heading3, Label } from '~/components/atom/Typography';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Input from '~/components/molecule/Input';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import validateFormValues from './utils/validateFormValues';
import getSubmitError from './utils/getSubmitError';
import Div from '~/components/atom/Div';
import Link from '~/components/molecule/Link';
import { SynchroniseEntityType } from '~/components/page/External/Nylas/types';
import getRealworksVariablesForMutation from './utils/getVariablesForMutation';

const text = {
  submit: 'Inloggen',
  header: 'Realworks e-mailadres',
  loginHelp: 'Problemen met inloggen?',
  loginHelpLink:
    'https://help.dathuis.nl/nl/articles/3666351-hoe-synchroniseer-ik-mijn-realworks-e-mailadres',
  info: 'Gebruik de logingegevens van het e-mail account. Deze gegevens vind je ',
  success:
    'Het e-mailadres is succesvol gekoppeld, het kan even duren voordat het op de pagina wordt weergegeven.',
};

type Props = {
  accountId: string;
  entityId: string;
  entityType: SynchroniseEntityType;

  /** Is the block collapsed. By default it is. */
  collapsed?: boolean;

  // Refetches any other query than SessionHydration (getAccount, getOffice etc.)
  refetch: () => void;
};

export type FormData = {
  password: string | null;
  email: string | null;
};

const ExpandableEmailSyncForm: React.FCC<Props> = ({
  accountId,
  entityId,
  entityType,
  dataTestId,
  collapsed = true,
  refetch,
}) => {
  const [, refetchSessionHydration] = useSessionHydration();
  const addToast = useAddToast();

  const [isExpanded, setExpanded] = useState(!collapsed);

  const [startEmailSyncV3, { error, loading }] = useStartEmailSyncV3Mutation();

  const onSubmit = (fields: FormData, form: FormApi<FormData>) => {
    const password = fields.password || '';
    const email = fields.email ? fields.email.toLocaleLowerCase() : '';

    const providerInfo = {
      name: email,
      email: email,
      imapUsername: email,
      imapPassword: password,
      smtpUsername: email,
      smtpPassword: password,
    };

    void startEmailSyncV3({
      variables: {
        accountId,
        realworks: getRealworksVariablesForMutation({
          providerInfo,
          entityType,
          entityId,
        }),
      },
    }).then(async ({ data, errors }) => {
      if (!data) return;
      if (errors && errors.length > 0) return;

      addToast([formatToastMessage(text.success, 'success')]);

      await refetchSessionHydration();
      refetch();

      form.reset();
    });
  };

  const onToggle = () => setExpanded(prev => !prev);

  return (
    <CollapsibleChevronBlock
      header={
        <Heading3
          margin={[null]}
          size="base"
          dataTestId={TEST_ID.SYNC_WITH_CUSTOM_PROVIDER_HEADER}
        >
          {text.header}
        </Heading3>
      }
      isOpen={isExpanded}
      onToggle={onToggle}
      headerClickable
      dataTestId={dataTestId}
    >
      <Div padding={['xs']} width="100%">
        <Form
          initialValues={{
            email: null,
            password: null,
          }}
          onSubmit={onSubmit}
          validate={validateFormValues}
        >
          {({ handleSubmit, submitting, values }) => {
            const submitError = getSubmitError(values, error);

            return (
              <form
                id="custom-signup-form"
                onSubmit={handleSubmit}
                data-testid={TEST_ID.SYNC_WITH_CUSTOM_PROVIDER_FORM}
              >
                <Body>
                  {text.info}&nbsp;
                  <Link
                    to={text.loginHelpLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-testid={TEST_ID.LOGIN_HELP_LINK}
                  >
                    hier
                  </Link>
                  .
                </Body>
                <FieldsContainer direction="column" gap="m" width="100%">
                  <EmailField name="email">
                    {({ input, meta: { error, touched } }) => (
                      <Input
                        label={Catalog.emailLabel}
                        disabled={loading}
                        dataTestId={TEST_ID.SYNC_EMAIL_INPUT_FIELD}
                        externalErrors={touched && error ? [error] : undefined}
                        width="100%"
                        {...input}
                      />
                    )}
                  </EmailField>

                  <PasswordField name="password">
                    {({ input, meta: { error, touched } }) => (
                      <Input
                        label="Wachtwoord"
                        type="password"
                        externalErrors={touched && error ? [error] : undefined}
                        disabled={loading}
                        autoComplete="current-password"
                        width="100%"
                        dataTestId={TEST_ID.SYNC_PASSWORD_INPUT_FIELD}
                        {...input}
                      />
                    )}
                  </PasswordField>

                  {submitError && (
                    <Label
                      color={{ group: 'danger' }}
                      fontWeight="regular"
                      data-testid={TEST_ID.SYNC_WITH_CUSTOM_PROVIDER_ERROR}
                    >
                      {submitError}
                    </Label>
                  )}

                  <Button
                    size="medium"
                    onClick={handleSubmit}
                    type="submit"
                    disabled={loading}
                    dataTestId={TEST_ID.SYNC_WITH_CUSTOM_PROVIDER_BUTTON}
                    loading={submitting || loading}
                    label={text.submit}
                    style={{ alignSelf: 'flex-end' }}
                  />
                </FieldsContainer>
              </form>
            );
          }}
        </Form>
      </Div>
    </CollapsibleChevronBlock>
  );
};

const FieldsContainer = styled(JustificationContainer)<{}>`
  ${({ theme }) => css`
    max-width: 500px;
    margin: ${theme.space('m')} auto 0 auto;
  `};
`;

export default ExpandableEmailSyncForm;
