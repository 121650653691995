import React from 'react';
import MasonryCard, { Props } from '~/components/molecule/MasonryCard';

/**
 *
 * @param WrappedComponent
 * @param opts options
 *
 * Wraps the given component with the Masonry Card component
 */
const withMasonryCard =
  (
    WrappedComponent: typeof React.Component | React.FC,
    {
      containerTestId,
      ...opts
    }: { containerTestId?: string } & Omit<Props, 'children'>,
  ) =>
  props => (
    <MasonryCard dataTestId={containerTestId} {...opts}>
      <WrappedComponent {...props} />
    </MasonryCard>
  );

export default withMasonryCard;
