import React from 'react';
import type { JsonStructure, JsonStructureItem } from 'react-cmdk';
import { navigate } from '@gatsbyjs/reach-router';
import waitForElement from '~/util/waitForElement';
import Icon from '~/components/atom/Icon';
import { useSetRecoilState } from 'recoil';
import { pageSelector } from '../../state';

const useContactsItems = (): JsonStructure[number] => {
  const setPage = useSetRecoilState(pageSelector);

  const items: Array<JsonStructureItem> = [
    {
      id: 'contacts',
      children: 'Contacten',
      icon: () => <Icon name="users" color={{ group: 'secondary' }} />,
      onClick: () => {
        void navigate('/-/contacts');
      },
      showType: false,
      keywords: ['contacts'],
    },
    {
      id: 'open-contacts',
      children: 'Contacten zoeken',
      icon: () => <Icon name="search" color={{ group: 'secondary' }} />,
      onClick: () => {
        setPage('open-contacts');
      },
      closeOnSelect: false,
      showType: false,
      keywords: ['contact'],
    },
    {
      id: 'create-contact',
      children: 'Nieuw contact',
      icon: () => <Icon name="plus" color={{ group: 'secondary' }} />,
      showType: false,
      keywords: ['new', 'contact'],
      onClick: async () => {
        void navigate('/-/contacts/');
        const createButton = await waitForElement<HTMLButtonElement>(
          '[data-testid="src/components/page/Contacts/components/ContactList/ContactsToolBar/index.testid.ts-add-single-contact-button"]',
        );

        if (createButton) {
          createButton.click();
        }
      },
    },
    {
      id: 'import-contacts',
      children: 'Contacten importeren',
      icon: () => <Icon name="upload" color={{ group: 'secondary' }} />,
      showType: false,
      keywords: ['import', 'new', 'contact'],
      onClick: async () => {
        void navigate('/-/contacts/');
        const dropdown = await waitForElement<HTMLButtonElement>(
          '[data-testid="src/components/page/Contacts/components/ContactList/ContactsToolBar/index.testid.ts-import-multiple-contacts-dropdown"] > div',
        );

        if (dropdown) {
          dropdown.click();
          const csvButton = await waitForElement<HTMLButtonElement>(
            '[data-objectid="CSV"]',
          );
          if (csvButton) {
            csvButton.click();
          }
        }
      },
    },
  ];

  return {
    heading: 'Contacten',
    id: 'contacts',
    items,
  };
};

export default useContactsItems;
