import { isNil } from 'ramda';
import React from 'react';
import styled, { css } from 'styled-components';
import { Heading5, Label, Variant } from '~/components/atom/Typography';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { linkStyles } from '~/components/molecule/Link';
import TEST_ID from './index.testid';
import type { Issue as IssueProps } from '~/components/page/Forms/components/Builder/state/issues';
import { nodesSelector } from '~/components/page/Forms/components/Builder/state/nodesAndEvents';
import {
  interactionState,
  type FormBuilder_Interaction,
} from '~/components/page/Forms/components/Builder/state';
import JustificationContainer from '~/components/atom/JustificationContainer';

export type Props = {
  dataTestId?: string;
  showLink?: boolean;
  title?: string;
  onClick?: () => void;
} & IssueProps;

const Issue: React.FCC<Props> = ({
  dataTestId,
  level,
  message,
  nodeIds,
  showLink = true,
  title,
  onClick,
}) => {
  const [nodes] = useRecoilState(nodesSelector);
  const filteredNodes = nodes.filter(node => nodeIds?.includes(node.id));

  const setInteraction = useSetRecoilState(interactionState);
  const heading = level === 'error' ? 'Foutmelding' : 'Waarschuwing';

  return (
    <Container $issue={level} data-testid={dataTestId} onClick={onClick}>
      {!isNil(title) && <CardHeading>{title}</CardHeading>}
      <MessageContainer>
        <Heading5 inline variant={Variant.primary}>
          {heading}:{' '}
        </Heading5>
        <TextContainer>{message}</TextContainer>
        {filteredNodes.length > 0 && (
          <JustificationContainer
            direction="column"
            gap="xxxs"
            margin={['xxs', null, null, null]}
          >
            {filteredNodes.map(node => (
              <div key={`issue-${node.id}`}>
                <Label inline margin={[null, 'xxs', null, null]}>
                  {node.name}
                </Label>
                {!isNil(node) && showLink && (
                  <OpenActionButton
                    data-testid={TEST_ID.GO_TO_ACTION_WITH_ISSUE}
                    onClick={() => {
                      if (node) {
                        const interactionType: FormBuilder_Interaction =
                          node.__typename === 'FormBuilder_EventNode'
                            ? 'edit-node-event'
                            : 'edit-node-screen';
                        setInteraction({
                          interactionType,
                          subjectId: node.id,
                        });
                      }
                    }}
                  >
                    (aanpassen)
                  </OpenActionButton>
                )}
              </div>
            ))}
          </JustificationContainer>
        )}
      </MessageContainer>
    </Container>
  );
};

const Container = styled.div<{
  $issue: IssueProps['level'];
}>(
  ({ theme, $issue }) => css`
    background: ${$issue === 'error'
      ? theme.color('danger', 'translucent')
      : theme.color('warning', 'light')};
    padding: ${theme.spacing('m')};
    border-radius: ${theme.getTokens().border.radius.base};
    transition: all 0.3s ease-out;
    border: 1px solid transparent;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

    cursor: pointer;

    &:hover {
      border-color: ${theme.color($issue === 'error' ? 'danger' : 'warning')};
    }
  `,
);

const MessageContainer = styled(JustificationContainer)<{}>`
  display: inline;
`;

const CardHeading = styled.div<{}>(
  ({ theme }) => css`
    font-size: ${theme.fontSize('xxs')};
    font-weight: ${theme.fw('semiBold')};
    padding-bottom: ${theme.spacing('xxxs')};
  `,
);

const OpenActionButton = styled.span`
  ${linkStyles}
`;

const TextContainer = styled.span`
  ${({ theme }) => css`
    margin-right: ${theme.space('xxs')};
  `}
`;

export default Issue;
