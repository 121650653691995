import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { Link } from '~/components/molecule/Link';
import styled, { css } from 'styled-components';
import toasts from '~/state/toasts';
import TEST_ID from './index.testid';
import Icon from '~/components/atom/Icon';
import { Body } from '~/components/atom/Typography';
import JustificationContainer from '~/components/atom/JustificationContainer';
import {
  levelToColorMapping,
  levelToIconNameMapping,
} from '~/styles/constants';

export type ToastLevel = 'danger' | 'warning' | 'success' | 'info';

const AUTO_CLEAR_TIMEOUT = 10000;

export type LinkObj = {
  label: string;
  to: string;
  onClick?: () => void;
};

type Props = {
  /** A unique ID */
  id?: string;

  /** The message */
  message?: React.ReactNode;

  /** Show Upload button or not */
  level?: ToastLevel;

  /** Optional link to show */
  link?: LinkObj | null;

  /** Used to hide the close button */
  dismissable?: boolean;
};

const Toast: React.FCC<Props> = ({
  id,
  message,
  level = 'info',
  link = null,
  dismissable = true,
}) => {
  const [allToasts, setToasts] = useRecoilState(toasts);

  const clearToast = () => {
    setToasts(allToasts.filter(({ id: toastId }) => id !== toastId));
  };

  useEffect(() => {
    const timeout = setTimeout(clearToast, AUTO_CLEAR_TIMEOUT);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      border={{ radius: 'm' }}
      align="center"
      width="100%"
      backgroundColor={{ group: 'primary' }}
      padding={['m', 'l', 'm', 'm']}
      margin={[null, null, 'm', null]}
      dataTestId={TEST_ID.CONTAINER}
      data-messagelevel={level}
    >
      {dismissable && (
        <CloseContainer onClick={clearToast} data-testid={TEST_ID.CLOSE_BUTTON}>
          <Icon name="close" strokeWidth={2.5} />
        </CloseContainer>
      )}
      <IconContainer $level={level}>
        <Icon name={levelToIconNameMapping[level]} strokeWidth={2.5} />
      </IconContainer>
      <Inner>
        <Body
          dataTestId={TEST_ID.TOAST_MESSAGE}
          margin={[null]}
          size="base"
          color={{ group: 'white' }}
        >
          {message}
        </Body>
        <LinkContainer>
          {link && (
            <Link
              to={link.to}
              onClick={link.onClick}
              data-testid={TEST_ID.LINK}
            >
              {link.label}
            </Link>
          )}
        </LinkContainer>
      </Inner>
    </Container>
  );
};

const Container = styled(JustificationContainer)<{}>`
  width: 100%;
  position: relative;
  z-index: 500;
  ${({ theme }) => css`
    color: ${theme.color('white')};
  `}
`;

const CloseContainer = styled.button<{}>`
  background: transparent;
  border: none;
  outline: none;
  position: absolute;
  cursor: pointer;
  transition: all 350ms ease-out;
  ${({ theme }) => css`
    font-size: ${theme.fontSize('s')};
  `}

  &:hover {
    & svg {
      transform: rotate(180deg);
    }
  }

  ${({ theme }) => css`
    /* Top and right take care of half the position */
    top: ${theme.space('xxs')};
    right: ${theme.space('xxs')};
    /* Padding does other half of position and increases pointer area */
    padding: ${theme.space('xxs')};
  `}
`;

type IconContainerProps = {
  $level: ToastLevel;
};

const IconContainer = styled.span<IconContainerProps>(
  ({ theme, $level }) => css`
    margin-right: ${theme.space('l')};
    width: ${theme.space('xxl')};
    height: ${theme.space('xxl')};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
    border-radius: ${theme.getTokens().border.radius.full};
    background-color: ${theme.color(
      levelToColorMapping[$level],
      $level === 'info' ? 'light' : 'base',
    )};
    font-size: ${theme.space('m')};
  `,
);

const Inner = styled.div<{}>`
  /* Theme is not used because xxxs is small, xxs is too much */
  margin-right: 4px;
  p {
    margin: 0;
  }
`;

const LinkContainer = styled.div<{}>`
  ${({ theme }) => css`
    a {
      color: ${theme.color('white')};
      font-weight: 100;
    }
    a:hover {
      color: ${theme.color('white')};
    }
  `}
`;

export default Toast;
