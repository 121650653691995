import React, { useEffect, useState } from 'react';
import styled, { DefaultTheme, css, keyframes } from 'styled-components';

import { safeFormattedDateTime } from '~/util/date';
import MarkdownBlock from '../Block/components/Data/Markdown';
import Attachments from './components/Attachments';
import { isNil } from 'ramda';
import { ActivityV2 } from '../../types';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { LayoutSize } from '~/graphql/types';
import DividerBlock from '~/components/template/EventTimelineV2/components/Block/components/Data/Divider';
import hasChildren from '~/util/hasChildren';

export enum EventCardAppearance {
  Error = 'error',
}

export type Props = Omit<
  ActivityV2,
  'body' | 'id' | 'header' | 'highlighted'
> & {
  dataTestId?: string;

  /** Any content to place in event card's body. ReactNode is used to allow
   * activities to have custom bodies such as SendEmail and Task activities
   */
  body?: React.ReactNode;

  /** Shows activity's state. Highlights newly added activity, shows error when activity has failed and so on */
  appearance?: EventCardAppearance | null;

  /** Used to add components at the bottom of the card, e.g. StatusContainer */
  bottomBar?: React.ReactNode;

  /** Makes the card disabled */
  disabled?: boolean;

  /** Can be used to open a modal */
  onClick?: () => void;

  header: {
    text: string;

    /** Only used for Task event currently so we handle it on the frontend */
    icon?: React.ReactNode;
  };

  /** Highlighted state of the card. This comes from the backend */
  highlighted?: boolean;
};

const BaseEventCard: React.FC<Props> = ({
  dataTestId,
  sortingDate,
  header,
  body,
  bottomBar,
  appearance,
  attachments = [],
  onClick,
  disabled,
  highlighted,
  _v,
  createdDate: _createdDate,
  accountId: _accountId,
}) => {
  const formattedDate = sortingDate
    ? safeFormattedDateTime(new Date(sortingDate))
    : null;

  const [internalHighlighted, setInternalHighlighted] = useState(false);

  // Re-highlight the card when the version is updated
  useEffect(() => {
    if (highlighted) {
      setInternalHighlighted(true);

      setTimeout(() => {
        setInternalHighlighted(false);
      }, 7000);
    }
  }, [highlighted, _v]);

  return (
    <Container
      dataTestId={dataTestId}
      direction="column"
      width="100%"
      margin={[null, null, 'xl', null]}
      $appearance={appearance}
      $highlighted={internalHighlighted}
      $clickable={!isNil(onClick) && !disabled}
      onClick={onClick}
    >
      <HeadingContainer
        backgroundColor={{ group: 'white' }}
        direction="column"
        width="100%"
        padding={['base', 'base', !hasChildren(body) ? 'base' : null, 'base']}
      >
        <DateLabel>{formattedDate}</DateLabel>

        <JustificationContainer gap="xs" align="center">
          {header?.icon}
          <MarkdownBlock value={header?.text} margin={[null]} />
        </JustificationContainer>
        {hasChildren(body) && (
          <DividerBlock margin={[LayoutSize.Base, null, null, null]} />
        )}
      </HeadingContainer>

      {body && <BodyContainer>{body}</BodyContainer>}

      <Attachments attachments={attachments} />

      {bottomBar && <BottomContainer>{bottomBar}</BottomContainer>}
    </Container>
  );
};

export const CARD_Z = 100;
export const CARD_MIN_COLUMN_WIDTH = 300;

const getFlashBorder = (theme: DefaultTheme) => keyframes`
  to { outline: 2px solid transparent; }
  10% { outline: 2px solid ${theme.color('primary')}; }
  70% { outline: 2px solid ${theme.color('primary')}; }
  100% { outline: 2px solid transparent; }
`;

const Container = styled(JustificationContainer)<{
  $appearance?: Props['appearance'];
  $clickable?: boolean;
  $highlighted: boolean;
}>(
  ({ theme, $appearance, $clickable, $highlighted }) => css`
    outline: ${$appearance === EventCardAppearance.Error
      ? `1px solid ${theme.color('danger')}`
      : `1px solid  ${theme.color('tertiary', 'light')}`};

    animation: 7s ${$highlighted ? getFlashBorder(theme) : 'no-animation'}
      ease-out forwards;

    border-radius: ${theme.getTokens().border.radius.m};
    overflow: hidden;
    background-color: ${theme.color('white')};
    min-width: ${CARD_MIN_COLUMN_WIDTH}px;

    z-index: ${CARD_Z};

    ${$clickable &&
    css`
      cursor: pointer;
    `};
  `,
);

const HeadingContainer = styled(JustificationContainer)<{}>(
  () => css`
    position: relative;
  `,
);

const BodyContainer = styled.div<{}>(
  () => css`
    width: 100%;
  `,
);

const BottomContainer = styled.div<{}>(
  () => css`
    width: 100%;
  `,
);

const DateLabel = styled.span<{}>(
  ({ theme }) => css`
    color: ${theme.color('tertiary', 'dark')};
    font-size: ${theme.fontSize('s')};
    margin-bottom: ${theme.space('xxxs')};
  `,
);

export default BaseEventCard;
