import React, { useState } from 'react';
import { navigate } from '@gatsbyjs/reach-router';
import useBrandSettings, {
  type BrandSettingsField,
} from '~/hooks/useBrandSettings';
import { Props } from '../../index';
import CallToActionBlock from '~/components/organism/CallToActionBlock';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Label, Variant } from '~/components/atom/Typography';
import ToggleCheckbox from '~/components/molecule/ToggleCheckbox';
import TextButton from '~/components/atom/TextButton';
import Button from '~/components/atom/Button';
import getSelectedBrandSettings from '../../utils/getSelectedBrandSettings';
import SubjectBasedComponent from '../SubjectBasedComponent';
import hasValue from '~/util/hasValue';
import getValidSubjects from './utils/getValidSubjects';

const text = {
  ctaDescription: 'Er is nog geen huisstijl ingesteld voor dit account',
  ctaButtonLabel: 'Huisstijl instellen',
  saveButtonLabel: 'Opslaan',
  cancelButtonLabel: 'Annuleren',
  toggleLabel: 'Instelling toepassen',
};

const BrandSettingsContent: React.FC<Props> = ({
  subjects,
  onConfirm,
  onClose,
}) => {
  const brandSettings = useBrandSettings();
  const validSubjects = getValidSubjects(subjects, brandSettings);

  const [checkedSettings, setCheckedSettings] = useState<
    Set<BrandSettingsField>
  >(new Set(validSubjects));

  const toggleSetting = (setting: BrandSettingsField) => {
    const updatedSet = new Set(checkedSettings);

    if (updatedSet.has(setting)) {
      updatedSet.delete(setting);
    } else {
      updatedSet.add(setting);
    }
    setCheckedSettings(updatedSet);
  };

  if (!hasValue(brandSettings) || validSubjects.length === 0) {
    return (
      <CallToActionBlock
        width="100%"
        margin={['base', null]}
        align="center"
        description={text.ctaDescription}
        icon={{
          name: 'triangle',
          background: { group: 'warning' },
        }}
        button={{
          appearance: 'primary',
          label: text.ctaButtonLabel,
          icon: 'arrowRight',
          onClick: () => navigate('/-/settings/brand'),
        }}
      />
    );
  }

  const hasChanges = hasValue(Array.from(checkedSettings));

  return (
    <JustificationContainer width="100%" direction="column">
      {subjects.map(subject => (
        <JustificationContainer
          key={subject}
          direction="column"
          width="100%"
          backgroundColor={{ group: 'primary', variant: 'translucent' }}
          padding={['s']}
          border={{ radius: 'base' }}
          margin={[null, null, 'base', null]}
        >
          <JustificationContainer
            width="100%"
            gap="s"
            justification="space-between"
          >
            <Label
              margin={[null]}
              fontWeight="semiBold"
              variant={Variant.primary}
              size="m"
            >
              {text.toggleLabel}
            </Label>
            <ToggleCheckbox
              size="small"
              value={checkedSettings.has(subject)}
              onChange={() => toggleSetting(subject)}
              disabled={!hasValue(brandSettings[subject])}
            />
          </JustificationContainer>
          <JustificationContainer
            backgroundColor={{ group: 'white' }}
            direction="column"
            gap="s"
            padding={['xs']}
            width="100%"
            border={{ radius: 'base' }}
            margin={['s', null, null, null]}
          >
            <SubjectBasedComponent
              subject={subject}
              brandSettings={brandSettings}
            />
          </JustificationContainer>
        </JustificationContainer>
      ))}
      <JustificationContainer
        width="100%"
        justification="space-between"
        align="center"
        margin={['s', null, null, null]}
      >
        <TextButton
          size="medium"
          appearance="danger"
          label={text.cancelButtonLabel}
          onClick={onClose}
          padding={[null]}
        />
        <Button
          size="medium"
          disabled={!hasChanges}
          appearance="secondary"
          label={text.saveButtonLabel}
          onClick={() => {
            const selectedSettings = getSelectedBrandSettings({
              brandSettings,
              selectedValues: checkedSettings,
            });

            onConfirm(selectedSettings);
          }}
        />
      </JustificationContainer>
    </JustificationContainer>
  );
};

export default BrandSettingsContent;
