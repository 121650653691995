import React from 'react';
import styled, { css } from 'styled-components';
import Button from '~/components/atom/Button';

import OptionsButton from '~/components/organism/OptionsButton';

const text = {
  rotateClockwise: 'Rechtsom',
  rotateCounterClockwise: 'Linksom',

  flipX: 'Horizontaal draaien',
  flipY: 'Verticaal draaien',
};

export type RotationDirection = 'clockwise' | 'counter-clockwise';
export type FlipDirection = 'x' | 'y';

type Props = {
  /**
   * Zoom in button was clicked
   */
  onZoomIn: () => void;
  /**
   * Zoom out button was clicked
   */
  onZoomOut: () => void;

  /**
   * Maximize crop window was clicked
   */
  onMaximize: () => void;

  /**
   * Rotates the image
   * @param direction
   */
  onRotate: (direction: RotationDirection) => void;

  /**
   * Flips image on passed axis
   */
  onFlip: (direction: FlipDirection) => void;
};

const Toolbar: React.FCC<Props> = ({
  onRotate,
  onFlip,
  onZoomIn,
  onZoomOut,
  onMaximize,
}) => (
  <Container>
    <Button
      icon="zoom-in"
      size="small"
      appearance="tertiary"
      ghost
      onClick={onZoomIn}
    />
    <Button
      icon="zoom-out"
      size="small"
      appearance="tertiary"
      ghost
      onClick={onZoomOut}
    />
    <Button
      icon="maximize"
      size="small"
      appearance="tertiary"
      ghost
      onClick={onMaximize}
    />
    <OptionsButton
      icon="rotate-cw"
      options={[
        {
          key: 'cw',
          label: text.rotateClockwise,
          payload: 'clockwise',
          icon: { name: 'rotate-cw' },
          onClickAction: () => onRotate('clockwise'),
        },
        {
          key: 'ccw',
          label: text.rotateCounterClockwise,
          payload: 'counter-clockwise',
          icon: { name: 'rotate-ccw' },
          onClickAction: () => onRotate('counter-clockwise'),
        },
      ]}
    />
    <OptionsButton
      icon="flip-x"
      options={[
        {
          key: 'flip-x',
          label: text.flipX,
          payload: 'flip-x',
          icon: { name: 'flip-x' },
          onClickAction: () => onFlip('x'),
        },
        {
          key: 'flip-y',
          label: text.flipY,
          payload: 'flip-y',
          icon: { name: 'flip-y' },
          onClickAction: () => onFlip('y'),
        },
      ]}
    />
  </Container>
);

const Container = styled.div<{}>(
  ({ theme }) => css`
    position: absolute;
    bottom: ${theme.space('m')};

    background-color: ${theme.color('white')};
    box-shadow: ${theme.boxShadow('sleek')};
    border-radius: ${theme.getTokens().border.radius.m};

    padding: ${theme.space('xs')};

    display: flex;
    gap: ${theme.space('xs')};

    z-index: ${theme.z('top')};
  `,
);

export default Toolbar;
