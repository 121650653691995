import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { animated, useTransition, UseTransitionProps } from 'react-spring';
import getDataPointColor from '~/util/getDataPointColor';
import { ChartDataPoint } from '../../..';
import LegendIcon from '../../../LegendIcon';
import { ANIMATION_CONFIG } from '~/styles/constants';

export type Props = {
  data: Array<ChartDataPoint<{ [key: string]: number }>>;
  activeBar: string | null;
  setActiveBar: (value: string | null) => void;
};
const Legend: React.FCC<Props> = ({ data, setActiveBar, activeBar }) => {
  const theme = useTheme();
  const { value } = data[0];
  const items = Object.keys(value);

  const transitionProps: UseTransitionProps = {
    from: { transform: 'translate(0, -40px)', opacity: 0 },
    enter: { transform: 'translate(0, 0px)', opacity: 1 },
    leave: { transform: 'translate(0, -40px)', opacity: 0 },
    config: ANIMATION_CONFIG.config,
    trail: 125,
    keys: item => item,
  };
  const transitions = useTransition(items, transitionProps);

  return (
    <LegendContainer>
      {transitions(
        (style, item, { key }, index) =>
          key && (
            <LegendItemContainer
              key={index}
              onMouseEnter={() => setActiveBar(key)}
              onMouseLeave={() => setActiveBar(null)}
              style={style}
            >
              <StyledLegendIcon
                $active={!activeBar || activeBar === key}
                $color={getDataPointColor(index, theme)}
              />
              <span>{key}</span>
            </LegendItemContainer>
          ),
      )}
    </LegendContainer>
  );
};

const LegendContainer = styled.div<{}>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => css`
    margin-top: ${theme.space('m')};
  `}
`;
const LegendItemContainer = styled(animated.div)<{}>`
  display: flex;
  cursor: default;

  ${({ theme }) => css`
    margin-right: ${theme.space('m')};

    span {
      font-weight: ${theme.fw('semiBold')};
    }
  `}
`;

const StyledLegendIcon = styled(LegendIcon)<{}>(
  ({ theme }) => css`
    margin-right: ${theme.space('base')};
  `,
);

export default Legend;
