import React, { useState } from 'react';
import { navigate, type RouteComponentProps } from '@gatsbyjs/reach-router';

import JustificationContainer from '~/components/atom/JustificationContainer';
import { Body, Heading2 } from '~/components/atom/Typography';
import Modal from '~/components/organism/ModalsV2/Modal';
import Overlay from '~/components/organism/ModalsV2/Overlay';
import TipBanner from '~/components/organism/TipBanner';
import ChatLink from '~/components/organism/TipBanner/ChatLink';
import Input from '~/components/molecule/Input';
import Button from '~/components/atom/Button';
import TextButton from '~/components/atom/TextButton';
import Catalog from '~/Catalog';
import CollapsibleChevronBlock from '~/components/molecule/CollapsibleChevronBlock';
import isValidToken from './utils/isValidToken';
import Link from '~/components/molecule/Link';
import queryString from 'query-string';
import { useImportFlowLazyQuery } from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';

export type Props = {
  onClose: () => void;
} & RouteComponentProps;

const text = {
  header: 'Importeer een gedeelde flow',
  tipHeader: 'Hulp nodig?',
  tipBody: (
    <>
      Kom je er niet uit? Lees de volledige instructie in{' '}
      <Link
        to="https://help.dathuis.nl/nl/articles/10071733-een-automation-flow-delen"
        target="_blank"
      >
        onze kennisbank
      </Link>{' '}
      of <ChatLink linkText="start een chat met ons." />
    </>
  ),
  description: (
    <>
      Importeer een flow. Gebruik de token van de flow dat met jou is gedeeld.
    </>
  ),
  tokenLabel: 'Plak token',
  flowDescriptionLabel: 'Omschrijving',
  flowNameLabel: 'Naam',
  overwriteSettings: 'Zelf een naam en omschrijving van deze flow opgeven',

  submitLabel: 'Flow importeren',

  invalidToken: 'Deze token is niet geldig',
  flowNotSharedError: 'Deze flow lijkt niet gedeeld te zijn.',
  importFlowError:
    'De opgegeven token is ongeldig. Controleer de token en probeer opnieuw.',

  importSuccess: 'Flow geïmporteerd!',
  goToFlowLabel: 'Ga nu naar de flow',
};

const ImportFlow: React.FCC<Props> = ({ dataTestId, onClose, ...rest }) => {
  const [importData, setImportData] = useState<{
    name: string;
    description: string;
    token: string;
  }>({
    name: '',
    description: '',
    token: '',
  });
  const [overwriteExpanded, setOverwriteExpanded] = useState(false);
  const [error, setError] = useState<null | string>(null);
  [];

  const { id: accountId } = useCurrentAccount();
  const addToast = useAddToast();

  const [importFlow, { loading }] = useImportFlowLazyQuery({});

  const onSubmit = async () => {
    if (!isValidToken(importData.token)) {
      return setError(text.invalidToken);
    }

    const flow = await importFlow({
      variables: { accountId, token: importData.token },
    });

    if (flow.data == null || flow.error) {
      setError(text.invalidToken);
      return addToast([formatToastMessage(text.importFlowError, 'danger')]);
    }

    void navigate(
      `/-/automation/flows/builder-v2/create/${importData.token}?${queryString.stringify({ name: importData.name, description: importData.description })}`,
    );
  };

  return (
    <Overlay onClose={onClose}>
      <Modal maxWidth="800px">
        <JustificationContainer
          direction="column"
          padding={['xl']}
          width="100%"
          data-testid={dataTestId}
          {...rest}
        >
          <JustificationContainer>
            <Heading2>{text.header}</Heading2>
          </JustificationContainer>
          <Body>{text.description}</Body>
          <TipBanner
            id="share-form-tip"
            headerText={text.tipHeader}
            dismissible={false}
            width="100%"
          >
            <Body size="s">{text.tipBody}</Body>
          </TipBanner>
          <JustificationContainer
            direction="column"
            width="100%"
            gap="m"
            margin={[null, null, 'm', null]}
          >
            <Input
              width="100%"
              externalErrors={error ? [error] : []}
              label={{ text: text.tokenLabel }}
              validation={[
                val => (isValidToken(val) ? true : text.invalidToken),
              ]}
              placeholder="xxxxxx-xxxx-xxx-xxx-xxxxxxx"
              onChange={event => {
                setError(null);
                setImportData(prev => ({ ...prev, token: event.target.value }));
              }}
              value={importData.token}
              autoFocus
            />
            <CollapsibleChevronBlock
              header={text.overwriteSettings}
              isOpen={overwriteExpanded}
              onToggle={() => {
                setOverwriteExpanded(prev => !prev);
                // if true it will be false on next update so we clear the fields
                if (overwriteExpanded === true) {
                  setImportData(prev => ({
                    ...prev,
                    name: '',
                    description: '',
                  }));
                }
              }}
            >
              <JustificationContainer width="100%" direction="column" gap="m">
                <Input
                  width="100%"
                  label={{ text: text.flowNameLabel }}
                  onChange={event => {
                    setImportData(prev => ({
                      ...prev,
                      name: event.target.value,
                    }));
                  }}
                  value={importData.name}
                />
                <Input
                  width="100%"
                  label={{ text: text.flowDescriptionLabel }}
                  onChange={event =>
                    setImportData(prev => ({
                      ...prev,
                      description: event.target.value,
                    }))
                  }
                  value={importData.description}
                />
              </JustificationContainer>
            </CollapsibleChevronBlock>
          </JustificationContainer>
          <JustificationContainer width="100%" justification="space-between">
            <TextButton label={Catalog.cancel} onClick={onClose} />
            <Button
              label={text.submitLabel}
              onClick={onSubmit}
              size="medium"
              disabled={!isValidToken(importData.token)}
              loading={loading}
            />
          </JustificationContainer>
        </JustificationContainer>
      </Modal>
    </Overlay>
  );
};

export default ImportFlow;
