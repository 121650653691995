import React from 'react';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Heading5, Variant } from '~/components/atom/Typography';

export type Props = {
  leads: number;
  linkTo: string;
};

const WaitStatistics: React.FCC<Props> = ({ dataTestId, leads }) => (
  <JustificationContainer dataTestId={dataTestId}>
    <Heading5 variant={Variant.primary}>Nu wachtend:</Heading5>
    &nbsp;
    {leads > 0 ? <>{leads} contact(en) </> : '—'}
  </JustificationContainer>
);

export default WaitStatistics;
