import React from 'react';
import styled, { css } from 'styled-components';
import { SystemColorPalette } from '~/theme';
import { outline } from '~/components/atom/Button';
import { SystemSize } from '~/theme/System/tokens';
import { AnimatedComponent } from 'react-spring';
import Icon, { IconType } from '~/components/atom/Icon';
import { isNil } from 'ramda';
import arrayToCss from '~/util/arrayToCss';
import { SystemFontWeight } from '~/theme';
import type { ComponentSizes, Size } from '~/styles/constants';
import { componentSizes } from '~/styles/constants';
import type { ThemeColor } from '~/theme/System/tokens/colorPalette';
import JustificationContainer from '../JustificationContainer';

type Width = 'max-content' | 'auto';
type TextButtonAppearances =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'accent';

const textButtonAppearances: {
  [key in TextButtonAppearances]: ThemeColor;
} = {
  primary: {
    group: 'primary',
    variant: 'base',
  },
  secondary: {
    group: 'secondary',
    variant: 'base',
  },
  success: {
    group: 'success',
    variant: 'base',
  },
  danger: {
    group: 'danger',
    variant: 'light',
  },
  accent: {
    group: 'accent',
    variant: 'base',
  },
};

const textButtonSizes: ComponentSizes = {
  ...componentSizes,
  large: {
    fontSize: 'm',
    padding: ['m', 'm'],
  },
};

export type Props = {
  label?: React.ReactNode;
  icon?: IconType;
  margin?: Array<SystemSize | null>;
  padding?: Array<SystemSize | null>;
  /* Changes the order of the icon and the label if both provided */
  direction?: 'ltr' | 'rtl';
  appearance?: TextButtonAppearances;
  size?: Size;
  disabled?: boolean;
  type?: 'button' | 'submit';
  as?: AnimatedComponent<any>;
  width?: Width;
  dataTestId?: string;

  /* Replaces the icon for our loading icon and forces the disabled state as well */
  loading?: boolean;
} & React.HtmlHTMLAttributes<HTMLButtonElement>;

const TextButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      dataTestId,
      label,
      appearance = 'primary',
      size = 'small',
      type = 'button',
      margin = [null, null, null, null],
      padding,
      icon,
      width = 'max-content',
      direction = 'ltr',
      loading,
      disabled,
      ...rest
    },
    ref,
  ) => (
    <Button
      {...rest}
      data-testid={dataTestId}
      $appearance={appearance}
      $icon={icon}
      $size={size}
      $margin={margin}
      $padding={padding}
      type={type}
      ref={ref}
      $width={width}
      disabled={disabled || loading}
    >
      {icon ? (
        <JustificationContainer
          align="center"
          justification="center"
          gap="xxs"
          direction={direction === 'ltr' ? 'row' : 'row-reverse'}
        >
          <Icon name={loading ? 'spinner' : icon} strokeWidth={2.5} />
          {label && <LabelWrapper $fontWeight="medium">{label}</LabelWrapper>}
        </JustificationContainer>
      ) : (
        <LabelWrapper>{label}</LabelWrapper>
      )}
    </Button>
  ),
);

const LabelWrapper = styled.span<{
  $fontWeight?: SystemFontWeight;
}>(
  ({ theme, $fontWeight }) => css`
    font-weight: ${theme.fw($fontWeight || 'regular')};
  `,
);

const Button = styled.button<{
  $appearance: keyof SystemColorPalette;
  $size: Size;
  $icon?: IconType;
  $padding: Array<SystemSize | null>;
  $margin: Array<SystemSize | null>;
  disabled: boolean;
  $width: Width;
}>(
  ({
    theme,
    $appearance,
    $icon,
    $size,
    $margin,
    $padding,
    $width,
    disabled,
  }) => {
    const { group, variant } = textButtonAppearances[$appearance];
    const color = theme.color(group, variant);

    return css`
      border: none;
      background: none;
      text-align: left;

      display: flex;
      align-items: center;
      width: ${$width};

      /* Only add margin if provided */
      ${$margin.filter(m => !isNil(m)).length !== 0
        ? `margin: ${arrayToCss($margin, theme)};`
        : ''}

      text-decoration: ${$icon != null ? 'none' : 'underline'};
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
      color: ${disabled ? theme.color('tertiary', 'dark') : color};

      font-size: ${$size === 'small'
        ? theme.fontSize('s')
        : theme.fontSize(textButtonSizes[$size].fontSize)};

      border-radius: ${theme.getTokens().border.radius.s};
      padding: ${$padding
        ? arrayToCss($padding, theme)
        : textButtonSizes[$size].padding
            .map(size => theme.space(size))
            .join(' ')};

      margin: ${arrayToCss($margin, theme)};

      &:active,
      &:hover {
        color: ${theme.color(group, 'dark')};
        background-color: ${$padding &&
        $padding.length === 1 &&
        $padding[0] === null
          ? 'none'
          : theme.color(group, 'translucent')};
        transition: all 0.3s ease;
        ${outline(theme.color(group, 'light'))}
      }

      &:disabled {
        pointer-events: none;
      }
    `;
  },
);

export default TextButton;
