import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Icon, { IconType } from '~/components/atom/Icon';
import JustificationContainer, {
  Props as JustificationProps,
} from '~/components/atom/JustificationContainer';
import Tooltip, { Props as TooltipProps } from '~/components/molecule/Tooltip';
import useHover from '~/hooks/useHover';
import type { SystemSize } from '~/theme';
import type { ThemeColor } from '~/theme/System/tokens/colorPalette';

export type Props = {
  dataTestId?: string;
  tooltipMessage: React.ReactNode;
  iconProps?: {
    name: IconType;
    color?: ThemeColor;
    margin?: Array<SystemSize | null>;
  };
  tooltipProps?: TooltipProps;
  containerProps?: JustificationProps;
  /** If true, tooltip is shown onClick instead of onHover. Set to `false` by default */
  showOnClick?: boolean;
};

/** Adds icon to the passed children and show tooltip when hovers the icon */
const withIconTooltip = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  args?: P,
) => {
  // To show nice name in React Dev Tools
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithIconTooltip: React.FCC<Props & P> = ({
    dataTestId,
    tooltipMessage,
    iconProps,
    tooltipProps,
    containerProps,
    showOnClick = false,
    ...rest
  }) => {
    const [showTooltip, hoverProps] = useHover();
    const [showTooltipOnClick, setShowOnClick] = useState<boolean>(false);
    const ref = useRef(null);

    const tooltipVisibilityProps =
      showOnClick === false
        ? hoverProps
        : { onClick: () => setShowOnClick(prev => !prev) };

    return (
      <JustificationContainer
        gap={containerProps?.gap || 'xxs'}
        align={containerProps?.align || 'center'}
        {...containerProps}
      >
        <WrappedComponent dataTestId={dataTestId} {...args} {...(rest as P)} />
        <div>
          <StyledIcon
            {...iconProps}
            ref={ref}
            color={iconProps?.color ?? { group: 'info' }}
            name={
              iconProps && iconProps.name ? iconProps.name : 'question-mark'
            }
            {...tooltipVisibilityProps}
            strokeWidth={2.5}
          />
          {(showTooltip || showTooltipOnClick) && (
            <Tooltip {...tooltipProps}>{tooltipMessage}</Tooltip>
          )}
        </div>
      </JustificationContainer>
    );
  };

  ComponentWithIconTooltip.displayName = `withTooltip(${displayName})`;
  return ComponentWithIconTooltip;
};

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

export default withIconTooltip;
