import { FlowData___FlowInstanceFragment } from '~/graphql/types';
import extractInstanceForCondition from '~/components/page/Automation/v2/state/flowInstance/utils/extractInstanceForCondition';
import getSubject, {
  SubjectMap,
} from '~/components/page/Automation/v2/components/UpdateAction/components/Selector/utils/getSubject';
import type { Flow___ConditionPartial } from '~/components/page/Automation/v2/components/UpdateAction/components/ConditionEditorV2/types.ts';

type GetInstanceForCondition_Args = {
  condition: Flow___ConditionPartial;
  actionId: string;
};
type GetInstanceForCondition_Opts = {
  subjectMap: SubjectMap;
};
const getInstanceForCondition = (
  { condition, actionId }: GetInstanceForCondition_Args,
  { subjectMap }: GetInstanceForCondition_Opts,
): FlowData___FlowInstanceFragment | null => {
  /** We only use subject field conditions to generate instances. */
  if (condition.__typename !== 'Flow___SubjectFieldCondition') return null;

  /**
   * If this is a condition on a trigger we need to give the option to have a condition
   * on this trigger. We add the instance here to make the pointers available.
   */
  const subject = getSubject(
    {
      type: condition.type,
      typeId: 'typeId' in condition ? condition.typeId : undefined,
    },
    subjectMap,
  );

  if (!subject) return null;

  return extractInstanceForCondition(
    {
      condition: condition,
      actionId,
      conditionId: condition.id,
      dirs: [
        {
          key: actionId,
          label: `${subject.label} in deze conditie`,
          weight: Number.MAX_SAFE_INTEGER,
        },
      ],
    },
    {
      subjectMap,
    },
  );
};

export default getInstanceForCondition;
