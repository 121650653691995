import { navigate } from '@gatsbyjs/reach-router';
import React from 'react';
import CommandPalette from 'react-cmdk';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Loading from '~/components/atom/Loading';
import {
  ConnectorOperator,
  useGetContactsV2Query,
  type GetContactsV2QueryVariables,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { pageSelector, paletteState, type PalettePage } from '../../state';
import Icon from '~/components/atom/Icon';

const PAGE_ID: PalettePage = 'open-contacts';

const ContactSearchPage: React.FC<{}> = () => {
  const { search } = useRecoilValue(paletteState);
  const setPage = useSetRecoilState(pageSelector);
  const account = useCurrentAccount();
  const variables: GetContactsV2QueryVariables = {
    filters: {
      connector: ConnectorOperator.And,
      negate: false,
      nodes: [
        {
          Node: {
            connector: ConnectorOperator.And,
            negate: false,
            nodes: [],
          },
        },
      ],
    },
    accountId: account.id,
    // pages are 0 based
    page: 0,
    query: search,
    extend: {
      lastActivity: false,
      leadActivity: false, // get lead activity
      metadata: false, // get lead score
    },
  };

  const { data, loading } = useGetContactsV2Query({
    variables,
  });

  if (loading) {
    return (
      <CommandPalette.Page id={PAGE_ID}>
        <Loading />
      </CommandPalette.Page>
    );
  }

  return (
    <CommandPalette.Page id={PAGE_ID} onEscape={() => setPage('root')}>
      <CommandPalette.List key={PAGE_ID} heading="Contacten zoeken">
        {data?.getContactsV2.items.map(({ id, name, ...rest }, index) => (
          <CommandPalette.ListItem
            key={id}
            index={index}
            icon={() => <Icon name="user" color={{ group: 'secondary' }} />}
            onClick={() => {
              setPage('root');
              void navigate(`/-/contacts/${id}`);
            }}
            showType={false}
            {...rest}
          >
            {name}
          </CommandPalette.ListItem>
        ))}
      </CommandPalette.List>
    </CommandPalette.Page>
  );
};

export default ContactSearchPage;
