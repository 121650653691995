import React from 'react';
import Icon, { IconType } from '~/components/atom/Icon';
import styled, { css, keyframes } from 'styled-components';

import type { SystemSize } from '~/theme/System/tokens';
import { isNil } from 'ramda';
import arrayToCss from '~/util/arrayToCss';
import {
  componentSizes,
  interactiveAppearances,
  type InteractiveAppearance,
  type Size,
} from '~/styles/constants';
import LimitedIcon from '~/components/molecule/LimitedIcon';

export type Direction = 'ltr' | 'rtl';

export type Props = {
  dataTestId?: string;

  size?: Size;
  appearance?: InteractiveAppearance;
  disabled?: boolean;
  /* Replaces the icon for our loading icon and forces the disabled state as well */
  loading?: boolean;
  icon?: IconType;
  label?: React.ReactNode;

  /* Outlined only version */
  ghost?: boolean;

  /* always show the active style */
  active?: boolean;

  /* Changes the order of the icon and the label if both provided */
  direction?: 'ltr' | 'rtl';
  type?: 'button' | 'submit';
  pulse?: boolean;
  margin?: Array<SystemSize | null>;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  /**
   * Adds the LimitedIcon to this button, used when locking down features
   */
  limited?: boolean;
} & React.HtmlHTMLAttributes<HTMLButtonElement>;

const Button = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      ghost = false,
      size = 'small',
      disabled = false,
      appearance = 'primary',
      label,
      dataTestId,
      loading = false,
      direction = 'ltr',
      icon,
      type = 'button',
      pulse = false,
      margin = [null, null, null, null],
      active = false,
      limited = false,
      ...rest
    },
    ref,
  ) => {
    const ButtonImpl = ghost ? GhostButton : DefaultButton;
    const localIcon = loading ? 'spinner' : icon;
    const isDisabled = disabled || loading;

    const props = {
      disabled: isDisabled,
      $size: size,
      $appearance: appearance,
      $direction: direction,
      $shouldPulse: pulse,
      ...rest,
    };

    const onlyIconButton = !isNil(icon) && isNil(label);

    return (
      <ButtonImpl
        {...props}
        type={type}
        $margin={margin}
        $active={active}
        ref={ref}
        $onlyIconButton={onlyIconButton}
        data-testid={dataTestId}
      >
        {limited && <LimitedIcon />}
        {localIcon && (
          <IconContainer $isLoading={loading} $onlyIcon={onlyIconButton}>
            <Icon name={localIcon} strokeWidth={2.5} />
          </IconContainer>
        )}
        {!isNil(label) && <Label>{label}</Label>}
      </ButtonImpl>
    );
  },
);

const Label = styled.span<{}>(
  ({ theme }) => css`
    height: 1em;
    width: max-content;
    font-weight: ${theme.fw('medium')};
  `,
);

const IconContainer = styled.div<{
  $isLoading?: boolean;
  $onlyIcon?: boolean;
}>(
  ({ $isLoading, $onlyIcon }) => css`
    /* Scale the icon to the font-size of the button */
    font-size: 1em;
    /* Removing 'display: flex;' may change baseline alignment.
       If the icon has descenders, its position might shift,
       causing a slight increase in height. */
    display: flex;
    /** Center the icon unless it is a spinner icon, spinner is fine on its own */
    & svg {
      ${!$isLoading &&
      !$onlyIcon &&
      css`
        transform: translateY(-1px);
      `}
    }
  `,
);

export const outline = color => css`
  &:focus {
    box-shadow:
      0 0 0 2px #fff,
      0 0 0 4px ${color};
    outline: 2px solid transparent;
  }
`;

const pulse = keyframes`
from {
  transform: scale(1.5, 2);
}

to {
  transform: scale(1, 1);
}
`;

type BaseButtonProps = {
  $size: Size;
  $direction: Direction;
  $appearance: InteractiveAppearance;
  $shouldPulse: boolean;
  $margin: Array<SystemSize | null>;
  $active: boolean;
  $onlyIconButton?: boolean;
  disabled: boolean;
};

const ButtonBase = styled.button<BaseButtonProps>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-out;

  ${({
    theme,
    $direction,
    disabled,
    $size,
    $appearance,
    $shouldPulse,
    $margin,
    $onlyIconButton,
  }) => {
    const { group, variant } = interactiveAppearances[$appearance];

    return css`
      position: relative;
      gap: ${theme.space('xxs')};
      flex-direction: ${$direction === 'rtl' ? 'row-reverse' : 'row'};
      border-radius: ${theme.getTokens().border.radius.base};
      font-weight: ${theme.fw('semiBold')};
      cursor: ${disabled ? 'not-allowed' : 'pointer'};

      /* Only add margin to buttons of there is margin provided */
      ${$margin.filter(m => !isNil(m)).length !== 0
        ? `margin: ${arrayToCss($margin, theme)};`
        : ''}

      font-size: ${theme.fontSize(componentSizes[$size].fontSize)};
      padding: ${!$onlyIconButton
        ? arrayToCss(componentSizes[$size].padding, theme)
        : theme.space(componentSizes[$size].padding[0])};

      ${$shouldPulse &&
      css`
        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: ${theme.color(group, 'translucent')};
          border-radius: ${theme.getTokens().border.radius.base};
          transform: scale(1.5, 2);
          animation: ${pulse} 0.7s ease-in alternate infinite;
        }
      `}

      ${outline(theme.color(group, variant))};
    `;
  }}
`;

const GhostButton = styled(ButtonBase)<BaseButtonProps>`
  background: none;
  ${({ theme, $appearance, $margin, $active }) => {
    const { group, variant } = interactiveAppearances[$appearance];
    const color = theme.color(group, variant);
    const disabledColor = theme.color('tertiary', 'dark');

    return css`
      border: ${theme.getTokens().border.width.s} solid ${color};
      margin: ${arrayToCss($margin, theme)};

      color: ${$active ? theme.color('white') : color};
      background-color: ${$active ? color : 'transparent'};

      &:hover,
      &:active,
      &:focus {
        &:not([disabled]) {
          background: ${color};
          color: ${theme.color('white')};
        }
      }

      &:disabled {
        border: ${theme.getTokens().border.width.s} solid ${disabledColor};
        color: ${disabledColor};
      }
    `;
  }};
`;

const DefaultButton = styled(ButtonBase)`
  border: none;
  ${({ theme, $appearance }) => {
    const { group, variant } = interactiveAppearances[$appearance];
    const color = theme.color(group, variant);

    return css`
      color: ${theme.color('white')};
      background: ${color};
      /* We need to add the border to default button so they can appear next to a ghost button */
      border: ${theme.getTokens().border.width.s} solid ${color};
      &:hover,
      &:active {
        background: ${theme.color(group, 'dark')};
      }

      &:disabled {
        background: ${theme.color('tertiary', 'dark')};
        border: ${theme.getTokens().border.width.s} solid
          ${theme.color('tertiary', 'dark')};
      }
    `;
  }}
`;

/**
 * Without alignment
 */
export const UnalignedButton = styled(Button)<{}>`
  align-self: inherit;
`;

export default Button;
