import { isNil } from 'ramda';
import { reporter } from '~/hooks/useErrorReporter';
import type { GroupLookup } from '../../components/Apps/components/ToggleAccordion';

/**
 * Returns the item names that are selected to be shown in Umbrella Widget
 * @param {string} appsLookup - Apps Group lookup
 * keywords: selectedItemNames
 */
const getSelectedItemNames = (appsLookup: GroupLookup): Array<string> => {
  const enabledApps = Object.keys(appsLookup).reduce((acc, key) => {
    const app = appsLookup[key];

    if (app.mainChecked === false) return acc;

    if (isNil(app.items) && app.appConfiguration) {
      acc.push(app.name);
    }

    app.items?.forEach(curr => {
      if (curr.checked) {
        try {
          acc.push(curr.name);
        } catch (error) {
          reporter.captureException(error);
        }
      }
    });
    return acc;
  }, [] as Array<string>);

  return enabledApps;
};
export default getSelectedItemNames;
