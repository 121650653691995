import { keys, sort } from 'ramda';
import React from 'react';
import styled from 'styled-components';
import Group from './components/Group';
import type { WidgetSettingsPinned } from '~/graphql/types';
import type { ShareAppArgs } from '~/components/page/Widget/components/Apps';
import groupNameToAnchorId from '~/components/organism/SelectableAccordion/utils/groupNameToAnchorId';

export type AccordionGroup = {
  __typename?: string;
  id: string;
  name: string;
  checked: boolean;
  hidden?: boolean;
  slug: string | null;
  appConfiguration?: string | null;
  pinned?: WidgetSettingsPinned | null;
};

type GroupType = {
  items: Array<AccordionGroup> | null;
  mainChecked: boolean;
  hidden?: boolean;
  name: string;
  weight: number;
  slug: string | null;
  appConfiguration?: string | null;
  pinned?: WidgetSettingsPinned | null;
};

export type GroupLookup = {
  [groupId: string]: GroupType;
};

export type Props = {
  groupLookup: GroupLookup;
  selectable?: boolean;
  onChange: (args: {
    groupId: string;
    groupLookup: {
      items: Array<AccordionGroup> | null;
      mainChecked: boolean;
      hidden?: boolean;
    };
  }) => void;
  onShare: (args: ShareAppArgs) => void;
};

const ToggleAccordion: React.FCC<Props> = ({
  dataTestId,
  groupLookup,
  onChange,
  selectable = true,
  onShare,
  ...rest
}) => {
  const groupIds = keys(groupLookup);

  const sortedGroups = sort(
    (a, b) => groupLookup[a].weight - groupLookup[b].weight,
    groupIds,
  );

  return (
    <Container data-testid={dataTestId} {...rest}>
      {sortedGroups.map((id: string) => {
        if (!groupLookup[id]) return null;

        const { items, name, mainChecked, hidden, slug } = groupLookup[
          id
        ] as GroupType;

        return (
          <Group
            id={id}
            anchorId={groupNameToAnchorId(name)}
            key={id}
            items={items}
            slug={slug}
            checked={mainChecked}
            hidden={hidden}
            name={name}
            onShare={onShare}
            onChange={update => {
              onChange({
                groupId: id,
                groupLookup: {
                  ...groupLookup[id],
                  ...update,
                },
              });
            }}
            selectable={selectable}
          />
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

export default ToggleAccordion;
