import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { navigate } from '@gatsbyjs/reach-router';
import {
  SessionHydrationUserFieldsFragment,
  SessionHydrationUserFields_PendingUser_Fragment,
  useResendUserInvitationMutation,
  UserRole,
  useUpdateOfficeRelationMutation,
} from '~/graphql/types';
import Button from '~/components/atom/Button';
import TEST_ID from './index.testid';
import { Link as NavLink } from '~/components/molecule/Link';
import { convertViewableUserOfficeToListItemForDeletion } from '~/components/page/Settings/Users/utils';
import useCountdown from '~/util/useCountdown';
import useUserRelationToOffice from '~/hooks/useUserRelationToOffice';
import useUserLookup from '~/hooks/useUserLookup';
import {
  ButtonContainer,
  CardElement,
  UserEmailElement,
  UserPhoneElement,
  OfficeCounter,
  OfficeIcon,
  UserNameContainer,
  EnvelopeIcon,
  UserRoleElement,
} from '~/components/page/Settings/Users/components/UserCard.style';
import { isPendingStatus } from '~/util/constants';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useCurrentUser from '~/hooks/useCurrentUser';
import { createOfficeItemForModal } from '~/components/page/Settings/Profile/utils/createOfficeItemForModal';
import Dropdown, { SelectedOptionOf } from '~/components/molecule/Dropdown';
import { isNil } from 'ramda';
import useViewingModeProps from '~/hooks/useViewingModeProps';
import useSessionHydration from '~/hooks/useSessionHydration';
import useOffices from '~/hooks/useOffices';
import formatToastMessage from '~/util/formatToastMessage';
import useAddToast from '~/hooks/useAddToast';
import Catalog from '~/Catalog';
import useConfirmModal from '~/hooks/useConfirmModal';
import type { ListItemForDeletion } from '../../../components/DeleteEntityModals/components/ListItemCard';
import DeleteUserFromOfficeModal from '../../../components/DeleteEntityModals/DeleteUserFromOffice';

type Props = {
  refetch: () => void;

  /** Used in delete user from office modal and getting userToOffice relations */
  officeId: string;

  userId: string;

  /** Can the logged in user interact with buttons and dropdowns */
  canEdit: boolean;
};

const StyledNavLink = styled(NavLink)<{}>(
  ({ theme }) => css`
    color: ${theme.color('primary', 'light')};

    &:hover {
      color: ${theme.color('primary')};
    }
  `,
);

const text = {
  user: 'Gebruiker',
  admin: 'Admin',
  noName: 'Er is geen naam ingevoerd',
  deleteErrorTitle: 'Verwijderen uit vestiging niet mogelijk',
  deleteErrorMessage: (
    <>
      Een gebruiker dient aan tenminste één vestiging gekoppeld te zijn. Ga naar{' '}
      <StyledNavLink to={'/-/settings/users'}>Gebruikers</StyledNavLink> om deze
      gebruiker te wijzigen of te verwijderen.
    </>
  ),
  sendInvitationAgain: 'Uitnodiging opnieuw versturen',
  roleConfirmModal: {
    title: 'Weet je zeker dat je de rol wil aanpassen?',
    message: 'Het is altijd mogelijk om de rol weer terug te zetten.',
    confirmButton: 'Ja ik weet het zeker',
  },
};

type RoleOption = { label: string; key: UserRole; payload: UserRole };
const roleOptions: Array<RoleOption> = [
  {
    label: text.user,
    key: UserRole.User,
    payload: UserRole.User,
  },
  {
    label: text.admin,
    key: UserRole.Admin,
    payload: UserRole.Admin,
  },
];

const isPending = (
  user,
): user is SessionHydrationUserFields_PendingUser_Fragment =>
  user?.__typename === 'PendingUser';

const OfficeUserCard: React.FCC<Props> = ({
  officeId,
  userId,
  canEdit,
  refetch,
}) => {
  const { id: accountId } = useCurrentAccount();
  const addToast = useAddToast();
  const viewingModeProps = useViewingModeProps();
  const { modal: errorModal, setShowModal: setErrorModal } = useConfirmModal({
    labels: { title: text.deleteErrorTitle, message: text.deleteErrorMessage },
    buttons: [],
    level: 'danger',
  });

  const [, refetchSessionHydration] = useSessionHydration();

  const account = useCurrentAccount();
  const me = useCurrentUser();

  const userLookup = useUserLookup(['User', 'PendingUser']) as {
    [userId: string]: SessionHydrationUserFieldsFragment;
  };

  const user = userLookup?.[userId];
  const relation = useUserRelationToOffice({
    officeId,
    userId,
  });

  const offices = useOffices({ userIds: [userId] });

  const [showDeleteUserFromOfficeModal, setShowDeleteUserFromOfficeModal] =
    useState<Array<ListItemForDeletion> | null>(null);

  const handleDeleteUserFromOfficeModalAction = (
    office: Array<ListItemForDeletion> | null,
  ) => {
    setShowDeleteUserFromOfficeModal(office);
  };

  let userDetails: {
    name: string;
    phone: string;
    lastInvitationTime?: SessionHydrationUserFields_PendingUser_Fragment['lastInvitationTime'];
    dataTestId: string;
  };

  if (isPending(user)) {
    userDetails = {
      name: user?.email,
      phone: '---',
      lastInvitationTime: user?.lastInvitationTime,
      dataTestId: 'user-list-item-inactive',
    };
  } else {
    userDetails = {
      name: user?.name,
      phone: user?.phone,
      lastInvitationTime: undefined,
      dataTestId: 'user-list-item-active',
    };
  }

  const hasPendingStatus = isPending(user) || isPendingStatus(relation?.status);
  const { name, phone, lastInvitationTime, dataTestId } = userDetails;

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [resendUserInvitation] = useResendUserInvitationMutation({
    onCompleted: () => refetchSessionHydration(),
  });

  const [secondsLeft] = useCountdown(lastInvitationTime, 60);

  const buttonLabel = `${text.sendInvitationAgain} ${
    secondsLeft ? `(${secondsLeft})` : ''
  }`;

  useEffect(() => {
    setButtonDisabled(!isNil(secondsLeft));
  }, [secondsLeft]);

  const [updateOfficeRelation, { loading }] = useUpdateOfficeRelationMutation();

  const [newRole, setNewRole] = useState<UserRole | null>(null);
  const updateUserRole = async ({ userId, role }) => {
    void updateOfficeRelation({
      variables: {
        accountId,
        officeId,
        userId,
        update: {
          role,
        },
      },
    }).then(({ errors }) => {
      if (errors && errors.length > 0) {
        addToast([
          formatToastMessage(Catalog.genericUnknownErrorMessage, 'danger'),
        ]);
        return;
      }

      return refetch();
    });
  };

  const { setShowModal, modal } = useConfirmModal({
    level: 'warning',
    labels: {
      title: text.roleConfirmModal.title,
      message: text.roleConfirmModal.message,
    },
    buttons: [
      {
        label: text.roleConfirmModal.confirmButton,
        onClick: async () => updateUserRole({ userId, role: newRole }),
        dataTestId: 'modal-confirm-button',
      },
    ],
  });

  return (
    <>
      {modal}
      <CardElement
        data-testid={dataTestId}
        $isPending={hasPendingStatus}
        onClick={() => {
          if (userId === me.id) return navigate('/-/settings/profile');
          if (!hasPendingStatus) void navigate(`/-/settings/users/${userId}`);
          return;
        }}
        data-objectid={userId}
      >
        <UserNameContainer data-testid="user-name" {...viewingModeProps}>
          {hasPendingStatus && (
            <EnvelopeIcon name="mail" title="Invited User" />
          )}
          {name}
        </UserNameContainer>
        <UserPhoneElement data-testid="user-phone" {...viewingModeProps}>
          {phone}
        </UserPhoneElement>
        <UserEmailElement data-testid="user-email" {...viewingModeProps}>
          {user?.email}
        </UserEmailElement>

        <ButtonContainer>
          {lastInvitationTime && (
            <Button
              ghost
              disabled={buttonDisabled || !canEdit}
              onClick={() => {
                setButtonDisabled(true);
                return resendUserInvitation({
                  variables: {
                    userId,
                    accountId: account.id,
                  },
                });
              }}
              data-testid={TEST_ID.RESEND_INVITATION_BUTTON}
              label={buttonLabel}
            />
          )}

          {!lastInvitationTime && (
            <UserRoleElement title="Vestiging role">
              <Dropdown
                dataTestId="user-role"
                options={roleOptions}
                selectedOptionIdx={roleOptions.findIndex(
                  role => role.key === relation?.role,
                )}
                disabled={!canEdit || loading}
                onChange={({ option }: SelectedOptionOf<UserRole>) => {
                  if (!option) return;

                  const newRole = option.payload;
                  if (relation?.role === newRole) {
                    return;
                  } else {
                    setNewRole(newRole);
                    setShowModal(true);
                  }
                }}
              />
            </UserRoleElement>
          )}

          <OfficeCounter>
            <OfficeIcon name="office" />
            <span data-testid="user-officeNumber">{offices.length}</span>
          </OfficeCounter>

          <Button
            margin={[null, null, null, 'base']}
            ghost
            icon="trashcan"
            appearance="danger"
            size="medium"
            dataTestId={TEST_ID.DELETE_USER_BUTTON}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();

              if (offices.length <= 1) {
                setErrorModal(true);
              } else {
                handleDeleteUserFromOfficeModalAction([
                  createOfficeItemForModal(
                    offices.map(convertViewableUserOfficeToListItemForDeletion),
                    officeId ? officeId : '',
                  ),
                ]);
              }
            }}
            disabled={!canEdit}
          />
        </ButtonContainer>
      </CardElement>

      {showDeleteUserFromOfficeModal && (
        <DeleteUserFromOfficeModal
          list={showDeleteUserFromOfficeModal}
          onCancel={() => handleDeleteUserFromOfficeModalAction(null)}
          onClose={() => handleDeleteUserFromOfficeModalAction(null)}
          userId={userId}
        />
      )}

      {errorModal}
    </>
  );
};

export default OfficeUserCard;
