import React from 'react';
import styled, { css } from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';

export type Props = {
  termsOfUseText: React.ReactNode;
};

const text = {
  title: 'Gebruiksvoorwaarden',
};

const TermsOfUse: React.FCC<Props> = ({ dataTestId, termsOfUseText }) => (
  <>
    <Title>{text.title}</Title>
    <Container data-testid={dataTestId} margin={[null]}>
      {termsOfUseText}
    </Container>
  </>
);

const MAX_HEIGHT = 500;
const Title = styled.div(
  ({ theme }) => css`
    font-size: ${theme.fontSize('s')};
    margin-bottom: ${theme.space('xxxs')};
  `,
);

const Container = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    border: ${theme.getTokens().border.width.s} solid
      ${theme.color('tertiary', 'base')};
    padding: ${theme.space('xxs')};
    cursor: default;
    border-radius: ${theme.getTokens().border.radius.s};
    max-height: ${MAX_HEIGHT}px;
    overflow-y: scroll;
  `,
);

export default TermsOfUse;
