import Catalog from '~/Catalog';
import formatToastMessage, {
  FormattedToastMessage,
} from '~/util/formatToastMessage';
import { ImageUploadError } from '~/util/uploadS3Image';

const text = {
  invalidSize: 'De afbeelding mag maximaal 10mb groot zijn.',
  invalidType:
    'Bestandstype is niet geldig, probeer een JPG, JPEG, PNG, GI, WEBP of SVG.',
};

/**
 * Returns toast message for image upload error
 * @param {string} message - Thrown error message
 * keywords: imageError, uploadError
 */
const getErrorMessage = (message: string): FormattedToastMessage => {
  let toastMessage = Catalog.genericUnknownErrorMessage;

  if (message.includes(ImageUploadError.InvalidFileSize)) {
    toastMessage = text.invalidSize;
  }

  if (message.includes(ImageUploadError.InvalidFileType)) {
    toastMessage = text.invalidType;
  }

  return formatToastMessage(toastMessage, 'danger');
};

export default getErrorMessage;
