import {
  RouteComponentProps,
  useLocation,
  WindowLocation,
} from '@gatsbyjs/reach-router';

import React from 'react';

import FullPageInformation from '~/components/template/FullPageInformation';
import Validation from '~/util/Validation';
import parseStateString from './utils/parseStateString';
import mutationVariablesForState from './utils/mutationVariablesForState';
import StartEmailSyncComponent from './components/StartEmailSyncComponent';
import PassThroughComponent from './components/PassThroughComponent';

const text = {
  invalidStateTitle: 'Oeps!',
  close: 'Sluiten',
  syncError:
    'Er is iets misgegaan bij het synchroniseren van het e-mail account. Sluit deze venster en probeer het nog eens.',
};

export type Props = {};

const Nylas: React.FCC<RouteComponentProps> = () => {
  const location = useLocation() as WindowLocation<{
    code: string;
    stateString: string;
  }>;

  if (!location.state) return <PassThroughComponent location={location} />;

  const { code, stateString } = location.state;

  const props = {
    explanation: text.syncError,
    title: text.invalidStateTitle,
    button: { onClick: () => window.close(), label: text.close },
  };

  if (
    !Validation.String.isNonEmptyString(stateString) ||
    !Validation.String.isNonEmptyString(code)
  ) {
    return <FullPageInformation {...props} />;
  }

  const state = parseStateString(stateString);

  if (state === null) {
    return <FullPageInformation {...props} />;
  }

  const mutationVariables = mutationVariablesForState(state, code);

  if (mutationVariables === null) {
    return <FullPageInformation {...props} />;
  }

  return <StartEmailSyncComponent state={state} code={code} />;
};
export default Nylas;
