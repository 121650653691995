import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import Validation from '~/util/Validation';

import Button from '~/components/atom/Button';
import Dropdown from '~/components/molecule/Dropdown';
import { Form, Field, EmailField } from '~/components/organism/Forms';
import Catalog from '~/Catalog';
import { OfficeOptions } from '~/hooks/useOfficeOptions';
import { useInviteUsersToOfficeMutation } from '~/graphql/types';
import FormUtils from '~/components/organism/FormUtils';
import Drawer from '~/components/organism/Drawer';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Input from '~/components/molecule/Input';
import { Body } from '~/components/atom/Typography';
import withDelayedLoading, {
  type WithDelayedLoading,
} from '~/hocs/withDelayedLoading';

type FormData = {
  email: string | null | undefined;
  officeId: string | null | undefined;
};

type MyProps = {
  officeOptions: OfficeOptions;
  accountId: string;

  /** Called when the user is successfully added */
  onUserAdded?: () => void;
  isShowing: boolean;
  hide: () => void;
};
type Props = WithDelayedLoading & MyProps;

export const text = {
  emailLabel: Catalog.emailLabel,
  officeLabel: Catalog.officeLabel,
  addMore: 'voeg meer toe',
  submit: 'Uitnodigen',
  noEmailEntered: Catalog.noEmail,
  noOfficeSelected: Catalog.noOffice,
  invalidEmail: Catalog.invalidEmail,
  generalErrorMsg: Catalog.genericUnknownErrorMessage,
  asideTitle: 'Gebruiker toevoegen',
  asidePara1: 'Voeg hier collegas toe aan het system.',
  asidePara2:
    'Admins kunnen ook gebruikers toevoegen en wijzigingen aanbrengen in bijvoorbeeld de apps & vestigingen.',
  disclaimer: Catalog.mayIncreaseCostsWarning('Gebruikers'),
};

const AddUserSidebar: React.FC<Props> = ({
  officeOptions,
  accountId,
  onUserAdded,
  isShowing,
  hide,
  ...props
}) => {
  const [responseError, setResponseError] = useState(false);

  const onCompleted = (result: $Object) => {
    props.removeLoading();
    const anyError =
      result.inviteUsersToOffice.length && result.inviteUsersToOffice[0].error;

    if (anyError) {
      setResponseError(true);
    } else {
      hide();
    }
  };

  const onError = () => {
    setResponseError(true);
    props.removeLoading();
  };

  const validate = (fields: FormData) => {
    const errors: FormData = { email: undefined, officeId: undefined };

    if (Validation.String.isEmpty(fields.email))
      errors.email = text.noEmailEntered;
    if (Validation.String.isEmpty(fields.officeId))
      errors.officeId = text.noOfficeSelected;
    if (fields.email && !Validation.Email.isValid(fields.email))
      errors.email = text.invalidEmail;

    return errors;
  };

  const [inviteUsersToOffice, { error: mutationError, loading }] =
    useInviteUsersToOfficeMutation({
      onError,
      onCompleted: (result: $Object) => {
        onCompleted(result);
        if (onUserAdded) onUserAdded();
      },
    });

  const handleSubmit = async (formData: FormData) => {
    const officeId = formData.officeId ? formData.officeId : '';
    const users = formData.email ? [formData.email.toLocaleLowerCase()] : [];

    props.setLoading();
    return inviteUsersToOffice({
      variables: {
        accountId,
        officeId,
        users,
      },
    });
  };

  return (
    <Drawer active={isShowing} hideSidebar={hide} title={text.asideTitle}>
      <p>{text.asidePara1}</p>
      <p>{text.asidePara2}</p>

      <Form
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={{
          email: null,
          officeId:
            officeOptions.length === 1 ? officeOptions[0]?.payload?.id : null,
        }}
        data-testid="add-user-form"
      >
        {({ handleSubmit, submitError, submitting, pristine }) => {
          const newUserError =
            mutationError || submitError || responseError
              ? text.generalErrorMsg
              : false;

          return (
            <form onSubmit={handleSubmit} data-testid="add-user-form">
              {newUserError && (
                <ErrorMsg data-testid="add-user-error">{newUserError}</ErrorMsg>
              )}

              <JustificationContainer
                width="100%"
                gap="s"
                direction="column"
                margin={[null, null, 'base', null]}
              >
                <EmailField name="email">
                  {({ input, meta: { error, touched } }) => (
                    <Input
                      required
                      width="100%"
                      size="large"
                      label={{ text: text.emailLabel }}
                      externalErrors={
                        FormUtils.showError(error, touched)
                          ? [FormUtils.showError(error, touched)]
                          : []
                      }
                      {...input}
                      dataTestId="user-email-input"
                      autoComplete="new-email"
                      disabled={loading}
                    />
                  )}
                </EmailField>

                <Field name="officeId">
                  {({ input, meta: { error, touched } }) => (
                    <Dropdown
                      name="office"
                      dataTestId="office-dropdown"
                      label={text.officeLabel}
                      size="large"
                      options={officeOptions}
                      selectedOptionIdx={officeOptions.findIndex(option => {
                        const nextOfficeId = option.payload?.id ?? null;
                        return nextOfficeId === input.value;
                      })}
                      error={FormUtils.showError(error, touched)}
                      {...input}
                      onChange={({ option }) =>
                        input.onChange(option.payload.id)
                      }
                      disabled={loading}
                      required
                    />
                  )}
                </Field>
              </JustificationContainer>

              <ActionWrapper>
                <Button
                  size="large"
                  loading={props.loading}
                  onClick={handleSubmit}
                  appearance="secondary"
                  type="submit"
                  disabled={submitting || pristine || loading}
                  dataTestId="add-new-user-submit"
                  label={text.submit}
                />
              </ActionWrapper>
              <Body margin={['s', null, null, null]} size="s" italic>
                {text.disclaimer}
              </Body>
            </form>
          );
        }}
      </Form>
    </Drawer>
  );
};

const ActionWrapper = styled.div<{}>`
  display: flex;
  align-items: center;

  & button {
    margin-left: auto;
  }
`;

const ErrorMsg = styled.div<{}>`
  ${({ theme }) => css`
    color: ${theme.color('danger')};
  `};
`;

export default withDelayedLoading<MyProps>(AddUserSidebar);
