import React from 'react';
import styled from 'styled-components';

import Loading from '~/components/atom/Loading';
import AppErrorScreen from '~/components/template/AppErrorScreen';
import Catalog from '~/Catalog';
import ErrorTypes from '~/ErrorTypes';
import { getErrorTypes } from '~/util/errorHandling';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { useGetExtendedEmailQuery } from '~/graphql/types';

const text = {
  unknownErrorMessage: Catalog.genericUnknownErrorMessage,
  notSyncedErrorMessage:
    'De volledige e-mail is nog niet beschikbaar, probeer het later nog eens.',
  mailboxNotFoundErrorMessage:
    'De volledige e-mail kan niet worden weergegeven. Mogelijk is deze verwijderd of verplaatst.',
};
type GetFullEmailMessageProps = {
  eventId: string;
  renderProp: (extendedEmail: string) => JSX.Element | null;
};
export const GetFullEmailMessage: React.FCC<GetFullEmailMessageProps> = ({
  eventId,
  renderProp,
}) => {
  const account = useCurrentAccount();

  const { data, loading, error } = useGetExtendedEmailQuery({
    variables: {
      accountId: account.id,
      id: eventId,
    },
    fetchPolicy: 'cache-first',
  });

  if (loading) return <Loading />;

  if (!data || !data.getExtendedEmail || error) {
    const errorTypes = getErrorTypes(error);
    let errorMessage = text.unknownErrorMessage;

    if (errorTypes.includes(ErrorTypes.emailNotSynced)) {
      errorMessage = text.notSyncedErrorMessage;
    }

    if (errorTypes.includes(ErrorTypes.notFoundError)) {
      errorMessage = text.mailboxNotFoundErrorMessage;
    }

    return (
      <AppErrorScreen
        inline
        message={errorMessage}
        setBackgroundColor={false}
      />
    );
  }

  return <Container>{renderProp(data.getExtendedEmail.body)}</Container>;
};

type Props = {
  eventId: string;
};
const FullEmailMessage: React.FCC<Props> = ({ eventId }) => (
  // We can safely set the html as backend has already sanitized it
  <GetFullEmailMessage
    eventId={eventId}
    renderProp={extendedEmail => (
      <MailHTMLContainer
        className={'dh-email-container'}
        dangerouslySetInnerHTML={{ __html: extendedEmail }}
      />
    )}
  />
);
const MailHTMLContainer = styled.span<{}>`
  border: ${({ theme }) => theme.getTokens().border.width.s} solid transparent;
`;

const Container = styled.div<{}>`
  min-height: 50px;
`;

export default FullEmailMessage;
