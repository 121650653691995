import { GetMyAccountsQueryResult } from '~/graphql/types';
import {
  ACCOUNT_ID_CURRENTLY_IMPERSONATED,
  SELECTED_ACCOUNT_STORAGE_KEY,
} from '../localStorageKeys';

const getCurrentAccount = (
  data: GetMyAccountsQueryResult['data'],
): string | null => {
  if (!data || data.getMyAccounts.length === 0) return null;
  const accounts = data.getMyAccounts;
  const selectedAccountId = global.localStorage.getItem(
    SELECTED_ACCOUNT_STORAGE_KEY,
  );

  // This gets added the moment impersonation starts and gets removed when impersonation is stopped
  const currentlyImpersonatedAccount = global.localStorage.getItem(
    ACCOUNT_ID_CURRENTLY_IMPERSONATED,
  );

  if (currentlyImpersonatedAccount) return currentlyImpersonatedAccount;
  if (selectedAccountId && accounts.find(acc => acc.id === selectedAccountId))
    return selectedAccountId;

  const headAccountId = accounts[0].id;
  global.localStorage.setItem(SELECTED_ACCOUNT_STORAGE_KEY, headAccountId);
  return headAccountId;
};

export default getCurrentAccount;
