import React, { useState, useEffect } from 'react';
import { FORM_ERROR } from 'final-form';
import { Helmet as MetaTags } from 'react-helmet';
import { isNil } from 'ramda';
import { navigate, RouteComponentProps } from '@gatsbyjs/reach-router';
import { Form, Field, EmailField } from '~/components/organism/Forms';
import Amplify from '~/amplify';
import Validation from '~/util/Validation';
import PasswordInputGroup from '~/components/page/Auth/components/PasswordInputGroup';
import FormUtils from '~/components/organism/FormUtils';
import Catalog from '~/Catalog';
import { useLocation } from '@gatsbyjs/reach-router';
import TEST_ID from '~/components/page/Auth/Register/index.testid';
import styled, { css } from 'styled-components';
import {
  SIGNUP_ID,
  SIGNUP_PARAMS,
  TAPFILLIATE_REF,
  setLocalStorageItem,
} from '~/util/localStorageKeys';
import { isValidSignupParams } from '~/hooks/useSignupParams';
import Button from '~/components/atom/Button';
import { isEmpty } from 'lodash';
import { reporter } from '~/hooks/useErrorReporter';
import { Body, Heading1 } from '~/components/atom/Typography';
import CheckboxLabel from '~/components/page/Auth/Register/components/CheckboxLabel/index';
import Checkbox from '~/components/molecule/Checkbox';
import useStoreUtmParams from '~/hooks/useStoreUtmParams';
import JustificationContainer from '~/components/atom/JustificationContainer';
import AuthFormWrapper from '../components/AuthFormWrapper';
import usePasswordValidation from '~/hooks/usePasswordValidation';
import Input from '~/components/molecule/Input';
import UnauthNav from '~/components/organism/UnauthNav';
import createPageTitle from '~/util/createPageTitle';
import useDelayedLoading from './hooks/useDelayedLoading';

type FormData = {
  name: string | null;
  password: string | null;
  passwordRepeat: string | null;
  email: string | null;
  phone: string | null;
};

const text = {
  noName: Catalog.noName,
  nameLabel: 'Voor- en achternaam',
  invalidEmail: Catalog.invalidEmail,

  unknownError: Catalog.genericUnknownErrorMessage,
  invalidPasswordFromCognito:
    'Het wachtwoord wordt niet geaccepteerd, probeer een ander wachtwoord',
  invalidEmailFromCognito:
    'Het e-mailadres wordt niet geaccepteerd, probeer een ander e-mailadres',
  title: 'Registreren',
  emailLabel: Catalog.emailLabel,
  phoneLabel: Catalog.phoneLabel,
  invalidPhone: Catalog.invalidPhone,
  noPhone: Catalog.noPhone,
  passwordLabel: Catalog.passwordLabel,
  passwordRepeatLabel: Catalog.passwordRepeatLabel,
  registerButton: 'Registreren',
  userAlreadyExists:
    'Er bestaat al een gebruiker met dit e-mailadres. Je kunt met dit e-mailadres inloggen.',
};

const Register: React.FCC<RouteComponentProps> = () => {
  useStoreUtmParams();

  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [loading, startLoading, stopLoading] = useDelayedLoading();
  const [title, setTitle] = useState<string>(text.title);

  const { validatePassword, passwordConditions } = usePasswordValidation();
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  /** Check the location to see if a signupcode or name is used */
  const signupCode = params.get('signupCode');

  useEffect(() => {
    const tapfillateRef = params.get('ref');
    const signupId = params.get('signupId');
    if (tapfillateRef) {
      setLocalStorageItem(
        TAPFILLIATE_REF,
        JSON.stringify({ date: Date.now(), ref: tapfillateRef }),
      );
    }

    if (signupId) {
      setLocalStorageItem(SIGNUP_ID, JSON.stringify({ signupId }));
    }

    const accountName = params.get('accountName');
    if (accountName != null) {
      setTitle(`Registreer ${accountName}`);
    }

    const couponCode = params.get('couponCode');

    const signupParamsInURL = { couponCode };
    if (isValidSignupParams(signupParamsInURL)) {
      setLocalStorageItem(SIGNUP_PARAMS, JSON.stringify(signupParamsInURL));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validate = ({
    name,
    email,
    phone,
    password,
    passwordRepeat,
  }: FormData) => {
    const errors: {
      name: string | undefined;
      email: string | undefined;
      phone: string | undefined;
      password: string | undefined;
      passwordRepeat: string | undefined;
    } = {
      name: undefined,
      email: undefined,
      phone: undefined,
      password: undefined,
      passwordRepeat: undefined,
    };
    if (Validation.String.isEmpty(name)) {
      errors.name = text.noName;
    }
    if (Validation.String.isEmpty(phone)) {
      errors.phone = text.noPhone;
    }
    if (!Validation.Phone.isValid(phone)) {
      errors.phone = text.invalidPhone;
    }
    if (!Validation.Email.isValid(email)) {
      errors.email = text.invalidEmail;
    }

    const passwordErrors = validatePassword({ password, passwordRepeat });

    return { ...errors, ...passwordErrors };
  };

  const onFormSubmit = ({ name, email, password, phone }: FormData) => {
    if (email === null || password === null) return;
    startLoading();
    const lowerCaseEmail = email.toLocaleLowerCase();
    const signupAttributes = {
      name,
      'custom:phone': phone,
      'custom:acceptedTNCDate': new Date().toISOString(),
    };

    if (isValidSignupCode(signupCode)) {
      signupAttributes['custom:signupCode'] = signupCode;
    }

    const signupOptions = {
      username: lowerCaseEmail,
      password,
      attributes: signupAttributes,
    };

    return Amplify.Auth.signUp(signupOptions)
      .then((result: any) => {
        if (!result.userConfirmed) {
          void navigate('/verify', {
            state: { email: lowerCaseEmail },
          });
          return;
        }
        stopLoading();
        void navigate('/login');
      })
      .catch(err => {
        stopLoading();
        if (err.code === 'UserLambdaValidationException') {
          return { [FORM_ERROR]: text.invalidEmailFromCognito };
        }
        if (err.code === 'InvalidPasswordException') {
          // report this to us, we should always validate the password beforehand so if we get this error something else went wrong
          reporter.captureException(err);
          return {
            [FORM_ERROR]: text.invalidPasswordFromCognito,
          };
        }
        if (err.code === 'UsernameExistsException') {
          return {
            [FORM_ERROR]: text.userAlreadyExists,
          };
        }
        reporter.captureException(err);
        return { [FORM_ERROR]: text.unknownError };
      });
  };

  return (
    <>
      <MetaTags>
        <title>{createPageTitle(title)}</title>
        <meta name="description" content="Maak een nieuw DatHuis account aan" />
      </MetaTags>

      <UnauthNav
        buttons={[
          {
            label: 'Inloggen',
            onClick: () => navigate('/login'),
            appearance: 'primary',
            size: 'large',
          },
        ]}
      />

      <AuthFormWrapper>
        <Heading1
          lineHeight="base"
          margin={['l', null, 'base', null]}
          color={{ group: 'accent' }}
          size="xxxxl"
          skewed
        >
          {title}
        </Heading1>

        <Form
          initialValues={initialValues}
          onSubmit={onFormSubmit}
          validate={validate}
        >
          {({ handleSubmit, submitError, submitting, pristine }) => (
            <form
              id="register"
              onSubmit={handleSubmit}
              data-testid={TEST_ID.REGISTER_FORM}
            >
              {submitError ? (
                <Body
                  size="base"
                  margin={[null]}
                  color={{ group: 'danger', variant: 'light' }}
                  dataTestId={TEST_ID.REGISTER_ERROR_MESSAGE}
                >
                  {submitError}
                </Body>
              ) : null}
              <JustificationContainer align="center" margin={['m', null]}>
                <Field name="name">
                  {({ input, meta: { error, touched } }) => (
                    <Input
                      {...input}
                      autoFocus
                      width="100%"
                      size="large"
                      label={{
                        text: text.nameLabel,
                        color: { group: 'white' },
                      }}
                      disabled={submitting}
                      appearance="secondary"
                      externalErrors={
                        error && touched
                          ? [FormUtils.showError(error, touched)]
                          : undefined
                      }
                    />
                  )}
                </Field>
              </JustificationContainer>
              <JustificationContainer align="center" margin={['m', null]}>
                <EmailField name="email">
                  {({ input, meta: { error, touched } }) => (
                    <Input
                      {...input}
                      size="large"
                      width="100%"
                      label={{
                        text: text.emailLabel,
                        color: { group: 'white' },
                      }}
                      disabled={submitting}
                      externalErrors={
                        error && touched
                          ? [FormUtils.showError(error, touched)]
                          : undefined
                      }
                      {...input}
                    />
                  )}
                </EmailField>
              </JustificationContainer>
              <JustificationContainer align="center" margin={['m', null]}>
                <Field name="phone">
                  {({ input, meta: { error, touched } }) => (
                    <Input
                      {...input}
                      width="100%"
                      size="large"
                      label={{
                        text: text.phoneLabel,
                        color: { group: 'white' },
                      }}
                      disabled={submitting}
                      externalErrors={
                        error && touched
                          ? [FormUtils.showError(error, touched)]
                          : undefined
                      }
                    />
                  )}
                </Field>
              </JustificationContainer>

              <PasswordInputGroup
                labelColor={{ group: 'white' }}
                passwordText={text.passwordLabel}
                repeatText={text.passwordRepeatLabel}
                passwordConditions={passwordConditions}
                submitting={submitting}
              />

              <JustificationContainer align="center" margin={['m', null]}>
                <Checkbox
                  onChange={() => setTermsAccepted(!termsAccepted)}
                  value={termsAccepted}
                  labelComponent={<CheckboxLabel />}
                  name="termsAccepted"
                  dataTestId={TEST_ID.TERMS_AND_CONDITIONS_CHECKBOX}
                />
              </JustificationContainer>
              <JustificationContainer align="center" justification="end">
                <FullWidthButton
                  appearance="primary"
                  type="submit"
                  loading={loading}
                  disabled={submitting || !termsAccepted || pristine || loading}
                  dataTestId={TEST_ID.SUBMIT_PASSWORD_BUTTON}
                  label={text.registerButton}
                  size="large"
                  style={{ justifyContent: 'center' }}
                />
              </JustificationContainer>
            </form>
          )}
        </Form>
      </AuthFormWrapper>
    </>
  );
};

const isValidSignupCode = (signupCode: string | null | undefined) => {
  if (isNil(signupCode)) return false;
  if (isEmpty(signupCode)) return false;
  return true;
};

const FullWidthButton = styled(Button)<{}>(
  () => css`
    justify-content: left;
    width: 100%;
  `,
);

const initialValues: FormData = {
  name: null,
  password: null,
  passwordRepeat: null,
  email: null,
  phone: null,
};

export default Register;
