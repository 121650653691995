import React, { useLayoutEffect } from 'react';
import queryString from 'query-string';

import FullPageInformation from '~/components/template/FullPageInformation';
import { WindowLocation, navigate } from '@gatsbyjs/reach-router';
import useErrorReporter from '~/hooks/useErrorReporter';

export type Props = {
  location: WindowLocation;
};

const text = {
  invalidStateTitle: 'Oeps!',
  invalidState:
    'Er is iets misgegaan bij het synchroniseren van het e-mail account. Probeer het nog eens.',
  close: 'Sluit deze venster',
};

/**
 * Helps to set the state from the address bar
 */
const PassThroughComponent: React.FCC<Props> = ({ location }) => {
  const errorReporter = useErrorReporter();

  const parsedSearchObject = queryString.parse(location.search ?? '');
  const stateString = parsedSearchObject.state;
  const code = parsedSearchObject.code;

  if (!stateString || !code) {
    errorReporter.captureException(
      new Error(
        `stateString or code is missing in Nylas. location.search: ${
          location.search
        } | parsedSearchObject: ${JSON.stringify(parsedSearchObject)}`,
      ),
      'debug',
    );

    return (
      <FullPageInformation
        title={text.invalidStateTitle}
        explanation={text.invalidState}
        button={{ onClick: () => window.close(), label: text.close }}
      />
    );
  }

  return <NavigaterComponent state={{ code, stateString }} />;
};

const NavigaterComponent: React.FC<{
  state: {
    code: string | Array<string | null> | null;
    stateString: string | Array<string | null>;
  };
}> = ({ state }) => {
  useLayoutEffect(() => {
    void navigate(location.pathname, {
      replace: true,
      state,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default PassThroughComponent;
