import React from 'react';
import styled, { css } from 'styled-components';
import { text } from '../../utils/getLabelForRep';
import getLabelForRep from '../../utils/getLabelForRep';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { assertNever } from '~/util/assertion';
import { splitAt } from 'ramda';
import ConditionArgumentContainer from './components/ConditionArgumentContainer';
import ConditionButtonV2 from './components/ConditionButtonV2';
import { getCondition } from '../../../Selector/utils/getConditions';
import getSubject from '../../../Selector/utils/getSubject';
import isActiveCondition from '../../utils/isActiveCondition';
import { Props as ConditionGroupProps } from '../ConditionGroup';
import type { Flow___ConditionPartial } from '../../types';
import activeSelectorState from '~/components/page/Automation/v2/state/conditionEditor';
import { useRecoilValue } from 'recoil';

export type Props = Pick<
  ConditionGroupProps,
  | 'context'
  | 'conditionGroupIdx'
  | 'conditionSubExpressionIdx'
  | 'textObject'
  | 'inputListPrimitives'
  | 'inputPrimitives'
  | 'maps'
> & {
  dataTestId?: string;

  conditionIdx: number;
  /** Condition to render */
  condition: Flow___ConditionPartial | null;
  /** Label shown when no condition is selected */
  emptyConditionLabel: string | React.ReactNode;

  /**
   * Determines the layout of this condition
   *
   * "complete": Render the whole condition including the input
   * "onlyArguments": Render only the arguments, used mostly in groupings
   */
  layout: 'complete' | 'onlyArguments';

  isLast: boolean;
  /**
   * This condition can be deleted.
   */
  isDeletable: boolean;

  /** The group this condition is a part of is currently being hovered */
  groupHover: boolean;

  /** Callback when this condition is removed - only relevant when `isDeletable: true` */
  onDelete?: (args: {
    conditionSubExpressionIdx: number;
    conditionGroupIdx: number;
    conditionIdx: number;
  }) => void;
};

const Condition: React.FC<Props> = ({
  conditionSubExpressionIdx,
  conditionGroupIdx,
  conditionIdx,
  condition,
  emptyConditionLabel,
  layout,
  isDeletable,
  onDelete,
  groupHover,
  isLast,
  maps,
  context,
}) => {
  const activeSelector = useRecoilValue(activeSelectorState);
  if (!condition) {
    return (
      <StyledJustificationContainer>
        <ConditionButtonV2
          argumentId={{
            conditionSubExpressionIdx,
            conditionGroupIdx,
            conditionIdx,
            /** First-time condition selection - opens empty selector */
            argumentIdx: undefined,
          }}
          label={emptyConditionLabel}
          type={{ type: 'empty' }}
        />
      </StyledJustificationContainer>
    );
  }

  const flowData = (() => {
    if (condition.__typename === 'Flow___InstanceCondition') {
      return getCondition(condition.type, maps.conditionMap) ?? null;
    }

    return (
      getSubject(
        { type: condition.type, typeId: condition.typeId },
        maps.subjectMap,
      )?.fields.find(({ key }) => condition.field === key) ?? null
    );
  })();

  if (!flowData) {
    /**
     * We are missing the appropriate flow data
     * - This condition does not exist anymore ?
     */
    return (
      <StyledJustificationContainer>
        <ConditionButtonV2
          argumentId={{
            conditionSubExpressionIdx,
            conditionGroupIdx,
            conditionIdx,
            /** Reselect condition type - condition exists but is invalid */
            argumentIdx: activeSelector?.argumentIdx ?? 0,
          }}
          label={text.invalidVariable}
          type={{ type: 'empty', hasError: true }}
        />
      </StyledJustificationContainer>
    );
  }

  const conditionArgumentsInfo = (():
    | {
        hasArguments: false;
      }
    | {
        hasArguments: true;
        firstArgumentIndex: number;
        lastArgumentIndex: number;
      } => {
    const firstArgumentIndex = flowData.representation.findIndex(
      ({ variable }) => !!variable,
    );

    const lastArgumentIndex = [...flowData.representation]
      .reverse()
      .findIndex(({ variable }) => !!variable);

    const hasNoArguments = firstArgumentIndex === -1;
    const argumentsEndIndex =
      flowData.representation.length - lastArgumentIndex;

    if (hasNoArguments) return { hasArguments: false };

    return {
      hasArguments: true,
      firstArgumentIndex,
      lastArgumentIndex: argumentsEndIndex,
    };
  })();

  const representations = (() => {
    switch (layout) {
      case 'complete':
        return flowData.representation;
      case 'onlyArguments': {
        if (!conditionArgumentsInfo.hasArguments) return [];

        return flowData.representation.slice(
          conditionArgumentsInfo.firstArgumentIndex,
        );
      }

      default:
        return assertNever(layout);
    }
  })();

  const representationsWithLabels = representations.map(representation => ({
    representation,
    label: getLabelForRep(
      {
        representation,
        condition,
        ctx: context,
      },
      maps,
    ),
  }));

  const representationElements = representationsWithLabels.map(
    ({ representation: { variable }, label: labelData }, repIndex) => {
      const key = `${variable ?? ''}-${repIndex}`;
      const isArgument = (variable ?? 0) > 0;
      const hasError = labelData.error != null;
      const label = hasError ? labelData.error : labelData.result;
      const type = labelData.type;

      if (variable == null) {
        if (!label) return null;
        /**
         * No variable - only text.
         * If we render an input variable here (is always 0) just show it as text.
         */
        return (
          <NonVariableRepresentation key={key}>
            {label}
          </NonVariableRepresentation>
        );
      }

      if (!isArgument && layout === 'onlyArguments') return null;

      return (
        <ConditionButtonV2
          key={key}
          argumentId={{
            conditionSubExpressionIdx,
            conditionGroupIdx,
            conditionIdx,
            argumentIdx: variable,
          }}
          label={label}
          type={type}
        />
      );
    },
  );

  const {
    beforeConditionArguments,
    conditionArguments,
    afterConditionArguments,
  } = (() => {
    if (!conditionArgumentsInfo.hasArguments) {
      return {
        beforeConditionArguments: representationElements,
        conditionArguments: [],
        afterConditionArguments: [],
      };
    }

    const [beforeConditionArguments, rest] = splitAt(
      layout === 'onlyArguments'
        ? 0
        : conditionArgumentsInfo.firstArgumentIndex,
      representationElements,
    );

    const [conditionArguments, afterConditionArguments] = splitAt(
      conditionArgumentsInfo.lastArgumentIndex -
        conditionArgumentsInfo.firstArgumentIndex,
      rest,
    );

    return {
      beforeConditionArguments,
      conditionArguments,
      afterConditionArguments,
    };
  })();

  const activeCondition =
    isActiveCondition({
      activeSelector,
      argumentId: {
        conditionSubExpressionIdx,
        conditionGroupIdx,
        conditionIdx,
      },
    }) && !!activeSelector?.argumentIdx;

  const elements = [...beforeConditionArguments];

  if (conditionArguments.length) {
    elements.push(
      <ConditionArgumentContainer
        key={'deletable-container'}
        onDelete={() =>
          onDelete?.({
            conditionSubExpressionIdx,
            conditionGroupIdx,
            conditionIdx,
          })
        }
        isDeletable={isDeletable}
        isActiveCondition={activeCondition}
        show={groupHover}
      >
        <StyledJustificationContainer>
          {conditionArguments}
        </StyledJustificationContainer>
      </ConditionArgumentContainer>,
    );
  }

  if (isLast) {
    elements.push(...afterConditionArguments);
  }

  return <>{elements}</>;
};

const StyledJustificationContainer: React.FC<
  $Props<typeof JustificationContainer>
> = props => (
  <JustificationContainer
    justification="start"
    align="center"
    wrap="wrap"
    gap="xs"
    {...props}
  />
);

const NonVariableRepresentation = styled.span(
  ({}) => css`
    white-space: 'nowrap';
  `,
);

export default Condition;
