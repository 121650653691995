import type { ConditionExpression, Flow___ConditionPartial } from '../../types';

/**
 * Get all Triggers for a given condition expression.
 */
const getTriggers = (
  condition: ConditionExpression,
): Array<Flow___ConditionPartial> => {
  const triggers = condition.conditionSubExpression
    .map(
      subExpression =>
        subExpression.conditionGroups?.[0]?.conditions?.[0] ?? null,
    )
    .filter((trigger): trigger is Flow___ConditionPartial => !!trigger);

  return triggers;
};

export default getTriggers;
