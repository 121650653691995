import { all, compose, prop, sortBy, toLower } from 'ramda';
import { AppConfigurationFragment } from '~/graphql/types';
import hasValue from '~/util/hasValue';
import type { GroupLookup } from '../../components/Apps/components/ToggleAccordion';

/**
 * returns groupLookup for SelectableAccordion
 * @param {Array<AppsConfigurationFragment>} apps
 */

const lowerCaseName = compose(toLower, prop('name'));
const sortByName = sortBy(lowerCaseName);

const getAppsGroupLookup = (
  apps: Array<AppConfigurationFragment>,
): GroupLookup => {
  if (apps.length === 0) return {};

  const sortedApps = sortByName(apps) as Array<AppConfigurationFragment>;

  const groupLookup: GroupLookup = apps.reduce((acc, app) => {
    if (app.items && app.items.length === 0) return acc;

    const { name, enabled, items, type, appConfiguration, pinned, hidden } =
      app;

    const hasItems = (items && items.length > 0) ?? false;
    const isGroupHidden = all(item => item.hidden === true, items || []);

    acc[type] = {
      name,
      pinned,
      mainChecked: enabled,
      // If the group has items, we will be using item slug, otherwise use group slug
      slug:
        hasItems || !appConfiguration ? null : getWidgetSlug(appConfiguration),
      hidden: hasItems ? isGroupHidden : hidden,
      weight: sortedApps.findIndex(a => a.type === type),
      items: items
        ? items.map(({ enabled, appConfiguration, ...rest }) => ({
            ...rest,
            appConfiguration,
            checked: enabled,
            slug: getWidgetSlug(appConfiguration),
          }))
        : null,
      appConfiguration,
    };

    return acc;
  }, {});

  return groupLookup;
};

const getWidgetSlug = (appConfiguration: string): string | null => {
  const parsedConfig = JSON.parse(appConfiguration);

  if (!hasValue(parsedConfig)) return null;

  return parsedConfig.slug;
};

export default getAppsGroupLookup;
