import React, { useEffect } from 'react';
import { Body } from '~/components/atom/Typography';
import { WizardStepProps } from '~/components/organism/Wizard/context/WizardContext';
import useWizardStep from '~/hooks/useWizardStep';
import TEST_ID from './index.testid';
import RealworksHelpBlock from '../../Apps/Realworks/components/RealworksHelpBlock';

const text = {
  title: 'Installatie',
  body: (
    <>
      Loop door deze wizard om de app te installeren. In enkele stappen zet je
      de app aan en stel je de automatische opvolging (flows) in.
    </>
  ),
};

export const title = text.title;
export const id = 'GenericStart';

export type OutputType = {
  type: typeof id;
};
export const Component: React.FCC<WizardStepProps> = props => {
  const [, api] = useWizardStep(props.step.id);

  useEffect(() => {
    api.free({ type: id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Body>{text.body}</Body>
      <RealworksHelpBlock
        dataTestId={TEST_ID.TIP_BANNER}
        tipId="generic-wizard"
      />
    </>
  );
};

export default {
  title,
};
