import React from 'react';
import { animated } from 'react-spring';
import type { ActivityV2 } from '../../../types';
import BaseEventCard from '../../BaseEventCard';
import Block from '../../Block';
import SendEmail from '../../Cards/SendEmail';
import Task from '../../Cards/Task';
import TimelineWrapper from '../../TimelineWrapper';
import type { EventTimelineContact } from '../../..';
import { Task as TaskDetailProps } from '~/components/page/Tasks/types';

type Props = {
  data: ActivityV2;
  style: any;
  contact?: EventTimelineContact;
  onOpenTaskModal?: (task: TaskDetailProps | null) => void;
};

const Activity: React.FC<Props> = ({
  data,
  style,
  contact,
  onOpenTaskModal,
}) => {
  const commonProps = {
    id: data.id,
    key: data.id,
  };

  if (!data) return null;

  if (contact && data.__typename === 'Event_Contact_Email') {
    return (
      <animated.div {...commonProps} style={style}>
        <TimelineWrapper icon={data.icon}>
          <SendEmail {...data} contact={contact} />
        </TimelineWrapper>
      </animated.div>
    );
  }

  if (data.__typename === 'Task_Contact') {
    return (
      <animated.div {...commonProps} style={style}>
        <TimelineWrapper icon={data.icon}>
          <Task {...data} onOpenTaskModal={onOpenTaskModal} />
        </TimelineWrapper>
      </animated.div>
    );
  }

  return (
    <animated.div {...commonProps} style={style}>
      <TimelineWrapper icon={data.icon}>
        <BaseEventCard
          {...data}
          body={
            <>
              {data.body.map(block => (
                <Block {...block} key={block.id} />
              ))}
            </>
          }
          dataTestId={`${data.__typename}_Card`}
        />
      </TimelineWrapper>
    </animated.div>
  );
};

export default Activity;
