import React from 'react';
import Icon, { type IconType } from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import useTooltipLayer from '~/hooks/useTooltipLayer';
import type { ThemeColor } from '~/theme/System/tokens/colorPalette';

export type Props = {
  dataTestId?: string;
  label: React.ReactNode | string;
  tooltip: {
    message: string;
  };
  icon: {
    name: IconType;
    color: ThemeColor;
  };
};

const CellWithTooltip: React.FCC<Props> = ({ label, tooltip, icon }) => {
  const tooltipProps = useTooltipLayer({ tooltipMessage: tooltip.message });

  return (
    <JustificationContainer align="center" gap="xxs">
      <Icon
        name={icon.name || 'exclamation'}
        color={icon.color}
        strokeWidth={2.5}
        {...tooltipProps}
      />

      {label}
    </JustificationContainer>
  );
};

export default CellWithTooltip;
