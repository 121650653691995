import type { RelativeMaps } from '../../../Builder/utils/getRelativeMaps';
import React, { useEffect } from 'react';
import { clone, remove } from 'ramda';
import styled, { css } from 'styled-components';
import {
  FlowAction,
  ConnectorOperator,
  FlowData___PrimitiveInputFragment,
  FlowData___PrimitiveListInputFragment,
} from '~/graphql/types';
import Icon from '~/components/atom/Icon';
import ConnectorComponent from './components/ConditionGroup/components/ConnectorComponent';
import isActiveConditionSubExpression from './utils/isActiveConditionSubExpression';
import TextButton from '~/components/atom/TextButton';
import { CONDITION_ARROW_TRAIL } from '../../../Builder/constants/zIndexes';
import type { ConditionExpression } from './types';
import isEmptyConditionExpression from './utils/isEmptyConditionExpression';
import getConditionGroupElements from './utils/getConditionGroupElements';
import getSubConditions from './utils/getSubConditions';
import DeleteExpressionContainer from './components/DeleteExpressionContainer';
import SubConditions from './components/SubConditions';
import { useRecoilState } from 'recoil';
import activeSelectorState from '../../../../state/conditionEditor';

export type Props = {
  dataTestId?: string;
  /** Type of action this is rendered in */
  action: FlowAction;
  /** FlowBlueprintActionId - used to make pointer to self */
  actionId: string;
  /** Current condition to be rendered */
  conditionExpression: ConditionExpression;
  /** onChange handler - updating the condition whenever a change happened */
  onChange: (nextConditionExpression: ConditionExpression) => void;
  /** Outer container which will never be exceeded */
  outerContainer: HTMLElement | null;
  /** Assumes the first condition as the trigger */
  hasTriggers: boolean;
  /** If hasTriggers is set to true, this limits the max amount of triggers */
  limitTriggers?: number;

  /** Flow builder data maps */
  maps: RelativeMaps;

  /** Input primitives for this condition editor */
  inputPrimitives: Array<FlowData___PrimitiveInputFragment>;
  /** Input list primitives for this condition editor */
  inputListPrimitives: Array<FlowData___PrimitiveListInputFragment>;

  /** Intl of the different fields in the condition editor */
  textObject: {
    newTrigger: React.ReactNode;
    emptyTrigger: React.ReactNode;
    newCondition: React.ReactNode;
    emptyCondition: React.ReactNode;
    addValue: React.ReactNode;
  };
};

export const text = {
  addCondition: 'Conditie toevoegen',
  refineCondition: 'Conditie verfijnen',
  done: 'Klaar',
  cancel: 'Afbreken',
  removeBlock: 'Stap verwijderen',
  triggerRowLabel: 'Trigger',
  conditionRowLabel: 'Condities',
};

const ConditionEditorV2: React.FCC<Props> = ({
  onChange,
  actionId,
  action,
  conditionExpression,
  outerContainer,
  hasTriggers,
  limitTriggers,
  textObject,
  inputPrimitives,
  inputListPrimitives,
  maps,
}) => {
  const [activeSelector, setActiveSelector] =
    useRecoilState(activeSelectorState);

  useEffect(() => {
    /** Initial setting of the active selector */
    if (!isEmptyConditionExpression(conditionExpression)) return;

    setActiveSelector({
      conditionSubExpressionIdx: 0,
      conditionGroupIdx: 0,
      conditionIdx: 0,
      argumentIdx: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { conditionSubExpression, connector: conditionExpressionConnector } =
    conditionExpression;

  const triggerLimitExceeded =
    limitTriggers != null
      ? limitTriggers <= conditionSubExpression.length
      : false;

  const setConditionExpression = (
    nextConditionExpression: ConditionExpression,
  ) => {
    if (nextConditionExpression.conditionSubExpression.length !== 0) {
      return onChange(nextConditionExpression);
    }

    /** We removed the last conditionSubExpression - create empty one */
    onChange({
      conditionSubExpression: [
        {
          conditionGroups: [
            { conditions: [null], connector: ConnectorOperator.And },
          ],
          connector: ConnectorOperator.And,
        },
      ],
      connector: conditionExpressionConnector,
    });

    setActiveSelector({
      conditionSubExpressionIdx: 0,
      conditionGroupIdx: 0,
      conditionIdx: 0,
    });
  };

  const conditionSubExpressionElements = conditionSubExpression.map(
    (
      { conditionGroups, connector: conditionSubExpressionConnector },
      conditionSubExpressionIdx,
    ) => {
      const expressionKey = `${conditionSubExpression.length}-${conditionSubExpressionIdx}`;
      const triggerCondition = conditionGroups[0]?.conditions[0];

      const conditionGroupElements = getConditionGroupElements({
        conditionGroups,
        expressionKey,
        hasTriggers,
        conditionSubExpressionIdx,
        conditionExpressionConnector,
        conditionSubExpression,
        outerContainer,
        maps,
        action,
        actionId,
        inputListPrimitives,
        inputPrimitives,
        textObject,
        triggerCondition,
        setConditionExpression,
      });

      const activeConditionSubExpression = isActiveConditionSubExpression({
        activeSelector,
        argumentId: {
          conditionSubExpressionIdx,
        },
      });

      if (hasTriggers) {
        /** We render them expecting the first one to be a trigger */
        const triggerCondition = conditionGroups[0].conditions[0];
        const trigger = conditionGroupElements[0];

        const subConditionsOpts = (() => {
          if (triggerCondition == null) return { canHaveSubconditions: false };

          const subConditions = getSubConditions({
            conditionGroupElements,
            conditionSubExpressionConnector,
            conditionSubExpression,
            conditionSubExpressionIdx,
            setConditionExpression,
            conditionExpressionConnector,
            setActiveSelector,
          });

          return { canHaveSubconditions: true, subConditions };
        })();

        return (
          <ConditionSubExpressionContainer
            key={conditionSubExpressionIdx}
            $activeConditionSubExpression={activeConditionSubExpression}
          >
            <TriggerContainer>{trigger}</TriggerContainer>
            {subConditionsOpts.canHaveSubconditions && (
              <SubConditions
                newConditionLabel={textObject.newCondition}
                subConditions={subConditionsOpts.subConditions ?? []}
                conditionSubExpression={conditionSubExpression}
                conditionSubExpressionIdx={conditionSubExpressionIdx}
                conditionExpressionConnector={conditionExpressionConnector}
                setConditionExpression={setConditionExpression}
                setActiveSelector={setActiveSelector}
              />
            )}
            <DeleteExpressionContainer
              onClick={e => {
                e.preventDefault();

                setActiveSelector(null);
                setConditionExpression({
                  conditionSubExpression: remove(
                    conditionSubExpressionIdx,
                    1,
                    conditionSubExpression,
                  ),
                  connector: conditionExpressionConnector,
                });
              }}
            >
              <Icon name="trashcan" />
            </DeleteExpressionContainer>
          </ConditionSubExpressionContainer>
        );
      }

      return (
        <ConditionSubExpressionContainer
          key={conditionSubExpressionIdx}
          $activeConditionSubExpression={activeConditionSubExpression}
        >
          {conditionGroupElements}
          <DeleteExpressionContainer
            onClick={e => {
              e.preventDefault();

              setActiveSelector(null);
              setConditionExpression({
                conditionSubExpression: remove(
                  conditionSubExpressionIdx,
                  1,
                  conditionSubExpression,
                ),
                connector: conditionExpressionConnector,
              });
            }}
          >
            <Icon name="trashcan" />
          </DeleteExpressionContainer>
        </ConditionSubExpressionContainer>
      );
    },
  );

  const elements = conditionSubExpressionElements.map((item, index) => {
    const isLast = conditionSubExpressionElements.length === index + 1;

    return (
      <GroupContainer key={item.key}>
        <ArrowTrail>
          <Arrow />
        </ArrowTrail>
        {item}
        {!isLast && (
          <ConditionSelect>
            <ConnectorComponent
              connector={conditionExpressionConnector}
              onChange={nextValue => {
                setConditionExpression({
                  conditionSubExpression,
                  connector: nextValue,
                });
              }}
              editable={true}
              label={{
                and: 'en',
                or: 'of',
              }}
            />
          </ConditionSelect>
        )}
        {isLast && !triggerLimitExceeded && (
          <TextButton
            label={textObject.newTrigger}
            icon="plus"
            padding={[null]}
            margin={['l', null, null, null]}
            size="large"
            onClick={() => {
              const nextConditionSubExpression = clone(conditionSubExpression);

              nextConditionSubExpression.push({
                conditionGroups: [
                  {
                    conditions: [null],
                    connector: ConnectorOperator.And,
                  },
                ],
                connector: ConnectorOperator.And,
              });

              setConditionExpression({
                conditionSubExpression: nextConditionSubExpression,
                connector: conditionExpressionConnector,
              });

              setActiveSelector({
                conditionSubExpressionIdx:
                  nextConditionSubExpression.length - 1,
                conditionGroupIdx: 0,
              });
            }}
          />
        )}
      </GroupContainer>
    );
  });

  return <div style={{ minHeight: '150px' }}>{elements}</div>;
};

const GroupContainer = styled.div(
  ({ theme }) => css`
    position: relative;
    padding-left: ${theme.space('s')};
    padding-bottom: ${theme.space('xxxxl')};
  `,
);

const ConditionSelect = styled.div(
  () => css`
    position: absolute;
    bottom: 8px;
    left: -8px;
    z-index: ${CONDITION_ARROW_TRAIL + 1};
  `,
);

const ArrowTrail = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: -3px;
    left: 0px;
    transition: transform 200ms ease-out;
    z-index: ${CONDITION_ARROW_TRAIL};
    font-weight: ${theme.fontWeight('medium')};
    height: 32px;
    width: 23px;
  `,
);

const Arrow = styled.div<{}>(
  ({ theme }) => css`
    width: calc(100% - 3px);
    height: calc(100% - 3px);
    border-width: 0px 0px 2px 2px;
    border-style: dashed;
    border-color: ${theme.color('tertiary')};

    border-bottom-left-radius: 6px;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      border-style: solid;
      border-width: 4px 0px 4px 8px;
      border-color: transparent transparent transparent
        ${theme.color('tertiary')};
    }
  `,
);

const ConditionSubExpressionContainer = styled.div<{
  $activeConditionSubExpression: boolean;
}>(
  ({ $activeConditionSubExpression, theme }) => css`
    position: relative;
    background-color: ${theme.color('white')};
    margin-bottom: 8px;
    border-radius: ${theme.getTokens().border.radius.base};
    transition: all 200ms ease-out;

    box-shadow: ${theme.boxShadow('sleek')};

    ${$activeConditionSubExpression &&
    css`
      outline: 1px solid ${theme.color('info')};
    `};
  `,
);

const TriggerContainer = styled.div(() => css``);

export default ConditionEditorV2;
