import { isNil, pipe } from 'ramda';
import React, { useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { validImageTypes } from '~/util/uploadS3Image';
import AvatarWithUploader from '../AvatarWithUploader';
import DragAndDropContainer from '../DragAndDropContainer';
import ImageUploadTextButton from '../ImageUploadTextButton';
import ProgressBar from '~/components/molecule/ProgressBar';
import ImageEditorModal from '../ImageEditorModal';

const text = {
  uploading: 'Uploaden...',
  info: 'Je beeld mag maximaal 10mb groot zijn. Geaccepteerde bestanden zijn JPG, JPEG, PNG, GIF, WEBP en SVG.',
};

export type Props = {
  imageUrl: string | null | undefined;
  onUpload: (files: FileList) => void;
  /** When onDelete is not provided, it means the image is mandatory and can only be reuploaded */
  onDelete?: () => void;
  imagePlaceholderText?: string;
  uploading: boolean;
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  disabled?: boolean;
  imagePreview?: boolean;
};

const ImageUploadComponent: React.FCC<Props> = ({
  dataTestId,
  imageUrl,
  imagePlaceholderText,
  uploading,
  onDelete,
  onUpload,
  direction = 'row',
  disabled = false,
  imagePreview = true,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [fileList, setFileList] = useState<FileList | null>(null);
  const [editImage, setEditImage] = useState<boolean>(false);

  const onFileSelected = useCallback(
    (files: FileList) => {
      if (!uploading) {
        setFileList(files);
        setEditImage(true);
      }
    },
    [uploading],
  );

  const onReset = pipe(
    // Reset file list
    () => setFileList(null),
    // Reset edit image
    () => setEditImage(false),
    // Reset the value of the actual HTML element so next clicks allow the user to select new image
    () => {
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    },
  );

  const onConfirm = pipe((file: File) => {
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);

    // onUpload expects a FileList but from our modal we only receive a single image
    // So we put the image in a file list and emit that upwards.
    onUpload(dataTransfer.files);
  }, onReset);

  const showDeleteButton = onDelete && !uploading && !isNil(imageUrl);

  return (
    <>
      <DragAndDropContainer
        handleDrop={files => onFileSelected(files)}
        uploading={uploading}
        acceptedFileTypes={validImageTypes}
      >
        <Container $direction={direction}>
          <TextContainer>
            <ImageUploadTextButton
              onUpload={onFileSelected}
              uploading={uploading}
              disabled={disabled}
              ref={inputRef}
            />

            {uploading ? <ProgressBar loading={uploading} /> : text.info}
          </TextContainer>

          {imagePreview && (
            <StyledAvatarWithUploader
              onUpload={onFileSelected}
              onDelete={onDelete}
              simpleImage
              edit={!uploading && !disabled}
              dataTestId={dataTestId}
              imageUrl={imageUrl}
              showDeleteButton={showDeleteButton}
              imagePlaceholderText={
                uploading ? text.uploading : imagePlaceholderText
              }
              style={{
                maxWidth: 'unset',
                width: '100%',
                height: '130px',
              }}
            />
          )}
        </Container>
      </DragAndDropContainer>
      {fileList && editImage && (
        <ImageEditorModal
          fileList={fileList}
          onClose={onReset}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};

const Container = styled.div<{ $direction?: Props['direction'] }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: ${({ $direction }) => $direction};
  justify-content: space-between;

  ${({ theme }) => css`
    border: ${theme.getTokens().border.width.s} solid ${theme.color('tertiary')};
    padding: ${theme.space('s')};
    border-radius: ${theme.getTokens().border.radius.base};

    ${theme.mq.lessThan('tabletLandscape')`
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
      padding-bottom: ${theme.space('xl')};
    `}

    &:hover {
      border: ${theme.getTokens().border.width.s} solid ${theme.color('text')};
    }
  `};
`;

const TextContainer = styled.div<{}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => css`
    padding: ${theme.space('l')};
  `};
`;

const StyledAvatarWithUploader = styled(AvatarWithUploader)<{}>`
  flex-basis: 200px;
  margin-right: 0;
`;

export default ImageUploadComponent;
