import { isNil } from 'ramda';
import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '~/components/atom/Icon';

export type Props = {
  type: 'true' | 'false';

  /** Used to make the width the same for both labels */
  minWidth?: string;

  /** If the label is clickable */
  onClick?: () => void;
};

const text = {
  trueChild: 'Waar',
  falseChild: 'Niet waar',
};

const TrueOrFalseLabel: React.FCC<Props> = ({ type, minWidth, onClick }) => {
  const iconName = type === 'true' ? 'check' : 'error';
  const iconColor = type === 'true' ? 'success' : 'danger';
  const labelText = type === 'true' ? text.trueChild : text.falseChild;

  return (
    <Container
      $minWidth={minWidth}
      $hasClick={!isNil(onClick)}
      onClick={onClick}
    >
      <Icon name={iconName} color={{ group: iconColor }} /> {labelText}
    </Container>
  );
};

const Container = styled.span<{
  $minWidth?: string;
  $hasClick: boolean;
}>(
  ({ theme, $minWidth, $hasClick }) => css`
    display: inline-flex;
    align-items: center;
    gap: ${theme.space('xxs')};
    padding: ${theme.space('xxs')} ${theme.space('xs')};
    border: 1px solid ${theme.color('tertiary', 'light')};
    border-radius: ${theme.getTokens().border.radius.s};
    font-size: ${theme.fontSize('base')};
    min-width: ${$minWidth || 'unset'};
    cursor: ${$hasClick ? 'pointer' : 'default'};
  `,
);

export default TrueOrFalseLabel;
