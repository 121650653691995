import type { Flow___ConditionFragment } from '~/graphql/types';
import type { Flow___ConditionPartial } from '../../types';

/**
 * Checks whether a partial condition is complete or not.
 */
const isCompleteCondition = (
  condition: Flow___ConditionPartial,
): condition is Flow___ConditionFragment => {
  for (const arg of condition.args) {
    if (arg == null) return false;
  }

  return true;
};

export default isCompleteCondition;
