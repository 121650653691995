import React from 'react';
import styled, { css } from 'styled-components';

type Props = {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

const DeleteExpressionContainer: React.FCC<Props> = ({ children, onClick }) => (
  <Container onClick={onClick}>{children}</Container>
);

const Container = styled.div(
  ({ theme }) => css`
    position: absolute;
    display: flex;
    justify-content: center;
    top: -10px;
    right: -10px;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    background-color: ${theme.color('white')};
    cursor: pointer;
    padding: ${theme.space('base')};
    transition: all 300ms ease-out;
    box-shadow: ${theme.boxShadow('base')};

    &:hover {
      color: ${theme.color('white')};
      background-color: ${theme.color('danger')};
      box-shadow: ${theme.boxShadow('s')};
    }
  `,
);

export default DeleteExpressionContainer;
